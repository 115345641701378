import React from 'react';
import Svg, { Path } from 'svgs';

const FatClear = ({ width, height, fill }) => {
  return (
    <Svg
      height={height || 24}
      width={width || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M20 6.91L17.09 4 12 9.09 6.91 4 4 6.91 9.09 12 4 17.09 6.91 20 12 14.91 17.09 20 20 17.09 14.91 12 20 6.91z" />
    </Svg>
  );
};

export default FatClear;
