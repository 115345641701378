import React, { useCallback, useState } from 'react';
import LocalizedStrings from 'react-localization';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import KebabMenu from '../../common/components/KebabMenu';
import Pencil from '../../common/components/icons/Pencil';
import Trash from '../../common/components/icons/Trash';
import ConfirmationModal from '../../common/components/ConfirmationModal';
import { deleteCoBrand } from '../admin-actions';

let strings = new LocalizedStrings({
  en: {
    deleteCoBrand: 'Delete',
    deleteCoBrandConfirm: 'Delete Co-Brand',
    deleteModalContent: 'Are you sure you want to delete this Co-Brand? This action cannot be undone.',
    deleteModalTitle: 'Delete {0}?',
    editCoBrand: 'Edit',
    showMenu: 'Show Menu',
  },
});

const CoBrand = ({ id, imageUrl, name, domains }) => {
  const dispatch = useDispatch();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [kebabMenuIsOpen, setKebabMenuIsIsOpen] = useState(false);

  const onDeleteCoBrand = useCallback(() => {
    dispatch(deleteCoBrand(id));
    setDeleteModalIsOpen(false);
  }, [dispatch, id]);

  return (
    <div className="container co-brand">
      <span className="co-brand__logo">
        <img alt={`${name} Logo`} src={imageUrl} height={32} />
      </span>
      <span className="co-brand__name">{name}</span>
      <span className="co-brand__domains">{domains.map(({ domain }) => `@${domain}`).join(', ')}</span>

      <span className="co-brand__kebab-menu">
        <KebabMenu
          buttonAriaLabel={strings.showMenu}
          menuOpened={kebabMenuIsOpen}
          onDisengage={() => setKebabMenuIsIsOpen(false)}
          onClick={() => setKebabMenuIsIsOpen(true)}
        >
          <Link className="button--icon menu-item" to={`/admin/co-branding/${id}`} aria-label={strings.editCoBrand}>
            <Pencil fill="#505D6F" />
            <span>{strings.editCoBrand}</span>
          </Link>
          <button
            className="button--icon menu-item"
            type="button"
            onClick={() => setDeleteModalIsOpen(true)}
            aria-label={strings.deleteCoBrand}
          >
            <Trash fill="#505D6F" />
            <span>{strings.deleteCoBrand}</span>
          </button>
        </KebabMenu>
        <ConfirmationModal
          isOpened={deleteModalIsOpen}
          buttonText={strings.deleteCoBrandConfirm}
          onClose={() => setDeleteModalIsOpen(false)}
          onConfirm={() => onDeleteCoBrand()}
          content={strings.deleteModalContent}
          titleText={strings.formatString(strings.deleteModalTitle, name)}
        />
      </span>
    </div>
  );
};

export default CoBrand;
