import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

let strings = new LocalizedStrings({
  en: {
    myProfile: 'My Profile',
    myResults: 'My Results',
    myFittingCodes: 'My Fitting Codes',
    settings: 'Settings',
  },
});

class ProfileLayout extends Component {
  render() {
    const { loggedInUser } = this.props;
    return (
      <div>
        <div className="results--sub-nav">
          <div className="results__sub-nav--left">
            <NavLink className="results__sub-nav--left__link" to="/profile" exact>
              {strings.myProfile}
            </NavLink>
            <NavLink className="results__sub-nav--left__link" to="/profile/my-results" strict>
              {strings.myResults}
            </NavLink>
            <NavLink className="results__sub-nav--left__link" to="/profile/my-fitting-codes" strict>
              {strings.myFittingCodes}
            </NavLink>
            {loggedInUser.get('type') === 'admin' ||
              (loggedInUser.get('type') === 'fitter' && (
                <NavLink className="results__sub-nav--left__link" to="/profile/settings" strict>
                  {strings.settings}
                </NavLink>
              ))}
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
  })),
  withRouter
)(ProfileLayout);
