import React, { Component } from 'react';
import { calculateIronStoppingPreference, resetCalculatedIronStoppingPreference } from './questionnaire-actions';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { connect as formikConnect } from 'formik';
import compose from 'lodash/flowRight';
import ValidationError from '../../common/forms/ValidationError';

export const strings = new LocalizedStrings({
  en: {
    currentLandingAngle: 'Current Landing Angle',
    recommendedLandingAngle: 'Recommended Landing Angle',
    recommendationText: 'Ballnamic Recommends',
    description: 'Find your recommended landing angle preference:',
    recommendationButtonText: 'Calculate',
    recommendations: 'Recommendations',
    calculationErrorLabel: 'Angle calculation error',
    couldNotCompleteYourRequest: 'Could not complete your request at this time. Please try again.',
    loading: 'Calculating...',
  },
});

class StoppingPowerRecommendation extends Component {
  componentDidUpdate(prevProps) {
    const { calculatedIronStop, dispatch } = this.props;
    const { values, setFieldValue } = this.props.formik;
    const {
      iron_ball_speed,
      iron_launch_angle,
      iron_spin_rate,
      iron_distance,
      iron_spin_type,
      iron_launch_type,
    } = values;

    if (calculatedIronStop && calculatedIronStop !== prevProps.calculatedIronStop) {
      setFieldValue('iron_stop_pref_calc', calculatedIronStop.get('iron_stop_pref'));
      setFieldValue('iron_stop_pref', calculatedIronStop.get('iron_stop_pref'));
    }

    if (
      calculatedIronStop &&
      (iron_spin_rate !== prevProps.formik.values.iron_spin_rate ||
        iron_launch_angle !== prevProps.formik.values.iron_launch_angle ||
        iron_ball_speed !== prevProps.formik.values.iron_ball_speed ||
        iron_distance !== prevProps.formik.values.iron_distance ||
        iron_spin_type !== prevProps.formik.values.iron_spin_type ||
        iron_launch_type !== prevProps.formik.values.iron_launch_type)
    ) {
      dispatch(resetCalculatedIronStoppingPreference());
    }
  }

  getStoppingPowerRecommendation() {
    const { dispatch } = this.props;
    const { typical_alt, typical_temp, iron_ball_speed, iron_launch_angle, iron_spin_rate } = this.props.formik.values;
    dispatch(
      calculateIronStoppingPreference({
        typical_alt,
        typical_temp,
        iron_spin_rate,
        iron_launch_angle,
        iron_ball_speed,
      })
    );
  }

  renderError() {
    const { error } = this.props;
    if (!error) {
      return null;
    }
    return (
      <ValidationError aria-label={strings.calculationErrorLabel}>
        {strings.couldNotCompleteYourRequest}
      </ValidationError>
    );
  }

  renderRecommendations() {
    const { gettingStoppingPowerRecommendation, calculatedIronStop } = this.props;

    if (calculatedIronStop) {
      const recommendation = calculatedIronStop.get('description');
      const actual_land = calculatedIronStop.get('actual_land');
      const rec_land = calculatedIronStop.get('rec_land');

      return (
        <div className="recommendations-wrapper" aria-label={strings.recommendations}>
          <h4>
            {strings.currentLandingAngle}: <span className="rec-value">{actual_land}</span>
          </h4>
          <h4>
            {strings.recommendedLandingAngle}: <span className="rec-value">{rec_land}</span>
          </h4>
          <h4>
            {strings.recommendationText}: <span className="rec-value">{recommendation}</span>
          </h4>
        </div>
      );
    } else {
      return (
        <>
          <div className="helper-text">{strings.description}</div>
          <div>
            <button
              aria-label={strings.recommendationButtonText}
              type="button"
              className="button button--color--primary"
              disabled={!!gettingStoppingPowerRecommendation}
              onClick={() => this.getStoppingPowerRecommendation()}
            >
              {gettingStoppingPowerRecommendation ? (
                <span aria-label={strings.loading}>{strings.loading}</span>
              ) : (
                strings.recommendationButtonText
              )}
            </button>
          </div>
          {this.renderError()}
        </>
      );
    }
  }

  render() {
    return <div className="label-component">{this.renderRecommendations()}</div>;
  }
}

export default compose(
  connect(state => {
    return {
      values: state.questionnaire.values,
      gettingStoppingPowerRecommendation: state.questionnaire.calculateIronStoppingPreference.get('loading'),
      error: state.questionnaire.calculateIronStoppingPreference.get('error'),
      calculatedIronStop: state.questionnaire.calculatedIronStop,
    };
  }),
  formikConnect
)(StoppingPowerRecommendation);
