import React, { useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NoCoBrands from '../../common/components/icons/NoCoBrands';
import { getCoBrands, resetDeleteCoBrand } from '../admin-actions';
import CoBrand from './CoBrand';

const strings = new LocalizedStrings({
  en: {
    addCoBrand: 'Add Co-Brand',
    coBrands: 'Co-Brands',
    noCoBrands: "You haven't added any Co-Brands yet.",
  },
});

const CoBranding = () => {
  const dispatch = useDispatch();
  const coBrands = useSelector(({ admin }) => admin.coBrands.toJS());
  const deleteCoBrand = useSelector(({ admin }) => admin.deleteCoBrand);

  useEffect(() => {
    dispatch(getCoBrands());
  }, [dispatch]);

  useEffect(() => {
    if (deleteCoBrand.get('loaded')) {
      dispatch(getCoBrands());
      dispatch(resetDeleteCoBrand());
    }
  }, [deleteCoBrand, dispatch]);

  return (
    <div className="container">
      <div className="co-brands-header__container">
        <h1>{strings.coBrands}</h1>
        <div className="actions">
          {Boolean(coBrands.length) && (
            <Link to="/admin/co-branding/add" className="button button--color--primary">
              {strings.addCoBrand}
            </Link>
          )}
        </div>
      </div>

      {coBrands.map(({ id, name, svg_src, domains }) => (
        <CoBrand key={id} name={name} imageUrl={svg_src} domains={domains} id={id} />
      ))}

      {!Boolean(coBrands.length) && (
        <div className="co-brands__empty">
          <NoCoBrands />
          <div>{strings.noCoBrands}</div>
          <Link to="/admin/co-branding/add" className="button button--color--primary">
            {strings.addCoBrand}
          </Link>
        </div>
      )}
    </div>
  );
};

export default CoBranding;
