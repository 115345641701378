import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from '../../../common/forms/Slider';
import numeral from 'numeral';
import TooltipIcon from '../../../common/components/TooltipIcon';
import LocalizedStrings from 'react-localization';
import { connect as formikConnect } from 'formik';
import compose from 'lodash/flowRight';

const strings = new LocalizedStrings({
  en: {
    ballSpeed: 'Ball Speed (mph)',
    speedUnits: 'mph',
    launchAngle: 'Launch Angle (deg)',
    spinRate: 'Spin Rate (rpm)',
    spinRateUnits: 'rpm',
    ballSpeedTooltip: `The ball speed off your irons influences the peak height and carry distance. Knowing your iron ball speed will help us curate balls that may work well for your iron play. (We’ve seen 7-iron carry change by up to 8 yards in our testing).`,
    launchAngleTooltip: `With so many iron models to choose from today, launch angle can vary drastically. Your iron fitting will determine which clubs work best for you; Ballnamic is designed to attempt to help you identify balls that may work well for your game, and possibly optimize your desired stopping power.`,
    spinRateTooltip: `A lot of players struggle with stopping power because some of today’s 7-irons don’t produce enough spin. If you struggle with spin generation, the Ballnamic fitting engine will find balls that fly higher and land steeper for your swing and clubs. (Our robotic testing has found that balls can vary in spin rate by over 1400-rpm with a 7-iron!)`,
  },
});

class IronConditionsKnown extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
  };

  render() {
    const { submittingCalcLaunchConditions, isActive } = this.props;
    const { values, setFieldValue } = this.props.formik;

    return (
      <div className="questionnaire-blocks__container">
        <Slider
          label={strings.ballSpeed}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>{strings.ballSpeedTooltip}</TooltipIcon>
          }
          displayValue={`${values.iron_ball_speed}${strings.speedUnits}`}
          sliderProps={{
            tabIndex: isActive ? undefined : -1,
            disabled: submittingCalcLaunchConditions,
            min: 60,
            max: 150,
            step: 1,
            marks: {
              60: <div className="slider--mark">60</div>,
              150: <div className="slider--mark">150</div>,
            },
            value: values.iron_ball_speed,
            onChange: value => setFieldValue('iron_ball_speed', value),
          }}
        />
        <Slider
          label={strings.launchAngle}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>{strings.launchAngleTooltip}</TooltipIcon>
          }
          displayValue={`${numeral(values.iron_launch_angle).format('0.0')}˚`}
          sliderProps={{
            tabIndex: isActive ? undefined : -1,
            disabled: submittingCalcLaunchConditions,
            min: 10,
            max: 25,
            step: 0.5,
            marks: {
              10: <div className="slider--mark">10</div>,
              25: <div className="slider--mark">25</div>,
            },
            value: values.iron_launch_angle,
            onChange: value => setFieldValue('iron_launch_angle', value),
          }}
        />
        <Slider
          label={strings.spinRate}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>{strings.spinRateTooltip}</TooltipIcon>
          }
          displayValue={`${numeral(values.iron_spin_rate).format('0,0')}${strings.spinRateUnits}`}
          sliderProps={{
            tabIndex: isActive ? undefined : -1,
            disabled: submittingCalcLaunchConditions,
            min: 4000,
            max: 8500,
            step: 50,
            marks: {
              4000: <div className="slider--mark">4000</div>,
              8500: <div className="slider--mark">8500</div>,
            },
            value: values.iron_spin_rate,
            onChange: value => setFieldValue('iron_spin_rate', value),
          }}
        />
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    submittingCalcLaunchConditions: state.questionnaire.calculateIronLaunchConditions.get('loading'),
  })),
  formikConnect
)(IronConditionsKnown);
