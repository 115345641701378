import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class ScrollToTop extends Component {
  static propTypes = {
    shouldScrollToTop: PropTypes.func,
  };

  static defaultProps = {
    shouldScrollToTop: () => true,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.shouldScrollToTop(prevProps.location.pathname, this.props.location.pathname)) {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(ScrollToTop);
