import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import LocalizedStrings from 'react-localization';
import Button from '../../../common/components/Button';
import uuid from 'uuid';

export const strings = new LocalizedStrings({
  en: {
    cancel: 'Cancel',
    applyFilter: 'Apply Filter',
    noValue: '(No Value)',
    allAccounts: 'All Accounts',
    fittersFilterForm: 'Fitters filter form',
    selectAccount: 'Select Account',
  },
});

const allAccountsStringValue = uuid.v4();

const FittersFilterForm = ({ fitters, onCancel, initialValues, onApply }) => {
  const accountOptions = useMemo(() => {
    const options = [];
    if (!fitters?.filtersList) {
      return [];
    }

    fitters.filtersList.forEach(filter => {
      if (!filter && !options.find(option => option.value === '')) {
        options.push({ value: '', label: strings.noValue });
      } else if (filter && !options.find(option => option.value === filter)) {
        options.push({ value: filter, label: filter });
      }
    });
    return options;
  }, [fitters]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        if (values.account === allAccountsStringValue) {
          onApply(Object.assign({}, values, { account: null }));
        } else {
          onApply(values);
        }
      }}
    >
      {() => (
        <Form aria-label={strings.fittersFilterForm} className="tooltip-form">
          <Field as="select" name="account" aria-label={strings.selectAccount}>
            <option aria-label={strings.allAccounts} value={allAccountsStringValue}>
              {strings.allAccounts}
            </option>
            {accountOptions
              .sort((a, b) => {
                return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1;
              })
              .map(accountOption => (
                <option key={accountOption.label} value={accountOption.value} aria-label={accountOption.label}>
                  {accountOption.label}
                </option>
              ))}
          </Field>
          <div className="tooltip-button__wrapper">
            <Button aria-label={strings.cancel} onClick={onCancel} buttonStyle="text" type="button">
              {strings.cancel}
            </Button>
            <Button aria-label={strings.applyFilter} type="submit">
              {strings.applyFilter}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

FittersFilterForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  fitters: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({ account: PropTypes.string }).isRequired,
};

export default FittersFilterForm;
