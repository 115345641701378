import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import ArrowRightIcon from '../common/components/icons/ArrowRightVariant';
import { withRouter } from 'react-router';

const ArticleItem = props => {
  const { article, location } = props;

  return (
    <div className="article-item">
      <h1 className="article-item__title">
        <Link
          to={{
            pathname: `/library/articles/${article.get('slug')}`,
            state: location.state,
          }}
        >
          {article.get('title')}
        </Link>
      </h1>
      <p className="article-item__preview">{article.get('summary')}</p>
      <Link
        className="article-item__read-more-link"
        to={{
          pathname: `/library/articles/${article.get('slug')}`,
          state: location.state,
        }}
      >
        <span>Read More</span>
        <span>
          <ArrowRightIcon />
        </span>
      </Link>
    </div>
  );
};

ArticleItem.propTypes = {
  article: PropTypes.instanceOf(Map),
};

export default withRouter(ArticleItem);
