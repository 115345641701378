import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { dismissAnnouncement, getActiveAnnouncement } from '../lib/contentful/contentful-actions';
import CloseIcon from '../common/components/icons/Clear';

function Announcement({ dispatch, activeAnnouncement, dismissedAnnouncements }) {
  useEffect(() => {
    dispatch(getActiveAnnouncement());
  }, [dispatch]);

  function urlify(text) {
    if (text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return '<a style="color:white" target="_blank" rel="noopener noreferrer" href="' + url + '">' + url + '</a>';
      });
    }
    return '';
  }
  return (
    activeAnnouncement &&
    !dismissedAnnouncements.includes(activeAnnouncement.getIn(['sys', 'id'])) && (
      <div className="announcement">
        <div
          className="announcement-text"
          dangerouslySetInnerHTML={{ __html: urlify(activeAnnouncement.getIn(['fields', 'announcement'])) }}
        ></div>

        <button
          type="button"
          onClick={() => {
            dispatch(dismissAnnouncement(activeAnnouncement.getIn(['sys', 'id'])));
          }}
          aria-label="Close"
        >
          <CloseIcon fill="white" />
        </button>
      </div>
    )
  );
}

export default connect(state => ({
  activeAnnouncement: state.contentful.activeAnnouncement,
  dismissedAnnouncements: state.contentful.dismissedAnnouncements,
}))(Announcement);
