import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Slider from '../../common/forms/Slider';
import { setQuestionnaireValues, calculateIronLaunchConditions } from './questionnaire-actions';
import LocalizedStrings from 'react-localization';
import compose from 'lodash/flowRight';
import { connect as formikConnect } from 'formik';
import IronLaunchConditions from './IronLaunchConditions';
import ArrowRight from '../../common/components/icons/ArrowRight';
import ArrowLeft from '../../common/components/icons/ArrowLeft';
import TooltipIcon from '../../common/components/TooltipIcon';
import { calculateLaunchConditionsIfNeeded } from './utils';
import StoppingPowerRecommendation from './StoppingPowerRecommendation';
import pick from 'lodash/pick';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    sevenIronDetails: '7-Iron Details',
    sixIronDetails: '6-Iron Details',
    enterSevenIronDetails: 'Enter your 7-iron details here',
    enterSixIronDetails: 'Enter your 6-iron details here',
    stoppingPowerPreference: 'Stopping Power Preference',
    workabilityPreference: 'Workability Preference',
    flierPreventionImportance: 'Flier Prevention Importance',
    lowest: 'Shallow\nLanding\nAngle',
    belowAverage: 'Moderately Shallow\nLanding Angle',
    average: 'Neutral Landing Angle',
    aboveAverage: 'Moderately Steep\nLanding Angle',
    highest: 'Steep\nLanding\nAngle',
    leastCurve: 'Least Curve',
    someWorkability: 'Some Workability',
    mostWorkability: 'Most Workability',
    flierMinimum: 'Not\nImportant',
    flierModerate: 'Moderate Importance',
    flierMaximum: 'High\nImportance',
    stoppingPowerTooltip: `Balls can fly differently off of drivers versus irons. The Ballnamic algorithm will curate a ball that can get you more stopping power and a steeper landing angle – or – reduce the landing angle if you tend to flight the ball too high or play on softer greens.`,
    stoppingPowerSubtext: `If you prioritize stopping power, consider selecting a steeper landing angle preference. Conversely, a shallower trajectory preference may be more appropriate on soft greens.`,
    workabilityTooltip: `Our testing process attempts to identify differences in how balls may curve. If shaping your irons is important to you, the Ballnamic fitting engine will attempt to identify balls that may provide you with the ability to enhance your creativity. Or, if you just want help hitting it straighter, the Ballnamic application strives to project balls that may help reduce curve relative to other balls.`,
    workabilitySubtext: `If you want a ball with more workability, you may sacrifice how it performs in the wind.`,
    flierPreventionSubtext: `How important is finding a ball that reduces fliers? This may be a high priority if you play in wet conditions or experience fliers frequently.`,
    flierPreventionTooltip: `With short irons, a “flier” is a shot that flies further and more erratically as a result of decreased backspin. It’s typically caused by grass or water between the ball and clubface at impact. We’ve seen ball performance vary drastically in flier conditions in the Ballnamic testing. With a poor performing ball, a typical pitching wedge shot that generates 9,000 rpm of spin can plummet to 5,000 rpm, causing inconsistent distance control.`,
  },
});

const stoppingPowerLabelMap = {
  0: strings.lowest,
  1: strings.belowAverage,
  2: strings.average,
  3: strings.aboveAverage,
  4: strings.highest,
};

const workabilityLabelMap = {
  0: strings.leastCurve,
  2: strings.someWorkability,
  4: strings.mostWorkability,
};

const flierPreventionLabelMap = {
  0: strings.flierMinimum,
  1: strings.flierModerate,
  2: strings.flierMaximum,
};

class IronDetails extends Component {
  state = {
    displayedModal: null,
  };

  componentDidUpdate(prevProps) {
    const { iron_distance, iron_spin_type, iron_launch_type } = this.props.formik.values;
    if (
      iron_distance !== prevProps.formik.values.iron_distance ||
      iron_spin_type !== prevProps.formik.values.iron_spin_type ||
      iron_launch_type !== prevProps.formik.values.iron_launch_type
    ) {
      this.calculateIronLaunchConditions();
    }

    const { loggedInUser } = this.props;
    const isSixIronFitting = loggedInUser.get('six_iron_fitting');
    if (prevProps.loggedInUser.get('six_iron_fitting') !== isSixIronFitting) {
      this.props.formik.setFieldValue('iron_distance', 145);
      this.props.formik.setFieldValue('iron_spin_type', 'mid');
      this.props.formik.setFieldValue('iron_launch_type', 'mid');
    }
  }

  calculateIronLaunchConditions() {
    const { dispatch, formik } = this.props;
    dispatch(setQuestionnaireValues(formik.values));
    dispatch(
      calculateIronLaunchConditions(
        pick(formik.values, [
          'iron_distance',
          'iron_spin_type',
          'iron_launch_type',
          'driver_ball_speed',
          'driver_launch_angle',
          'driver_spin_rate',
          'typical_alt',
          'typical_temp',
        ])
      )
    );
  }

  segment_iron_post(){
    const { formik: { values} } = this.props;
    const sp_clean = stoppingPowerLabelMap[values.iron_stop_pref].replace("\n"," ");
    const w_clean = workabilityLabelMap[values.iron_workability_pref];
    const fp_clean = flierPreventionLabelMap[values.iron_flier_imp].replace("\n", " ");
    var rla_clean = null; 
    if(values.iron_stop_pref_calc){
      rla_clean = stoppingPowerLabelMap[values.iron_stop_pref_calc];
    }
    var iLaunchAnglePost = values.iron_launch_type.toString();
    iLaunchAnglePost = iLaunchAnglePost.charAt(0).toUpperCase() + iLaunchAnglePost.slice(1);
    var iSpinPost = values.iron_spin_type.toString();
    iSpinPost = iSpinPost.charAt(0).toUpperCase() + iSpinPost.slice(1);
    window.analytics.track("Player Profile Irons",{
      fitting_id: null,
      carry_distance: values.iron_distance,
      launch_angle: iLaunchAnglePost,
      launch_angle_degree: values.iron_launch_angle,
      spin_rate: iSpinPost,
      spin_rate_rpm: values.iron_spin_rate,
      ball_speed_mph: values.iron_ball_speed,
      recommended_landing_angle: rla_clean,
      landing_angle_preference: sp_clean,
      workability_preference: w_clean,
      flier_prevention_importance: fp_clean
    });
    window.gtag('event', 'Player Profile Irons', {
      fitting_id: null,
      carry_distance: values.iron_distance,
      launch_angle: iLaunchAnglePost,
      launch_angle_degree: values.iron_launch_angle,
      spin_rate: iSpinPost,
      spin_rate_rpm: values.iron_spin_rate,
      ball_speed_mph: values.iron_ball_speed,
      recommended_landing_angle: rla_clean,
      landing_angle_preference: sp_clean,
      workability_preference: w_clean,
      flier_prevention_importance: fp_clean
    });
  }

  render() {
    const {
      dispatch,
      history,
      savedValues,
      loggedInUser,
      formik: { values, setFieldValue },
    } = this.props;
    const isSixIronFitting = loggedInUser.get('six_iron_fitting');

    const ironDetails = isSixIronFitting ? strings.sixIronDetails : strings.sevenIronDetails;

    return (
      <div className="questionnaire__wrapper">
        <Helmet>
          <title>{`${ironDetails} - ${strings.title}`}</title>
        </Helmet>
        <div className="questionnaire--inner">
          <div className="questionnaire--header">
            <h1>{ironDetails}</h1>
            <h4>{isSixIronFitting ? strings.enterSixIronDetails : strings.enterSevenIronDetails}</h4>
          </div>
          <div className="questionnaire-blocks__container">
            <IronLaunchConditions isSixIronFitting={isSixIronFitting} />
            <Slider
              label={strings.stoppingPowerPreference}
              labelComponent={<StoppingPowerRecommendation />}
              tooltip={<TooltipIcon>{strings.stoppingPowerTooltip}</TooltipIcon>}
              subLabel={strings.stoppingPowerSubtext}
              displayValue={stoppingPowerLabelMap[values.iron_stop_pref]}
              sliderProps={{
                min: 0,
                max: 4,
                step: 1,
                marks: {
                  0: <div className="slider--mark text">{stoppingPowerLabelMap[0]}</div>,
                  4: <div className="slider--mark text">{stoppingPowerLabelMap[4]}</div>,
                },
                dots: true,
                value: values.iron_stop_pref,
                onChange: value => setFieldValue('iron_stop_pref', value),
              }}
            />
            <Slider
              label={strings.workabilityPreference}
              tooltip={<TooltipIcon>{strings.workabilityTooltip}</TooltipIcon>}
              subLabel={strings.workabilitySubtext}
              displayValue={workabilityLabelMap[values.iron_workability_pref]}
              sliderProps={{
                min: 0,
                max: 4,
                step: 2,
                marks: {
                  0: <div className="slider--mark text">{workabilityLabelMap[0]}</div>,
                  4: <div className="slider--mark text">{workabilityLabelMap[4]}</div>,
                },
                dots: true,
                value: values.iron_workability_pref,
                onChange: value => setFieldValue('iron_workability_pref', value),
              }}
            />
            <Slider
              label={strings.flierPreventionImportance}
              tooltip={<TooltipIcon>{strings.flierPreventionTooltip}</TooltipIcon>}
              displayValue={flierPreventionLabelMap[values.iron_flier_imp]}
              subLabel={strings.flierPreventionSubtext}
              sliderProps={{
                min: 0,
                max: 2,
                step: 1,
                marks: {
                  0: <div className="slider--mark text">{flierPreventionLabelMap[0]}</div>,
                  2: <div className="slider--mark text">{flierPreventionLabelMap[2]}</div>,
                },
                dots: true,
                value: values.iron_flier_imp,
                onChange: value => setFieldValue('iron_flier_imp', value),
              }}
            />
          </div>
          <div className="questionnaire--button__wrapper">
            <button
              className="button button--color--primary button--theme--outline button--icon"
              type="button"
              onClick={() => {
                dispatch(setQuestionnaireValues(values));
                calculateLaunchConditionsIfNeeded('iron', values, savedValues.toJS(), dispatch, loggedInUser);
                this.segment_iron_post();
                history.push('/my-fitting/driver');
              }}
            >
              <ArrowLeft />
              <div>Back</div>
            </button>
            <button
              className="button button--color--primary button--icon"
              type="button"
              onClick={() => {
                dispatch(setQuestionnaireValues(values));
                calculateLaunchConditionsIfNeeded('iron', values, savedValues.toJS(), dispatch, loggedInUser);
                this.segment_iron_post();
                history.push('/my-fitting/wedges');
              }}
            >
              <div>Next</div>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    submittingCalcLaunchConditions: state.questionnaire.calculateIronLaunchConditions.get('loading'),
    savedValues: state.questionnaire.values,
  })),
  formikConnect
)(IronDetails);
