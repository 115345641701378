import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import PrivacyPolicy from '../components/PrivacyPolicy';
import { Field, ErrorMessage } from 'formik';
import ValidationError from './ValidationError';
import * as Yup from 'yup';

const strings = new LocalizedStrings({
  en: {
    readAndUnderstandPrivacyPolicy: 'I read and understand the Privacy Policy',
    mustAgree: 'Agreeing to the Privacy Policy is required',
  },
});

export const privacyPolicySchema = Yup.boolean().equals([true], strings.mustAgree);

class PrivacyPolicyField extends Component {
  render() {
    return (
      <>
        <PrivacyPolicy scrollable />
        <label className="checkbox">
          <Field type="checkbox" name="agreedToTerms" />
          <span>{strings.formatString(strings.readAndUnderstandPrivacyPolicy)}</span>
          <ErrorMessage name="agreedToTerms" component={ValidationError} />
        </label>
      </>
    );
  }
}

export default PrivacyPolicyField;
