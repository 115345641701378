import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import LocalizedStrings from 'react-localization';
import config from '../../config';

let strings = new LocalizedStrings({
  en: {
    downloadCsv: 'Download CSV',
    downloadFilteredCsv: 'Download Filtered CSV',
  },
});

class FitterCsvDownloadButton extends Component {
  render() {
    const { token, selectedAccountFilter } = this.props;
    const filterActive = selectedAccountFilter !== null;

    const query = filterActive
      ? new URLSearchParams({ token, filter: selectedAccountFilter }).toString()
      : new URLSearchParams({ token }).toString();

    return (
      <a className="button button--color--primary download-csv-button" href={`${config.apiUrl}fitters/csv?${query}`}>
        {filterActive ? strings.downloadFilteredCsv : strings.downloadCsv}
      </a>
    );
  }
}

export default compose(
  connect(state => ({
    token: state.auth.token,
  }))
)(FitterCsvDownloadButton);
