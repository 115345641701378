import React, { Component } from 'react';
import ScrollToTop from '../common/components/ScrollToTop';
import { Helmet } from 'react-helmet';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'California Privacy Rights',
  },
});

class PrivacyCaliforniaPage extends Component {
  componentDidMount(){
    window.analytics.page("Privacy Policy (California)");
  }
  render() {
    return (
      <ScrollToTop>
        <div className="container legal">
          <Helmet>
            <title>{`${strings.page} - ${strings.title}`}</title>
          </Helmet>
          <h1>California Information Sharing Disclosure</h1>
          <p>
            California Civil Code Sections 1798.115(c), 1798.130(a)(5)(c), 1798.130(c), and 1798.140 indicate that
            organizations should disclose whether the following categories of personal information are collected,
            transferred for “valuable consideration,” or transferred for an organization’s “business purpose” (as those
            terms are defined under California law). We do not “sell” your personal information. The table below
            indicates the categories of personal information we collect and transfer in a variety of contexts. Please
            note that because this list is comprehensive, it may refer to types of information that we collect and share
            about people other than yourself. For example, while we transfer credit card or debit card numbers for our
            business purpose in order to process payments for orders placed with us, we do not collect or transfer
            credit card or debit card numbers of individuals that submit questions through the “how to contact us”
            section of our privacy policy.
          </p>
          <table>
            <thead>
              <tr>
                <th style={{ width: '400px' }}>Categories of Personal Information</th>
                <th style={{ width: '480px' }}>Personal Information Collected</th>
                <th>Collected</th>
                <th>Sold</th>
                <th>Disclosed for a Business Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan={5}>Identifiers</td>
                <td>Name or alias</td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
              </tr>
              <tr>
                <td>Contact information (e.g. name, address, and telephone number, e-mail address)</td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
              </tr>
              <tr>
                <td>Unique identifiers (e.g., cookies, pixel tags, device identifier and attributes, etc.)</td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
              </tr>
              <tr>
                <td>Online identifiers (e.g. internet protocol address)</td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
              </tr>
              <tr>
                <td>Government issued ID (e.g. social security number, driver’s license number, passport number)</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td rowspan={3}>Other types of Personal Information</td>
                <td>Payment information (e.g. credit or debit card number)</td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
              </tr>
              <tr>
                <td>Financial information (e.g. bank account number)</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Medical information or health insurance information</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td rowspan={3}>Characteristics of legally protected classifications</td>
                <td>Race</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Ethnicity</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td rowspan={2}>Commercial Information</td>
                <td>Information about products and services obtained from us</td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
              </tr>
              <tr>
                <td>Individual’s preferences</td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
              </tr>
              <tr>
                <td rowspan={2}>Biometric</td>
                <td>Fingerprint, face print, voiceprint</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Sleep, health, exercise data</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td rowspan={2}>Internet/ Electronic Activity</td>
                <td>Browsing and/or search history</td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Information about interactions with our website, mobile application, or advertisements</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Geolocation data</td>
                <td>Precise physical location using GPS</td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
              </tr>
              <tr>
                <td rowspan={3}>Audio, electronic, visual, thermal, olfactory, or similar information</td>
                <td>Call recordings</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Photographs</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Video surveillance</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td rowspan={3}>Professional/employment information</td>
                <td>Occupation</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Employment history</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Professional references</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td rowspan={6}>
                  Education information (i.e., personally identifiable information contained in education records)
                </td>
                <td>Student name</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Name of student’s parent or other family members</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Address of student or student’ family</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Personal identifier of student (social security number, student number, biometric record)</td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>
                  Other indirect identifiers, such as the student’s date of birth, place of birth, and mother’s maiden
                  name
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>
                  Other information that, alone or in combination, is linked or linkable to a specific student that
                  would allow a reasonable person in the school community, who does not have personal knowledge of the
                  relevant circumstances, to identify the student with reasonable certainty
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
              </tr>
              <tr>
                <td>Inferences drawn from any of the above information</td>
                <td></td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={false} readOnly />
                </td>
                <td>
                  <input type="checkbox" checked={true} readOnly />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ScrollToTop>
    );
  }
}

export default PrivacyCaliforniaPage;
