import React from 'react';
import Modal from './Modal';

const ConfirmationModal = ({
  isOpened = false,
  content,
  onConfirm,
  onPurchase,
  onClose,
  titleText,
  leftButtonText,
  buttonText,
  cancelText = 'Cancel',
}) => {
  return (
    <Modal isOpened={isOpened} title={titleText} onClose={onClose} leftButtonText={leftButtonText}>
      <div>
        <p className="modal-text">{content}</p>
        <div className="modal--button__wrapper">
          {onClose && (
            <button className="button button--color--white" type="button" onClick={onClose}>
              {cancelText}
            </button>
          )}
          {leftButtonText && (
            <div>
              <button className="button button--color--primary" type="button" onClick={() => onPurchase()}>
                {leftButtonText}
              </button>
            </div>
          )}
          <button className="button button--color--primary" type="button" onClick={() => onConfirm()}>
            {buttonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
