import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './logo/Logo';
import LocalizedStrings from 'react-localization';
import CoBrand from './CoBrand';
import cn from 'classnames';

const strings = new LocalizedStrings({
  en: {
    landingPage: 'Landing Page',
  },
});

export default function Header({
  children,
  coBrandImageUrl,
  coBrandName,
  coBrandPreview,
  kebabMenu,
  logoLinkTo = '/',
  nonFitter,
}) {
  return (
    <nav>
      <div className={cn('user-nav', nonFitter && 'co-branding--static__wrapper')}>
        <div className="user-nav__logo--container">
          <Link to={logoLinkTo} aria-label={strings.landingPage}>
            <Logo width={171} height={40} fill="#5057FF" />
          </Link>
        </div>
        {children}
        <div className="user-nav__co-brand-kebab">
          {(coBrandImageUrl || coBrandPreview) && (
            <CoBrand
              className="user-nav__co-brand"
              imageUrl={coBrandImageUrl}
              brandName={coBrandName}
              preview={coBrandPreview}
              nonFitter={nonFitter}
            />
          )}
          {kebabMenu && kebabMenu}
        </div>
      </div>
    </nav>
  );
}
