import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ScrollToTop from '../common/components/ScrollToTop';
import LocalizedStrings from 'react-localization';
import LandingPageHeader from '../landing-page/LandingPageHeader';
import { getMyFittingCodesList, getFitterFittingCode } from '../user/user-actions';
import Modal from '../common/components/Modal';

import { fetchLoggedInUser } from '../auth/auth-actions';
import { validateVoucherCode } from '../payment/payment-actions';
import LoadingIndicator from '../common/components/LoadingIndicator';
import { PurchaseFormContext } from '../context/PurchaseFormContext';
import { Link, useHistory } from 'react-router-dom';

const strings = new LocalizedStrings({
  en: {
    voucherHeading: 'Enter Your Fitting Code to Get Started',
    fittingCode: 'FITTING CODE',
    voucherText1:
      'Don’t have a code? Purchase one ',
    voucherText2: 'here',
    voucherText3:
      ' to unlock your fitting.',
    startMyFitting: 'Start My Fitting',
    blankFittingCode: 'Please insert a valid fitting code',
    invalidFittingCode: 'This fitting code is invalid or expired.',
    insertFittingCode: "Enter your fitting code",
    redeemSteps: {
      title: 'Redeem Your Ball Fitting Code',
      step1: 'Register for an account.',
      step2: 'Enter your fitting code.',
      step3: 'Complete your fitting and get your results.'
    },
    getStartedButton: 'Get Started',
  },
});

const Purchase = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.auth.loggedInUser);
  const token = useSelector(state => state.auth.token);
  const fetchingLoggedInUser = useSelector(state => state.auth.fetchLoggedInUser.get('loading'));
  const { handlePurchaseForm, showPurchase } = useContext(PurchaseFormContext);
  const [fittingCode, setFittingCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const myFittingCodesList = useSelector(state => state.user.myFittingCodesList);
  const fitterFittingCode = useSelector(state => state.user.fitterFittingCode);
  const [showReloadDialog, setShowReloadDialog] = useState(false);
  const [hasShownReloadDialog, setHasShownReloadDialog] = useState(false);

  useEffect(() => {
    window.analytics.page();
    if (token) {
      dispatch(fetchLoggedInUser());
    }
  }, [dispatch, token]);

  useEffect(() => {
    const userType = loggedInUser?.get('type');
    if (userType === 'fitter' || userType === 'admin') {
      dispatch(getFitterFittingCode());
    } else {
      if (!showPurchase && loggedInUser) {
        dispatch(getMyFittingCodesList({ email: loggedInUser?.get('oidcData')?.get('email') }));
      }
    }
  }, [dispatch, showPurchase, loggedInUser]);
  

  useEffect(() => {
    if (fitterFittingCode) {
      setFittingCode(fitterFittingCode.get('fitterFittingCode') ?? '');
    }
    else if (myFittingCodesList.size > 0) {
      const activeCodes = myFittingCodesList.filter(code => code.get('status') === 'active');
      if (activeCodes.size === 0) {
        if(!hasShownReloadDialog){
        setShowReloadDialog(true);
        setHasShownReloadDialog(true);
        }
      } else {
        const firstActiveFittingCode = activeCodes.first().get('code');
        setFittingCode(firstActiveFittingCode);
      }
    }
  }, [myFittingCodesList, hasShownReloadDialog, fitterFittingCode]);

  useEffect(() => {
    const handleValidation = async () => {
      if (localStorage.getItem('action') === 'redeemVoucher' && localStorage.getItem('voucher')) {
        setFittingCode(localStorage.getItem('voucher'));
        await validateVoucher(localStorage.getItem('voucher'));
      }
      if (localStorage.getItem('purchasedWithoutAccount')) {
        localStorage.removeItem('purchasedWithoutAccount');
      }
    };

    handleValidation();
  // eslint-disable-next-line
  }, []);

  const handleButtonClick = () => {
    // window.analytics.track("Checkout Step Viewed", { step: 'fitting_voucher_1' });

    // window.gtag('event', 'Checkout Step Viewed', { step: 'fitting_voucher_1' });
    if (!loggedInUser) {
      handlePurchaseForm();
    } else {
      window.analytics.identify(loggedInUser.get('id'), {
        firstName: loggedInUser.get('first_name'),
        lastName: loggedInUser.get('last_name'),
        email: loggedInUser.get('oidcData').get('email'),
      });
      window._cio.identify({
        id: loggedInUser.get('id'),
        email: loggedInUser.get('oidcData').get('email'),
        first_name: loggedInUser.get('first_name'),
        last_name: loggedInUser.get('last_name'),
      });
      handlePurchaseForm();
      setErrorMessage('');
    }
  };

  const validateVoucher = async (voucherToRedeem) => {
    try {
      const actionResponse = await validateVoucherCode(voucherToRedeem);
      if (actionResponse) { 
        localStorage.setItem('voucher', voucherToRedeem);
        if(isAuthenticatedUser){
          history.push('/my-fitting');
        }else{
          localStorage.setItem('action', 'redeemVoucher');
          history.push('/login');
        }
        setErrorMessage('');
      } else {
        if(fittingCode === null || fittingCode === ''){
          throw new Error(strings.blankFittingCode);
        }else{
          throw new Error(strings.invalidFittingCode);
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleCloseDialog = () => {
    setShowReloadDialog(false);
  };

  const renderReloadDialog = () => (
    <Modal
      className="voucher-modal"
      isOpened={showReloadDialog}
      onClose={() => setShowReloadDialog(false)}
      shouldCloseOnOutsideClick={false}
    >
      <h3 style={{ paddingBottom: '18px', textAlign: 'center', fontSize: '40px' }}><strong>Time to Reload</strong></h3>
      <p style={{ textAlign: 'center', paddingBottom: '6px', fontSize: '24px' }}>Add more fitting codes to your</p>
      <p style={{ paddingBottom: '36px', textAlign: 'center', fontSize: '24px' }}>account to unlock your next fitting.</p>
        <button
              type="button"
              className="button button--color--primary questionnaire-centered-button"
              onClick={() => {
                handleCloseDialog();
                handleButtonClick();
              }}
            >
              {'Reload Now'}
        </button>
    </Modal>
  );

  const renderContent = () => {
    if (!isAuthenticatedUser) {
      return (
        <div className="redeem-steps-container">
          <h1>{strings.redeemSteps.title}</h1>
          <div className="steps-list">
            <div className="step">
              <span className="step-number">1</span>
              <span className="step-text">{strings.redeemSteps.step1}</span>
            </div>
            <div className="step">
              <span className="step-number">2</span>
              <span className="step-text">{strings.redeemSteps.step2}</span>
            </div>
            <div className="step">
              <span className="step-number">3</span>
              <span className="step-text">{strings.redeemSteps.step3}</span>
            </div>
          </div>
          <button 
            className="button button--color--primary" 
            onClick={() => history.push('/registration')}
          >
            {strings.getStartedButton}
          </button>
        </div>
      );
    }

    return (
      <>
        <div className="gift-voucher-text-wrapper">
          <h1>{strings.voucherHeading}</h1>
        </div>
        <div className="gift-inner">
          <p>{strings.fittingCode}</p>
          <div className="form-field" style={{ paddingBottom: '20px' }}>
            <input
              type="text"
              id="fittingCode"
              name="fittingCode"
              placeholder={strings.insertFittingCode}
              value={fittingCode}
              onChange={(e) => {
                setFittingCode(e.target.value);
                setErrorMessage('');
              }}
              style={{ borderRadius: '0', fontWeight: '500', backgroundColor: '#F5F6FA', border: '1.0px solid #b6bdc2' }}
            />
            {errorMessage && <div className="error-message" style={{ color: 'red', paddingTop: '8px' }}>{errorMessage}</div>}
          </div>
          <button 
            className="button button--color--primary gift-button" 
            onClick={() => validateVoucher(fittingCode)}
          >
            {strings.startMyFitting}
          </button>
        </div>
      </>
    );
  };

  if (fetchingLoggedInUser) {
    return <LoadingIndicator />;
  }

  const email = loggedInUser?.get('oidcData')?.get('email');
  const isAuthenticatedUser = email != null;

  return (
    <ScrollToTop>
      <div className="ballnamic--gift-page">
        <LandingPageHeader 
          isLoggedIn={!!loggedInUser} 
          loggedInUser={loggedInUser} 
          handleBuyMoreCodes={handleButtonClick}
        />
        <div className="gift-page">
          {renderContent()}
        </div>
        <div className="purchase">
          {strings.voucherText1}
          <Link onClick={handleButtonClick}>{strings.voucherText2}</Link>
          {strings.voucherText3}
        </div>
        {showReloadDialog && renderReloadDialog()}
      </div>
    </ScrollToTop>
  );
};

export default Purchase;
