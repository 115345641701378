import React from 'react';
import Svg, { Path } from 'svgs';

const WedgeSpin = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M13.775 9.98272727l.9125.86272723c-.28125.4904546-.4375 1.0636364-.4375 1.6545455 0 1.9581005 1.6789322 3.5454545 3.75 3.5454545v-1.7727272l2.5 2.3636363L18 19v-1.7727273c-2.7614237 0-5-2.1164721-5-4.7272727 0-.9277273.2875-1.7904545.775-2.51727273zM3 8v8h2V9h2v7h2V8h2v8c0 1.1045695-.8954305 2-2 2H3c-1.1045695 0-2-.8954305-2-2V8h2zm15-2v1.77272727c2.7614237 0 5 2.11647209 5 4.72727273 0 .9277273-.2875 1.7904545-.775 2.5172727l-.9125-.8627272c.28125-.4904546.4375-1.0636364.4375-1.6545455 0-1.9581005-1.6789322-3.54545455-3.75-3.54545455v1.77272725l-2.5-2.36363634L18 6z" />
    </Svg>
  );
};

export default WedgeSpin;
