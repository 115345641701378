import React from 'react';

const VimeoEmbed = ({id}) => {
    const embedCode = {
      __html: `
        <iframe src="https://player.vimeo.com/video/${id}?badge=0&autopause=0&player_id=0&app_id=58479"
        frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        title="Ballnamic Ball Fitting Results Explained"></iframe>
      `,
    };

    return <div dangerouslySetInnerHTML={embedCode} />;
  };

  export default VimeoEmbed;

