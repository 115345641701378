import React from 'react';
import Toggle from 'react-toggle';

const Preference = props => {
  const { checked, label, handleToggle } = props;

  return (
    <div className="toggle--container">
      <label>
        <span className="toggle-label" style={{ fontWeight: '450' }}>
          {label}
        </span>
        <Toggle checked={checked} icons={false} onChange={handleToggle} aria-selected={checked} aria-label={label} />
      </label>
    </div>
  );
};

export default Preference;
