import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import {
  setQuestionnaireValues,
  calculateDriverLaunchConditions,
  calculateIronLaunchConditions,
} from './questionnaire-actions';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    pleaseSpecifyCurrentBall: 'Please specify the model and year of your current ball',
  },
});

export async function validateProfileStep(formik) {
  const { validateForm, setErrors, setTouched } = formik;
  const validationErrors = await validateForm();
  if (Object.keys(validationErrors).length) {
    if (validationErrors.model || validationErrors.year) {
      window.alert(strings.pleaseSpecifyCurrentBall);
    }
    setErrors(validationErrors);
    const touched = {};
    Object.keys(validationErrors).forEach(field => {
      touched[field] = true;
    });
    setTouched(touched);
    return false;
  }
  return true;
}

export function areLaunchConditionsKnown(type, values, loggedInUser) {
  if (type !== 'iron' && type !== 'driver') {
    throw new Error(`Unknown launch condition type: ${type}`);
  }

  const isKnownField = type === 'driver' ? 'driver_launch_conditions_known' : 'iron_launch_conditions_known';
  return values[isKnownField] === null ? loggedInUser.get('type') !== 'player' : values[isKnownField];
}

export function calculateLaunchConditionsIfNeeded(type, values, savedValues, dispatch, loggedInUser) {
  if (type !== 'iron' && type !== 'driver') {
    throw new Error(`Unknown launch condition type: ${type}`);
  }

  const updateIfChanged =
    type === 'driver'
      ? ['driver_distance', 'driver_spin_type', 'driver_launch_type']
      : ['iron_distance', 'iron_spin_type', 'iron_launch_type'];

  const updateIfConditionsUnknown =
    type === 'driver'
      ? ['typical_alt', 'typical_temp']
      : ['driver_ball_speed', 'driver_launch_angle', 'driver_spin_rate', 'typical_alt', 'typical_temp'];

  const actionCreator = type === 'driver' ? calculateDriverLaunchConditions : calculateIronLaunchConditions;

  const allFields = updateIfChanged.concat(updateIfConditionsUnknown);

  const areKnown = areLaunchConditionsKnown(type, values, loggedInUser);

  if (
    !isEqual(pick(values, updateIfChanged), pick(savedValues, updateIfChanged)) ||
    (!areKnown && !isEqual(pick(values, updateIfConditionsUnknown), pick(savedValues, updateIfConditionsUnknown)))
  ) {
    /**
     * Save unsaved values to redux state first, otherwise the response from the calc
     * endpoint will be merged in with the stale values and the form will be
     * reinitialized with old values.
     */
    dispatch(setQuestionnaireValues(values));
    dispatch(actionCreator(pick(values, allFields)));
  }
}
