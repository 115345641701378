// Separate file to prevent circular dependencies
export const CALCULATE_FLIGHTS = 'CALCULATE_FLIGHTS';
export const SET_QUESTIONNAIRE_VALUES = 'SET_QUESTIONNAIRE_VALUES';
export const SET_RECIPIENTS = 'SET_RECIPIENTS';
export const RESET_QUESTIONNAIRE = 'RESET_QUESTIONNAIRE';
export const FETCH_BRANDS = 'FETCH_BRANDS';
export const FETCH_MODELS = 'FETCH_MODELS';
export const FETCH_YEARS = 'FETCH_YEARS';
export const RESET_MODELS = 'RESET_MODELS';
export const RESET_YEARS = 'RESET_YEARS';
export const CALCULATE_DRIVER_LAUNCH_CONDITIONS = 'CALCULATE_DRIVER_LAUNCH_CONDITIONS';
export const CALCULATE_IRON_LAUNCH_CONDITIONS = 'CALCULATE_IRON_LAUNCH_CONDITIONS';
export const CALCULATE_RELATIVE_RESULTS = 'CALCULATE_RELATIVE_RESULTS';
export const RESET_RELATIVE_RESULTS = 'RESET_RELATIVE_RESULTS';
export const FETCH_PRICES = 'FETCH_PRICES';
export const FETCH_RESULTS = 'FETCH_RESULTS';
export const SEND_RESULTS = 'SEND_RESULTS';
export const RESET_SEND_RESULTS = 'RESET_SEND_RESULTS';
export const FETCH_STATIC_RESULTS = 'FETCH_STATIC_RESULTS';
export const LOOK_UP_TEMPERATURE_AND_ALTITUDE = 'LOOK_UP_TEMPERATURE_AND_ALTITUDE';
export const CALCULATE_DRIVER_HEIGHT_PREFERENCE = 'CALCULATE_DRIVER_HEIGHT_PREFERENCE';
export const RESET_CALCULATED_DRIVER_HEIGHT = 'RESET_CALCULATED_DRIVER_HEIGHT';
export const CALCULATE_IRON_STOPPING_PREFERENCE = 'CALCULATE_IRON_STOPPING_PREFERENCE';
export const RESET_CALCULATED_IRON_STOP = 'RESET_CALCULATED_IRON_STOP';
export const ACCEPT_DISCLAIMER = 'ACCEPT_DISCLAIMER';
export const IRON_LAUNCH_CONDITIONS_KNOWN = 'IRON_LAUNCH_CONDITIONS_KNOWN';
export const DRIVER_LAUNCH_CONDITIONS_KNOWN = 'DRIVER_LAUNCH_CONDITIONS_KNOWN';
export const GET_RESULT_TEASER = 'GET_RESULT_TEASER';
