import React from 'react';
import Svg, { Path } from 'svgs';

const GolfBallTee = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M7 19V17H17V19H15C14.5 19 14 19.2 13.6 19.6S13 20.5 13 21V22H11V21C11 20.5 10.8 20 10.4 19.6S9.5 19 9 19H7M11 6C11 5.7 11.1 5.5 11.3 5.3S11.7 5 12 5 12.5 5.1 12.7 5.3 13 5.7 13 6 12.9 6.5 12.7 6.7 12.3 7 12 7 11.5 6.9 11.3 6.7 11 6.3 11 6M13 8C13 7.7 13.1 7.5 13.3 7.3S13.7 7 14 7 14.5 7.1 14.7 7.3 15 7.7 15 8 14.9 8.5 14.7 8.7 14.3 9 14 9 13.5 8.9 13.3 8.7 13 8.3 13 8M9 8C9 7.7 9.1 7.5 9.3 7.3S9.7 7 10 7 10.5 7.1 10.7 7.3 11 7.7 11 8 10.9 8.5 10.7 8.7 10.3 9 10 9 9.5 8.9 9.3 8.7 9 8.3 9 8M16.9 4.1C15.5 2.7 13.9 2.1 12 2.1S8.4 2.8 7.1 4.1 5 7.1 5 9 5.7 12.6 7 13.9 10 15.9 11.9 15.9 15.5 15.2 16.8 13.9 18.8 10.9 18.8 9 18.3 5.4 16.9 4.1M15.5 12.5C14.5 13.5 13.3 14 12 14S9.4 13.5 8.5 12.5 7 10.4 7 9 7.5 6.4 8.5 5.5 10.6 4 12 4 14.6 4.5 15.5 5.5 17 7.6 17 9 16.5 11.6 15.5 12.5Z" />
    </Svg>
  );
};

export default GolfBallTee;
