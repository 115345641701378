import React from 'react';
import Svg, { Path } from 'svgs';

const IronRoll = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M18.1181818 14.6885364L21.9816364 18.539l-3.8504546 3.8633636-1.2878182-1.2834545 1.6493637-1.655-11.17123639.0383636c-.36987273 1.0604546-1.37645455 1.8235455-2.5636 1.8288182-1.50621818.0068182-2.73273636-1.2087273-2.73952809-2.715-.00673555-1.5061818 1.20880991-2.7327 2.71502809-2.7394636 1.18777273-.0053364 2.20160909.7494363 2.58052727 1.8074636l11.19580912-.0383636-1.675-1.6693818 1.2834545-1.2878091zM4.74155455 17.6948087c-.50207273.0021913-.90725455.4111004-.90500937.9131004.00226391.5020909.41110937.9072727.913173.9050095.50207273-.0021913.90725455-.4111004.90500937-.9131004-.00226391-.5020909-.41110028-.9072727-.913173-.9050095zM15 2v2.2h-1.5v6.6H15V13H9v-2.2h1.5V4.2H9V2h6z" />
    </Svg>
  );
};

export default IronRoll;
