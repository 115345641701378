import React from 'react';
import Svg, { Path, G } from 'svgs';

const Mastercard = ({ width, height, fill }) => {
  return (
    <Svg
      width={width || 24}
      height={height || 16}
      viewBox="0 0 24 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h24v24H0z" />
        <G fillRule="nonzero">
          <Path fill="#FF5F00" d="M8.795 2.753h6.302v10.293H8.795z" />
          <Path
            d="M9.444 7.9a6.535 6.535 0 0 1 2.5-5.145 6.545 6.545 0 1 0 0 10.292 6.535 6.535 0 0 1-2.5-5.147z"
            fill="#EB001B"
          />
          <Path
            d="M22.014 12.702v-.829h.166v-.171h-.396v.171h.156v.829h.074zm.77 0v-1h-.12l-.14.714-.14-.714h-.12v1h.086v-.757l.13.65h.09l.13-.65v.757h.084zM22.535 7.9a6.545 6.545 0 0 1-10.591 5.145 6.545 6.545 0 0 0 0-10.292 6.545 6.545 0 0 1 10.59 5.145V7.9z"
            fill="#F79E1B"
          />
        </G>
      </G>
    </Svg>
  );
};

export default Mastercard;
