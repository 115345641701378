import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import hash from 'object-hash';
import { connect } from 'react-redux';
import {
  calculateFlights,
  calculateRelativeResults,
  fetchResults,
  resetRelativeResults,
  resetSendResults,
  sendResults,
  sendSelfResults,
  acceptDisclaimer,
} from '../questionnaire-actions';
import { fetchLoggedInUser } from '../../../auth/auth-actions';
import compose from 'lodash/flowRight';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import ResultList from './ResultList';
import Explore from './Explore';
import { NavLink, Switch, Route, Redirect } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import SendResultsModal from './SendResultsModal';
import SendIcon from '../../../common/components/icons/Send';
import isEqual from 'lodash/isEqual';
import clamp from '../../../common/utils/clamp';
import { validateVoucherCode } from '../../../payment/payment-actions';
import { COST_MIN, COST_MAX } from '../FinishingTouches';
import ConfidenceRangeDisclaimer from './ConfidenceRangeDisclaimer';
import ResultsAnimatedLoadingIndicator from './ResultsAnimatedLoadingIndicator';
import localstorage from 'store2';
import PropTypes from 'prop-types';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    results: 'Results',
    compareResults: 'Compare Results',
    exploreResults: 'Explore Results',
    invalidCode: 'Your fitting code is invalid, expired or already used.',
    startOver: 'Start Over',
    areYouSure: 'Are you sure?',
    sendResults: 'Send Results',
  },
});

const SEND_RESULTS_MODAL = 'SEND_RESULTS_MODAL';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedModal: null,
      favoritedBall: null,
      submittedDisclaimer: false,
      showMaximumRequestsAlmostReachedModal: false,
      loadingIndicatorComplete: false,
      teaserPreloadStarted: false,
      isModalOpen: false,
      isPaymentRequired: null,
    };
  }

  sendResultsRef = React.createRef();
  confirmationModalRef = React.createRef();
  compareResultsRef = React.createRef();

  componentDidMount() {
    const { submittingCalcLaunchConditions, loggedInUser } = this.props;

    if (loggedInUser.get('type') !== 'player' && !submittingCalcLaunchConditions) {
      this.fetchResults();
    }

    this.checkPaymentRequirement(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, results, loggedInUser, submittingCalcLaunchConditions } = this.props;

    if (
      loggedInUser.get('type') !== 'player' &&
      !isEqual(this.getPerformancePayload(prevProps), this.getPerformancePayload(this.props))
    ) {
      this.fetchResults();
    }

    if (results && prevProps.results !== results) {
      this.setState({ favoritedBall: results.getIn(['results', 0, 'info', 'Ball']) }, () => {
        dispatch(
          calculateRelativeResults({
            favorite_ball: this.state.favoritedBall,
            raw_df: results.get('raw'),
          })
        );
      });

      dispatch(
        calculateFlights(
          this.getPerformancePayload(this.props),
          results
            .get('results')
            .map(result => result.getIn(['info', 'Ball']))
            .toJS()
        )
      );
    }

    if (
      loggedInUser.get('type') === 'player' &&
      !prevState.submittedDisclaimer &&
      this.state.submittedDisclaimer &&
      !submittingCalcLaunchConditions
    ) {
      this.fetchResults();
    }

    if (!prevProps.fetchResultsError && this.props.fetchResultsError) {
      if (this.props.fetchResultsError.get('statusCode') === 402) {
        dispatch(fetchLoggedInUser());
      }
    }

    if (prevProps.loggedInUser !== this.props.loggedInUser) {
      this.checkPaymentRequirement(this.props);
    }
  }

  updateLoadingBarComplete() {
    this.setState({ loadingIndicatorComplete: true });
  }

  async fetchResults() {
    const { dispatch, results } = this.props;
    const values = this.getPerformancePayload(this.props);

    // Skip fetch if we already have results for these values
    if (results && results.get('valuesHash')) {
      const valuesHash = hash(values);
      if (valuesHash === results.get('valuesHash')) {
        return;
      }
    }

    dispatch(fetchResults(values));
  }
  
  getPerformancePayload(props) {
    const { values, loggedInUser } = props;
    const payload = values.toJS();

    payload.current_ball = `${payload.year} ${payload.brand} ${payload.model}`;
    payload.voucher = localStorage.getItem('voucherToRedeem');
    delete payload.year;
    delete payload.brand;
    delete payload.model;
    delete payload.recipients;
    delete payload.handicap;
    delete payload.driver_distance;
    delete payload.driver_spin_type;
    delete payload.driver_launch_type;
    delete payload.iron_distance;
    delete payload.iron_spin_type;
    delete payload.iron_launch_type;
    delete payload.driver_launch_conditions_known;
    delete payload.iron_launch_conditions_known;

    if (!loggedInUser.get('show_price_preference')) {
      payload.cost_preference = 'none';
    }

    // Clamping values in case out-of-range values from a previous version are still in state
    payload.cost_lower_limit = clamp(payload.cost_lower_limit, COST_MIN, COST_MAX);
    payload.cost_upper_limit = clamp(payload.cost_upper_limit, COST_MIN, COST_MAX);

    payload.dtc_ind = 1;
    payload.house_ind = 1;

    const userSavedBrands = loggedInUser?.getIn(['userBrandSetting', 'settings'])?.toJS();
    const brands = userSavedBrands?.brands
      .map(brand => {
        return brand.on ? brand.brandName : null;
      })
      .filter(item => item);
    values.brands = brands;
    payload.brands = brands;

    return payload;
  }

  setFavorite = favorite => {
    const { dispatch, results } = this.props;

    this.setState({ favoritedBall: favorite }, () => {
      if (this.state.favoritedBall === null) {
        dispatch(resetRelativeResults());
      } else {
        dispatch(
          calculateRelativeResults({
            favorite_ball: this.state.favoritedBall,
            raw_df: results.get('raw'),
          })
        );
      }
    });
  };

  sendResults = sendResultsValues => {
    const { dispatch, recipients, loggedInUser } = this.props;
    const { fitter_comments } = sendResultsValues;
    const { favoritedBall } = this.state;
    const code = JSON.parse(localstorage.get('promoCode'));
    const promoCode = code?.code ? code?.code : undefined;
    if (loggedInUser.get('type') === 'player') {
      dispatch(sendSelfResults({email: loggedInUser.get('oidcData').get('email')}));
    } else {
      dispatch(
        sendResults(recipients, this.getPerformancePayload(this.props), favoritedBall, fitter_comments, promoCode)
      );
    }
  };

  async checkPaymentRequirement(props) {
    const isPaymentRequired = await this.isPaymentRequired(props);
    this.setState({ isPaymentRequired });
  }

  async isPaymentRequired(props) {
    const { loggedInUser, history } = props;

    if (loggedInUser.get('type') === 'player') {
      if (localStorage.getItem('voucherToRedeem') !== null) {
        try {
          const isValid = await validateVoucherCode(localStorage.getItem('voucherToRedeem'));
          if(!isValid){
            history.push('/redeem');
            toast(strings.invalidCode, { type: 'error' });
          }
          return !isValid; // Access not allowed if voucher is invalid
        } catch (error) {
          return true; // Access not allowed if validation fails
        }
      } else{
        history.push('/redeem');
        toast(strings.invalidCode, { type: 'error' });
      }
      return true; // Access not allowed if no voucher
    }
    return false; // Non-players always have access
  }

  renderModals() {
    const { sendingResults, sentResults, dispatch, loggedInUser } = this.props;

    return (
      <>
        <SendResultsModal
          isOpened={this.state.displayedModal === SEND_RESULTS_MODAL}
          onClose={() => {
            dispatch(resetSendResults());
            this.setState({ displayedModal: null });
            this.sendResultsRef.current.focus();
          }}
          loggedInUser={loggedInUser}
          onSubmit={this.sendResults}
          sending={sendingResults}
          success={sentResults}
        />
      </>
    );
  }

  renderNav() {
    const { results } = this.props;

    const disabledStyles = { opacity: results ? 1 : 0.5, pointerEvents: results ? 'all' : 'none' };

    return (
      <div className="results--sub-nav">
        <div className="results__sub-nav--left">
          <NavLink
            className="results__sub-nav--left__link"
            style={disabledStyles}
            to="/my-fitting/results"
            exact
            disabled={results}
            ref={this.compareResultsRef}
          >
            {strings.compareResults}
          </NavLink>
          <NavLink
            className="results__sub-nav--left__link"
            style={disabledStyles}
            to="/my-fitting/results/explore/"
            strict
            disabled={results}
          >
            {strings.exploreResults}
          </NavLink>
        </div>
        <div className="results__sub-nav--right">
          <button
            className="results__sub-nav--right__button button--icon"
            style={disabledStyles}
            type="button"
            onClick={() => this.setState({ displayedModal: SEND_RESULTS_MODAL })}
            ref={this.sendResultsRef}
          >
            <div className="icon__wrapper">
              <SendIcon />
            </div>
            <span>{strings.sendResults}</span>
          </button>
        </div>
      </div>
    );
  }

  renderContents() {
    const {
      flights,
      loadingResults,
      results,
      relativeResults,
      values,
      submittingCalcLaunchConditions,
      dispatch,
      hasAcceptedDisclaimer,
      savedValues,
      onDisclaimerAccept,
    } = this.props;

    if (!this.state.submittedDisclaimer) {

      window.analytics.track("Player Profile Agreement", {
        fitting_id: null,
      });
      window.gtag('event', 'Player Profile Agreement', {
        fitting_id: null,
      });

      return (
        <ConfidenceRangeDisclaimer
          onSubmit={() => {
            dispatch(acceptDisclaimer());
            this.setState({ submittedDisclaimer: true });
            this.setState({ isModalOpen: true });
            onDisclaimerAccept();
          }}
          hasAcceptedDisclaimer={hasAcceptedDisclaimer}
          questionnaireData={savedValues.toJS()}
          onDisclaimerAccept={onDisclaimerAccept}
        />
      );
    }

    if (this.state.isPaymentRequired && !this.state.teaserPreloadStarted) {
      this.setState({ teaserPreloadStarted: true });
    }

    if (!this.state.loadingIndicatorComplete) {
      return <ResultsAnimatedLoadingIndicator onComplete={() => this.setState({ loadingIndicatorComplete: true })} />;
    }

    return (
      <div className="results__wrapper">
        {this.renderModals()}
        {this.renderNav()}
        {loadingResults || submittingCalcLaunchConditions || !results ? (
          <div className="results--loading-indicator">
            <LoadingIndicator />
            <h4>Calculating the best balls for your game...</h4>
          </div>
        ) : (
          <Switch>
            <Route exact path="/my-fitting/results">
              <ResultList
                tooltipText={results.get('helperText')}
                results={results.get('results')}
                relativeResults={relativeResults}
                questionnaireValues={values}
                favoritedBall={this.state.favoritedBall}
                setFavorite={this.setFavorite}
                isSixIronFitting={results.get('six_iron_fitting')}
              />
            </Route>
            <Route exact path="/my-fitting/results/explore/:shotType">
              <Explore
                results={results.get('results')}
                questionnaireValues={values}
                favoritedBall={this.state.favoritedBall}
                flights={flights}
              />
            </Route>
            <Redirect from="/my-fitting/results/explore/" to="/my-fitting/results/explore/driver" exact />
          </Switch>
        )}
      </div>
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{`${strings.results} - ${strings.title}`}</title>
        </Helmet>
        {this.renderContents()}
      </>
    );
  }
}

Results.propTypes = {
  onDisclaimerAccept: PropTypes.func.isRequired,
};

export default compose(
  connect(state => ({
    loadingResults: state.questionnaire.fetchResults.get('loading'),
    fetchResultsError: state.questionnaire.fetchResults.get('error'),
    results: state.questionnaire.results,
    flights: state.questionnaire.flights,
    relativeResults: state.questionnaire.relativeResults,
    values: state.questionnaire.values,
    recipients: state.questionnaire.recipients,
    sendingResults: state.questionnaire.sendResults.get('loading'),
    sentResults: state.questionnaire.sendResults.get('loaded'),
    loggedInUser: state.auth.loggedInUser,
    submittingCalcLaunchConditions:
      state.questionnaire.calculateDriverLaunchConditions.get('loading') ||
      state.questionnaire.calculateIronLaunchConditions.get('loading'),
    hasAcceptedDisclaimer: state.questionnaire.hasAcceptedDisclaimer,
    getResultTeaser: state.questionnaire.getResultTeaser,
    resultTeaser: state.questionnaire.resultTeaser,
    savedValues: state.questionnaire.values,
  })),
  withRouter
)(Results);
