import React from 'react';
import CoBrandPlaceholder from './icons/CoBrandPlaceholder';

const CoBrand = ({ className, height = 40, imageUrl, brandName, preview, nonFitter }) => {
  return (
    <div className={className}>
      <div className={`${className}-logo`}>
        {imageUrl && <img alt={`${brandName} Logo`} src={imageUrl} height={height} />}
        {!imageUrl && preview && <CoBrandPlaceholder />}
      </div>
      {!nonFitter && <div className="co-brand--pipe" />}
    </div>
  );
};

export default CoBrand;
