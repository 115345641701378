import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLoginUrl } from '../common/utils/oidc-url-builders';
import compose from 'lodash/flowRight';

class Login extends Component {
  componentDidMount() {
    window.analytics.page("Login Page"); 
    window.analytics.identify();
    if (this.props.token) {
      document.location.replace(`/redirect`);
    } else {
      document.location.replace(getLoginUrl());
    }
  }

  render() {
    return null;
  }
}

export default compose(
  connect(state => ({
    token: state.auth.token,
  })),
  withRouter
)(Login);
