import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from '../../../common/forms/Slider';
import numeral from 'numeral';
import TooltipIcon from '../../../common/components/TooltipIcon';
import LocalizedStrings from 'react-localization';
import { connect as formikConnect } from 'formik';
import compose from 'lodash/flowRight';

const strings = new LocalizedStrings({
  en: {
    ballSpeed: 'Ball Speed (mph)',
    speedUnits: 'mph',
    launchAngle: 'Launch Angle (deg)',
    spinRate: 'Spin Rate (rpm)',
    spinRateUnits: 'rpm',
    ballSpeedTooltip: `We know that higher initial ball speeds produced by the player initiates more rise in the flight. Our algorithms identify balls that are projected to optimize their aerodynamics based on the speed you generate. For faster speeds, this could be a ball with less lift to prevent wasting energy in vertical flight (aka “ballooning”). For slower speeds, this could be a ball with more lift to keep it carrying in the air longer.`,
    launchAngleTooltip: `Your initial launch angle is primarily driven by your driver fitting and swing mechanics, but our testing and algorithms have identified initial launch angles changing by over 1°. Consider the ball as the final equipment piece to synergistically optimize your driving.`,
    spinRateTooltip: `If you are struggling to get optimal spin through your driver fitting, Ballnamic attempts to help you identify balls that may increase performance.  Not only do we see estimated down-range flight differences between balls, but we’ve also seen initial spin vary by as much as 600-rpm!`,
  },
});

class DriverConditionsKnown extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
  };

  render() {
    const { submittingCalcLaunchConditions, isActive } = this.props;
    const { values, setFieldValue } = this.props.formik;

    return (
      <div className="questionnaire-blocks__container">
        <Slider
          label={strings.ballSpeed}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>{strings.ballSpeedTooltip}</TooltipIcon>
          }
          displayValue={`${values.driver_ball_speed}${strings.speedUnits}`}
          sliderProps={{
            tabIndex: isActive ? undefined : -1,
            disabled: submittingCalcLaunchConditions,
            min: 80,
            max: 200,
            step: 1,
            marks: {
              80: <div className="slider--mark">80</div>,
              200: <div className="slider--mark">200</div>,
            },
            value: values.driver_ball_speed,
            onChange: value => setFieldValue('driver_ball_speed', value),
          }}
        />
        <Slider
          label={strings.launchAngle}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>{strings.launchAngleTooltip}</TooltipIcon>
          }
          displayValue={`${numeral(values.driver_launch_angle).format('0.0')}˚`}
          sliderProps={{
            tabIndex: isActive ? undefined : -1,
            disabled: submittingCalcLaunchConditions,
            min: 6,
            max: 18,
            step: 0.5,
            marks: {
              6: <div className="slider--mark">6</div>,
              18: <div className="slider--mark">18</div>,
            },
            value: values.driver_launch_angle,
            onChange: value => setFieldValue('driver_launch_angle', value),
          }}
        />
        <Slider
          label={strings.spinRate}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>{strings.spinRateTooltip}</TooltipIcon>
          }
          displayValue={`${numeral(values.driver_spin_rate).format('0,0')}${strings.spinRateUnits}`}
          sliderProps={{
            tabIndex: isActive ? undefined : -1,
            disabled: submittingCalcLaunchConditions,
            min: 1400,
            max: 3800,
            step: 50,
            marks: {
              1400: <div className="slider--mark">1400</div>,
              3800: <div className="slider--mark">3800</div>,
            },
            value: values.driver_spin_rate,
            onChange: value => setFieldValue('driver_spin_rate', value),
          }}
        />
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    submittingCalcLaunchConditions: state.questionnaire.calculateDriverLaunchConditions.get('loading'),
  })),
  formikConnect
)(DriverConditionsKnown);
