import React from 'react';
import Svg, { Path } from 'svgs';

const Arroba = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M4 3h14c.5304 0 1.0391.21071 1.4142.58579C19.7893 3.96086 20 4.46957 20 5v7.08c-1.55-.26-3.08.1-4.32.92H12v4h1.08c-.11.68-.11 1.35 0 2H4c-.53043 0-1.03914-.2107-1.41421-.5858C2.21071 18.0391 2 17.5304 2 17V5c0-.53043.21071-1.03914.58579-1.41421C2.96086 3.21071 3.46957 3 4 3Zm0 4v4h6V7H4Zm8 0v4h6V7h-6Zm-8 6v4h6v-4H4Z" />
      <Path
        stroke={fill || 'currentColor'}
        strokeWidth=".5"
        d="M18.7619 15.9048h-.5714v1.9047l1.6305.9676.2742-.4609-1.3333-.7924v-1.619ZM18.5714 14c-.9093 0-1.7814.3612-2.4243 1.0042-.643.643-1.0042 1.5151-1.0042 2.4244H14l1.5086 1.5352 1.539-1.5352h-1.1428c0-.7073.2809-1.3855.781-1.8856s1.1784-.7811 1.8856-.7811c.7073 0 1.3855.281 1.8856.7811s.7811 1.1783.7811 1.8856c0 .7072-.281 1.3855-.7811 1.8856s-1.1783.781-1.8856.781c-.7352 0-1.4019-.3009-1.8819-.7847l-.5409.5409c.6209.6248 1.4704 1.0057 2.4228 1.0057.9093 0 1.7814-.3612 2.4244-1.0042C21.6388 19.21 22 18.3379 22 17.4286c0-.9093-.3612-1.7814-1.0042-2.4244-.643-.643-1.5151-1.0042-2.4244-1.0042Z"
      />
    </Svg>
  );
};

export default Arroba;
