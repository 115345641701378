import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import Toggle from 'react-toggle';
import compose from 'lodash/flowRight';
import { updateUserProfile } from '../../user-actions';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import Button from '../../../common/components/Button';
import FieldWithLabelAndValidation from '../../../common/forms/FieldWithLabelAndValidation';
import * as Yup from 'yup';

const strings = new LocalizedStrings({
  en: {
    email: 'Email',
    results: 'Results',
    sendMeAllResultsEmails: 'Send me all results emails',
    saveResultsEmailPreference: 'Save results email preference',
    mustBeAValidEmailAddress: 'Must be a valid email address',
    emailIsRequired: 'Email address is required',
  },
});

class FitterResultsEmailForm extends Component {
  render() {
    const { loggedInUser, values, setFieldValue } = this.props;

    if (loggedInUser.get('type') !== 'fitter') {
      return null;
    }

    return (
      <div className="fitter-results-email-form">
        <Form>
          <h3>{strings.results}</h3>
          <div className="fitter-results-email-form__inner">
            <div className="toggle--container">
              <label>
                <Toggle
                  checked={values.send_fitter_results}
                  icons={false}
                  onChange={event => {
                    setFieldValue('send_fitter_results', !values.send_fitter_results);
                  }}
                  aria-selected={!!loggedInUser.get('fitter_results_email')}
                  aria-label={strings.sendMeAllResultsEmails}
                />
                <span className="toggle-label">{strings.sendMeAllResultsEmails}</span>
              </label>
            </div>
            {values.send_fitter_results && (
              <FieldWithLabelAndValidation
                label={strings.email}
                fieldProps={{
                  name: 'fitter_results_email',
                  type: 'text',
                }}
              />
            )}
            <Button buttonStyle="primary" type="submit">
              {strings.saveResultsEmailPreference}
            </Button>
          </div>
        </Form>
        <hr />
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    userProfileUpdated: state.user.updateUserProfile.get('loaded'),
  })),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => ({
      send_fitter_results: !!props.loggedInUser.get('fitter_results_email'),
      fitter_results_email: props.loggedInUser.get('fitter_results_email') || '',
    }),
    validationSchema: Yup.object().shape({
      send_fitter_results: Yup.boolean(),
      fitter_results_email: Yup.string().when('send_fitter_results', {
        is: val => !!val,
        then: Yup.string()
          .trim()
          .email(strings.mustBeAValidEmailAddress)
          .required(strings.emailIsRequired),
        otherwise: Yup.string(),
      }),
    }),
    handleSubmit(values, { props }) {
      return props.dispatch(
        updateUserProfile({
          fitter_results_email: values.send_fitter_results ? values.fitter_results_email.trim() : '',
        })
      );
    },
  })
)(FitterResultsEmailForm);
