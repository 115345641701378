export function trackRedeemNow(code){
    window.analytics.track('Redeem Now', {
        fitting_code: code
    });
}

export function trackShareVoucher(payload){
    const { sender, email, code} = payload;
    window.analytics.track('Fitting Code Shared', {
        sender_email: sender,
        recipient_email: email,
        fitting_code: code
    });
}