import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';
import { fetchLoggedInUser } from './auth-actions';

class Redirector extends Component {
  componentDidMount() {
    const { token, dispatch, loggedInUser } = this.props;
    if (!token) {
      this.props.history.replace('/login');
    } else if (!loggedInUser) {
      dispatch(fetchLoggedInUser());
    } else {
      this.redirectLoggedInUser();
    }
  }

  componentDidUpdate(prevProps) {
    const { loggedInUser } = this.props;
    if (!prevProps.loggedInUser && loggedInUser) {
      this.redirectLoggedInUser();
    }
  }

  redirectLoggedInUser() {
    const { loggedInUser, history } = this.props;

    if (loggedInUser.get('deleted_at')) {
      history.replace('/no-access');
    } else if (loggedInUser.get('type') === 'admin') {
      history.replace('/admin');
    } else if (localStorage.getItem('action') === 'redeemVoucher'){          
      history.replace('/my-fitting');
    } else{
      history.replace('/my-account');
    }
  }

  render() {
    return null;
  }
}

export default compose(
  withRouter,
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    token: state.auth.token,
  }))
)(Redirector);
