import client from './contentful-client';
import previewClient from './contentful-preview-client';
import {
  GET_ARTICLES,
  GET_ARTICLE,
  GET_FAQS,
  GET_ACTIVE_ANNOUNCEMENT,
  DISMISS_ANNOUNCEMENT,
} from './contentful-action-constants';
import localstorage from 'store2';

export function getArticles(query = null) {
  return {
    type: GET_ARTICLES,
    promise: client.getEntries({ content_type: 'article', limit: 1000, query }),
  };
}

export function getArticle(slug) {
  return {
    type: GET_ARTICLE,
    promise: client.getEntries({ content_type: 'article', 'fields.slug': slug }).then(results => {
      if (results.total !== 1) {
        throw new Error('Article slug not found');
      } else {
        return results.items[0];
      }
    }),
  };
}

export function getArticlePreview(slug) {
  return {
    type: GET_ARTICLE,
    promise: previewClient.getEntries({ content_type: 'article', 'fields.slug': slug }).then(results => {
      if (results.total !== 1) {
        throw new Error('Article slug not found');
      } else {
        return results.items[0];
      }
    }),
  };
}

export function getFAQs() {
  return {
    type: GET_FAQS,
    promise: client.getEntries({ content_type: 'faq', limit: 1000, order: 'sys.createdAt' }),
  };
}

export function getActiveAnnouncement() {
  return {
    type: GET_ACTIVE_ANNOUNCEMENT,
    promise: client.getEntries({ content_type: 'activeAnnouncement', limit: 1000 }),
  };
}

export function dismissAnnouncement(announcementId) {
  const dismissedAnnouncements = localstorage.get('dismissedAnnouncements') || [];
  dismissedAnnouncements.push(announcementId);
  localstorage.set('dismissedAnnouncements', dismissedAnnouncements);
  return {
    type: DISMISS_ANNOUNCEMENT,
    dismissedAnnouncements,
  };
}
