import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connect as formikConnect } from 'formik';
import classNames from 'classnames';
import ReactCardFlip from 'react-card-flip';
import Flippable from '../../common/components/Flippable';
import compose from 'lodash/flowRight';
import { calculateLaunchConditionsIfNeeded, areLaunchConditionsKnown } from './utils';
import { driverLaunchConditionsKnown, ironLaunchConditionsKnown } from './questionnaire-actions';

class LaunchConditionsFlipper extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['driver', 'iron']).isRequired,
    unknownButtonText: PropTypes.string.isRequired,
    knownButtonText: PropTypes.string.isRequired,
    KnownForm: PropTypes.elementType.isRequired,
    UnknownForm: PropTypes.elementType.isRequired,
  };

  render() {
    const {
      dispatch,
      loggedInUser,
      savedValues,
      type,
      unknownButtonText,
      knownButtonText,
      KnownForm,
      UnknownForm,
      isSixIronFitting,
    } = this.props;
    const { setFieldValue, values } = this.props.formik;

    const conditionsKnown = areLaunchConditionsKnown(type, values, loggedInUser);

    const knownButtonClasses = classNames({
      'flipper-button': true,
      active: conditionsKnown,
    });

    const unknownButtonClasses = classNames({
      'flipper-button': true,
      active: !conditionsKnown,
    });

    return (
      <div className="launch-conditions-flipper">
        <div className="flipper--tab__wrapper">
          <button
            className={unknownButtonClasses}
            type="button"
            onClick={() => {
              setFieldValue(`${type}_launch_conditions_known`, false);

              if (type === 'iron') {
                dispatch(ironLaunchConditionsKnown(false));
              } else if (type === 'driver') {
                dispatch(driverLaunchConditionsKnown(false));
              }
            }}
          >
            {unknownButtonText}
          </button>
          <button
            className={knownButtonClasses}
            type="button"
            onClick={async () => {
              setFieldValue(`${type}_launch_conditions_known`, true);
              if (type === 'iron') {
                dispatch(ironLaunchConditionsKnown(true));
              } else if (type === 'driver') {
                dispatch(driverLaunchConditionsKnown(true));
              }

              calculateLaunchConditionsIfNeeded(
                type,
                Object.assign({}, values, { [`${type}_launch_conditions_known`]: true }),
                savedValues.toJS(),
                dispatch,
                loggedInUser
              );
            }}
          >
            {knownButtonText}
          </button>
        </div>
        <ReactCardFlip cardZIndex={99} isFlipped={!conditionsKnown}>
          <Flippable isActive={conditionsKnown}>
            <KnownForm isActive={conditionsKnown} isSixIronFitting={isSixIronFitting} />
          </Flippable>
          <Flippable isActive={!conditionsKnown}>
            <UnknownForm isActive={!conditionsKnown} isSixIronFitting={isSixIronFitting} />
          </Flippable>
        </ReactCardFlip>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    savedValues: state.questionnaire.values,
  })),
  formikConnect
)(LaunchConditionsFlipper);
