import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchLoggedInUser } from '../../auth/auth-actions';
import FitterLayout from '../../fitting/FitterLayout';
import LandingPageHeader from '../../landing-page/LandingPageHeader';
import LoadingIndicator from '../components/LoadingIndicator';

class LibraryLayout extends Component {
  componentDidMount() {
    const { dispatch, token, loggedInUser, fetchingLoggedInUser } = this.props;

    if (token && !loggedInUser && !fetchingLoggedInUser) {
      dispatch(fetchLoggedInUser());
    }
  }

  render() {
    const { fetchingLoggedInUser, loggedInUser } = this.props;

    if (fetchingLoggedInUser) {
      return <LoadingIndicator />;
    }

    const Wrapper = loggedInUser
      ? FitterLayout
      : props => (
          <div>
            <LandingPageHeader />
            {props.children}
          </div>
        );

    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

export default connect(state => ({
  token: state.auth.token,
  loggedInUser: state.auth.loggedInUser,
  fetchingLoggedInUser: state.auth.fetchLoggedInUser.get('loading'),
}))(LibraryLayout);
