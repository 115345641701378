import { SUBMIT_RESULTS_PAYMENT } from '../../payment/payment-action-constants';
import { FETCH_LOGGED_IN_USER, REGISTER } from '../../auth/auth-action-constants';
import { updateDataLayer, triggerGTMEvent } from './gtm';

const actionTypeToEventStringMap = {
  [`${SUBMIT_RESULTS_PAYMENT}_SUCCESS`]: 'transaction_completed',
  [`${REGISTER}_SUCCESS`]: 'registration_completed',
};
const eventTypesThatFireGTMEvents = Object.keys(actionTypeToEventStringMap);

const gtmEventMiddleware = store => next => action => {
  const dataLayer = {};

  const loggedInUser = store.getState().auth.loggedInUser;
  dataLayer.userId = (loggedInUser && loggedInUser.get('id')) || null;

  if (action.type === `${FETCH_LOGGED_IN_USER}_SUCCESS` && action.json.id !== dataLayer.userId) {
    dataLayer.userId = action.json.id;
    updateDataLayer(dataLayer);
  } else if (eventTypesThatFireGTMEvents.includes(action.type)) {
    const event = actionTypeToEventStringMap[action.type];
    triggerGTMEvent(event, dataLayer);
  }
  return next(action);
};

export default gtmEventMiddleware;
