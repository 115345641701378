import React, { createContext, useState } from 'react';
import { connect } from 'react-redux';
import PurchaseForm from '../purchase/PurchaseForm'


export const PurchaseFormContext = createContext();

const PurchaseFormProvider = ({ children, loggedInUser }) => {
  const [showPurchase, setShowPurchase] = useState(false);
  const [initialFittingType, setInitialFittingType] = useState('premium');

  const handlePurchaseForm = (fittingType) => {
    setInitialFittingType(fittingType);
    setShowPurchase(!showPurchase);
  };

  const email = loggedInUser?.get('oidcData')?.get('email');
  const firstName = loggedInUser?.get('first_name');
  const lastName = loggedInUser?.get('last_name');
  const braintreeCustomerId = loggedInUser?.get('braintree_customer_id');
  const userId = loggedInUser?.get('id');
  const initialPlayingZip = loggedInUser?.get('playing_zip');
  const isAuthenticatedUser = email != null;  


  return (
    <PurchaseFormContext.Provider value={{ showPurchase, handlePurchaseForm }}>
      {children}
      {showPurchase && (
        <PurchaseForm 
          isOpened={showPurchase} 
          onCancel={handlePurchaseForm} 
          initialEmail={email} 
          initialFirstName={firstName}
          initialLastName={lastName}
          isAuthenticatedUser={isAuthenticatedUser} 
          braintreeCustomerId={braintreeCustomerId}
          userId={userId}
          initialPlayingZip={initialPlayingZip}
          initialFittingType={initialFittingType}
        />
      )}
    </PurchaseFormContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.loggedInUser,
});

export default connect(mapStateToProps)(PurchaseFormProvider);