import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { getBillingHistory } from '../payment/payment-actions';
import LoadingIndicator from '../common/components/LoadingIndicator';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Dinero from 'dinero.js';
import querystring from 'query-string';
import compose from 'lodash/flowRight';

import Visa from '../common/components/icons/Visa';
import Mastercard from '../common/components/icons/Mastercard';
import Amex from '../common/components/icons/Amex';
import Discover from '../common/components/icons/Discover';
import DinersClub from '../common/components/icons/DinersClub';
import Jcb from '../common/components/icons/Jcb';
import UnionPay from '../common/components/icons/UnionPay';

const strings = new LocalizedStrings({
  en: {
    billingHistory: 'Billing History',
    date: 'Date',
    name: 'Name',
    amount: 'Amount',
    paymentMethod: 'Payment Method',
    noBillingHistory: 'No billing history',
  },
});

const DEFAULT_PAGE_SIZE = 20;

class BillingHistory extends Component {
  componentDidMount() {
    this.fetchPage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchPage();
    }
  }

  fetchPage() {
    const { dispatch, location, loggedInUser } = this.props;
    const searchQuery = querystring.parse(location.search);
    dispatch(getBillingHistory(searchQuery.page || 0, this.getPageSize(), loggedInUser.getIn(['oidcData', 'email'])));
  }

  getPageSize() {
    const { location } = this.props;
    const searchQuery = querystring.parse(location.search);
    return searchQuery.page_size || DEFAULT_PAGE_SIZE;
  }

  formatAmount(transaction) {
    const price = Dinero({
      amount: parseInt(transaction.get('amount').replace(/[^\d]/, ''), 10),
      currency: transaction.get('currencyIsoCode'),
    });
    return price.toFormat('$0,0.00');
  }

  getSvgForBrand(brand) {
    if (brand === 'Visa') {
      return <Visa />;
    }
    if (brand === 'MasterCard') {
      return <Mastercard />;
    }
    if (brand === 'American Express') {
      return <Amex />;
    }
    if (brand === 'Discover') {
      return <Discover />;
    }
    if (brand === 'DinersClub') {
      return <DinersClub />;
    }
    if (brand === 'Jcb') {
      return <Jcb />;
    }
    if (brand === 'UnionPay') {
      return <UnionPay />;
    }
  }

  render() {
    const { transactions, loadingBillingHistory } = this.props;

    if(!transactions) return null;
    if(loadingBillingHistory) return <LoadingIndicator />;

    const displayedTransactions = transactions.get('results')?.slice(0, 5) || [];

    return (
      <div className="billing-history__wrapper">
        {transactions.get('total') === 0 ? (
          <div>{strings.noBillingHistory}</div>
        ) : (
          <div className="billing-history-list">
            {displayedTransactions.map((transaction, id) => (
              <div key={id} className="billing-history-item">
                <div className="billing-history-info">
                  <div className="order-id">Order #{transaction.get('id')}</div>
                  <div className="date">{moment(transaction.get('createdAt')).format('L')}</div>
                </div>
                <div className="amount">{this.formatAmount(transaction)}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    transactions: state.payment.billingHistory,
    loadingBillingHistory: state.payment.getBillingHistory.get('loading'),
  }))
)(BillingHistory);
