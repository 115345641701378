import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LandingPageHeader from '../landing-page/LandingPageHeader';
import compose from 'lodash/flowRight';
import Footer from '../common/components/Footer';


class FitterLayout extends Component {
  renderNav() {
    const { loggedInUser } = this.props;

    return (
          <LandingPageHeader isLoggedIn={!!loggedInUser} loggedInUser={loggedInUser} />
    );
  }

  render() {
    return (
      <div className="fitter-layout">
        {this.renderNav()}
        <div className="fitter-layout__children">{this.props.children}</div>
        <Footer fitter />
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
  })),
  withRouter
)(FitterLayout);
