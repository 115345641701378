import React, { useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import Preference from './Preference';
import IronToggle from './IronToggle';
import Brands from './Brands';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserSettings } from '../../../user/user-actions';
import { settingsModalStrings } from '../../../fitting/SettingsModal';
import { fetchBrands } from '../../../fitting/Questionnaire/questionnaire-actions';

export const brandTogglingStrings = new LocalizedStrings({
  en: {
    brandToggling: 'Brand Toggling',
    globalSettings: 'Global Settings',
    brands: 'Brands',
  },
});

const BrandToggling = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);
  const loggedInUser = useSelector(state => state.auth.loggedInUser);
  const handleToggle = () => {
    dispatch(updateUserSettings({ show_price_preference: !loggedInUser.get('show_price_preference') }));
  };

  return (
    <div>
      <div className="page--my-profile">
        <div className="container small">
          <h1>{brandTogglingStrings.globalSettings}</h1>
          <div className="container">
            <div className="brand-toggling">
              <Preference
                label={settingsModalStrings.pricePreference}
                checked={loggedInUser.get('show_price_preference')}
                handleToggle={handleToggle}
              />
              <div style={{ marginTop: '50px' }}>
                <IronToggle />
              </div>
              <Brands
                savedBrands={loggedInUser.getIn(['userBrandSetting', 'settings'])}
                user={loggedInUser.get('id')}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="invite-user-form"></div>
      </div>
    </div>
  );
};

export default BrandToggling;
