import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RCSlider from 'rc-slider';

class Slider extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
    labelComponent: PropTypes.node,
    subLabel: PropTypes.string,
    displayValue: PropTypes.node.isRequired,
    sliderProps: PropTypes.object.isRequired,
    tooltip: PropTypes.node,
  };

  render() {
    const {
      label,
      displayValue,
      sliderProps,
      textMarks,
      subLabel,
      tooltip,
      labelComponent,
      displayValueString,
    } = this.props;

    return (
      <div className={`slider__wrapper ${textMarks ? 'text-marks' : ''}`}>
        <div className="slider--headers">
          <div className="label-tooltip__wrapper">
            <h3 className="h3">{label}</h3>
            {tooltip && tooltip}
          </div>
          {labelComponent ? labelComponent : null}
          {subLabel ? <p className="slider--helper-text">{subLabel}</p> : null}
        </div>
        <div className={`slider__inner ${sliderProps.dots ? 'dots' : ''}`}>
          <div className="slider--value">{displayValue}</div>
          <RCSlider
            {...sliderProps}
            ariaLabelForHandle={`${label} Slider Handle`}
            ariaLabelledByForHandle={label}
            ariaValueTextFormatterForHandle={() => {
              // Return a human-readable text alternative for the value of the slider
              if (typeof displayValue === 'object' && displayValue !== null) {
                return displayValueString;
              }
              return displayValue;
            }}
          />
        </div>
      </div>
    );
  }
}

export default Slider;
