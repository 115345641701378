import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import numeral from 'numeral';
import classNames from 'classnames';
import CrossSectionModal from './CrossSectionModal';
import TooltipIcon from '../../../../common/components/TooltipIcon';
import ChevronLeftIcon from '../../../../common/components/icons/ChevronLeft';
import ChevronRightIcon from '../../../../common/components/icons/ChevronRight';
import TriangleDownIcon from '../../../../common/components/icons/TriangleDown';
import TriangleUpIcon from '../../../../common/components/icons/TriangleUp';
import TrophyIcon from '../../../../common/components/icons/Trophy';
import DriverDistanceIcon from '../../../../common/components/icons/DriverDistance';
import DriverHeightIcon from '../../../../common/components/icons/DriverHeight';
import DriverWindScoreIcon from '../../../../common/components/icons/DriverWindScore';
import GreensideSpinIcon from '../../../../common/components/icons/GreensideSpin';
import IronCarryIcon from '../../../../common/components/icons/IronCarry';
import IronRollIcon from '../../../../common/components/icons/IronRoll';
import IronWorkIcon from '../../../../common/components/icons/IronWork';
import PutterFeelIcon from '../../../../common/components/icons/PutterFeel';
import WedgeSpinIcon from '../../../../common/components/icons/WedgeSpin';
import BallCrossSectionIcon from '../../../../common/components/icons/BallCrossSection';
import FlierPreventionIcon from '../../../../common/components/icons/FlierPrevention';
import LocalizedStrings from 'react-localization';
import StarIcon from '../../../../common/components/icons/Star';
import confidenceIntervalStrings from '../confidence-interval-strings';
// import { InlineShareButtons } from 'sharethis-reactjs';
// import config from '../../../../config';
// import uuid from 'uuid';
import { connect } from 'react-redux';
import localstorage from 'store2';

const strings = new LocalizedStrings({
  en: {
    setFavoriteBall: 'Set Favorite Ball',
    setAsFavoriteBall: '{ballYear} {ballBrand} {ballName}',
    currentBall: 'Current Ball',
    favoriteBall: 'Favorite Ball',
    showPreviousResult: 'Show previous result',
    showNextResult: 'Show next result',
    changeRelativeToFavoriteBall: 'Change relative to favorite ball: {value}',
    trophy: 'Trophy',
    buyNow: 'Buy Now',
    outstandingPerformance: 'Outstanding Performance',
    driverDistance: 'Driver Distance',
    driverHeight: 'Driver Height',
    driverWindScore: 'Driver Wind Score',
    sevenIronCarry: '7-Iron Carry',
    sixIronCarry: '6-Iron Carry',
    sevenIronWorkabilityScore: '7-Iron Workability Score',
    sixIronWorkabilityScore: '6-Iron Workability Score',
    sevenIronRoll: '7-Iron Roll',
    sixIronRoll: '6-Iron Roll',
    flierPreventionScore: 'Flier Prevention Score',
    fullWedgeSpinScore: 'Full Wedge Spin Score',
    greensideSpin: 'Greenside Spin',
    putterFeel: 'Putter Feel',
    resultList: 'Result List',
    shopForTopMatchNow: 'Shop for Top Match Now',
  },
});

const RESULTS_PER_PAGE = 3;
const RESULTS_PER_PAGE_MOBILE = 2;
const MOBILE_RESULTS_WIDTH = 900;

class ResultList extends Component {
  static propTypes = {
    results: PropTypes.instanceOf(Immutable.List).isRequired,
    tooltipText: PropTypes.instanceOf(Immutable.Map).isRequired,
    relativeResults: PropTypes.instanceOf(Immutable.List),
    questionnaireValues: PropTypes.instanceOf(Immutable.Map),
    favoritedBall: PropTypes.string,
    currentBall: PropTypes.string,
    setFavorite: PropTypes.func,
    isSixIronFitting: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      cursor: 0,
      isMobile: false,
      showCrossSection: null,
    };

    this.updateResultsDisplay = this.updateResultsDisplay.bind(this);

    this.promoCode = JSON.parse(localstorage.get('promoCode'));
  }

  componentDidMount() {
    let results = [...this.getDisplayedResults(5)];
    let resultData = [];

    let currentBall = this.props.currentBall;
    if (!currentBall) {
      const { year, brand, model } = this.props.questionnaireValues.toJS();
      currentBall = `${year} ${brand} ${model}`;
    }

    for (var i = 0; i < results.length; i++) {
      var data = results[i].get('display');
      var isCB = false;
      if (results[i].getIn(['display', 'Ball']) === currentBall) {
        isCB = true;
      }

      let ball_data = {
        ball: data.get('Ball'),
        match_rate: data.get('MatchPercentage'),
        outstanding_performance: data.get('TopPerformance'),
        driver_distance: data.get('DriverTotal'),
        driver_height: data.get('DriverHeight'),
        driver_wind_score: data.get('DriverWind'),
        seven_iron_carry: data.get('IronCarry'),
        seven_iron_workability_score: data.get('IronWorkability'),
        seven_iron_roll: data.get('IronRoll'),
        flier_prevention_score: data.get('FlierPrevention'),
        wedge_spin_score: data.get('FullWedgeSpin'),
        greenside_spin: data.get('GreensideSpin'),
        putter_feel: data.get('PutterFeel'),
        ranking: i + 1,
        current_ball: isCB,
        image_url: results[i].getIn(['info', 'BallLogoImageURL']),
      };

      resultData.push(ball_data);
    }
    const { loggedInUser } = this.props;
    // FIXME: this needs work
    if (loggedInUser) {
      if (loggedInUser.get('transaction')) {
        /* perhaps order completed should go here? so we can have orderID */
        window.analytics.track('Fitting Results', {
          fitting_id: null,
          order_id: loggedInUser.get('transaction').get('transactionId'),
          results: resultData,
        });
        window.gtag('event', 'Fitting Results', {
          fitting_id: null,
          order_id: loggedInUser.get('transaction').get('transactionId'),
          results: resultData,
        });
      } else {
        window.analytics.track('Fitting Results', {
          fitting_id: null,
          results: resultData,
        });
        window.gtag('event', 'Fitting Results', {
          fitting_id: null,
          results: resultData,
        });
      }
    }

    window.innerWidth < MOBILE_RESULTS_WIDTH && this.setState({ isMobile: true });
    window.addEventListener('resize', this.updateResultsDisplay);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateResultsDisplay);
  }

  updateResultsDisplay() {
    const { isMobile, cursor } = this.state;
    if (window.innerWidth > MOBILE_RESULTS_WIDTH && isMobile) {
      this.setState({ isMobile: false });
      if (cursor >= RESULTS_PER_PAGE) {
        this.setState({ cursor: RESULTS_PER_PAGE - 1 });
      }
    } else if (window.innerWidth <= 900 && !isMobile) {
      this.setState({ isMobile: true });
    }
  }

  isSixIronFitting() {
    return this.props.isSixIronFitting;
  }

  sliceResults(results, cursor, resultsPerPage) {
    if (cursor + resultsPerPage > results.size) {
      const updatedCursor = cursor - 1;
      return results.slice(updatedCursor, updatedCursor + resultsPerPage);
    }

    return results.slice(cursor, cursor + resultsPerPage);
  }

  getDisplayedResults(resultsPerPage) {
    return this.sliceResults(this.props.results, this.state.cursor, resultsPerPage);
  }

  getDisplayedRelativeResults(resultsPerPage) {
    if (!this.props.relativeResults) {
      return null;
    }

    return this.sliceResults(this.props.relativeResults, this.state.cursor, resultsPerPage);
  }

  isCurrentBall(result) {
    if (!this.props.currentBall && !this.props.questionnaireValues) {
      return null;
    }
    let currentBall = this.props.currentBall;
    if (!currentBall) {
      const { year, brand, model } = this.props.questionnaireValues.toJS();
      currentBall = `${year} ${brand} ${model}`;
    }
    return result.getIn(['display', 'Ball']) === currentBall;
  }

  isFavoriteBall(result) {
    return result.getIn(['display', 'Ball']) === this.props.favoritedBall;
  }

  getColumnClassname(result) {
    if (this.isFavoriteBall(result)) {
      return 'favorited';
    }
    return '';
  }

  renderCrossSectionButton(index) {
    return (
      <button
        type="button"
        className="cross-section--button"
        onClick={() => this.setState({ showCrossSection: index })}
        aria-label="Show cross-section"
        aria-haspopup="true"
      >
        <BallCrossSectionIcon fill="#5057FF" />
      </button>
    );
  }

  renderResultHeadings(resultsPerPage, isMobile = false) {
    const results = this.getDisplayedResults(resultsPerPage);

    const Wrapper = isMobile ? 'div' : 'th';

    const contents = results.map((result, index) => (
      <Wrapper
        key={index}
        className={classNames('ball-header--th', this.isFavoriteBall(result) && 'favorite-ball--td')}
        id={
          result.getIn(['info', 'Brand']).replace(/\s+/g, '_') +
          '_' +
          result.getIn(['info', 'Model']).replace(/\s+/g, '_')
        }
        {...(!isMobile && { scope: 'col' })}
      >
        <div className={classNames('ball-header', this.getColumnClassname(result))}>
          {this.isFavoriteBall(result) ? <div className="ball-tag--favorite">{strings.favoriteBall}</div> : null}
          <div className="ball-logo">
            <img src={result.getIn(['info', 'BrandImageURL'])} alt={`${result.getIn(['info', 'Brand'])} Logo`} />
          </div>
          <div className="ball-name">
            <span>{result.getIn(['info', 'Model'])}</span>
            {this.isCurrentBall(result) ? (
              <Fragment>
                <span className="current-ball--bullet">&bull;</span>
                <span className="ball-tag--current">{strings.currentBall}</span>
              </Fragment>
            ) : null}
          </div>
          <div className="ball-match">
            {numeral(result.getIn(['display', 'MatchPercentage']) * 100).format('0.0')}% Match
          </div>
          <div className="ball-image">
            <img
              src={result.getIn(['info', 'BallLogoImageURL'])}
              alt={result.getIn(['info', 'Brand']) + ' ' + result.getIn(['info', 'Model']) + ', '}
            />
            {this.renderCrossSectionButton(index)}
          </div>
          {this.promoCode?.code !== null &&
            this.promoCode?.code === 'Golf' &&
            result.getIn(['info', 'FairwayJockeyURL']) && (
              <div className="ball-buy-now">
                <button
                  type="button"
                  id={'button-buy-now' + result.getIn(['info', 'Brand']).replace(/\s+/g, '_')}
                  className="button button--color--primary"
                  onClick={e => {
                    const url = result.getIn(['info', 'FairwayJockeyURL']);
                    window.open(url, '_blank');
                  }}
                >
                  {strings.buyNow}
                </button>
              </div>
            )}
        </div>
      </Wrapper>
    ));

    if (isMobile) {
      return <div className="main-ball-info">{contents}</div>;
    } else {
      return <Fragment>{contents}</Fragment>;
    }
  }

  renderRelativeValue(resultsPerPage, index, key) {
    const relativeResults = this.getDisplayedRelativeResults(resultsPerPage);

    const changeUnitsMap = {
      DriverWind: ' yds',
      FullWedgeSpin: '% rpm',
      FlierPrevention: '% rpm',
      DriverTotal: ' yds',
      DriverHeight: ' ft',
      IronCarry: ' yds',
      IronRoll: ' ft',
    };

    const units = changeUnitsMap[key] || '';

    if (relativeResults && relativeResults.getIn([index, key])) {
      const relativeValue = relativeResults.getIn([index, key]);
      if (relativeValue > 0) {
        return (
          <span
            className="change--positive"
            aria-label={strings.formatString(strings.changeRelativeToFavoriteBall, {
              value: `+${relativeValue}`,
            })}
          >
            <TriangleUpIcon size={24} /> +{relativeValue}
            {units}
          </span>
        );
      } else if (relativeValue < 0) {
        return (
          <span
            className="change--negative"
            aria-label={strings.formatString(strings.changeRelativeToFavoriteBall, {
              value: `${relativeValue}`,
            })}
          >
            <TriangleDownIcon size={24} /> {relativeValue}
            {units}
          </span>
        );
      } else {
        return null;
      }
    }
    return null;
  }

  renderValueColumns(resultsPerPage, key, renderCallback = value => value, isMobile = false, label = 'label') {
    const results = this.getDisplayedResults(resultsPerPage);

    const Wrapper = isMobile ? 'div' : 'td';

    const contents = results.map((result, index) => (
      <Wrapper
        key={index}
        className={classNames(
          'ball-data--td',
          this.getColumnClassname(result),
          this.isFavoriteBall(result) && 'favorite-ball--td'
        )}
        headers={
          result.getIn(['info', 'Brand']).replace(/\s+/g, '_') +
          '_' +
          result.getIn(['info', 'Model']).replace(/\s+/g, '_') +
          ' ' +
          label
        }
      >
        <div className="inner" aria-label={strings.trophy}>
          <div className="value-trophy__wrapper">
            {result.getIn(['win', key]) === 1 ? (
              <span className="trophy">
                <TrophyIcon fill="#EDAE49" aria-label={strings.trophy} />
              </span>
            ) : null}
            <span className="value">{renderCallback(result.getIn(['display', key]))}</span>
          </div>
          {this.renderRelativeValue(resultsPerPage, index, key)}
        </div>
      </Wrapper>
    ));

    if (isMobile) {
      return <div className="value-columns__mobile">{contents}</div>;
    } else {
      return <Fragment>{contents}</Fragment>;
    }
  }

  renderBackAndForwardButtons(resultsPerPage) {
    const { results } = this.props;

    return (
      <div className="results--arrow-buttons__wrapper">
        <button
          className="results--arrow-button button--icon"
          type="button"
          disabled={this.state.cursor === 0}
          onClick={() => this.setState(prevState => ({ cursor: prevState.cursor - 1 }))}
          aria-label={strings.showPreviousResult}
        >
          <ChevronLeftIcon size={40} />
        </button>
        <button
          className="results--arrow-button button--icon"
          type="button"
          disabled={this.state.cursor + resultsPerPage >= results.size}
          onClick={() => this.setState(prevState => ({ cursor: prevState.cursor + 1 }))}
          aria-label={strings.showNextResult}
        >
          <ChevronRightIcon size={40} />
        </button>
      </div>
    );
  }

  renderFavoriteCheckboxes(resultsPerPage, isMobile) {
    const results = this.getDisplayedResults(resultsPerPage);

    if (!this.props.setFavorite) {
      return null;
    }

    const classes = classNames({
      'favorite-checkboxes__fieldset': true,
      'favorite-checkboxes__mobile': isMobile,
    });

    return (
      <fieldset className={classes}>
        <div className="favorite-ball__fieldset--inner">
          <legend className="legend-desktop">
            <StarIcon size={32} fill="#5057FF" />
            <span>{strings.setFavoriteBall}</span>
          </legend>
          {results.map((result, index) => {
            const checked = this.isFavoriteBall(result);
            return (
              <div key={index}>
                <div className="favorite-ball--input__wrapper">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={() => {
                        if (checked) {
                          this.props.setFavorite(null);
                        } else {
                          this.props.setFavorite(result.getIn(['display', 'Ball']));
                        }
                      }}
                    />
                    <span>
                      {strings.formatString(strings.setAsFavoriteBall, {
                        ballName: result.getIn(['display', 'DisplayBall']),
                      })}
                    </span>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </fieldset>
    );
  }

  renderResultsRow(isMobile, icon, label, resultKey, renderCallback) {
    const { tooltipText } = this.props;

    const Tr = isMobile ? 'div' : 'tr';
    const Th = isMobile ? 'div' : 'th';

    const confidenceIntervalStringKeyMap = {
      DriverTotal: 'carryDistanceRange',
      DriverHeight: 'driverMaxHeightRange',
      DriverWind: 'windDistanceRange',
      IronCarry: 'ironCarryRange',
      IronRoll: 'ironRollRange',
      IronWorkability: 'ironWorkabilityScoreRange',
      FlierPrevention: 'flierPreventionRange',
      FullWedgeSpin: 'wedgeSpinRange',
    };
    const confidenceIntervalKey = confidenceIntervalStringKeyMap[resultKey];

    return (
      <Tr>
        <Th className="ball-attribute--th" id={label.replace(/\s+/g, '_')} {...(!isMobile && { scope: 'row' })}>
          <div className="inner">
            <div className="attribute-icon">{icon}</div>
            <div className="ball-attribute--label">
              <div>{label}</div>
            </div>
            {tooltipText.has(resultKey) && <TooltipIcon>{this.props.tooltipText.get(resultKey)}</TooltipIcon>}
            {isMobile && (
              <div className="confidence-interval mobile">
                {confidenceIntervalKey ? <span>{confidenceIntervalStrings[confidenceIntervalKey]}</span> : null}
              </div>
            )}
          </div>
          {!isMobile && (
            <div className="confidence-interval">
              {confidenceIntervalKey ? <span>{confidenceIntervalStrings[confidenceIntervalKey]}</span> : null}
            </div>
          )}
        </Th>
        {this.renderValueColumns(
          isMobile ? RESULTS_PER_PAGE_MOBILE : RESULTS_PER_PAGE,
          resultKey,
          renderCallback,
          isMobile,
          label.replace(/\s+/g, '_')
        )}
      </Tr>
    );
  }

  renderResultsBuyNowRow(isMobile) {
    const Tr = isMobile ? 'div' : 'tr';
    const Th = isMobile ? 'div' : 'th';

    const Wrapper = isMobile ? 'div' : 'td';

    const resultsPerPage = isMobile ? RESULTS_PER_PAGE_MOBILE : RESULTS_PER_PAGE;

    const results = this.getDisplayedResults(resultsPerPage);

    // If all info.FairwayJockeyURL are empty strings, return null
    if (
      results.every(result => {
        const url = result.getIn(['info', 'FairwayJockeyURL']);
        return !url || url.trim() === '';
      })
    ) {
      return null;
    }

    const contents = results.map((result, index) => (
      <Wrapper key={index} className="ball-data--td buy-now-bottom">
        {this.promoCode?.code !== null &&
          this.promoCode?.code === 'FairwayJockey' &&
          result.getIn(['info', 'FairwayJockeyURL']) && (
            <div className="inner">
              <button
                type="button"
                id={'down-button-buy-now' + result.getIn(['info', 'Brand']).replace(/\s+/g, '_')}
                className="button button--color--primary"
                onClick={e => {
                  const url = result.getIn(['info', 'FairwayJockeyURL']);
                  window.open(url, '_blank');
                }}
              >
                {strings.buyNow}
              </button>
            </div>
          )}
      </Wrapper>
    ));

    return (
      <Tr>
        <Th className="ball-attribute--th" id="BuyNowButtons" {...(!isMobile && { scope: 'row' })}></Th>
        {isMobile ? <div className="value-columns__mobile">{contents}</div> : <Fragment>{contents}</Fragment>}
      </Tr>
    );
  }

  renderResults(isMobile) {
    return (
      <Fragment>
        {this.renderResultsRow(isMobile, <TrophyIcon />, strings.outstandingPerformance, 'TopPerformance')}
        {this.renderResultsRow(isMobile, <DriverDistanceIcon />, strings.driverDistance, 'DriverTotal', v => `${v} yd`)}
        {this.renderResultsRow(isMobile, <DriverHeightIcon />, strings.driverHeight, 'DriverHeight', v => `${v} ft`)}
        {this.renderResultsRow(isMobile, <DriverWindScoreIcon />, strings.driverWindScore, 'DriverWind')}
        {this.renderResultsRow(isMobile, <IronCarryIcon />, strings.sevenIronCarry, 'IronCarry', v => `${v} yds`)}
        {this.renderResultsRow(isMobile, <IronWorkIcon />, strings.sevenIronWorkabilityScore, 'IronWorkability')}
        {this.renderResultsRow(isMobile, <IronRollIcon />, strings.sevenIronRoll, 'IronRoll', v => `${v} ft`)}
        {this.renderResultsRow(isMobile, <FlierPreventionIcon />, strings.flierPreventionScore, 'FlierPrevention')}
        {this.renderResultsRow(isMobile, <WedgeSpinIcon />, strings.fullWedgeSpinScore, 'FullWedgeSpin')}
        {this.renderResultsRow(isMobile, <GreensideSpinIcon />, strings.greensideSpin, 'GreensideSpin')}
        {this.renderResultsRow(isMobile, <PutterFeelIcon />, strings.putterFeel, 'PutterFeel')}
        {this.renderResultsBuyNowRow(isMobile)}
      </Fragment>
    );
  }

  // renderShareButtons() {
  //   const bestBall = this.props.results.get(0);

  //   const percentMatch = numeral(bestBall.getIn(['display', 'MatchPercentage']) * 100).format('0.0');

  //   return (
  //     <InlineShareButtons
  //       config={{
  //         alignment: 'center',
  //         color: 'social',
  //         enabled: true,
  //         font_size: 16,
  //         labels: 'cta',
  //         language: 'en',
  //         networks: ['facebook', 'twitter'],
  //         padding: 12,
  //         radius: 4,
  //         show_total: false,
  //         size: 40,
  //         url: encodeURI(
  //           `${config.apiUrl}fitting/share?match=${percentMatch}&image=${bestBall.getIn([
  //             'info',
  //             'BallLogoImageURL',
  //           ])}&cachebuster=${uuid.v4()}`
  //         ),
  //         title: `My top ball from Ballnamic @BallFitting is a ${percentMatch}% match`,
  //       }}
  //     />
  //   );
  // }

  renderResultsMobileView(resultsPerPage, isMobile) {
    return (
      <>
        <h3 className="results--header__mobile">{`Showing ${resultsPerPage} of 5 Matches`}</h3>
        <div className="results--top__container__mobile">{this.renderBackAndForwardButtons(resultsPerPage)}</div>
        <div className="ball-results__mobile">
          {this.renderFavoriteCheckboxes(resultsPerPage, isMobile)}
          {this.renderResultHeadings(resultsPerPage, isMobile)}
          {this.renderResults(isMobile)}
        </div>
      </>
    );
  }

  renderResultsWebView(resultsPerPage) {
    return (
      <>
        <h1 className="results--header">{`Showing ${resultsPerPage} of 5 Matches`}</h1>
        <div className="results--top__container">{this.renderBackAndForwardButtons(resultsPerPage)}</div>
        <div className="results-table">
          <table>
            <tbody>
              <tr>
                <td className="results-arrows--th">{this.renderFavoriteCheckboxes(resultsPerPage)}</td>
                {this.renderResultHeadings(resultsPerPage)}
              </tr>
              {this.renderResults()}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  render() {
    const { isMobile } = this.state;

    let resultsPerPage = RESULTS_PER_PAGE;
    if (isMobile) {
      resultsPerPage = RESULTS_PER_PAGE_MOBILE;
    }

    const results = this.getDisplayedResults(resultsPerPage);

    return (
      <div className="results-list__wrapper" aria-label={strings.resultList}>
        {this.renderResultsWebView(resultsPerPage)}
        {this.renderResultsMobileView(resultsPerPage, isMobile)}
        <CrossSectionModal
          title={
            <div className="cross-section-modal__title">
              <img
                src={results.getIn([this.state.showCrossSection, 'info', 'BrandImageURL'])}
                alt={`${results.getIn([this.state.showCrossSection, 'info', 'Model'])} Cross-Section`}
              />
              <div>{results.getIn([this.state.showCrossSection, 'info', 'Model'])} Cross-Section</div>
            </div>
          }
          crossSectionImage={
            this.state.showCrossSection === null
              ? null
              : results.getIn([this.state.showCrossSection, 'info', 'BallCrossSectionURL'])
          }
          onClose={() => this.setState({ showCrossSection: null })}
        />
        <div className="results--disclaimer">
          <p>
            You assume any risk associated with your use of Ballnamic and recommendations or results provided by
            Ballnamic or your retailer. Ballnamic results and precision flight simulations are provided for
            informational purposes only. Actual results may vary.
          </p>
          <br />
          <p>
            Ballnamic is a trademark of Karsten Manufacturing Corporation in the U.S. and other countries. All third
            party trademarks are the property of their respective owners, and use of such trademarks does not imply any
            affiliation with or endorsement by them.
          </p>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  loggedInUser: state.auth.loggedInUser,
}))(ResultList);
