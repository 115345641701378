import client from '../client';

import {
  FETCH_BRAINTREE_TOKEN,
  GET_PRICE,
  FETCH_PRICES,
  SUBMIT_RESULTS_PAYMENT,
  GET_BILLING_HISTORY,
  GET_SALES_TAX,
  RESET_SALES_TAX,
  GET_PROMO_CODE,
  RESET_SUBMIT_RESULTS_PAYMENT,
  FETCH_PURCHASED_VOUCHERS_LIST,
} from './payment-action-constants';

export function getPrice(fittingType) {
  return {
    type: GET_PRICE,
    promise: client.get(`v2/payment/price?fittingType=${fittingType}`),
  };
}

export function fetchPrices() {
  return {
    type: FETCH_PRICES,
    promise: client.get(`v2/payment/prices`),
  };
}

export function getSalesTax(payload) {
  return {
    type: GET_SALES_TAX,
    promise: client.post(`v2/payment/calculate-sales-tax`, payload),
    meta: { values: payload },
  };
}

export function resetSalesTax() {
  return {
    type: RESET_SALES_TAX,
  };
}

export function fetchBraintreeToken({ braintreeCustomerId, email, firstName, lastName }) {
  return {
    type: FETCH_BRAINTREE_TOKEN,
    promise: client.post('v2/payment/customer-token', { braintreeCustomerId, email, firstName, lastName }),
    meta: { values: { braintreeCustomerId, email, firstName, lastName } },
  };
}

export function fetchPurchasedVouchersList(payload) {
  return {
    type: FETCH_PURCHASED_VOUCHERS_LIST,
    promise: client.post('v2/payment/vouchers-by-transaction-id', payload),
  };
}

export function submitResultsPayment(nonce, payload, fittingType) {
  return {
    type: SUBMIT_RESULTS_PAYMENT,
    promise: client.post('v2/payment/purchase-fitting', { nonce, fittingType, ...payload }),
  };
}

export function submitUserResultsPayment(nonce, payload, fittingType) {
  return {
    type: SUBMIT_RESULTS_PAYMENT,
    promise: client.post('v2/payment/user-purchase-fitting', { nonce, fittingType, ...payload }),
  };
}

export async function validateVoucherCode(code) {
    try {
      const response = await client.post('v2/payment/validate-voucher', { code });
      return response.ok;
    } catch (error) {
      return false;
    }
}

export function resetResultsPayment() {
  return {
    type: RESET_SUBMIT_RESULTS_PAYMENT,
  };
}

export function getBillingHistory(page = 0, page_size = 1, email) {
  return {
    type: GET_BILLING_HISTORY,
    promise: client.post('v2/user/payments', {page: page, page_size: page_size, email: email}),
  };
}

export function getPromoCode(payload) {
  return {
    type: GET_PROMO_CODE,
    promise: client.post('v2/payment/promo', payload),
  };
}
