import React, { useRef } from 'react';
import Brand from './Brand';
import { brandTogglingStrings } from '..';
import { useSelector, useDispatch } from 'react-redux';
import LoadingIndicator from '../../LoadingIndicator';
import { updateUserBrandSettings } from '../../../../user/user-actions';
import { toast } from 'react-toastify';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    message: 'Please enable more brands to meet the minimum requirement of 5 models selected.',
  },
});
const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()];
};

const Brands = props => {
  const dispatch = useDispatch();
  let brands = [];
  const allBrands = useSelector(state => state.questionnaire.brands);
  if (allBrands) {
    const newBrands = allBrands.toJS();
    newBrands.shift();
    const mybrands = newBrands.map(item => {
      return { brandName: item, on: true };
    });
    if (props.savedBrands) {
      const brand = props.savedBrands.toJSON();
      brands = brand.brands;
    }
    brands = [...mybrands, ...brands];
    brands = getUniqueListBy(brands, 'brandName');
  }

  const brandsData = useRef(brands);

  const handleSelectedBrand = ({ brandName, on }) => {
    const updatedBrands = brands.map(brand => {
      if (brand.brandName === brandName) {
        brand.on = on;
      }
      return brand;
    });
    brandsData.current = updatedBrands;
  };

  const handleSave = () => {
    const payload = { user_id: props.user, settings: { brands: brandsData.current } };
    dispatch(updateUserBrandSettings(payload))
      .then(success => {
        if (success.json.message) {
          toast(strings.message, {
            type: 'error',
            position: toast.POSITION.BOTTOM_CENTER,
          });
          return;
        }
        toast('Settings Updated', { type: 'success', position: toast.POSITION.BOTTOM_CENTER });
      })
      .catch(error => {
        toast('Something went wrong', { type: 'error', position: toast.POSITION.BOTTOM_CENTER });
      });
  };
  return (
    <>
      {!allBrands ? (
        <LoadingIndicator />
      ) : (
        <div className="brands-wrapper">
          <h1 className="global-settings-h1">{brandTogglingStrings.brands}</h1>
          <div className="brand">
            {brands.map(brand => (
              <div key={brand.brandName}>
                <Brand {...brand} handleSelectedBrand={handleSelectedBrand} />
              </div>
            ))}
          </div>

          <div className="container brands-settings-save-button">
            <button onClick={handleSave} type="button" className="button button--color--primary ">
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Brands;
