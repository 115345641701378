import React from 'react';
import Svg, { Path } from 'svgs';

const GreensideSpin = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M13.775 9.98272727l.9125.86272723c-.28125.4904546-.4375 1.0636364-.4375 1.6545455 0 1.9581005 1.6789322 3.5454545 3.75 3.5454545v-1.7727272l2.5 2.3636363L18 19v-1.7727273c-2.7614237 0-5-2.1164721-5-4.7272727 0-.9277273.2875-1.7904545.775-2.51727273zM3.77777778 7l4.44444444 2.17777778-3.33333333 1.71111112v5.0166667C6.46666667 15.9944444 7.66666667 16.4444444 7.66666667 17c0 .6111111-1.49444445 1.1111111-3.33333334 1.1111111C2.49444444 18.1111111 1 17.6111111 1 17c0-.4111111.67222222-.7666667 1.66666667-.9611111V17h1.11111111V7zm5.83333333 8.8888889c.46023729 0 .83333329.373096.83333329.8333333 0 .4602373-.373096.8333334-.83333329.8333334s-.83333333-.3730961-.83333333-.8333334.37309604-.8333333.83333333-.8333333zM18 6v1.77272727c2.7614237 0 5 2.11647209 5 4.72727273 0 .9277273-.2875 1.7904545-.775 2.5172727l-.9125-.8627272c.28125-.4904546.4375-1.0636364.4375-1.6545455 0-1.9581005-1.6789322-3.54545455-3.75-3.54545455v1.77272725l-2.5-2.36363634L18 6z" />
    </Svg>
  );
};

export default GreensideSpin;
