import React from 'react';
import Svg, { Path } from 'svgs';

const Send = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
    </Svg>
  );
};

export default Send;
