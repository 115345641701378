import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import IronConditionsKnown from './IronConditionsKnown';
import IronConditionsUnknown from './IronConditionsUnknown';
import LaunchConditionsFlipper from '../LaunchConditionsFlipper';

const strings = new LocalizedStrings({
  en: {
    doNotKnowSevenIronConditions: `I don't know my 7-iron launch conditions`,
    doNotKnowSixIronConditions: `I don't know my 6-iron launch conditions`,
    knowIronConditions: `I know my launch conditions`,
  },
});

class IronLaunchConditions extends Component {
  render() {
    const { isSixIronFitting } = this.props;
    return (
      <LaunchConditionsFlipper
        type="iron"
        unknownButtonText={isSixIronFitting ? strings.doNotKnowSixIronConditions : strings.doNotKnowSevenIronConditions}
        knownButtonText={strings.knowIronConditions}
        KnownForm={IronConditionsKnown}
        UnknownForm={IronConditionsUnknown}
        isSixIronFitting={isSixIronFitting}
      />
    );
  }
}

export default IronLaunchConditions;
