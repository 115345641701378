import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';
import { logout } from './auth-actions-clientless';

class PostLogin extends Component {
  componentDidMount() {
    this.props.dispatch(logout);
    this.props.history.replace('/');
  }

  render() {
    return null;
  }
}

export default compose(
  connect(state => ({})),
  withRouter
)(PostLogin);
