import React from 'react';
import LocalizedStrings from 'react-localization';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { addCoBrand } from '../admin-actions';
import CoBrandForm from './CoBrandForm';

const strings = new LocalizedStrings({
  en: {
    addCoBrand: 'Add Co-Brand',
  },
});

const CoBrandAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <CoBrandForm
      initialValues={{ domains: [{ domain: '' }], svgSrc: '', pngSrc: '', name: '' }}
      onSubmit={(values, { setStatus }) => {
        return dispatch(addCoBrand(values)).then(action => {
          if (!action.response.ok) {
            setStatus(action.json && action.json.message ? action.json.message : 'An error occurred');
          } else {
            history.push('/admin/co-branding');
          }
        });
      }}
      title={strings.addCoBrand}
    />
  );
};

export default CoBrandAdd;
