import React from 'react';
import Svg, { Path } from 'svgs';

const DriverHeight = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M17.6724704 1.94844967l3.6216858 5.26719039c.3824131.54775181.1349689 1.05726233-.5623741 1.05726233h-2.633033l.0016476 3.17698661.000602 3.9313704h2.6307834c.6973431 0 .9447875.4982627.5623735 1.0606362l-3.6216857 5.2728149c-.3824138.5623735-1.0122724.5421285-1.3946865-.0089984l-3.6216858-5.2671904c-.3824131-.5477518-.134969-1.0572623.5623741-1.0572623h2.6330329l-.0017781-3.1800076-.0004714-3.92834941h-2.6307834c-.6973432 0-.9447876-.49826272-.5623736-1.06063624l3.6216857-5.27281491c.3824139-.56237352 1.0122725-.54212852 1.3946866.00899843zM6.67836257 6.26315789c1.29189415 0 2.33918129 1.04728714 2.33918129 2.33918129V15.619883c0 1.2918942-1.04728714 2.3391813-2.33918129 2.3391813H2V6.26315789zm0 2.33918129H4.33918129V15.619883h2.33918128V8.60233918z" />
    </Svg>
  );
};

export default DriverHeight;
