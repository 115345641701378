import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LocalizedStrings from 'react-localization';
import Tooltip from '../../../common/components/Tooltip';
import Button from '../../../common/components/Button';
import FilterIcon from '../../../common/components/icons/Filter';
import FittersFilterForm from './FittersFilterForm';

export const strings = new LocalizedStrings({
  en: {
    filters: 'Filters',
  },
});

class FittersFilter extends Component {
  static propTypes = {
    fitters: PropTypes.object.isRequired,
    onFiltersApplied: PropTypes.func.isRequired,
  };

  state = {
    filters: { account: undefined },
  };

  render() {
    const { fitters, onFiltersApplied } = this.props;

    if (fitters.size === 0) {
      return null;
    }

    return (
      <Tooltip
        top={80}
        left={-136}
        pointsAt={({ toggle }) => (
          <Button onClick={toggle} icon outline textTransform="none" aria-label={strings.filters}>
            <FilterIcon size={20} />
            <span>{strings.filters}</span>
          </Button>
        )}
      >
        {({ hideTooltip }) => (
          <FittersFilterForm
            initialValues={this.state.filters}
            fitters={fitters}
            onCancel={hideTooltip}
            onApply={values => {
              this.setState({ filters: values });
              onFiltersApplied(values.account);
              hideTooltip();
            }}
          />
        )}
      </Tooltip>
    );
  }
}

export default FittersFilter;
