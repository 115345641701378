import Immutable from 'immutable';
import config from '../../config';
import set from 'lodash/set';

export function formatValidationErrors(error) {
  const errors = {};

  if (!error || !error.validationErrors) {
    return Immutable.fromJS(errors);
  }

  const validationMessages = config.validationMessages || {};

  error.validationErrors.forEach(validationError => {
    const pathArray = Array.isArray(validationError.key) ? validationError.key : validationError.key.split('.');
    const newPath = pathArray
      .reduce((acc, node) => {
        const index = parseInt(node, 10);
        if (index === 0 || index) {
          return acc.slice(0, -1) + `[${index}].`;
        }
        return acc + `${node}.`;
      }, '')
      .slice(0, -1);

    set(errors, newPath, validationMessages[validationError.type] || validationError.message);
  });

  return Immutable.fromJS(errors);
}

export const defaultState = Immutable.Map({
  loaded: false,
  loading: false,
  error: false,
  validationErrors: Immutable.Map(),
  lastRequest: new Date(0),
});

const defaultRequestReducer = (state, action) =>
  state.merge({
    loaded: false,
    loading: true,
    error: false,
    validationErrors: Immutable.Map(),
    lastRequest: new Date(),
    meta: action.meta,
  });

const defaultSuccessReducer = (state, action) =>
  state.merge({
    loading: false,
    validationErrors: Immutable.Map(),
    loaded: true,
  });

const defaultFailureReducer = (state, action) =>
  state.merge({
    loading: false,
    loaded: false,
    error: Immutable.fromJS(action.json),
    validationErrors: formatValidationErrors(action.json),
  });

/**
 * Create a reducer that responds to the actions associated with an api request
 * with the given action type prefix.
 *
 * @param  {String}       actionTypePrefix      The action type prefix
 *                                              associated with the api request
 *                                              to respond to
 * @param  {Array}        [resetActions=[]]     Action types that return this
 *                                              reducer to its default state
 * @param  {Object}       [reducerOverrides={}] Map of reducer functions that
 *                                              override the defaults; expects
 *                                              attributes REQUEST, SUCCESS,
 *                                              and/or FAILURE
 * @return {Function}                           Reducer function
 */
const getApiReducer = (actionTypePrefix, resetActions = [], reducerOverrides = {}) => {
  let requestReducer = defaultRequestReducer;
  let successReducer = defaultSuccessReducer;
  let failureReducer = defaultFailureReducer;

  if (reducerOverrides.REQUEST) {
    requestReducer = reducerOverrides.REQUEST;
  }
  if (reducerOverrides.SUCCESS) {
    successReducer = reducerOverrides.SUCCESS;
  }
  if (reducerOverrides.FAILURE) {
    failureReducer = reducerOverrides.FAILURE;
  }

  return function(state = defaultState, action) {
    switch (action.type) {
      case `${actionTypePrefix}_REQUEST`:
        return requestReducer(state, action);
      case `${actionTypePrefix}_SUCCESS`:
        return successReducer(state, action);
      case `${actionTypePrefix}_FAILURE`:
        return failureReducer(state, action);
      default:
        break;
    }

    if (resetActions.indexOf(action.type) > -1) {
      return defaultState;
    }

    return state;
  };
};

export default getApiReducer;
