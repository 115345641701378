import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Logo from '../common/components/logo/Logo';
import MenuIcon from '../common/components/icons/Menu';
import ClearIcon from '../common/components/icons/Clear';
import LocalizedStrings from 'react-localization';
import { PurchaseFormContext } from '../context/PurchaseFormContext';

const strings = new LocalizedStrings({
  en: {
    login: 'Login',
    logout: 'Logout',
    myAccount: 'My Account',
    startMyFitting: 'Start My Fitting',
    RedeemCode: 'Redeem Code',
    BuyMoreCodes: 'Buy More Fittings',
    getStarted: 'Get Started',
    myProfile: 'Profile',
    library: 'Library',
    CloserLook: 'A Closer Look',
    getFit: 'Get Fit',
    landingPage: 'Landing Page',
    toggleSideBar: 'Toggle Side Bar',
    faq: 'FAQ',
    profile: 'Profile',
    fittingCodes: 'Fitting Codes',
    fittingResults: 'Fitting Results',
    billingHistory: 'Billing History',
    Subscriptions: 'Subscriptions'
  },
});

const LandingPageHeader = ({ isLoggedIn, history, handleBuyMoreCodes, scrollBtn, location }) => {
  const currentLocation = useLocation();

  const [state, setState] = useState({
    showKebabMenu: false,
    showMobileKebabMenu: false,
    showSideBar: false,
    showDropdown: false,
  });

  const { handlePurchaseForm } = useContext(PurchaseFormContext);

  const toggleSidebar = () => {
    setState(prev => ({
      ...prev,
      showSideBar: !prev.showSideBar,
    }));
  };

  const handleMouseEnter = () => {
    setState(prev => ({ ...prev, showDropdown: true }));
  };

  const handleMouseLeave = () => {
    setState(prev => ({ ...prev, showDropdown: false }));
  };

  const scrollToCloserLook = () => {
    if (currentLocation.pathname !== '/') {
      history.push('/');
      setTimeout(scrollToElement('closer-look'), 300);
    } else {
      setTimeout(scrollToElement('closer-look'), 200);
    }
  };

  const scrollToElement = (elementId) => () => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollCloserLookNavlink = () => {
    if (currentLocation.pathname !== '/') {
      history.push('/');
      setTimeout(scrollToElement('closer-look'), 300);
    } else {
      toggleSidebar()
      setTimeout(scrollToElement('closer-look'), 300);
    }
  };
  
  const renderMobileNav = () => (
    <div className="mobile-nav">
      <div className="mobile-nav__flex-full">
        <button type="button" onClick={toggleSidebar} aria-label={strings.toggleSideBar}>
          <MenuIcon fill="#616A70" />
        </button>
      </div>
      <div className="mobile-nav__logo">
        <Logo fill="#5057FF" width={111.67} height={32} />
      </div>
    </div>
  );

  const renderSideBar = () => {
    const { showSideBar } = state;

    const sidebarClasses = {
      sidebar: true,
      'sidebar--open': showSideBar,
    };
    const sidebarHeaderClasses = {
      'sidebar--header': true,
      'sidebar--header--open': showSideBar,
    };

    const mobileOverlayClasses = {
      'mobile-overlay': true,
      'mobile-overlay--visible': showSideBar,
    };
    const sidebarMenuClasses = {
      'sidebar-menu': true,
      'sidebar-menu--visible': showSideBar,
    };

    return (
      <Fragment>
        <div className={classNames(sidebarClasses)}>
          <div className={classNames(sidebarHeaderClasses)}>
            <button type="button" onClick={toggleSidebar} aria-label={strings.toggleSideBar}>
              <ClearIcon fill="#616A70" />
            </button>
          </div>
          <div className='wrapper-nav-link'>
            {isLoggedIn ? (
              <div className={classNames(sidebarMenuClasses)}>
                <NavLink className="button button-gift-mobile user-nav__links--link" to="/redeem" onClick={() => {
                    if (currentLocation.pathname === '/redeem') {
                      toggleSidebar();
                    }
                  }}>
                {isLoggedIn ? strings.startMyFitting : strings.getStarted}
                </NavLink>
              </div>
            ) : (
            <div className={classNames(sidebarMenuClasses)}>
              <NavLink className="button button-gift-mobile user-nav__links--link" to="/" onClick={() => {
                toggleSidebar();
                if (scrollBtn) {
                  setTimeout(scrollToElement('pricing'), 300);
                } else {
                  handlePurchaseForm();
                }
              }}>
                {isLoggedIn ? strings.startMyFitting : strings.getStarted}
              </NavLink>
              </div>
            )}
            {isLoggedIn ? (
              <div className={classNames(sidebarMenuClasses)}>
                <NavLink
                  className="button button-gift-mobile user-nav__links--link"
                  to='#'
                  onClick={() => {
                    toggleSidebar();
                    if (scrollBtn) {
                      setTimeout(scrollToElement('pricing'), 300);
                    } else {
                      handlePurchaseForm();
                    }
                  }}
                >
                  {strings.BuyMoreCodes}
                </NavLink>
              </div>
            ) : (
              <div className={classNames(sidebarMenuClasses)}>
                <NavLink
                  className="button button-gift-mobile user-nav__links--link"
                  to={"/redeem"}
                >
                  {strings.RedeemCode}
                </NavLink>
              </div>
            )}
          </div>
          {<div style={{ width: '90%', height: 2, backgroundColor: 'rgba(80, 87, 255, 0.5)' , marginTop: 15, marginLeft: 15}} />}
          {isLoggedIn && (
            <div className={classNames(sidebarMenuClasses)}>
              <NavLink
                className="user-nav__links--link"
                onClick={() => handleNavLinkClick("/my-account")}
                to="/my-account"
              >
                {strings.myAccount}
              </NavLink>
            </div>
          )}
          {!isLoggedIn && (
            <div className={classNames(sidebarMenuClasses)}>
              <NavLink className="user-nav__links--link" to="/login">
                {strings.login}
              </NavLink>
            </div>
          )}

          <div className={classNames(sidebarMenuClasses)}>
            <NavLink className="user-nav__links--link" onClick={() => handleNavLinkClick("/library")} to={currentLocation.pathname === "/library" ? "#" : "/library"}>
              {strings.library}
            </NavLink>
          </div>
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink className="user-nav__links--link" onClick={scrollCloserLookNavlink} to='/#'>
              {strings.CloserLook}
            </NavLink>
          </div>
          <div className={classNames(sidebarMenuClasses)}>
            <NavLink className="user-nav__links--link" onClick={() => handleNavLinkClick("/faq")} to="/faq">
              {strings.faq}
            </NavLink>
          </div>
          {isLoggedIn && (
            <div className={classNames(sidebarMenuClasses)}>
              <NavLink
                className="user-nav__links--link" to="/logout">
                {strings.logout}
              </NavLink>
            </div> 
          )}
        </div>
        <div className={classNames(mobileOverlayClasses)} onClick={toggleSidebar} />
      </Fragment>
    );
  };

  const handleNavLinkClick = (path) => {
    if (currentLocation.pathname === path) {
      toggleSidebar();
    }
  };

  return (
    <div className="landing-page-header">
      {renderMobileNav()}
      {renderSideBar()}
      <div className="container">
        <header className="header">
          <div className="header__logo-wrapper">
            <Link to="/" className="logo" aria-label={strings.landingPage}>
              <Logo width={172} height={48} />
            </Link>
          </div>
          <nav className="nav" role="navigation" aria-label="Navigation menu">
            <div className="nav-links">
              <div>
                <Link className="button button--color--primary button--theme--outline" to="/faq">
                  {strings.faq}
                </Link>
              </div>
              <div>
                <button
                  onClick={scrollToCloserLook}
                  className="button button--color--primary button--theme--outline"
                >
                  {strings.CloserLook}
                </button>
              </div>
              <div>
                <Link className="button button--color--primary button--theme--outline" to="/library">
                  {strings.library}
                </Link>
              </div>

              {isLoggedIn && <div style={{ width: 1, height: 48, backgroundColor: 'rgba(80, 87, 255, 0.5)' }} />}

              <div style={{ display: 'flex', gap: '12px', flexWrap: 'nowrap' }}>

                {isLoggedIn ? (
                  <button
                    className="button button--theme--transparent"
                    style={{
                      border: '1px solid #5057FF',
                      borderRadius: '6px',
                      marginLeft: '10px',
                      color: '#5057FF'
                    }}
                    onClick={() => {
                      if (scrollBtn) {
                        scrollBtn();
                      } else {
                        handlePurchaseForm();
                      }
                    }}
                  >
                    {strings.BuyMoreCodes}
                  </button>
                ) : (
                  <Link
                    className="button button--theme--transparent"
                    style={{
                      border: '1px solid #5057FF',
                      borderRadius: '6px',
                      marginLeft: '10px'
                    }}
                    to={"/redeem"}
                  >
                    {strings.RedeemCode}
                  </Link>
                )}

                {isLoggedIn ? (
                  <Link
                    className="button button--theme--transparent"
                    style={{
                      border: '1px solid #5057FF',
                      borderRadius: '6px',
                      marginRight: '10px'
                    }}
                    to={"/redeem"}
                  >
                    {isLoggedIn ? strings.startMyFitting : strings.getStarted}
                  </Link>
                ) :
                  <button
                    className="button button--theme--transparent"
                    style={{
                      border: '1px solid #5057FF',
                      borderRadius: '6px',
                      marginRight: '10px',
                      color: '#5057FF'
                    }}
                    onClick={() => {
                      if (scrollBtn) {
                        scrollBtn();
                      } else {
                        handlePurchaseForm();
                      }
                    }}
                  >
                    {isLoggedIn ? strings.startMyFitting : strings.getStarted}
                  </button>}
              </div>

              <div className='gift-button-wrapper' style={{ marginLeft: 'auto' }}>
                {isLoggedIn ? (
                  <div className="dropdown-container"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <button
                      className={classNames(
                        "button button--color--primary button--theme--outline",
                        { "button--active": state.showDropdown }
                      )}
                      onClick={() => history.push('/my-account')}
                    >
                      {strings.myAccount}
                    </button>
                    {state.showDropdown && (
                      <div className="dropdown-menu">
                        <Link to="/profile" className="dropdown-item">{strings.profile}</Link>
                        <Link to="/profile/my-results" className="dropdown-item">{strings.fittingResults}</Link>
                        <Link to="/profile/my-fitting-codes" className="dropdown-item">{strings.fittingCodes}</Link>
                        <Link to="/profile#billing" className="dropdown-item">{strings.billingHistory}</Link>
                        <Link to="/logout" className="dropdown-item">{strings.logout}</Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link className="button button--color--primary button--theme--outline" to="/login">
                    {strings.login}
                  </Link>
                )}
              </div>
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
};

LandingPageHeader.propTypes = {
  isLoggedIn: PropTypes.bool,
  history: PropTypes.object.isRequired,
  handleBuyMoreCodes: PropTypes.func,
  scrollBtn: PropTypes.func,
  location: PropTypes.object.isRequired,
};

export default withRouter(LandingPageHeader);
