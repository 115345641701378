import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import GolfAlert from '../common/components/icons/GolfAlert';
import Logo from '../common/components/logo/Logo';
import { Helmet } from 'react-helmet';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'No Access',
    noAccess: 'You do not have access to this site.',
    logout: 'Logout',
  },
});

class NoAccess extends Component {
  render() {
    return (
      <div className="something-went-wrong--page">
        <Helmet>
          <title>{`${strings.page} - ${strings.title}`}</title>
        </Helmet>
        <div className="header">
          <Logo />
        </div>
        <div className="content">
          <GolfAlert fill="#5057FF" />
          <h1>{strings.noAccess}</h1>
          <Link className="button button--color--primary" to="/logout">
            {strings.logout}
          </Link>
        </div>
      </div>
    );
  }
}

export default NoAccess;
