import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import querystring from 'query-string';
import ChevronLeft from '../components/icons/ChevronLeft';
import ChevronRight from '../components/icons/ChevronRight';

class Pager extends Component {
  static propTypes = {
    pageSize: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    const searchQuery = querystring.parse(props.location.search);
    this.initialPage = searchQuery.page ? parseInt(searchQuery.page, 10) : 0;
  }

  onPageChange = page => {
    const { history, location, pageSize } = this.props;
    const searchQuery = querystring.parse(location.search);
    searchQuery.page = page.selected;
    searchQuery.page_size = pageSize;
    const newSearch = querystring.stringify(searchQuery);
    return history.push({ pathame: location.pathName, search: newSearch, state: location.state });
  };

  render() {
    const { pageSize, totalItems } = this.props;
    const totalPages = Math.ceil(totalItems / pageSize);

    return (
      <Fragment>
        {totalPages <= 1 ? null : (
          <ReactPaginate
            disableInitialCallback
            onPageChange={this.onPageChange}
            initialPage={this.initialPage}
            pageCount={totalPages}
            pageRangeDisplayed={2}
            marginPagesDisplayed={5}
            previousLabel={
              <div className="previous-label">
                <ChevronLeft size={32} />
                <span className="label-text">Previous</span>
              </div>
            }
            nextLabel={
              <div className="next-label">
                <span className="label-text">Next</span>
                <ChevronRight size={32} />
              </div>
            }
            containerClassName={'pager-container'}
            nextClassName={'next-button'}
            previousClassName={'previous-button'}
            pageClassName={'page-number'}
            pageLinkClassName={'page-link'}
            breakClassName={'page-break'}
            activeClassName={'active-page'}
            disabledClassName={'disabled'}
          />
        )}
      </Fragment>
    );
  }
}

export default withRouter(Pager);
