import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tooltip extends Component {
  static propTypes = {
    button: PropTypes.node,
    children: PropTypes.func,
    top: PropTypes.number,
    left: PropTypes.number,
  };

  state = {
    showTooltip: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showTooltip && !prevState.showTooltip) {
      document.addEventListener('keydown', this.handleKeydown);
      document.addEventListener('mousedown', this.handleOutsideMouseClick);
    }

    if (!this.state.showTooltip && prevState.showTooltip) {
      this.removeListeners();
    }
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  removeListeners() {
    document.removeEventListener('keydown', this.handleKeydown);
    document.removeEventListener('mousedown', this.handleOutsideMouseClick);
  }

  handleOutsideMouseClick = e => {
    if (!this.state.showTooltip) {
      return;
    }
    const root = this.tooltipNode;
    if (!root || root.contains(e.target) || (e.button && e.button !== 0)) {
      return;
    }
    this.setState({ showTooltip: false });
  };

  render() {
    const { pointsAt, top, left } = this.props;
    const { showTooltip } = this.state;

    return (
      <div className="tooltip" ref={tooltipNode => (this.tooltipNode = tooltipNode)}>
        {pointsAt({
          toggle: () =>
            this.setState(prevState => ({
              showTooltip: !prevState.showTooltip,
            })),
        })}
        {showTooltip && (
          <div className="show-tooltip" style={{ top: top, left: left }}>
            {this.props.children({ hideTooltip: () => this.setState({ showTooltip: false }) })}
          </div>
        )}
      </div>
    );
  }
}

export default Tooltip;
