import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Range as RCRange } from 'rc-slider';

class Range extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    subText: PropTypes.string,
    displayValue: PropTypes.string.isRequired,
    sliderProps: PropTypes.object.isRequired,
    textMarks: PropTypes.bool,
  };

  render() {
    const { label, subText, displayValue, sliderProps, textMarks } = this.props;
    return (
      <div className={`range__wrapper slider__wrapper ${textMarks ? 'text-marks' : ''}`}>
        <h3>{label}</h3>
        <div className="slider-_inner">
          <div className="range--value">{displayValue}</div>
          <RCRange {...sliderProps} />
        </div>
        {subText ? <h4 className="subtext">{subText}</h4> : null}
      </div>
    );
  }
}

export default Range;
