import React from 'react';
import Svg, { Path, G } from 'svgs';

const Visa = ({ width, height, fill }) => {
  return (
    <Svg
      width={width || 24}
      height={height || 16}
      viewBox="0 0 24 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h24v24H0z" />
        <G fillRule="nonzero">
          <Path fill="#FFF" d="M0 0h24v15.908H0z" />
          <Path fill="#F7B600" d="M.4 13.323h23.2v2.17H.4z" />
          <Path
            fill="#1A1F71"
            d="M.4.417h23.2v2.17H.4zM9.687 4.979L7.29 10.945H5.727l-1.18-4.762c-.071-.292-.134-.4-.351-.524a5.988 5.988 0 0 0-1.46-.507l.035-.173H5.29a.7.7 0 0 1 .682.608l.622 3.456 1.54-4.057 1.554-.007zm6.125 4.018c.007-1.574-2.085-1.661-2.072-2.365 0-.213.2-.442.628-.5.5-.05 1.004.043 1.459.267l.259-1.265a3.84 3.84 0 0 0-1.384-.264c-1.462 0-2.49.811-2.5 1.97-.009.86.736 1.339 1.295 1.624.576.292.77.48.767.74 0 .4-.46.58-.888.58a2.993 2.993 0 0 1-1.52-.376l-.268 1.307c.346.165.984.309 1.644.317 1.556 0 2.571-.801 2.576-2.042l.004.007zm3.857 1.941h1.368l-1.19-5.96h-1.263a.674.674 0 0 0-.63.439l-2.222 5.528h1.556l.308-.892h1.897l.176.885zm-1.647-2.107l.778-2.24.444 2.24h-1.222zM11.8 4.98l-1.222 5.966h-1.48l1.222-5.966h1.48z"
          />
        </G>
      </G>
    </Svg>
  );
};

export default Visa;
