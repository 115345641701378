import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LocalizedStrings from 'react-localization';
import TrashIcon from './icons/Trash';

let strings = new LocalizedStrings({
  en: {
    delete: 'Delete',
    cancel: 'Cancel',
  },
});

class DeleteButton extends Component {
  static propTypes = {
    confirmationText: PropTypes.string.isRequired,
    onConfirmDelete: PropTypes.func.isRequired,
  };

  state = {
    showConfirmation: false,
  };

  renderConfirmationTooltip() {
    const { confirmationText, onConfirmDelete } = this.props;

    if (!this.state.showConfirmation) {
      return null;
    }

    return (
      <div className="confirmation-tooltip">
        <div className="confirmation-tooltip--header">{confirmationText}</div>
        <div className="button--wrapper">
          <div className="faux-link gray" onClick={() => this.setState({ showConfirmation: false })}>
            {strings.cancel}
          </div>
          <div className="faux-link danger" onClick={onConfirmDelete}>
            {strings.delete}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <span className="delete-button">
        <button
          className="admin-user-button__icon-wrapper delete"
          onClick={() => this.setState({ showConfirmation: true })}
          title={strings.delete}
          aria-label={strings.delete}
        >
          <TrashIcon size={20} fill="#A1A9AE" />
        </button>
        {this.renderConfirmationTooltip()}
      </span>
    );
  }
}

export default DeleteButton;
