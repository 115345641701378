import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  ChartLabel,
  DiscreteColorLegend,
} from 'react-vis';
import LoadingIndicator from '../../../../common/components/LoadingIndicator';
import Immutable from 'immutable';
import memoize from 'memoize-one';

const BEST_COLOR = '#9245FF';
const RUNNER_UP_COLOR = '#20CFD2';

class BallFlightChart extends Component {
  static propTypes = {
    flights: PropTypes.instanceOf(Immutable.List),
    ballInfo: PropTypes.instanceOf(Immutable.List),
  };

  getFlightDistanceInfo = memoize(flights => {
    if (flights.size === 1) {
      return {
        bestBallIndex: 0,
        runnerUpIndex: 0,
        distanceDifference: 0,
      };
    }

    const totalDistances = [];
    flights.forEach(flight => {
      const points = flight.get('points');
      const totalDistance = points.get(points.size - 1);
      totalDistances.push(totalDistance.get('x'));
    });

    const bestDistance = Math.max.apply(Math, totalDistances);
    const bestBallIndex = totalDistances.findIndex(distance => distance === bestDistance);
    const runnerUpIndex = bestBallIndex === 1 ? 0 : 1;
    const runnerUpDistance = totalDistances[runnerUpIndex];
    const distanceDifference = (bestDistance - runnerUpDistance).toFixed(2);

    return {
      bestBallIndex,
      runnerUpIndex,
      distanceDifference,
    };
  });

  getData() {
    const { flights } = this.props;

    const data = [];

    flights.forEach((flight, index) => {
      data[index] = [];
      flight.get('points').forEach(point => {
        data[index].push({ x: point.get('x'), y: point.get('y') });
      });
    });

    return data;
  }

  getLegendItem(flight, diff = null) {
    const { ballInfo, flights } = this.props;

    const info = ballInfo.find(info => info.get('Ball') === flight.getIn(['Ball', 0])).toJS();
    const index = flights.findIndex(f => f === flight);
    const { bestBallIndex } = this.getFlightDistanceInfo(flights);
    const isBest = index === bestBallIndex;

    return {
      title: (
        <div className="legend-item">
          <img width={100} src={info.BrandImageURL} alt={`${info.Brand} Logo`} />
          <div className="ball-diff__wrapper">
            <span>
              {info.Brand} {info.Year}, {info.Model}
            </span>{' '}
            {diff}
          </div>
        </div>
      ),
      color: isBest ? BEST_COLOR : RUNNER_UP_COLOR,
      strokeWidth: 20,
    };
  }

  renderLegend() {
    const { flights, shotType } = this.props;

    const items = [];

    if (flights.size === 1) {
      items.push(this.getLegendItem(flights.get(0)));
    } else if (flights.size === 2) {
      const { bestBallIndex, runnerUpIndex, distanceDifference } = this.getFlightDistanceInfo(flights);

      const diff = <span className="distance-difference">▲ +{distanceDifference} yds</span>;

      items.push.apply(items, [
        this.getLegendItem(flights.get(bestBallIndex), diff),
        this.getLegendItem(flights.get(runnerUpIndex)),
      ]);
    }

    return <DiscreteColorLegend items={items} key={shotType} />;
  }

  getBallInfo(flight) {
    const { ballInfo } = this.props;

    const info = ballInfo.find(info => info.get('Ball') === flight.getIn(['Ball', 0])).toJS();

    return info.Brand + info.Year + " " + info.Model;
  }

  getComparedBalls() {
    const { flights } = this.props;

    let ball, distanceDifference, bestBall;

    if (flights.size === 1) {
      ball = this.getBallInfo(flights.get(0));
    } else if (flights.size === 2) {
      let { bestBallIndex, runnerUpIndex } = this.getFlightDistanceInfo(flights);

      distanceDifference = this.getFlightDistanceInfo(flights).distanceDifference;

      const diff = " and ";

      ball = bestBall = this.getBallInfo(flights.get(bestBallIndex)); 
      ball += diff;
      ball += this.getBallInfo(flights.get(runnerUpIndex));
    }
    return [flights.size, ball, distanceDifference, bestBall];
  }

  getRoundedAxes(flightDataBest, flightDataRunnerUp) {
    const maxXbest = flightDataBest.reduce((max, point) => (point.x > max ? point.x : max), flightDataBest[0].x);
    const maxYbest = flightDataBest.reduce((max, point) => (point.y > max ? point.y : max), flightDataBest[0].y);
    const maxXrunner = flightDataRunnerUp?.reduce((max, point) => (point.x > max ? point.x : max), flightDataRunnerUp[0]?.x);
    const maxYrunner = flightDataRunnerUp?.reduce((max, point) => (point.y > max ? point.y : max), flightDataRunnerUp[0]?.y);

    const maxX = (maxXrunner === undefined || maxXbest > maxXrunner) ? maxXbest : maxXrunner;
    const maxY = (maxYrunner === undefined || maxYbest > maxYrunner) ? maxYbest : maxYrunner;

    const roundedMaxX = Math.floor(maxX / 10) * 10;
    let roundedMaxY;
    if (maxY < 30.99) {
      roundedMaxY = Math.floor(maxY / 2) * 2;
    } else {
      roundedMaxY = Math.floor(maxY / 5) * 5;
    }

    return [roundedMaxX, roundedMaxY];
  }

  getAriaLabelBallChart(dataBestBallIndex, dataRunnerUpIndex) {

    const [flightsSize, comparedBalls, distanceDifference, bestBall] = this.getComparedBalls();

    const [roundedMaxX, roundedMaxY] = this.getRoundedAxes(dataBestBallIndex, dataRunnerUpIndex);

    let ariaLabelBallChart = `Flight comparison chart of the two selected balls: ${comparedBalls}: `;
    if (distanceDifference !== undefined ) {
      ariaLabelBallChart += ` Ball ${bestBall} has + ${distanceDifference} yards distance difference. `;
    }
    if (flightsSize === 1 ) {
      ariaLabelBallChart = `Flight chart for ball: ${comparedBalls}. `;
    }
    ariaLabelBallChart += `Horizontal axis for Downline distance (from 0 to ${roundedMaxX} yards), Vertical axis for Height (from 0 to ${roundedMaxY} yards).`

    return ariaLabelBallChart;
  }

  renderChart() {
    const data = this.getData();
    const { bestBallIndex, runnerUpIndex } = this.getFlightDistanceInfo(this.props.flights);

    const ariaLabelBallChart = this.getAriaLabelBallChart(data[bestBallIndex], data[runnerUpIndex]);

    return (
      <div className="ball-flight-chart__wrapper"
        aria-label={ariaLabelBallChart}
        tabIndex={0}
      >
        <div aria-hidden={true}>
        <XYPlot className="chart" width={940} height={480} margin={{ left: 80, right: 10, top: 10, bottom: 120 }}>
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis className="chart--x-axis" />
          <YAxis className="chart--y-axis" />
          <ChartLabel
            text="Height (yds)"
            className="y-axis-label"
            xPercent={-0.07}
            yPercent={0.5}
            includeMargin={false}
            style={{
              transform: 'rotate(-90)',
              textAnchor: 'middle',
            }}
          />
          <ChartLabel
            text="Downline (yds)"
            className="x-axis-label"
            xPercent={0.5}
            yPercent={1.25}
            includeMargin={false}
            style={{
              textAnchor: 'middle',
            }}
          />
          {data[bestBallIndex] ? <LineSeries data={data[bestBallIndex]} color={BEST_COLOR} strokeWidth={4} /> : null}
          {data.length > 1 && data[runnerUpIndex] ? (
            <LineSeries data={data[runnerUpIndex]} color={RUNNER_UP_COLOR} strokeWidth={4} />
          ) : null}
        </XYPlot>
        </div>
        <span aria-hidden={true}>
          {this.renderLegend()}
        </span>
      </div>
    );
  }

  render() {
    const { calculatingFlights } = this.props;

    if (calculatingFlights) {
      return <LoadingIndicator />;
    }

    return <div>{this.renderChart()}</div>;
  }
}

export default connect(state => ({
  calculatingFlights: state.questionnaire.calculateFlights.get('loading'),
}))(BallFlightChart);
