import React, { Component } from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import LocalizedStrings from 'react-localization';
import FieldWithLabelAndValidation from '../../../common/forms/FieldWithLabelAndValidation';
import FatClear from '../../../common/components/icons/FatClear';
import PropTypes from 'prop-types';

const strings = new LocalizedStrings({
  en: {
    name: 'Name',
    email: 'Email',
    addRecipient: 'Add recipient',
    addNewRecipient: 'Add New Recipient',
    saveNewRecipient: 'Save new recipient',
    clearRecipientForm: 'Clear Recipient Form',
  },
});

class RecipientForm extends Component {
  static propTypes = {
    onFormShown: PropTypes.func,
    onFormHidden: PropTypes.func,
  };

  state = {
    addingRecipient: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.status.success && this.props.status.success) {
      this.setState({ addingRecipient: false });
      this.props.onFormHidden();
      this.props.resetForm();
    }
  }

  render() {
    const { setStatus, onFormShown, onFormHidden } = this.props;

    if (!this.state.addingRecipient) {
      return (
        <button
          className="button button--color--primary button--theme--outline button--full-width add-recipient--button"
          onClick={() => {
            setStatus({ success: false });
            onFormShown();
            this.setState({ addingRecipient: true });
          }}
        >
          {strings.addRecipient}
        </button>
      );
    }

    return (
      <Form className="add-new-recipient--form">
        <h4>{strings.addNewRecipient}</h4>
        <button
          className="add-new-recipient--form__close"
          type="button"
          onClick={() => {
            this.setState({ addingRecipient: false });
            onFormHidden();
            this.props.resetForm();
          }}
          aria-label={strings.clearRecipientForm}
        >
          <FatClear fill="#F95D5D" />
        </button>
        <FieldWithLabelAndValidation
          label={strings.email}
          fieldProps={{
            name: 'email',
            type: 'email',
            placeholder: 'Enter Email',
          }}
        />
        <button className="button button--color--primary button--full-width add-recipient--button" type="submit">
          {strings.saveNewRecipient}
        </button>
      </Form>
    );
  }
}

export default withFormik({
  mapPropsToValues: props => ({
    name: '',
    email: '',
  }),
  mapPropsToStatus: props => ({ success: false }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
  }),
  handleSubmit: (values, { props, setStatus }) => {
    props.onSave(values);
    setStatus({ success: true });
  },
})(RecipientForm);
