import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import FieldWithLabelAndValidation from '../../../common/forms/FieldWithLabelAndValidation';
import LocalizedStrings from 'react-localization';
import { updateUserProfile } from '../../user-actions';
import compose from 'lodash/flowRight';
import { getEmailSettingsUrl, getChangePasswordUrl } from '../../../common/utils/oidc-url-builders';
import BillingHistory from './BillingHistory';
import { toast } from 'react-toastify';
import FitterResultsEmailForm from './FitterResultsEmailForm';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Dropdown from '../../../fitting/Questionnaire/Dropdown';
import { fetchBrands, fetchModels, fetchYears } from '../../../fitting/Questionnaire/questionnaire-actions';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    myProfile: 'My Profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    gender: 'Gender',
    birthday: 'Birthday',
    email: 'Email Management',
    saveProfile: 'Save Profile',
    passwordManagement: 'Password Management',
    changePassword: 'Change Password',
    selectCurrentBall: 'Select Current Ball',
    currentBallMake: 'Brand',
    currentBallModel: 'Model',
    currentBallYear: 'Year',
    changeEmail: 'Change Email',
    profileUpdated: 'Profile Updated',
    logout: 'Logout',
    billingHistory: 'Billing History',
    genderOptions: {
      male: 'Male',
      female: 'Female',
      other: 'Other',
      preferNotToSay: 'Prefer not to say'
    }
  },
});

class MyProfile extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.userProfileUpdated && this.props.userProfileUpdated) {
      toast(strings.profileUpdated, { type: 'success' });
    }

    if (window.location.hash && window.location.hash !== prevProps.location.hash) {
      const element = document.querySelector(window.location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  componentDidMount() {
    if (window.location.hash) {
      const element = document.querySelector(window.location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    const { dispatch } = this.props;
    dispatch(fetchBrands());

    const { loggedInUser } = this.props;
    const currentBrand = loggedInUser.get('current_ball_brand');
    const currentModel = loggedInUser.get('current_ball_model');

    if (currentBrand) {
      this.fetchModels(currentBrand);
    }
    if (currentBrand && currentModel) {
      this.fetchYears(currentBrand, currentModel);
    }
  }

  fetchModels = (brand) => {
    const { dispatch } = this.props;
    dispatch(fetchModels(brand));
  };

  fetchYears = (brand, model) => {
    const { dispatch } = this.props;
    dispatch(fetchYears(brand, model));
  };

  renderEmailStatusTag() {
    const { loggedInUser } = this.props;

    return (
      <div
        className={`email-status-tag ${loggedInUser.getIn(['oidcData', 'profile', 'email_verified']) && 'verified'}`}
      >
        {loggedInUser.getIn(['oidcData', 'profile', 'email_verified']) ? 'Verified' : 'Unverified'}
      </div>
    );
  }

  renderEmailSettings() {
    const { loggedInUser } = this.props;
    return (
      <div className="email-settings">
        <h3>{strings.email}</h3>
        <div className="current-email__wrapper" style={{ 
          display: 'flex', 
          flexDirection: (window.innerWidth <= 768) ? 'column' : 'row', 
          alignItems: 'flex-start' 
        }}>
          <span className="current-email">{loggedInUser.getIn(['oidcData', 'email'])}</span>
          {this.renderEmailStatusTag()}
        </div>
        <a className="button button--color--primary" href={getEmailSettingsUrl()}>
          {strings.changeEmail}
        </a>
      </div>
    );
  }
  trackProfile = () => {
    const { loggedInUser } = this.props;
    const identifiers = {
      firstName: loggedInUser.get('first_name'),
      lastName: loggedInUser.get('last_name'),
      email: loggedInUser.get('oidcData').get('email'),
      handicap: loggedInUser.get('handicap'),
      gender: loggedInUser.get('gender'),
      birthDate: loggedInUser.get('birth_date'),
      postalCode: loggedInUser.get('playing_zip'),
    };
    window.analytics.identify(loggedInUser.get('id'), identifiers);
  }

  renderPasswordManagement() {
    return (
      <div className="password-management">
        <h3>{strings.passwordManagement}</h3>
        <a className="button button--color--primary" href={getChangePasswordUrl()}>
          {strings.changePassword}
        </a>
      </div>
    );
  }

  render() {
    const { loggedInUser, brands, models, years, values } = this.props;

    return (
      <div className="page--my-profile">
        <Helmet>
          <title>{`${strings.myProfile} - ${strings.title}`}</title>
        </Helmet>
        <div className="container small">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
            <h1>{strings.myProfile}</h1>
            <Link to="/logout" style={{ paddingTop: '13px', height: '0px' }}>
              {strings.logout}
            </Link>
          </div>
          <Form className="profile--name-form">
            <FieldWithLabelAndValidation
              label={strings.firstName}
              fieldProps={{
                name: 'first_name',
                type: 'text',
              }}
            />
            <FieldWithLabelAndValidation
              label={strings.lastName}
              fieldProps={{
                name: 'last_name',
                type: 'text',
              }}
            /> 
            <label className="input-label">{strings.birthday.toUpperCase()}</label>  
            <div className="input-scaffold" style={{ display: 'flex', gap: '10px' }}>
              <Field
                name="birth_month"
                as="select"
                className="form-control"
                style={{ fontSize: '18px', fontWeight: 'normal' }}
              >
                <option value="">Month</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {String(i + 1).padStart(2, '0')}
                  </option>
                ))}
              </Field>

              <Field
                name="birth_day"
                as="select"
                className="form-control"
                style={{ fontSize: '18px', fontWeight: 'normal' }}
              >
                <option value="">Day</option>
                {Array.from({ length: 31 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {String(i + 1).padStart(2, '0')}
                  </option>
                ))}
              </Field>

              <Field
                name="birth_year"
                as="select"
                className="form-control"
                style={{ fontSize: '18px', fontWeight: 'normal' }}
              >
                <option value="">Year</option>
                {Array.from({ length: 100 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </Field>
            </div>
            <div className="input-scaffold">
              <label className="input-label">{strings.gender.toUpperCase()}</label>
              <Field
                name="gender"
                as="select"
                className="form-control"
                style={{ fontSize: '18px', fontWeight: 'normal' }}
              >
                <option value="">Select gender</option>
                <option value="male">{strings.genderOptions.male}</option>
                <option value="female">{strings.genderOptions.female}</option>
                <option value="other">{strings.genderOptions.other}</option>
                <option value="prefer_not_to_say">{strings.genderOptions.preferNotToSay}</option>
              </Field>
            </div>
            <div className="input-scaffold">
              <label className="input-label">HANDICAP</label>
              <Field
                name="handicap"
                as="select"
                className="form-control"
                style={{ fontSize: '18px', fontWeight: 'normal' }}
              >
                <option value="">Select handicap</option>
                {Array.from({ length: 55 }, (_, i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </Field>
            </div>
            <div className="input-scaffold">
              <label className="input-label">PLAYING ZIP</label>
              <Field
                name="playing_zip"
                type="text"
                className="form-control"
                style={{ fontSize: '18px', fontWeight: 'normal' }}
                placeholder="Enter playing zip"
              />
            </div>
            <hr />
            <h3>{strings.selectCurrentBall}</h3>
            <div className="input-scaffold">
              <label className="input-label">{strings.currentBallMake}</label>
              <div className="questionnaire--block__inner">
                <Dropdown label={strings.brand} name="current_ball_brand" options={brands} onChange={this.fetchModels} />
              </div>
              </div>
              <div className="input-scaffold">
                <label className="input-label">{strings.currentBallModel}</label>
                <div className="questionnaire--block__inner">
                <Dropdown
                  label={strings.model}
                  name="current_ball_model"
                  options={models}
                  onChange={this.fetchYears.bind(this, values.current_ball_brand)}
                />
              </div>
              </div>
              <div className="input-scaffold">
              <label className="input-label">{strings.currentBallYear}</label>
              <div className="questionnaire--block__inner">
                <Dropdown label={strings.year} name="current_ball_year" options={years} />
              </div>
            </div>
            <div className="button__wrapper">
              <button className="button button--color--primary" type="submit">
                {strings.saveProfile}
              </button>
            </div>
          </Form>
          <hr />
          <FitterResultsEmailForm />
          {this.renderEmailSettings()}
          <hr />
          {this.renderPasswordManagement()}
          <hr />
          {loggedInUser.get('type') === 'player' ? (
            <div id="billing">
              <BillingHistory />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    userProfileUpdated: state.user.updateUserProfile.get('loaded'),
    brands: state.questionnaire.brands,
    models: state.questionnaire.models,
    years: state.questionnaire.years,
  })),
  withFormik({
    mapPropsToValues: props => {
      const birthDate = props.loggedInUser.get('birth_date');
      let birthYear = '', birthMonth = '', birthDay = '';
      
      if (birthDate) {
        const date = new Date(birthDate);
        birthYear = date.getFullYear();
        birthMonth = date.getMonth() + 1;
        birthDay = date.getDate() + 1;
      }

      return {
        first_name: props.loggedInUser.get('first_name') || '',
        last_name: props.loggedInUser.get('last_name') || '',
        gender: props.loggedInUser.get('gender') || '',
        handicap: props.loggedInUser.get('handicap') || '',
        birth_year: birthYear,
        birth_month: birthMonth,
        birth_day: birthDay,
        fitter_results_email: props.loggedInUser.get('fitter_results_email'),
        current_ball_brand: props.loggedInUser.get('current_ball_brand') || '',
        current_ball_model: props.loggedInUser.get('current_ball_model') || '',
        current_ball_year: props.loggedInUser.get('current_ball_year'),
        playing_zip: props.loggedInUser.get('playing_zip') || '',
      };
    },
    handleSubmit(values, { props }) {
      const birthDate = values.birth_year && values.birth_month && values.birth_day
        ? `${values.birth_year}-${String(values.birth_month).padStart(2, '0')}-${String(values.birth_day).padStart(2, '0')}`
        : null;
      const identifiers = {
        firstName: props.loggedInUser.get('first_name'),
        lastName: props.loggedInUser.get('last_name'),
        email: props.loggedInUser.get('oidcData').get('email'),
        handicap: values.handicap,
        gender: values.gender,
        birthDate: birthDate,
        postalCode: values.playing_zip,
      };
      window.analytics.identify(props.loggedInUser.get('id'), identifiers);

      return props.dispatch(updateUserProfile({
        first_name: values.first_name || '',
        last_name: values.last_name || '',
        gender: values.gender || '',
        handicap: values.handicap || '',
        birth_date: birthDate,
        fitter_results_email: props.loggedInUser.get('fitter_results_email'),
        current_ball_brand: values.current_ball_brand || '',
        current_ball_model: values.current_ball_model || '',
        current_ball_year: values.current_ball_year,
        playing_zip: values.playing_zip || '',
      }));
    },
  })
)(MyProfile);