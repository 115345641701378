import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import Modal from '../common/components/Modal';
import Toggle from 'react-toggle';
import { updateUserSettings } from '../user/user-actions';
import LocalizedStrings from 'react-localization';

export const settingsModalStrings = new LocalizedStrings({
  en: {
    settings: 'Settings',
    pricePreference: 'Price Preference',
    includeDtcBalls: 'Include Direct to Consumer Balls',
    includeHouseBalls: 'Include House Brand Balls',
    sevenIronFitting: '7-Iron Fitting',
    sixIronFitting: '6-Iron Fitting',
  },
});

class SettingsModal extends Component {
  static propTypes = {
    isOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  };

  renderToggle(name, label) {
    const { dispatch, loggedInUser } = this.props;

    return (
      <div className="toggle--container">
        <label>
          <span className="toggle-label">{label}</span>
          <Toggle
            checked={loggedInUser.get(name)}
            icons={false}
            onChange={event => {
              dispatch(updateUserSettings({ [name]: !loggedInUser.get(name) }));
            }}
            aria-selected={loggedInUser.get(name)}
            aria-label={label}
          />
        </label>
      </div>
    );
  }

  render6IronToggle() {
    const { loggedInUser, dispatch } = this.props;
    return (
      <div className="button--toggle__wrapper">
        <button
          className={`button button-toggle ${!loggedInUser?.get('six_iron_fitting') && 'button--color--primary'}`}
          onClick={() => {
            dispatch(updateUserSettings({ six_iron_fitting: !loggedInUser.get('six_iron_fitting') }));
          }}
        >
          {settingsModalStrings.sevenIronFitting}
        </button>
        <button
          className={`button button-toggle ${loggedInUser?.get('six_iron_fitting') && 'button--color--primary'}`}
          onClick={() => {
            dispatch(updateUserSettings({ six_iron_fitting: !loggedInUser.get('six_iron_fitting') }));
          }}
        >
          {settingsModalStrings.sixIronFitting}
        </button>
      </div>
    );
  }

  render() {
    const { isOpened, onClose } = this.props;

    return (
      <Modal className="settings-modal" isOpened={isOpened} onClose={onClose} title={settingsModalStrings.settings}>
        <div className="settings-modal__body">
          {this.renderToggle('show_price_preference', settingsModalStrings.pricePreference)}
          {this.renderToggle('include_dtc_balls', settingsModalStrings.includeDtcBalls)}
          {this.renderToggle('include_house_balls', settingsModalStrings.includeHouseBalls)}
          {this.render6IronToggle()}
        </div>
      </Modal>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
  }))
)(SettingsModal);
