import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import GolfAlert from './common/components/icons/GolfAlert';

const strings = new LocalizedStrings({
  en: {
    pageNotfound: 'Page Not Found',
  },
});

class NotFound extends Component {
  render() {
    return (
      <div className="something-went-wrong--page">
        <div className="content">
          <GolfAlert fill="#5057FF" />
          <h1>{strings.pageNotfound}</h1>
        </div>
      </div>
    );
  }
}

export default NotFound;
