import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import { shouldUpdateReducer } from '@synapsestudios/fetch-client-goalie';
import authReducers from './auth/auth-reducers';
import userReducers from './user/user-reducers';
import questionnaireReducers from './fitting/Questionnaire/questionnaire-reducers';
import paymentReducers from './payment/payment-reducers';
import contentfulReducers from './lib/contentful/contentful-reducers';
import { LOOK_UP_TEMPERATURE_AND_ALTITUDE } from './fitting/Questionnaire/questionnaire-action-constants';
import adminReducers from './admin/admin-reducers';

// Errors that shouldn't be set in the global `error` reducer because they are handled elsewhere
const handledErrorActions = [LOOK_UP_TEMPERATURE_AND_ALTITUDE].map(prefix => `${prefix}_FAILURE`);

const appReducer = combineReducers({
  browser: createResponsiveStateReducer(),
  shouldUpdate: shouldUpdateReducer,
  auth: authReducers,
  user: userReducers,
  questionnaire: questionnaireReducers,
  payment: paymentReducers,
  contentful: contentfulReducers,
  admin: adminReducers,
  error: (state = null, action) => {
    if (handledErrorActions.includes(action.type)) {
      return state;
    }
    if (/_FAILURE$/.test(action.type)) {
      if (action.response.status >= 500) {
        return action.json;
      }
    }
    if (/_ERROR$/.test(action.type)) {
      return action.error;
    }
    return state;
  },
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = {};
  }

  return appReducer(state, action);
};
export default rootReducer;
