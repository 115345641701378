import React from 'react';
import Svg, { Path } from 'svgs';

const DriverDistance = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M16.384074 14.5032264l5.0091742 3.4406014c.5342548.3632932.5150221.9616588-.0085485 1.3249522l-5.0038309 3.4406015c-.5203642.3632925-1.0043992.1282206-1.0043992-.5342553v-2.5013813H8.62353043v2.5013813c0 .6235067-.42876456.8684133-.91303848.5921892l-.09136073-.0579339L2.61530035 19.26878c-.4886659-.3390738-.53799749-.8829217-.10803421-1.2492986l.0994857-.0756536 5.00917417-3.4406014c.50282809-.3419231.95170549-.1538062 1.00277471.4218188l.00482971.1124361v2.4992442h6.75293917v-2.4992442c0-.6624761.4733495-.8975482 1.0076044-.5342549zM13.1111111 1c1.2272995 0 2.2222222.99492278 2.2222222 2.22222222v6.66666667c0 1.22729941-.9949227 2.22222221-2.2222222 2.22222221H8.66666667V1zm0 2.22222222h-2.2222222v6.66666667h2.2222222V3.22222222z" />
    </Svg>
  );
};

export default DriverDistance;
