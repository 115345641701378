import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../common/components/Modal';
import ValidationError from '../common/forms/ValidationError';
import {
  fetchBraintreeToken,
  getSalesTax,
  resetSalesTax,
  getPrice,
  submitResultsPayment,
  submitUserResultsPayment,
  fetchPurchasedVouchersList,
} from '../payment/payment-actions';
import config from '../config';
import LocalizedStrings from 'react-localization';
import getTotal from './get-total';
import Dinero from 'dinero.js';
import { Link } from 'react-router-dom';
import dropin from 'braintree-web-drop-in';
import { toast } from 'react-toastify';
import { ShareFormContext } from '../context/ShareFormContext';
import BillingLocationForm from './BillingLocationForm';
import * as Yup from 'yup';
import PromoCodeForm from './PromoCodeForm';
import { trackRedeemNow } from '../util/analytics';
// import store from '../store';

const stateCodesMap = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY"
};
const strings = new LocalizedStrings({
  en: {
    PaymentInformation: 'Payment Information',
    purchaseTitle: 'Purchase',
    Email: 'Email *',
    ConfirmEmail: 'Confirm Email *',
    buttonNext: 'Next',
    AnErrorOccurredPaymentForm: 'An error occurred with the payment form.',
    InvalidEmailFormat: 'Invalid email format',
    EmailsMustMatch: 'Emails must match',
    InvalidConfirmEmailFormat: 'Invalid Confirm email format',
    EmailIsRequired: 'Email is required',
    ConfirmEmailIsRequired: 'Confirm Email is required',
    code: 'FITTING CODES',
    status: 'STATUS',
    share: 'Share',
    shareAgain: 'Share Again',
    redeemNow: 'Redeem Now',
    price: 'Amount',
    salesTax: 'Sales Tax',
    updating: 'Updating',
    invoice: 'Invoice',
    total: 'Total',
    PleaseEnterEmailAddress: 'Please enter the email address that you would like the voucher sent to:',
    EmailThatWillReceiveVoucher: 'Email that will receive the voucher:',
    paymentCantBeProcessed: `Payment can't be processed at this time. Please try again later.`,
    noPaymentMethodSelected: 'Please selected payment method!',
    purchaseVoucherSuccessful: 'Your purchase has been successfully completed.',
    purchaseVoucherError: 'An error occurred while purchasing Voucher! Please try again.',
    confirmPurchase: 'Confirm Purchase',
    purchaseResultThanks: 'Thank you for your purchase! You will receive a confirmation email with your fitting codes.',
    purchaseResultInstructions: 'Next, click Redeem Now to start a fitting or click share to send a code to someone else.',
    singleFitting: 'Single Fitting',
    singleBallnamicFitting: 'Single Ballnamic Fitting',
    threeFittings: '3 Fittings',
    threeBallnamicFittings: '3 Ballnamic Fittings',
    fiveFittings: '5 Fittings',
    viewAll: 'You can view all of your fitting codes ',
    here: 'here.',
    fiveBallnamicFittings: '5 Ballnamic Fittings',
    FirstNameIsRequired: 'First Name is required',
    LastNameIsRequired: 'Last Name is required',
  },
});


const defaultPrice = Dinero({ amount: 3900, currency: 'USD' });

const PurchaseForm = ({ isOpened, onCancel, initialEmail, initialFirstName, initialLastName, isAuthenticatedUser, braintreeCustomerId, userId, initialPlayingZip, initialFittingType }) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(null);
  const [titleModal, setTitleModal] = useState(strings.purchaseTitle);
  const [email, setEmail] = useState(initialEmail ?? localStorage.getItem('email') ?? '');
  const [confirmEmail, setConfirmEmail] = useState(initialEmail);
  const [emailError, setEmailError] = useState('');
  const [confirmEmailError, setConfirmEmailError] = useState('');
  const [allowNext, setAllowNext] = useState((initialEmail != null && initialFirstName != null && initialLastName != null) ? true : false);
  const [braintreeError, setBraintreeError] = useState(null);
  const [instance, setInstance] = useState(null);
  const [formLoaded, setFormLoaded] = useState(false);
  const [submittingPayment, setSubmittingPayment] = useState(false);
  const braintreeToken = useSelector(state => state.payment.braintreeToken);
  const totalTax = useSelector(state => state.payment.totalTax);
  const prices = useSelector(state => state.payment.price);
  const purchasedVouchersList = useSelector(state => state.payment.purchasedVouchersList);
  const [transactionId, setTransactionId] = useState(null);
  const price = prices ? Dinero({ amount: prices.getIn(['PLAYER_RESULTS', 'USD']), currency: 'USD' }) : defaultPrice;
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [isUpdatingTaxes, setIsUpdatingTaxes] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [promoDiscountAmount, setPromoDiscountAmount] = useState(0);
  const [locationFormError, setLocationFormError] = useState('');
  const [fittingType, setFittingType] = useState(initialFittingType ?? 'premium');
  const [firstName, setFirstName] = useState(initialFirstName ?? localStorage.getItem('firstName') ?? '');
  const [lastName, setLastName] = useState(initialLastName ?? localStorage.getItem('lastName') ?? '',);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [totalReady, setTotalReady] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);
  const [hasTrackedOrder, setHasTrackedOrder] = useState(false);
  const fittingTypeMap = {
    'premium': '5 Fittings Package',
    'standard': '3 Fittings Package',
    'basic': 'Single Fitting Package',
  };

  const { handleShareForm, showShare } = useContext(ShareFormContext);

  useEffect(() => {
    if (redirect) {
      localStorage.setItem('purchasedWithoutAccount', 'true');
    }
  }, [redirect]);

  useEffect(() => {
    dispatch(fetchPurchasedVouchersList({ transactionId: transactionId }));
  }, [showShare, transactionId, dispatch]);

  useEffect(() => {
    const fetchSalesTax = () => {
      setIsUpdatingTaxes(true);
      const paymentLocation = {
        city,
        state,
        postalCode,
        country: 'US', // Assuming country is always 'US'
        fittingType
      };

      dispatch(getPrice(fittingType)); // Fetch prices based on the updated fittingType
      dispatch(resetSalesTax()); // Reset sales tax when fittingType changes
      dispatch(getSalesTax(paymentLocation)).finally(() => {
        setIsUpdatingTaxes(false);
      }); // Recalculate sales tax after resetting
    };

    if(city && state && postalCode && fittingType){
      fetchSalesTax();
    }
  }, [dispatch, fittingType, city, state, postalCode]);

  useEffect(() => {
    if (isOpened) {
      setCurrentStep('FORM_STEP');
    }
  }, [isOpened]);

  useEffect(() => {
    if (isOpened) {
      dispatch(resetSalesTax());
    }
  }, [isOpened, dispatch]);

  const calculateTotal = useCallback(() => {
    if (price && totalTax) {
      let totalPrice = getTotal(price, totalTax);
      if (promoCodeApplied) {
        setPromoCodeApplied(true);
        totalPrice = totalPrice.subtract(Dinero({ amount: promoDiscountAmount, currency: 'USD' }));
      }
      return totalPrice;
    }
  }, [price, totalTax, promoCodeApplied, promoDiscountAmount]);

  
  useEffect(() => {
    const computeTotal = () => {
      const total = calculateTotal();
      if (total) {
        setTotalPrice(total.toFormat('0.00')); // Update total price as string
        setTotalReady(true); // Mark total as ready
      } else {
        setTotalReady(false); // Total is not ready yet
      }
    };
  
    computeTotal();
  }, [calculateTotal]);

  useEffect(() => {
    if (isOpened && currentStep === 'SUMMARY_STEP' && email && firstName && lastName) {
      dispatch(fetchBraintreeToken({ braintreeCustomerId: braintreeCustomerId, email: email, firstName: firstName, lastName: lastName }));
    }
  }, [isOpened, currentStep, email, firstName, lastName, dispatch, braintreeCustomerId]);


  // useEffect(() => {
  //   if (isOpened && currentStep === 'SUMMARY_STEP' && email && firstName && lastName) {
  //     dispatch(fetchBraintreeToken({ braintreeCustomerId: braintreeCustomerId }));
  //   }
  // }, [isOpened, currentStep, email, firstName, lastName, dispatch, isAuthenticatedUser, braintreeCustomerId, userId]);
  

  useEffect(() => {
    if (braintreeToken && totalReady && isOpened && currentStep === 'SUMMARY_STEP') {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const createPaymentForm = () => {
        setBraintreeError(null);
        const container = document.querySelector('#braintree-container');
        if (container) {
          container.innerHTML = '';
        }
    
        const paypalConfig = config.payPalEnabled && { paypal: { flow: 'vault' } };
        const applePayConfig = config.applePayEnabled && {
          applePay: {
            displayName: 'Ping Ballnamic',
            paymentRequest: {
              total: {
                label: 'Ball Fitting Fee',
                amount: totalPrice,
              },
              countryCode: 'US',
              currencyCode: 'USD'
          }
        }
        };
        const googlePayConfig = config.googlePayEnabled && totalReady && {
          googlePay: {
            googlePayVersion: 2,
            merchantId: config.googleMerchantId,
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPrice,
              currencyCode: 'USD',
            },
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  billingAddressRequired: true,
                  billingAddressParameters: {
                    format: 'FULL',
                  },
                },
              },
            ],
          },
        };
    
        dropin.create({
          authorization: braintreeToken,
          selector: '#braintree-container',
          paymentOptionPriority: ['card', 'paypal', 'googlePay', 'applePay'],
          ...googlePayConfig,
          ...paypalConfig,
          ...applePayConfig,
          card: {
            overrides: {
              styles: {
                input: {
                  padding: '0 8px',
                },
              },
            },
          },
        }, (err, instance) => {
          if (instance) {
            setInstance(instance);
            setFormLoaded(true);
          } else if (err) {
            // handle error
            console.error(err || 'Payment form instance could not be initialized');
          }
        }
        );
      };
      createPaymentForm();
    };
  }, [braintreeToken, totalReady, isOpened, currentStep, totalPrice]);
  useEffect(() => {
    if (isOpened){
      switch (currentStep) {
        case 'FORM_STEP':
          window.analytics.track("Checkout Step Viewed", {
            product: fittingTypeMap[fittingType],
            checkout_step: 1,
            fitting_id: null
          });
          break;
        case 'LOCATION_STEP':
          window.analytics.track("Checkout Step Viewed", {
            product: fittingTypeMap[fittingType],
            checkout_step: 2,
            fitting_id: null
          });
          break;
        case 'SUMMARY_STEP':
          window.analytics.track("Checkout Step Viewed", {
            product: fittingTypeMap[fittingType],
            checkout_step: 3,
            fitting_id: null
          });
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, isOpened]);

  useEffect(() => {
    if (redirect && purchasedVouchersList && purchasedVouchersList.size > 0 && !hasTrackedOrder) {
      // Map fitting codes
      const codes = purchasedVouchersList.map((result, index) => ({
        [`fitting_code_${index + 1}`]: result.get('code').toUpperCase(),
      }));
  
      // Build order payload;
      const realTotal = getTotal(price, totalTax);
      const subTotalPost = realTotal.subtract(totalTax).toFormat('0.00');
      const totalPost = calculateTotal();
      const discountPost = price.subtract(totalPost.subtract(totalTax)).toFormat('0.00') || null;
  
      const orderPayload = {
        coupon: promoCode || null,
        discount: discountPost || null,
        fitting_id: null,
        order_id: null, // You might want to add transaction ID here
        products: {
          brand: 'Ballnamic',
          name: fittingTypeMap[fittingType],
          quantity: 1,
          price: price.toFormat('0.00'),
          discount_amount: discountPost || null,
          coupon: promoCode || null,
          ...codes.reduce((acc, code) => ({ ...acc, ...code }), {}),
        },
        recipient_email: email || null,
        subtotal: subTotalPost || null,
        tax: totalTax.toFormat('0.00') || null,
        total: totalPost.toFormat('0.00') || null,
      };
  
      // Track analytics
      window.analytics.track('Order Completed', orderPayload);
  
      // Mark as tracked to prevent duplicate firing
      setHasTrackedOrder(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect, purchasedVouchersList, hasTrackedOrder, totalPrice, totalTax, price, promoCode, fittingType, email]);
  
  
  // Cleanup asynchronous tasks when unmounting
  useEffect(() => {
  return () => {
    setInstance(null);
    setTotalReady(false);
    setTotalPrice(null);
  };
}, []);

  useEffect(() => {
    if (isOpened) {
      if (currentStep === 'SUMMARY_STEP') {
        // Explicitly reset promo code related states every time we enter SUMMARY_STEP
        setPromoCode('');
        setPromoCodeApplied(false);
        setPromoDiscountAmount(0);
        setPromoCodeError(null);
        setBraintreeError(null);
      }
    }
  }, [currentStep, isOpened]);

  useEffect(() => {
    calculateTotal();
  }, [promoCodeApplied, promoDiscountAmount, totalTax, price, calculateTotal]);


  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = event => {
    localStorage.setItem('email', event.target.value);
    setEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setEmailError(strings.InvalidEmailFormat);
      setAllowNext(false);
    } else if (confirmEmail && event.target.value !== confirmEmail) {
      setEmailError(strings.EmailsMustMatch);
      setAllowNext(false);
    } else {
      setEmailError('');
      setConfirmEmailError('');
      setAllowNext(true);
    }
  };

  const handleConfirmEmailChange = event => {
    setConfirmEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setConfirmEmailError(strings.InvalidConfirmEmailFormat);
      setAllowNext(false);
    } else if (email !== event.target.value) {
      setConfirmEmailError(strings.EmailsMustMatch);
      setAllowNext(false);
    } else {
      setEmailError('');
      setConfirmEmailError('');
      setAllowNext(true);
    }
  };

  const locationValidationSchema = Yup.object().shape({
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string().trim().required("Postal code is required"),
  });

  const validateLocationForm = async (city, state, postalCode) => {
    try {
      await locationValidationSchema.validate({ city, state, postalCode }, { abortEarly: false });
      return true;
    } catch (validationError) {
      const errors = {};
      validationError.inner.forEach(error => {
        if (!errors[error.path]) {
          errors[error.path] = error.message;
        }
      });

      return { errors };
    }
  };

  const handleLocationFormSubmit = async ({ city, state, postalCode }) => {
    window.analytics.identify({
      postalCode: postalCode,
      state: stateCodesMap[state],
      city: city,
      country: 'US',
    });
    window.analytics.track("Checkout Step Completed", {
      product: fittingTypeMap[fittingType],
      city: city,
      state: stateCodesMap[state],
      postal_code: postalCode,
      country: 'US',
      step: 2,
      fitting_id: null
    });
    window.gtag('event', 'Checkout Step Completed', {
      product: fittingTypeMap[fittingType],
      city: city,
      state: stateCodesMap[state],
      postal_code: postalCode,
      country: 'US',
      step: 2,
      fitting_id: null
    });

    setLocationFormError('');
    const validationResult = await validateLocationForm(city, state, postalCode);
    if (validationResult === true) {
      try {
        const country = "US"; // Static value for country

        setCity(city);
        setState(state);
        setPostalCode(postalCode);
        const actionResponse = dispatch(getSalesTax({ city, state, postalCode, country, fittingType }));
        if (actionResponse?.error || actionResponse?.response?.status === 422) {
          if (actionResponse?.payload && actionResponse?.response?.status === 422) {
            setLocationFormError('The provided billing location information is invalid. Please check your inputs and try again.');
          } else {
            setLocationFormError('Failed to calculate sales tax, please try again.');
          }
        }
        else {
          setCurrentStep('SUMMARY_STEP');
        }
      } catch (error) {
        console.error('Failed to calculate sales tax', error);
        setLocationFormError('Failed to calculate sales tax, please try again.');
      }
    }
  };

  const fetchNewSalesTax = (discount) => {
    try {
      const response = dispatch(getSalesTax({ city, state, postalCode, country: 'US', fittingType, price: (prices.getIn(['PLAYER_RESULTS', 'USD']) - discount)}));
      if (response.error) {
        console.error('Failed to fetch new sales tax', response.error);
        return;
      }
      return response.payload;
    } catch (error) {
      console.error('Failed to fetch new sales tax', error);
    }
  };
  const handlePromoCodeSubmit = (code, discountAmount, isPercentOff) => {
    if (isPercentOff) {
      const percentAmount = (prices.getIn(['PLAYER_RESULTS', 'USD']) * (discountAmount / 100));
      setPromoDiscountAmount(percentAmount);
      fetchNewSalesTax(percentAmount);
    } else {
      setPromoDiscountAmount(discountAmount);
      fetchNewSalesTax(discountAmount);
    }
    setPromoCode(code);
    setPromoCodeError(null);
    setPromoCodeApplied(true);
  };
  const handleFormSubmit = () => {
    let isValid = true; // Track overall validity

    // Validate email
    if (!email) {
      setEmailError(strings.EmailIsRequired);
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError(strings.InvalidEmailFormat);
      isValid = false;
    } else {
      setEmailError('');
    }

    // Validate confirm email
    if (!confirmEmail) {
      setConfirmEmailError(strings.ConfirmEmailIsRequired);
      isValid = false;
    } else if (email !== confirmEmail) {
      setConfirmEmailError(strings.EmailsMustMatch);
      isValid = false;
    } else {
      setConfirmEmailError('');
    }

    // Validate first name
    if (!firstName) {
      setFirstNameError(strings.FirstNameIsRequired);
      isValid = false;
    } else {
      setFirstNameError('');
    }

    // Validate last name
    if (!lastName) {
      setLastNameError(strings.LastNameIsRequired);
      isValid = false;
    } else {
      setLastNameError('');
    }

    // Set allowNext based on overall validity
    setAllowNext(isValid);

    if (currentStep === 'FORM_STEP' && isValid) {
      // let createdAt = Math.floor(Date.now()/1000);
      window._cio.identify({
        id: email, //use email as id for guest checkout
        first_name: firstName,
        last_name: lastName,
      });
      window.analytics.identify({
        email: email,
        firstName: firstName,
        lastName: lastName,
      });
      window.analytics.track("Checkout Step Completed", {
        product: fittingTypeMap[fittingType],
        first_name: firstName,
        last_name: lastName,
        email: email,
        step: 1,
      });
      window.gtag('event', 'Checkout Step Completed', {
        product: fittingTypeMap[fittingType],
        first_name: firstName,
        last_name: lastName,
        email: email,
        step: 1,
      });
      setCurrentStep('LOCATION_STEP');
    }
    if (currentStep === 'LOCATION_STEP' && isValid) {
      window.analytics.identify({
        product: fittingTypeMap[fittingType],
        step: 2,
        postalCode: postalCode,
        state: stateCodesMap[state],
        city: city,
        country: 'US',
      });
      window.analytics.track("Checkout Step Completed", {
        product: fittingTypeMap[fittingType],
        step: 2,
        city: city,
        state: stateCodesMap[state],
        postal_code: postalCode,
        country: 'US',
        fitting_id: null
      });
      window.gtag('event', 'Checkout Step Completed', {
        product: fittingTypeMap[fittingType],
        step: 2,
        city: city,
        state: stateCodesMap[state],
        postal_code: postalCode,
        country: 'US',
        fitting_id: null
      });
    }
  };

  const handleClose = () => {
    setCurrentStep(null);
    setTitleModal(strings.purchaseTitle);
    setEmail('');
    setConfirmEmail('');
    setFirstName('');
    setLastName('');
    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setConfirmEmailError('');
    setAllowNext(false);
    onCancel();
    setSubmittingPayment(false);
    const container = document.querySelector('#braintree-container');
    if (container) {
      container.innerHTML = '';
    }
  };

  const getPurchaseType = () => {
    switch (fittingType) {
      case 'premium':
        return strings.fiveBallnamicFittings;
      case 'standard':
        return strings.threeBallnamicFittings;
      default:
        return strings.singleBallnamicFitting;
    }
  }
  

  const handleSubmitPayment = (nonce, payload, fittingType) => {
    const processPaymentResponse = (response) => {
      setSubmittingPayment(false);
      if (response.response.status === 200) {
        setTransactionId(response.json.transaction.transactionId);
        toast(strings.purchaseVoucherSuccessful, { type: 'success', position: toast.POSITION.TOP_CENTER });
        setRedirect(true);
      } else {
        toast(strings.purchaseVoucherError, { type: 'error', position: toast.POSITION.BOTTOM_CENTER });
        handleClose();
      }
    };

    const paymentAction = isAuthenticatedUser ? submitUserResultsPayment : submitResultsPayment;
    // waitForVouchersAndTrack(dispatch, 'test', fittingType, payload, price, totalTax, email);

    dispatch(paymentAction(nonce, payload, fittingType)).then(processPaymentResponse);
    setHasTrackedOrder(false);
  };
  

  const submitPayment = nonce => {
    const paymentLocation = {
      city,
      state,
      postalCode,
      country: 'US', // Assuming country is always 'US'
    };
    const recepientEmail = { email };
    const recepientFirstName = { firstName };
    const recepientLastName = { lastName };

    const paymentPayload = {
      ...paymentLocation,
      ...recepientEmail,
      ...recepientFirstName,
      ...recepientLastName,
      expectedPrice: calculateTotal().getAmount(),
      gift: true,
    }

    if (promoCode) {
      paymentPayload.promoCode = promoCode;
    }
    return handleSubmitPayment(nonce, paymentPayload, fittingType);
  };

  const handlePayment = (e) => {
    e.preventDefault();
    if (!email || !validateEmail(email)) {
      setEmailError(strings.InvalidEmailFormat);
      return;
    }

    const totalPrice = calculateTotal();

    if (!totalPrice || totalPrice.isZero()) {
      submitPayment('no-nonce-required');
      return;
    }

    if (!instance) {
      console.error('Payment form instance not initialized');
      setBraintreeError(strings.noPaymentMethodSelected);
      setSubmittingPayment(false);
      return;
    }

    setSubmittingPayment(true);
    instance.requestPaymentMethod((err, payload) => {
      if (err) {
        console.error('Error requesting payment method:', err.message);
        setBraintreeError(err.message || strings.noPaymentMethodSelected);
        setSubmittingPayment(false);
        return;
      }

      if (payload) {
        submitPayment(payload.nonce);
      } else {
        console.error('No payment method selected');
        setBraintreeError(strings.noPaymentMethodSelected);
        setSubmittingPayment(false);
      }
    });
  };
  const Invoice = ({ totalTax, price }) => {

    let totalPrice = calculateTotal();

    return (
      <table className="invoice-table" aria-label={strings.invoice}>
        <thead>
          <tr>
            <th></th>
            <th>{strings.price}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ballnamic</td>
            <td>{price ? price.toFormat('$0,0.00') : strings.updating}</td>
          </tr>
          {promoDiscountAmount > 0 && (
            <tr>
              <td>Promo Discount</td>
              <td>-{Dinero({ amount: promoDiscountAmount, currency: 'USD' }).toFormat('$0,0.00')}</td>
            </tr>
          )}
          <tr>
            <td id="tax-amount-label">{strings.salesTax}</td>
            <td aria-labelledby="tax-amount-label">{totalTax ? totalTax.toFormat('$0,0.00') : strings.updating}</td>
          </tr>
          <tr>
            <td id="total-label">{strings.total}</td>
            <td aria-labelledby="total-label">{totalPrice ? totalPrice.toFormat('$0,0.00') : strings.updating}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const handleFittingTypeChange = (event) => {
    setFittingType(event.target.value);
    setPromoCode('');
    setPromoCodeApplied(false);
    setPromoDiscountAmount(0);
    setPromoCodeError(null);
    setBraintreeError(null);
  };

  const handleFirstNameChange = event => {
    localStorage.setItem('firstName', event.target.value);
    setFirstName(event.target.value);
    setFirstNameError(''); // Clear error on change
  };

  const handleLastNameChange = event => {
    localStorage.setItem('lastName', event.target.value);
    setLastName(event.target.value);
    setLastNameError(''); // Clear error on change
  };

  if (redirect) {
    return (
      <Modal
        className="voucher-modal"
        isOpened={redirect}
        onClose={() => {
          setRedirect(false);
          handleClose();
        }}
        title={getPurchaseType()}
        shouldCloseOnOutsideClick={false}
      >
        <div className="container small">
          <div className="purchased-text">{strings.purchaseResultThanks}</div>
          <div className="purchased-text">{strings.purchaseResultInstructions}</div>
          {purchasedVouchersList && <table className="purchased-codes-list purchase-voucher-history-table">
            <tbody>
              {purchasedVouchersList.map(result => (
                <tr key={result.get('code')}>
                  <td><bd>{result.get('code').toUpperCase()}</bd></td>
                  <td>
                    {(
                      <a
                        href={isAuthenticatedUser ? '/redeem' : '/registration'}
                        rel="noopener noreferrer"
                        onClick={() => {
                          localStorage.setItem('action', 'redeemVoucher');
                          localStorage.setItem('voucher', result.get('code').toUpperCase());
                          trackRedeemNow(result.get('code').toUpperCase());
                        }}
                      >
                        {strings.redeemNow}
                      </a>
                    )}
                  </td>
                  <td>
                {result.get('status') === 'active' && (
                  <Link                   
                    onClick={
                      () => handleShareForm(result.get('code').toUpperCase(), email, false)
                    }
                  >
                    {result.get('shared') ? strings.shareAgain : strings.share}
                  </Link>
                )}
              </td>
                </tr>
              ))}
            </tbody>
          </table>}
          <div className="view-all-text">
            <span>{strings.viewAll}</span>
            <div className="view-all-here">
              <a
                href={isAuthenticatedUser ? '/profile/my-fitting-codes' : '/registration'}
                rel="noopener noreferrer"
                onClick={() => {
                  localStorage.setItem('action', 'listVouchers');
                }}
              >
                {strings.here}
              </a>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      className="voucher-modal"
      isOpened={isOpened}
      onClose={handleClose}
      title={titleModal}
      shouldCloseOnOutsideClick={false}
    >
      <div>
        <label style={{ paddingRight: '10px' }}>
          <input
            type="radio"
            value="premium"
            checked={fittingType === 'premium'}
            onChange={handleFittingTypeChange}
            style={{ marginRight: '5px' }}
          />
          {strings.fiveFittings}
        </label>
        <label style={{ paddingRight: '10px' }}>
          <input
            type="radio"
            value="standard"
            checked={fittingType === 'standard'}
            onChange={handleFittingTypeChange}
            style={{ marginRight: '5px' }}
          />
          {strings.threeFittings}
        </label>
        <label style={{ paddingRight: '10px' }}>
          <input
            type="radio"
            value="basic"
            checked={fittingType === 'basic'}
            onChange={handleFittingTypeChange}
            style={{ marginRight: '5px' }}
          />
          {strings.singleFitting}
        </label>
      </div>
      <div style={{ color: '#616a70', fontSize: '14px' , fontWeight: '400', marginTop: '8px', marginLeft: '5px'}}>
        <p>Fitting Codes are valid for 1 year after purchase date.</p>
      </div>

      {currentStep === 'FORM_STEP' && (
        <div className="email-form">
          <div className="input-container">
            <label className="input-label" htmlFor="first-name-id">
              First Name *
            </label>
            <input
              id="first-name-id"
              name="firstName"
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
            />
            {firstNameError && <ValidationError>{firstNameError}</ValidationError>}
          </div>

          <div className="input-container">
            <label className="input-label" htmlFor="last-name-id">
              Last Name *
            </label>
            <input
              id="last-name-id"
              name="lastName"
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
            />
            {lastNameError && <ValidationError>{lastNameError}</ValidationError>}
          </div>

          <div className="helper-text" style={{ paddingTop: '13px' }}>{strings.PleaseEnterEmailAddress}</div>
          <div className="input-container">
            <label className="input-label" htmlFor="email-id">
              {strings.Email}
            </label>
            <input id="email-id" name="email" type="email" value={email} onChange={handleEmailChange} />
            {emailError && <ValidationError>{emailError}</ValidationError>}
          </div>

          <div className="input-container">
            <label className="input-label" htmlFor="confirmEmail-id">
              {strings.ConfirmEmail}
            </label>
            <input
              id="confirmEmail-id"
              name="confirmEmail"
              type="email"
              value={confirmEmail}
              onChange={handleConfirmEmailChange}
            />
            {confirmEmailError && <ValidationError>{confirmEmailError}</ValidationError>}
          </div>
          <div className="buttonNext-container">
            <button
              onClick={handleFormSubmit}
              className="button button--color--primary"
              aria-label="Next"
              disabled={!allowNext}
            >
              {strings.buttonNext}
            </button>
          </div>
        </div>
      )}
      {currentStep === 'LOCATION_STEP' && (
        <>
          {locationFormError && <ValidationError>{locationFormError}</ValidationError>}
          <BillingLocationForm onSubmit={handleLocationFormSubmit} initialPlayingZip={initialPlayingZip} />
        </>
      )}
      {currentStep === 'SUMMARY_STEP' && (
        <div>
          <div className="emailReceiveWrapper">
            <div className="">{strings.EmailThatWillReceiveVoucher}</div> <div className="summaryEmail">{email}</div>
          </div>
          <Invoice totalTax={totalTax} price={price} discount={promoDiscountAmount} />
          <PromoCodeForm key={promoCodeApplied ? 'applied' : 'not-applied'} onPromoCodeSubmit={handlePromoCodeSubmit} email={email} fittingType={fittingType} />
          {promoCodeError && <ValidationError>{promoCodeError}</ValidationError>}

          <div id="braintree-container"></div>
          {braintreeError && <ValidationError>{braintreeError}</ValidationError>}
          {formLoaded ? (
            <div className="summaryPayButton">
              <button
                className="button button--color--primary gift-button"
                onClick={handlePayment}
                aria-label={strings.confirmPurchase}
                disabled={isUpdatingTaxes || submittingPayment || !price || !totalTax}
              >
                {strings.confirmPurchase}
              </button>
            </div>
          ) : null}
        </div>
      )}
    </Modal>
  );
};

export default PurchaseForm;
