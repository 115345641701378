import React from 'react';
import Svg, { Path } from 'svgs';

const Upload = ({ size, fill }) => {
  return (
    <Svg
      height={size || 23}
      width={size || 20}
      viewBox="0 0 20 23"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'none'}
    >
      <Path
        d="M6.00008 17.3333V9.33333H0.666748L10.0001 0L19.3334 9.33333H14.0001V17.3333H6.00008ZM0.666748 22.6667V20H19.3334V22.6667H0.666748Z"
        fill="#5057FF"
      />
    </Svg>
  );
};

export default Upload;
