import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import ChevronLeft from './icons/ChevronLeft';
import ChevronRight from './icons/ChevronRight';
import PropTypes from 'prop-types';

const ControlledPager = props => {
  const { page, setPage, pageSize, totalItems, onPageChange, scrollOnChange } = props;

  const _onPageChange = page => {
    setPage(page.selected);
    onPageChange(page.selected);

    if (scrollOnChange) {
      window.scrollTo(0, 0);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);

  return (
    <Fragment>
      {totalPages <= 1 ? null : (
        <ReactPaginate
          forcePage={page}
          disableInitialCallback
          onPageChange={_onPageChange}
          initialPage={0}
          pageCount={totalPages}
          pageRangeDisplayed={2}
          marginPagesDisplayed={5}
          previousLabel={
            <div className="previous-label">
              <ChevronLeft size={32} />
              <span className="label-text">Previous</span>
            </div>
          }
          nextLabel={
            <div className="next-label">
              <span className="label-text">Next</span>
              <ChevronRight size={32} />
            </div>
          }
          containerClassName={'pager-container'}
          nextClassName={'next-button'}
          previousClassName={'previous-button'}
          pageClassName={'page-number'}
          pageLinkClassName={'page-link'}
          breakClassName={'page-break'}
          activeClassName={'active-page'}
          disabledClassName={'disabled'}
        />
      )}
    </Fragment>
  );
};

ControlledPager.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  scrollOnChange: PropTypes.bool,
};

export default withRouter(ControlledPager);
