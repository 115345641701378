import React, { Component } from 'react';

class ValidationError extends Component {
  render() {
    const { children, ...otherProps } = this.props;
    return (
      <div className="validation-error" {...otherProps}>
        {children}
      </div>
    );
  }
}

export default ValidationError;
