import React from 'react';

export default function convertNewLinesToBrTags(string) {
  return string.split('\n').map((fragment, index) => (
    <React.Fragment key={index}>
      {fragment}
      <br />
    </React.Fragment>
  ));
}
