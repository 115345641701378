import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form, ErrorMessage } from 'formik';
import FieldWithLabelAndValidation from '../common/forms/FieldWithLabelAndValidation';
import LocalizedStrings from 'react-localization';
import * as Yup from 'yup';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import ValidationError from '../common/forms/ValidationError';
import compose from 'lodash/flowRight';

const strings = new LocalizedStrings({
  en: {
    billingLocation: 'Billing Location',
    city: 'City',
    postalCode: 'Postal Code',
    country: 'Country',
    fieldIsRequired: 'Field is required',
    selectState: 'Select State',
    state: 'State',
    selectProvince: 'Select Province',
    province: 'Province',
    selectRegion: 'Select Region',
    region: 'Region',
    selectCountry: 'Select Country',
    submit: 'Submit',
  },
});



// const stateCodesMap = {
//   "Alabama"         : "AL",
//   "Alaska"          : "AK",
//   "Arizona"         : "AZ",
//   "Arkansas"        : "AR",
//   "California"      : "CA",
//   "Colorado"        : "CO",
//   "Connecticut"     : "CT",
//   "Delaware"        : "DE",
//   "Florida"         : "FL",
//   "Georgia"         : "GA",
//   "Hawaii"          : "HI",
//   "Idaho"           : "ID",
//   "Illinois"        : "IL",
//   "Indiana"         : "IN",
//   "Iowa"            : "IA",
//   "Kansas"          : "KS",
//   "Kentucky"        : "KY",
//   "Louisiana"       : "LA",
//   "Maine"           : "ME",
//   "Maryland"        : "MD",
//   "Massachusetts"   : "MA",
//   "Michigan"        : "MI",
//   "Minnesota"       : "MN",
//   "Mississippi"     : "MS",
//   "Missouri"        : "MO",
//   "Montana"         : "MT",
//   "Nebraska"        : "NE",
//   "Nevada"          : "NV",
//   "New Hampshire"   : "NH",
//   "New Jersey"      : "NJ",
//   "New Mexico"      : "NM",
//   "New York"        : "NY",
//   "North Carolina"  : "NC",
//   "North Dakota"    : "ND",
//   "Ohio"            : "OH",
//   "Oklahoma"        : "OK",
//   "Oregon"          : "OR",
//   "Pennsylvania"    : "PA",
//   "Rhode Island"    : "RI",
//   "South Carolina"  : "SC",
//   "South Dakota"    : "SD",
//   "Tennessee"       : "TN",
//   "Texas"           : "TX",
//   "Utah"            : "UT",
//   "Vermont"         : "VT",
//   "Virginia"        : "VA",
//   "Washington"      : "WA",
//   "West Virginia"   : "WV",
//   "Wisconsin"       : "WI",
//   "Wyoming"         : "WY"
// };

class BillingLocationForm extends Component {
  
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  getSelectRegionLabels() {
    const { values } = this.props;
    if (values.country === 'US') {
      return [strings.state, strings.selectState];
    } else if (values.country === 'CA') {
      return [strings.province, strings.selectProvince];
    }
    return [strings.region, strings.selectRegion];
  }

  render() {
    const { setFieldValue, values, error, isSubmitting } = this.props;

    const [regionLabel, regionDefaultOptionLabel] = this.getSelectRegionLabels();

    return (
      <div className="billing-location-form">
        <h3>{strings.billingLocation}</h3>
        <Form className="billing-location__scaffold">
          {error ? <ValidationError>{error}</ValidationError> : null}
          <FieldWithLabelAndValidation
            label={strings.city}
            fieldProps={{
              name: 'city',
              type: 'text',
            }}
          />
          <div className="input-scaffold">
            <label className="input-label" htmlFor="region-dropdown">
              {regionLabel}
            </label>
            <RegionDropdown
              id="region-dropdown"
              value={values.state}
              countryValueType="short"
              country={values.country}
              onChange={value => setFieldValue('state', value)}
              defaultOptionLabel={regionDefaultOptionLabel}
            />
            <ErrorMessage name="state" component={ValidationError} />
          </div>
          <FieldWithLabelAndValidation
            label={strings.postalCode}
            fieldProps={{
              name: 'postalCode',
              type: 'text',
            }}
          />
          <div className="input-scaffold">
            <label className="input-label" htmlFor="region-dropdown">
              {strings.country}
            </label>
            <CountryDropdown
              value={values.country}
              valueType="short"
              onChange={value => setFieldValue('country', value)}
              defaultOptionLabel={strings.selectCountry}
              disabled={true}
            />
            <ErrorMessage name="country" component={ValidationError} />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="button button--color--primary"
            aria-label={strings.submit}
          >
            {strings.submit}
          </button>
        </Form>
      </div>
    );
  }
}

export default compose(
  withFormik({
    mapPropsToValues: props => ({
      city: '',
      state: '',
      postalCode: props.initialPlayingZip || '',
      country: 'US',
    }),
    validationSchema: Yup.object().shape({
      city: Yup.string()
        .trim()
        .required(strings.fieldIsRequired),
      state: Yup.string().required(strings.fieldIsRequired),
      postalCode: Yup.string()
        .trim()
        .required(strings.fieldIsRequired),
      country: Yup.string().required(strings.fieldIsRequired),
    }),
    handleSubmit(values, { props, setSubmitting }) {
      return props.onSubmit(values).then(action => {
        if (!action.response.ok) {
          setSubmitting(false);
        }
        else{
          // window.analytics.identify({
          //   postalCode: values.postalCode,
          //   state: stateCodesMap[values.state],
          //   city: values.city,
          //   country: values.country
          // });
          // window.analytics.track("Checkout Step Completed", {
          //   city: values.city,
          //   state: stateCodesMap[values.state],
          //   postal_code: values.postalCode,
          //   country: values.country,
          //   fitting_id: null
          // });
          // window.gtag('event', 'Checkout Step Completed', {
          //   city: values.city,
          //   state: stateCodesMap[values.state],
          //   postal_code: values.postalCode,
          //   country: values.country,
          //   fitting_id: null
          // });
          // window.analytics.track("Checkout Step Viewed", {
          //   checkout_step: 3,
          //   fitting_id: null
          // });
          // window.gtag('event', 'Checkout Step Viewed', {
          //   checkout_step: 3,
          //   fitting_id: null
          // });
        }
      });
    },
  })
)(BillingLocationForm);
