import React, { Component } from 'react';
import Modal from '../../../../common/components/Modal';

class CrossSectionModal extends Component {
  render() {
    const { crossSectionImage, onClose, title } = this.props;

    return (
      <Modal isOpened={!!crossSectionImage} onClose={onClose}>
        {title}
        <img className="cross-section-img" src={crossSectionImage} alt="Ball Cross-section" />
      </Modal>
    );
  }
}

export default CrossSectionModal;
