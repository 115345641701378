import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../common/forms/Slider';
import MultiButtonField from '../../../common/forms/MultiButtonField';
import LocalizedStrings from 'react-localization';
import TooltipIcon from '../../../common/components/TooltipIcon';
import { connect as formikConnect } from 'formik';

const strings = new LocalizedStrings({
  en: {
    totalDriverDistance: 'Total Driver Distance (yds)',
    totalDriverDistanceTooltip: `Providing your Total Driving Distance helps us calculate your initial Ball Speed, a key metric to simulate and compare aerodynamic flight. Be sure to combine both Carry and Roll.`,
    distanceUnits: 'yd',
    typicalDriverSpinRate: 'Typical Driver Spin Rate',
    typicalDriverSpinRateTooltip: `If your driving distance is hurt by a headwind more than others, you likely have a High spin rate. If you tend to get more distance on firm fairways and you do not have as much hang time with your driver, you’re likely Low spin. If you are unsure, leave this as Medium.`,
    typicalDriverLaunchAngle: 'Typical Driver Launch Angle',
    typicalDriverLaunchAngleTooltip: `Does your driver ball flight start low, high, or somewhere in between? We will use this input to help us calculate the initial launch angle in your trajectory simulation. If you are unsure, leave this at Medium.`,
    driverLaunchConditions: 'Driver Launch Conditions',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
  },
});

class DriverLaunchConditionsUnknown extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
  };

  savedDriverDistanceValue() {
    const { values } = this.props.formik;
    return values.driver_distance;
  }

  state = {
    driver_distance: this.savedDriverDistanceValue() || 225,
  };

  render() {
    const { isActive } = this.props;
    const { values, setFieldValue } = this.props.formik;

    return (
      <div className="questionnaire-blocks__container">
        <Slider
          label={strings.totalDriverDistance}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>
              {strings.totalDriverDistanceTooltip}
            </TooltipIcon>
          }
          displayValue={`${this.state.driver_distance}${strings.distanceUnits}`}
          sliderProps={{
            tabIndex: isActive ? undefined : -1,
            min: 100,
            max: 350,
            step: 1,
            marks: {
              100: <div className="slider--mark">100</div>,
              350: <div className="slider--mark">350</div>,
            },
            value: this.state.driver_distance,
            onChange: value => this.setState({ driver_distance: value }),
            onAfterChange: value => setFieldValue('driver_distance', value),
          }}
        />
        <MultiButtonField
          label={strings.typicalDriverLaunchAngle}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>
              {strings.typicalDriverLaunchAngleTooltip}
            </TooltipIcon>
          }
          choices={[
            { label: strings.low, value: 'low' },
            { label: strings.medium, value: 'mid' },
            { label: strings.high, value: 'high' },
          ]}
          value={values.driver_launch_type}
          onChange={setFieldValue.bind(this, 'driver_launch_type')}
          untabbable={!isActive}
        />
        <MultiButtonField
          label={strings.typicalDriverSpinRate}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>
              {strings.typicalDriverSpinRateTooltip}
            </TooltipIcon>
          }
          choices={[
            { label: strings.low, value: 'low' },
            { label: strings.medium, value: 'mid' },
            { label: strings.high, value: 'high' },
          ]}
          value={values.driver_spin_type}
          onChange={setFieldValue.bind(this, 'driver_spin_type')}
          untabbable={!isActive}
        />
      </div>
    );
  }
}

export default formikConnect(DriverLaunchConditionsUnknown);
