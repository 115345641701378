import config from '../../config';
const contentful = require('contentful');

const client = contentful.createClient({
  space: config.contentful.space,
  accessToken: config.contentful.accessToken,
  environment: config.contentful.environment,
});

export default client;
