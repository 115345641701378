import client from '../client';
import config from '../config';
import localstorage from 'store2';

import { FETCH_LOGGED_IN_USER, FETCH_TOKEN, REGISTER } from './auth-action-constants';

export function fetchLoggedInUser() {
  return {
    type: FETCH_LOGGED_IN_USER,
    promise: client.get('user'),
  };
}

export function fetchToken(code) {
  return {
    type: FETCH_TOKEN,
    promise: client.post('token', {
      grant_type: 'authorization_code',
      code,
      redirect_uri: config.auth.redirectUri,
      client_id: config.auth.clientId,
      code_verifier: localstorage.get('code_verifier'),
    }),
  };
}

export function register(payload) {
  return {
    type: REGISTER,
    promise: client.post(`users/registration`, payload),
  };
}
