import React from 'react';
import Svg, { Path, G } from 'svgs';

const ArrowLeft = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <G fillRule="nonzero" transform="translate(5 1)">
        <Path d="M0 18.065v-2.78h13.896v2.085h-2.78c-.694 0-1.389.278-1.945.833-.556.556-.834 1.251-.834 1.946v1.39H5.558v-1.39c0-.695-.278-1.39-.833-1.946-.556-.555-1.251-.833-1.946-.833H0M6.253 9.032h1.39v1.39h-1.39v-1.39m0-5.558h1.39v4.169h-1.39v-4.17M6.948 0C3.106 0 0 3.127 0 6.948A6.948 6.948 0 106.948 0m0 12.506a5.558 5.558 0 110-11.116 5.558 5.558 0 010 11.116z" />
      </G>
    </Svg>
  );
};

export default ArrowLeft;
