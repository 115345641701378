import React from 'react';
import Svg, { Path } from 'svgs';

const SpinHeightBalance = ({ width, height, fill }) => {
  return (
    <Svg
      width={width || 72}
      height={height || 24}
      viewBox="0 0 72 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M11.9999998 4V1L7.99999976 5l4.00000004 4V6c3.309999 0 6 2.69000004 6 6 0 1.0099995-.2500006 1.9699995-.7000016 2.7999994l1.4599989 1.4599999c.780003-1.2300004 1.2400027-2.6900004 1.2400027-4.2599993 0-4.42000049-3.5800004-8-8-8zM12 17.999999c-3.30999978 0-6-2.6900004-6-5.9999997 0-1.0100001.24999986-1.9700001.69999964-2.79999999L5.23999973 7.7399994C4.45999997 8.96999982 4 10.4299997 4 11.9999993c0 4.4199999 3.57999969 7.9999996 8 7.9999996v2.9999998l4-3.9999998-4-3.9999997v2.9999998z" />
      <Path d="M68 17.55L62.5 23 57 17.55l1.4229358-1.41 3.0678899 3.03V4.83l-3.0678899 3.03L57 6.45 62.5 1 68 6.45l-1.4229358 1.41-3.0678899-3.03v14.34l3.0678899-3.03zM28 15s0-6 6-6c4 0 4.5 3.5 7.5 3.5 4 0 4-3.5 4-3.5H48s0 6-6 6c-4 0-5.5-3.5-7.5-3.5-4 0-4 3.5-4 3.5H28" />
    </Svg>
  );
};

export default SpinHeightBalance;
