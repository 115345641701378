import React from 'react';
import Svg, { Path, Circle } from 'svgs';

const LoadingGolfBall = ({ size, fill, ...rest }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...rest}
    >
      <Circle r="12" fill="#F5F6FA" transform="matrix(1 0 0 -1 12 12)"></Circle>
      <Path
        fill="#2D3E4E"
        fillRule="evenodd"
        d="M20 12a8 8 0 10-16 0 8 8 0 0016 0zm4 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12zm-7.061-2.677a1.477 1.477 0 110 2.954 1.477 1.477 0 010-2.954zM13.43 10.8a1.477 1.477 0 10-2.954 0 1.477 1.477 0 002.954 0zM6.97 9.323a1.477 1.477 0 110 2.954 1.477 1.477 0 010-2.954zM15.923 7.8a1.477 1.477 0 10-2.954 0 1.477 1.477 0 002.954 0zM9.462 6.323a1.477 1.477 0 110 2.954 1.477 1.477 0 010-2.954z"
        clipRule="evenodd"
      />
    </Svg>
  );
};

export default LoadingGolfBall;
