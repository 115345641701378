import { combineReducers } from 'redux';
import {
  FETCH_BRAINTREE_TOKEN,
  GET_PRICE,
  FETCH_PRICES,
  SUBMIT_RESULTS_PAYMENT,
  GET_BILLING_HISTORY,
  GET_SALES_TAX,
  RESET_SALES_TAX,
  GET_PROMO_CODE,
  RESET_SUBMIT_RESULTS_PAYMENT,
  VALIDATE_VOUCHER_CODE,
  RESET_VALIDATE_VOUCHER_CODE,
  FETCH_PURCHASED_VOUCHERS_LIST
} from './payment-action-constants';
import Immutable, { List } from 'immutable';
import getApiReducer from '../common/utils/getApiReducer';
import Dinero from 'dinero.js';

export default combineReducers({
  submitResultsPayment: getApiReducer(SUBMIT_RESULTS_PAYMENT, [RESET_SUBMIT_RESULTS_PAYMENT]),
  validateVoucherCode: getApiReducer(VALIDATE_VOUCHER_CODE, [RESET_VALIDATE_VOUCHER_CODE]),
  getBillingHistory: getApiReducer(GET_BILLING_HISTORY),
  getSalesTax: getApiReducer(GET_SALES_TAX, [RESET_SALES_TAX]),
  getPromoCode: getApiReducer(GET_PROMO_CODE),
  fetchPurchasedVouchersList: getApiReducer(FETCH_PURCHASED_VOUCHERS_LIST),
  purchasedVouchersList: (state = List(), action) => {
    if (action.type === `${FETCH_PURCHASED_VOUCHERS_LIST}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  fetchPrices: getApiReducer(FETCH_PRICES),
  braintreeToken: (state = null, action) => {
    switch (action.type) {
      case FETCH_BRAINTREE_TOKEN:
        return null; // Reset state when fetching
      case `${FETCH_BRAINTREE_TOKEN}_SUCCESS`:
        return action.json?.clientToken ? Immutable.fromJS(action.json.clientToken) : null; // Ensure it never returns undefined
      case `${FETCH_BRAINTREE_TOKEN}_FAILURE`:
        return null; // Reset state on failure
      default:
        return state; // Return previous state for unhandled actions
    }
  },
  promoCode: (state = null, action) => {
    if (action.type === `${GET_PROMO_CODE}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  price: (state = null, action) => {
    if (action.type === `${GET_PRICE}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  prices: (state = null, action) => {
    if (action.type === `${FETCH_PRICES}_SUCCESS`) {
      return action.json;
    }
    return state;
  },
  billingHistory: (state = null, action) => {
    if (action.type === `${GET_BILLING_HISTORY}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  totalTax: (state = null, action) => {
    if (action.type === `${GET_SALES_TAX}_REQUEST` || action.type === `RESET_SALES_TAX`) {
      return null;
    }
    if (action.type === `${GET_SALES_TAX}_SUCCESS`) {
      return Dinero({ amount: parseInt(action.json.totalTax * 100, 10), current: 'USD' });
    }
    return state;
  },
});
