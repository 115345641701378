import uploadToAzureBlob from './uploadToAzureBlob';
import uploadToS3 from './uploadToS3';
import config from '../../config';

const S3 = 'S3';
const AZURE_BLOB = 'AZURE_BLOB';

const uploadFile = (containerOrBucket, file, ext) => {
  if (config.fileStorage.driver === S3) {
    return uploadToS3(containerOrBucket, file, ext);
  } else if (config.fileStorage.driver === AZURE_BLOB) {
    return uploadToAzureBlob(containerOrBucket, file, ext);
  } else {
    throw new Error(`Invalid file storage driver: ${config.fileStorage.driver}`);
  }
};

export default uploadFile;
