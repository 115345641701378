import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KebabIcon from './icons/Kebab';

class KebabMenu extends Component {
  static propTypes = {
    menuOpened: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onDisengage: PropTypes.func.isRequired,
    buttonAriaLabel: PropTypes.string.isRequired,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDomClick);
    document.addEventListener('focusin', this.handleFocusIn);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDomClick);
    document.removeEventListener('focusin', this.handleFocusIn);
  }

  handleFocusIn = () => {
    if (this.props.menuOpened && !this.ref.contains(document.activeElement)) {
      this.props.onDisengage();
    }
  };

  handleDomClick = e => {
    if (!this.ref.contains(e.target) && this.props.menuOpened) {
      this.props.onDisengage();
    }
  };

  renderIcon() {
    return (
      <button
        aria-label={this.props.buttonAriaLabel}
        className="kebab-button"
        type="button"
        onClick={e => {
          e.stopPropagation();
          this.props.onClick(e);
        }}
      >
        <KebabIcon fill="#576975" />
      </button>
    );
  }

  render() {
    const { menuOpened } = this.props;

    return (
      <div
        className="kebab-menu"
        ref={node => {
          this.ref = node;
        }}
      >
        <div onClick={e => e.stopPropagation()}>{!menuOpened && this.renderIcon()}</div>
        {menuOpened && <div className="kebab-list">{this.props.children}</div>}
      </div>
    );
  }
}

export default KebabMenu;
