import React from 'react';
import PropTypes from 'prop-types';

const ForceReloadPrompt = ({ children, header }) => {
  return (
    <div>
      <div>
        <h1>{header}</h1>

        {children}

        <button
          onClick={() => document.location.reload(true)}
          className="button button--color--primary button--size--large"
        >
          Update Now
        </button>
      </div>
    </div>
  );
};

ForceReloadPrompt.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
};

ForceReloadPrompt.defaultProps = {
  header: 'New Updates are Available',
};

export default ForceReloadPrompt;
