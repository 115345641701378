import TagManager from 'react-gtm-module';
import config from '../../config';

export function initializeGTM(options = {}) {
  const tagManagerArgs = {
    gtmId: config.gtm.id,
    auth: config.gtm.auth,
    preview: config.gtm.preview,
    ...options,
  };

  TagManager.initialize(tagManagerArgs);
}

export function updateDataLayer(dataLayer) {
  TagManager.dataLayer({ dataLayer });
}

export function triggerGTMEvent(eventName, dataLayer = {}) {
  initializeGTM({
    dataLayer: {
      event: eventName,
      ...dataLayer,
    },
  });
}
