import React from 'react';
import LocalizedStrings from 'react-localization';
import Header from '../../common/components/Header';
import CoBrandPlaceholder from '../../common/components/icons/CoBrandPlaceholder';
import Kebab from '../../common/components/icons/Kebab';

let strings = new LocalizedStrings({
  en: {
    myFitting: 'My Fitting',
    library: 'Library',
    headerPreview: 'Header Preview',
    emailPreview: 'Email Preview',
    previewAltText: 'Co-Brand Preview',
  },
});

const CoBrandPreview = ({ svgSrc, pngSrc }) => {
  return (
    <div className="admin-add-co-brand-form__preview">
      <div className="input-scaffold header-preview">
        <label className="input-label">{strings.headerPreview}</label>
        <Header
          coBrandName={strings.previewAltText}
          coBrandImageUrl={svgSrc}
          coBrandPreview={!svgSrc}
          logoLinkTo="#"
          kebabMenu={<Kebab />}
        />
      </div>
      <div className="input-scaffold">
        <label className="input-label">{strings.emailPreview}</label>
        <div className="email-header-preview">
          <img
            alt="Logo"
            src="https://ballnamicimages.s3.us-west-2.amazonaws.com/pinggolf/pinggolf/ballnamic_logo_two_color.png"
            width="192"
            height="54"
            border="0"
          />
          <div className="co-brand--pipe" />
          {pngSrc && <img alt="Co-Brand Logo" src={pngSrc} width="192" height="54" border="0" />}
          {!pngSrc && <CoBrandPlaceholder />}
        </div>
      </div>
    </div>
  );
};

export default CoBrandPreview;
