import React, { useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCoBrand, resetCoBrand, updateCoBrand } from '../admin-actions';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import CoBrandForm from './CoBrandForm';

const strings = new LocalizedStrings({
  en: {
    editCoBrand: 'Edit Co-Brand',
  },
});

const CoBrandEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { coBrandId } = useParams();
  const coBrand = useSelector(({ admin }) => admin.coBrand.toJS());
  const getCoBrandRequest = useSelector(({ admin }) => admin.getCoBrand);

  useEffect(() => {
    dispatch(getCoBrand(coBrandId));
  }, [coBrandId, dispatch]);

  useEffect(() => () => dispatch(resetCoBrand()), [dispatch]);

  if (!getCoBrandRequest.get('loaded')) {
    return <LoadingIndicator />;
  }

  return (
    <CoBrandForm
      svgSrc={coBrand.svg_src}
      pngSrc={coBrand.png_src}
      initialValues={{
        domains: coBrand.domains.map(({ id, domain }) => ({ id, domain })),
        svgSrc: coBrand.svg_src,
        pngSrc: coBrand.png_src,
        name: coBrand.name,
      }}
      onSubmit={(values, { setStatus }) =>
        dispatch(updateCoBrand(coBrandId, values)).then(action => {
          if (!action.response.ok) {
            setStatus(action.json && action.json.message ? action.json.message : 'An error occurred');
          } else {
            history.push('/admin/co-branding');
          }
        })
      }
      title={strings.editCoBrand}
    />
  );
};

export default CoBrandEdit;
