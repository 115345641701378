import React from 'react';
import Svg, { Path } from 'svgs';

const BallCrossSection = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M2.25 16.5c.414 0 .75.336.75.75V21h3.75c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75H1.5C.6705 24 0 23.3295008 0 22.5v-5.25c0-.414.33599999-.75.75-.75zm21 0c.414 0 .75.336.75.75v5.25c0 .8295008-.6704992 1.5-1.5 1.5h-5.25c-.414 0-.75-.336-.75-.75v-1.5c0-.414.336-.75.75-.75H21v-3.75c0-.414.336-.75.75-.75zM12 4c4.418278 0 8 3.581722 8 8s-3.581722 8-8 8-8-3.581722-8-8 3.581722-8 8-8zm-.032 3.456c-2.52725502 0-4.576 2.04874498-4.576 4.576 0 2.527255 2.04874498 4.576 4.576 4.576 2.527255 0 4.576-2.048745 4.576-4.576 0-2.52725502-2.048745-4.576-4.576-4.576zm.032 2.56c1.1310792 0 2.048.9169208 2.048 2.048s-.9169208 2.048-2.048 2.048-2.048-.9169208-2.048-2.048.9169208-2.048 2.048-2.048zM6.75 0c.414 0 .75.33599999.75.75v1.5c0 .414-.336.75-.75.75H3v3.75c0 .414-.336.75-.75.75H.75C.33599999 7.5 0 7.164 0 6.75V1.5C0 .6705.6705 0 1.5 0zM22.5 0c.8295008 0 1.5.67049996 1.5 1.5v5.25c0 .414-.336.75-.75.75h-1.5c-.414 0-.75-.336-.75-.75V3h-3.75c-.414 0-.75-.336-.75-.75V.75c0-.41400001.336-.75.75-.75z" />
    </Svg>
  );
};

export default BallCrossSection;
