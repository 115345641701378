import React from 'react';
import Svg, { G, Path } from 'svgs';

const Jcb = ({ width, height, fill }) => {
  return (
    <Svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 223.23 171.97"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <G data-name="Layer 2">
        <Path d="M223.23 137.25A34.73 34.73 0 0 1 188.5 172H0V34.72A34.72 34.72 0 0 1 34.72 0h188.51z" fill="#fff" />
        <Path
          d="M161.77 102.08h14.36a10.55 10.55 0 0 0 1.71-.16 6.56 6.56 0 0 0 0-12.76 11.81 11.81 0 0 0-1.71-.16h-14.36z"
          fill="#034694"
        />
        <Path
          d="M174.45 11.57a24.81 24.81 0 0 0-24.81 24.81v25.76h35.1c.81 0 1.81 0 2.43.09 7.94.39 13.8 4.48 13.8 11.56 0 5.57-4 10.32-11.29 11.39v.28c8 .56 14.21 5 14.21 12 0 7.55-6.76 12.4-15.78 12.4h-38.47v50.56H186a24.8 24.8 0 0 0 24.81-24.8v-124z"
          fill="#034694"
        />
        <Path
          d="M181.19 75.58a5.89 5.89 0 0 0-5-5.93 11.75 11.75 0 0 0-1.42-.11h-13v12.08h13a11.75 11.75 0 0 0 1.42-.11 5.9 5.9 0 0 0 5-5.93zM37.2 11.57a24.81 24.81 0 0 0-24.8 24.81v61.25c7 3.37 14.14 5.56 21.46 5.56 8.57 0 13.29-5.23 13.29-12.27V62.14h21.33v28.78c0 11.25-6.91 20.28-30.62 20.28a106.42 106.42 0 0 1-25.46-3.08v52.27h36.38a24.8 24.8 0 0 0 24.8-24.8v-124zM105.83 11.57A24.81 24.81 0 0 0 81 36.38v32.47c6.29-5.39 17.16-8.76 34.81-8a112 112 0 0 1 19.48 3v10.5a48.63 48.63 0 0 0-18.79-5.48c-13.4-1-21.46 5.53-21.46 17.13s8.06 18.13 21.46 17.11a49.25 49.25 0 0 0 18.79-5.47v10.49a113.17 113.17 0 0 1-19.48 3c-17.65.79-28.52-2.58-34.81-8v57.27h36.4a24.8 24.8 0 0 0 24.8-24.8v-124z"
          fill="#034694"
        />
      </G>
    </Svg>
  );
};

export default Jcb;
