import React from 'react';
import Header from '../components/Header';

export default function PreviewLayout(props) {
  return (
    <div>
      <Header />
      {props.children}
    </div>
  );
}
