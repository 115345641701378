import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import DriverConditionsKnown from './DriverConditionsKnown';
import DriverConditionsUnknown from './DriverConditionsUnknown';
import LaunchConditionsFlipper from '../LaunchConditionsFlipper';

const strings = new LocalizedStrings({
  en: {
    doNotKnowDriverConditions: `I don't know my driver launch conditions`,
    knowDriverConditions: `I know my launch conditions`,
  },
});

class DriverLaunchConditions extends Component {
  render() {
    return (
      <LaunchConditionsFlipper
        type="driver"
        unknownButtonText={strings.doNotKnowDriverConditions}
        knownButtonText={strings.knowDriverConditions}
        KnownForm={DriverConditionsKnown}
        UnknownForm={DriverConditionsUnknown}
      />
    );
  }
}

export default DriverLaunchConditions;
