import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const buttonStyles = ['primary', 'icon-clear', 'text'];

class Button extends Component {
  static propTypes = {
    buttonStyle: PropTypes.oneOf(buttonStyles),
  };

  static defaultProps = {
    buttonStyle: 'primary',
  };

  render() {
    const { buttonStyle, textTransform, outline, icon, ...rest } = this.props;

    const className = classNames({
      button: true,
      'button--color--primary': buttonStyle === 'primary',
      'button--theme--outline': outline,
      'button--icon': icon,
      'button--icon-clear': buttonStyle === 'icon-clear',
      'faux-link gray borderless': buttonStyle === 'text',
    });
    return (
      <button className={className} style={{ textTransform: textTransform || 'uppercase' }} {...rest}>
        {this.props.children}
      </button>
    );
  }
}

export default Button;
