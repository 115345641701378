import React from 'react';
import Svg, { Path } from 'svgs';

const Logo = ({ width, height }) => {
  return (
    <a href="/">
      <Svg
        width={width || 248}
        height={height || 69}
        viewBox="0 0 248 69"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        role="img"
      >
        <Path
          fill="#575BFF"
          d="M63.19 65.37A29.07 29.07 0 0140.53 54.3h-4.17a32.16 32.16 0 0053.64 0h-4.15a29.07 29.07 0 01-22.66 11.07zM63.19 3.36a29 29 0 0122.66 11.08H90a32.15 32.15 0 00-53.66 0h4.17A29 29 0 0163.19 3.36z"
        />
        <Path
          fill="#575BFF"
          d="M32.81 23.33L28.89 23.33 11.96 23.33 11.21 27.05 27.9 27.05 31.71 27.05 43.63 27.05 44.38 23.33z"
        />
        <Path fill="#575BFF" d="M31.2 31.5L27.28 31.5 0.75 31.5 0 35.21 26.3 35.21 30.1 35.21 42.02 35.21 42.77 31.5z" />
        <Path
          fill="#575BFF"
          d="M29.52 39.66L25.61 39.66 15.47 39.66 14.72 43.37 20.3 43.37 28.42 43.37 40.35 43.37 41.09 39.66z"
        />
        <Path
          fill="#303133"
          d="M74.7 35a5.43 5.43 0 01.93 3.17 7 7 0 01-3.37 6.27 16.17 16.17 0 01-8.8 2.15h-14.6l5.31-26.55h13.42c2.987 0 5.27.537 6.85 1.61a5.25 5.25 0 012.37 4.61 6.55 6.55 0 01-1.25 4 8.29 8.29 0 01-3.49 2.64A6 6 0 0174.7 35zM67 40.41a2.69 2.69 0 001.14-2.35 1.89 1.89 0 00-.83-1.69 4.09 4.09 0 00-2.28-.55h-6.61l-1.07 5.38h6.53a5.38 5.38 0 003.12-.79zM60.5 25.5l-1 5.12h5.38a5.15 5.15 0 003.06-.77 2.66 2.66 0 001-2.26c0-1.39-1-2.09-3.15-2.09H60.5zM102.56 26.07l-4.1 20.56h-6.82l.41-1.94a8.45 8.45 0 01-6 2.28 8.65 8.65 0 01-4.27-1.12 8.51 8.51 0 01-3.2-3.24 9.94 9.94 0 01-1.22-5 12.31 12.31 0 011.5-6.05A11.17 11.17 0 0183 27.29a10.55 10.55 0 015.59-1.56c2.987 0 5.087.937 6.3 2.81l.49-2.47h7.18zm-10.43 13.5a6.12 6.12 0 001.36-4.13 3.9 3.9 0 00-1-2.88 3.82 3.82 0 00-2.86-1.07 4.45 4.45 0 00-3.53 1.6 6.18 6.18 0 00-1.36 4.17 3.61 3.61 0 003.9 3.91 4.45 4.45 0 003.49-1.6z"
        />
        <Path fill="#303133" d="M107.75 18.49L115 18.49 109.39 46.63 102.18 46.63z" />
        <Path fill="#303133" d="M118.67 18.49L125.88 18.49 120.27 46.63 113.06 46.63z" />
        <Path
          fill="#303133"
          d="M146.34 27.53a6.57 6.57 0 012 5.18 12.4 12.4 0 01-.27 2.54l-2.28 11.38h-7.2l2.12-10.7a6 6 0 00.15-1.36c0-1.82-.94-2.73-2.84-2.73a4.33 4.33 0 00-3.08 1.16 6.56 6.56 0 00-1.71 3.47l-2 10.17H124l4.1-20.56h6.82l-.41 2.13a8.44 8.44 0 013-1.86 9.87 9.87 0 013.45-.61 7.87 7.87 0 015.38 1.79z"
        />
        <Path
          fill="#303133"
          d="M175.56 26.07l-4.1 20.56h-6.83l.42-1.94a8.45 8.45 0 01-6 2.28 8.65 8.65 0 01-4.27-1.12 8.44 8.44 0 01-3.2-3.24 9.94 9.94 0 01-1.22-5 12.31 12.31 0 011.5-6.05 11.17 11.17 0 014.14-4.27 10.55 10.55 0 015.59-1.56c2.98 0 5.08.937 6.3 2.81l.49-2.47h7.18zm-10.43 13.5a6.12 6.12 0 001.36-4.13 3.94 3.94 0 00-1-2.88 3.83 3.83 0 00-2.86-1.07 4.44 4.44 0 00-3.53 1.6 6.18 6.18 0 00-1.37 4.17 3.63 3.63 0 003.91 3.91 4.45 4.45 0 003.49-1.6z"
        />
        <Path
          fill="#303133"
          d="M210.75 27.55a6.68 6.68 0 012 5.16 12.4 12.4 0 01-.27 2.54l-2.23 11.38H203L205.13 36a7.06 7.06 0 00.16-1.44 2.84 2.84 0 00-.67-2 2.52 2.52 0 00-1.91-.68c-2.3 0-3.76 1.48-4.36 4.43l-2.09 10.36h-7.2L191.22 36a6.22 6.22 0 00.15-1.41 2.85 2.85 0 00-.67-2.06 2.49 2.49 0 00-1.91-.7c-2.33 0-3.79 1.5-4.4 4.51l-2 10.28h-7.2l4.09-20.56h6.83l-.42 2.09a8.58 8.58 0 016.15-2.43 7.88 7.88 0 013.67.85 5.91 5.91 0 012.49 2.5 9.75 9.75 0 013.35-2.49 10.36 10.36 0 014.19-.85 7.44 7.44 0 015.21 1.82z"
        />
        <Path
          fill="#303133"
          d="M217.91 26.07h7.17L221 46.63h-7.17l4.08-20.56zm8.09-6.42a3.54 3.54 0 11-3.54-3.54 3.53 3.53 0 013.54 3.54z"
        />
        <Path
          fill="#303133"
          d="M230.48 45.81a8.82 8.82 0 01-3.79-3.28 9 9 0 01-1.34-4.93 11.35 11.35 0 016.18-10.31 13.67 13.67 0 016.56-1.56 12 12 0 016 1.46 8.68 8.68 0 013.71 4.12l-6.14 2.92a4 4 0 00-4-2.74 4.65 4.65 0 00-3.62 1.6 5.89 5.89 0 00-1.42 4.09 3.69 3.69 0 004 4 5 5 0 002.55-.65 5.33 5.33 0 001.89-2.05l5.27 3.19a10.15 10.15 0 01-4.15 3.9 12.77 12.77 0 01-5.99 1.43 13.29 13.29 0 01-5.71-1.19z"
        />
      </Svg>
    </a>
  );
};

export default Logo;
