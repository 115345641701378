import React, { createContext, useState } from 'react';
import { connect } from 'react-redux';
import ShareForm from '../user/Profile/MyFittingCodes/ShareForm';


export const ShareFormContext = createContext();

const ShareFormProvider = ({ children, loggedInUser }) => {
  const [showShare, setShowShare] = useState(localStorage.getItem('action') === 'shareVoucher');
  const [selectedCode, setSelectedCode] = useState(localStorage.getItem('voucher'));
  const [sender, setSender] = useState(loggedInUser?.get('oidcData')?.get('email'));
  const [reloadScreen, setReloadScreen] = useState(false);

  const handleShareForm = (code, sender, reloadScreen) => {
    setSelectedCode(code);
    setSender(sender);
    setReloadScreen(reloadScreen);
    setShowShare(!showShare);
  };

  return (
    <ShareFormContext.Provider value={{ showShare, handleShareForm }}>
      {children}
      {showShare && (
          <ShareForm isOpened={showShare} onCancel={handleShareForm} selectedCode={selectedCode} sender={sender} reloadScreen={reloadScreen} />
      )}
    </ShareFormContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.loggedInUser,
});

export default connect(mapStateToProps)(ShareFormProvider);