import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../common/forms/Slider';
import MultiButtonField from '../../../common/forms/MultiButtonField';
import LocalizedStrings from 'react-localization';
import TooltipIcon from '../../../common/components/TooltipIcon';
import { connect as formikConnect } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';

const strings = new LocalizedStrings({
  en: {
    sevenIronDistance: '7-Iron Carry Distance (yds)',
    sixIronDistance: '6-Iron Carry Distance (yds)',
    sevenIronDistanceTooltip: `Your 7-iron carry distance gives a good indication of your ball speed and how fast you swing throughout your set. For this metric, just set it at how far the ball typically lands – without including roll out.`,
    sixIronDistanceTooltip: `Your 6-iron carry distance gives a good indication of your ball speed and how fast you swing throughout your set. For this metric, just set it at how far the ball typically lands – without including roll out.`,
    distanceUnits: 'yd',
    typicalSevenIronSpinRate: 'Typical 7-Iron Spin Rate',
    typicalSixIronSpinRate: 'Typical 6-Iron Spin Rate',
    typicalIronSpinRateTooltip: `When it is windy, do you struggle with distance control? Do your irons curve too much? If so, your probably have High spin rate in your irons. If you struggle with Stopping Power in firm conditions, you probably have a Low spin rate in your irons. Higher or lower spin rate in your irons will drive how a ball performs in the wind and the workability.`,
    typicalSevenIronLaunchAngle: 'Typical 7-Iron Launch Angle',
    typicalSixIronLaunchAngle: 'Typical 6-Iron Launch Angle',
    typicalSevenIronLaunchAngleTooltip: `When entering 7-iron Launch Angle, think about how high in the air your ball flies compared to others who hit it a similar distance to you.`,
    typicalSixIronLaunchAngleTooltip: `When entering 6-iron Launch Angle, think about how high in the air your ball flies compared to others who hit it a similar distance to you.`,
    sevenIronLaunchConditions: '7-Iron Launch Conditions',
    sixIronLaunchConditions: '6-Iron Launch Conditions',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
  },
});

class IronConditionsUnknown extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
  };

  savedIronDistanceValue() {
    const { values } = this.props.formik;
    return values.iron_distance;
  }

  state = {
    iron_distance: this.savedIronDistanceValue() || 145,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loggedInUser.get('six_iron_fitting') !== this.props.loggedInUser.get('six_iron_fitting')) {
      this.setState({ iron_distance: 145 });
    }
  }

  render() {
    const { isActive, isSixIronFitting } = this.props;
    const { values, setFieldValue } = this.props.formik;

    return (
      <div className="questionnaire-blocks__container">
        <Slider
          label={isSixIronFitting ? strings.sixIronDistance : strings.sevenIronDistance}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>
              {isSixIronFitting ? strings.sixIronDistanceTooltip : strings.sevenIronDistanceTooltip}
            </TooltipIcon>
          }
          displayValue={`${this.state.iron_distance}${strings.distanceUnits}`}
          sliderProps={{
            tabIndex: isActive ? undefined : -1,
            min: 80,
            max: 210,
            step: 1,
            marks: {
              80: <div className="slider--mark">80</div>,
              210: <div className="slider--mark">210</div>,
            },
            value: this.state.iron_distance,
            onChange: value => this.setState({ iron_distance: value }),
            onAfterChange: value => setFieldValue('iron_distance', value),
          }}
        />
        <MultiButtonField
          label={isSixIronFitting ? strings.typicalSixIronLaunchAngle : strings.typicalSevenIronLaunchAngle}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>
              {isSixIronFitting ? strings.typicalSixIronLaunchAngleTooltip : strings.typicalSevenIronLaunchAngleTooltip}
            </TooltipIcon>
          }
          choices={[
            { label: strings.low, value: 'low' },
            { label: strings.medium, value: 'mid' },
            { label: strings.high, value: 'high' },
          ]}
          value={values.iron_launch_type}
          onChange={setFieldValue.bind(this, 'iron_launch_type')}
          untabbable={!isActive}
        />
        <MultiButtonField
          label={isSixIronFitting ? strings.typicalSixIronSpinRate : strings.typicalSevenIronSpinRate}
          tooltip={
            <TooltipIcon buttonProps={{ tabIndex: isActive ? null : -1 }}>
              {strings.typicalIronSpinRateTooltip}
            </TooltipIcon>
          }
          choices={[
            { label: strings.low, value: 'low' },
            { label: strings.medium, value: 'mid' },
            { label: strings.high, value: 'high' },
          ]}
          value={values.iron_spin_type}
          onChange={setFieldValue.bind(this, 'iron_spin_type')}
          untabbable={!isActive}
        />
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
  })),
  formikConnect
)(IronConditionsUnknown);
