import React from 'react';
import FieldWithLabelAndValidation from '../../common/forms/FieldWithLabelAndValidation';
import LocalizedStrings from 'react-localization';
import { useFormikContext } from 'formik';

let strings = new LocalizedStrings({
  en: {
    loading: 'Loading...',
    selectOne: 'Select One...',
  },
});

const Dropdown = function(props) {
  const { options, label, name, onChange } = props;
  const { handleChange } = useFormikContext();
  return (
    <FieldWithLabelAndValidation
      label={label}
      fieldProps={{
        as: 'select',
        name,
        disabled: !options || !options.size,
        onChange: event => {
          if (onChange) {
            onChange(event.target.value);
          }
          handleChange(event);
        },
      }}
    >
      <React.Fragment>
        <option value="">{strings.selectOne}</option>
        {options && options.size
          ? options.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))
          : null}
      </React.Fragment>
    </FieldWithLabelAndValidation>
  );
};

export default Dropdown;
