import React from 'react';
import Svg, { Path } from 'svgs';

const DriverWindScore = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M19.25 14.7c.9112698 0 1.65.7387302 1.65 1.65 0 .9112698-.7387302 1.65-1.65 1.65-.4565 0-.869-.187-1.166-.484-.209-.2145-.209-.561 0-.7755.2145-.2145.561-.2145.7755 0 .099.099.2365.1595.3905.1595.3037566 0 .55-.2462434.55-.55 0-.3037566-.2462434-.55-.55-.55h-7.7c-.3037566 0-.55-.2462434-.55-.55 0-.3037566.2462434-.55.55-.55h7.7zM6.67836257 6.26315789c1.29189415 0 2.33918129 1.04728714 2.33918129 2.33918129V15.619883c0 1.2918942-1.04728714 2.3391813-2.33918129 2.3391813H2V6.26315789zm0 2.33918129H4.33918129V15.619883h2.33918128V8.60233918zM19.8 10.3c.9112698 0 1.65.7387302 1.65 1.65 0 .9112698-.7387302 1.65-1.65 1.65h-7.7c-.3037566 0-.55-.2462434-.55-.55 0-.3037566.2462434-.55.55-.55h7.7c.3037566 0 .55-.2462434.55-.55 0-.3037566-.2462434-.55-.55-.55-.154 0-.2915.0605-.3905.1595-.2145.2145-.561.2145-.7755 0-.209-.2145-.209-.561 0-.7755.297-.297.7095-.484 1.166-.484zM15.95 7c1.2150264 0 2.2.98497355 2.2 2.2 0 1.2150264-.9849736 2.2-2.2 2.2h-4.4c-.3037566 0-.55-.2462434-.55-.55 0-.3037566.2462434-.55.55-.55h4.4c.6075132 0 1.1-.49248678 1.1-1.1 0-.60751322-.4924868-1.1-1.1-1.1-.3025 0-.5775.121-.7755.3245-.2145.2255-.5665.2255-.781 0-.2145-.2145-.2145-.5665 0-.781C14.795 7.2475 15.345 7 15.95 7z" />
    </Svg>
  );
};

export default DriverWindScore;
