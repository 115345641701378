import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import LocalizedStrings from 'react-localization';
import Button from '../../common/components/Button';
import ClearIcon from '../../common/components/icons/Clear';
import { clearFitterCache } from '../../user/user-actions';
import { toast } from 'react-toastify';

let strings = new LocalizedStrings({
  en: {
    clearCache: 'Clear Fitting API Cache',
    success: 'Fitting API cache cleared successfully',
    errorDefault: 'There was a problem clearing Fitting API cache',
  },
});

class FitterClearCacheButton extends Component {
  componentDidUpdate(prevProps) {
    const { clearCache } = this.props;
    if (!clearCache.loading && clearCache.success) {
      toast(strings.success, { type: 'success' });
    } else if (!clearCache.loading && clearCache.error) {
      toast(strings.errorDefault, { type: 'error' });
    }
  }

  render() {
    const { dispatch } = this.props;
    return (
      <Button
        onClick={() => dispatch(clearFitterCache())}
        icon
        outline
        textTransform="none"
        aria-label={strings.clearCache}
      >
        <ClearIcon size={20} />
        <span>{strings.clearCache}</span>
      </Button>
    );
  }
}

export default compose(
  connect(state => ({
    clearCache: state.user.clearCache,
  }))
)(FitterClearCacheButton);
