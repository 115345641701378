import { compose, createStore, applyMiddleware } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';
import apiRequestMiddleware from './common/utils/apiRequestMiddleware';
import gtmEventMiddleware from './common/utils/gtmEventMiddleware';
import reducers from './reducers';

// Apply Redux DevTools to local and staging environment
const composeEnhancers =
  (process.env.NODE_ENV !== 'prod' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
// Apply Redux middleware
let storeEnhancer = composeEnhancers(
  responsiveStoreEnhancer,
  applyMiddleware(apiRequestMiddleware, gtmEventMiddleware, thunk)
);

// Bootstrap the React app
export const createStoreWithInitialState = initialState => storeEnhancer(createStore)(reducers, initialState);

export default createStoreWithInitialState();
