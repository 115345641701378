import { Component } from 'react';
import { logout } from './auth-redirects';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';

class Logout extends Component {
  componentDidMount() {
    window.analytics.page("Signed Out"); 
    window.analytics.identify();
    window.analytics.track("Signed Out", {
    });
    window.gtag('event', 'Signed Out', {
    });
    window.analytics.user().anonymousId('');
    window.analytics.user().id('');
    // add email here
    logout();
  }

  render() {
    return null;
  }
}

export default compose(withRouter)(Logout);
