import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { deleteFitter, inviteFitter, updateFitter } from '../../user/user-actions';
import Tippy from '@tippyjs/react';
import DeleteButton from '../../common/components/DeleteButton';
import LocalizedStrings from 'react-localization';
import Button from '../../common/components/Button';
import { toast } from 'react-toastify';
import moment from 'moment';
import RefreshIcon from '../../common/components/icons/Refresh';
import AccountTooltipForm from './AccountTooltipForm';
import NotesTooltipForm from './NotesTooltipForm';
import truncateString from '../../common/utils/truncateString';
import convertNewLinesToBrTags from '../../common/utils/convertNewLinesToBrTags';

let strings = new LocalizedStrings({
  en: {
    invitationSent: 'Invitation Sent',
    verified: 'Verified',
    deleteUserQuestion: 'Delete User?',
    email: 'Email',
    invitationDate: 'Invitation Date',
    verifiedDate: 'Verified Date',
    account: 'Account',
    status: 'Status',
    notes: 'Notes',
  },
});

class FitterList extends Component {
  static propTypes = {
    fitters: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    const { inviteFitter } = this.props;
    if (!prevProps.inviteFitter.get('loaded') && inviteFitter.get('loaded')) {
      toast(strings.invitationSent, { type: 'success' });
    }
  }

  deleteFitter(fitter) {
    const { dispatch } = this.props;
    dispatch(deleteFitter(fitter.get('id')));
  }

  hasAcceptedInvite(fitter) {
    return fitter.getIn(['oidcUser', 'profile', 'email_verified']);
  }

  renderFitterStatus(fitter) {
    const verified = fitter.getIn(['oidcUser', 'profile', 'email_verified']);

    if (!verified) {
      return <div className="fitter-entry--status unverified">{strings.invitationSent}</div>;
    }

    return <div className="fitter-entry--status verified">{strings.verified}</div>;
  }

  render() {
    const { fitters, dispatch } = this.props;

    if (!fitters) {
      return null;
    }

    return (
      <table className="fitter-list">
        <thead>
          <tr>
            <th>{strings.email}</th>
            <th>{strings.invitationDate}</th>
            <th>{strings.verifiedDate}</th>
            <th>{strings.account}</th>
            <th>{strings.status}</th>
            <th>{strings.notes}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fitters.results?.map(fitter => (
            <tr key={fitter.get('id')}>
              <td title={fitter.getIn(['oidcUser', 'email'])}>{fitter.getIn(['oidcUser', 'email'])}</td>
              <td>{fitter.get('invited_at') ? moment(fitter.get('invited_at')).format('MM/DD/YYYY') : null}</td>
              <td>{fitter.get('verified_at') ? moment(fitter.get('verified_at')).format('MM/DD/YYYY') : null}</td>
              <td>
                <div className="tooltip-form-table__wrapper">
                  {fitter.get('account') ? (
                    <span className="truncated-string">{truncateString(fitter.get('account'), 20)}</span>
                  ) : (
                    <span className="add-button">Add Account</span>
                  )}
                  <AccountTooltipForm
                    initialValue={fitter.get('account')}
                    onSubmit={values => {
                      dispatch(updateFitter(fitter.get('id'), values));
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="fitter-status__wrapper">{this.renderFitterStatus(fitter)}</div>
              </td>
              <td>
                <div className="tooltip-form-table__wrapper">
                  {fitter.get('notes') ? (
                    <Tippy
                      content={convertNewLinesToBrTags(fitter.get('notes'))}
                      placement="bottom"
                      className="note-preview"
                    >
                      <span className="truncated-string">
                        {convertNewLinesToBrTags(truncateString(fitter.get('notes'), 50))}
                      </span>
                    </Tippy>
                  ) : (
                    <span className="add-button">Add Note</span>
                  )}
                  <NotesTooltipForm
                    initialValue={fitter.get('notes')}
                    onSubmit={values => {
                      dispatch(updateFitter(fitter.get('id'), values));
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="individual-user-action-buttons__wrapper">
                  {!this.hasAcceptedInvite(fitter) && (
                    <Button
                      className="admin-user-button__icon-wrapper refresh"
                      onClick={() => dispatch(inviteFitter(fitter.getIn(['oidcUser', 'email'])))}
                      icon
                    >
                      <RefreshIcon size={20} fill="#A1A9AE" />
                    </Button>
                  )}
                  <DeleteButton
                    confirmationText={strings.deleteUserQuestion}
                    onConfirmDelete={this.deleteFitter.bind(this, fitter)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default compose(
  connect(state => ({
    inviteFitter: state.user.inviteFitter,
  }))
)(FitterList);
