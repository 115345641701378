import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import LocalizedStrings from 'react-localization';
import { updateUserProfile, getMyFittingCodesList, fetchMyResultsList, getSubscriptionsList } from '../user/user-actions';
import compose from 'lodash/flowRight';
import moment from 'moment';
import { PurchaseFormContext } from '../context/PurchaseFormContext';
import localstorage from 'store2';
import { Helmet } from 'react-helmet';
import BillingHistory from './BillingHistory';
import { Link } from 'react-router-dom';
import { trackRedeemNow } from '../util/analytics';
import LoadingIndicator from '../common/components/LoadingIndicator';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    myProfile: 'My Profile',
    profile: 'Profile',
    fittingCodes: 'Fitting Codes',
    fittingResults: 'Fitting Results',
    subscriptions: 'Subscriptions',
    billingHistory: 'Billing History',
    edit: 'Edit',
    viewAll: 'View All',
    redeemed: 'REDEEMED',
    redeemNow: 'REDEEM NOW',
    viewResults: 'View Results',
    subscribed: 'Subscribed',
    noFittingCodes: 'Get ',
    noFittingResults: 'No fitting results available',
    noSubscriptions: 'No Subscriptions available',
    here: 'here',
  },
});

function MyAccount(props) {
  const { loggedInUser, myFittingCodesList, history, fittingResults, mySubscriptionsList, transactions, dispatch } = props;
  const { handlePurchaseForm } = useContext(PurchaseFormContext);

  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [loadingFittingCodes, setLoadingFittingCodes] = useState(true);
  const [loadingResults, setLoadingResults] = useState(true);

  useEffect(() => {
    dispatch(getMyFittingCodesList({ email: loggedInUser.getIn(['oidcData', 'email']) }))
      .then(() => setLoadingFittingCodes(false))
      .catch(() => setLoadingFittingCodes(false));
    dispatch(getSubscriptionsList({ email: loggedInUser.getIn(['oidcData', 'email']) }))
      .then(() => setLoadingSubscriptions(false))
      .catch(() => setLoadingSubscriptions(false));
    dispatch(fetchMyResultsList())
      .then(() => setLoadingResults(false))
      .catch(() => setLoadingResults(false));
  }, [dispatch, loggedInUser]);

  function renderProfile() {
    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.profile}</h2>
          <Link className="my-account-edit-button" to="/profile">{strings.edit}</Link>
        </div>
        <div className="my-account-profile-info">
          <div>{`${loggedInUser.get('first_name')} ${loggedInUser.get('last_name')}`}</div>
          <div>{loggedInUser.getIn(['oidcData', 'email'])}</div>
          <div>{`Current ball: ${loggedInUser.get('current_ball_brand') || ''} ${loggedInUser.get('current_ball_model') || '-'} ${loggedInUser.get('current_ball_year') || ''}`}</div>
          <div>{`Handicap: ${loggedInUser.get('handicap') || '-'}`}</div>
        </div>
      </div>
    );
  }

  function renderFittingCodes() {
    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.fittingCodes}</h2>
          {myFittingCodesList.size > 0 && <Link to="/profile/my-fitting-codes" className="my-account-view-all-button">
            {strings.viewAll}
          </Link>}
        </div>
        <div className="my-account-codes-list">
          {loadingFittingCodes ? (
            <LoadingIndicator />
          ) : myFittingCodesList.size === 0 ? <div>{strings.noFittingCodes}<Link onClick={() => {
            localStorage.setItem('selectedFitting', 'premium');
            handlePurchaseForm();
          }} style={{ textDecoration: 'underline' }}>{strings.here}</Link></div> : myFittingCodesList
              .sort((a, b) => {
                if (a.get('status') === 'active' && b.get('status') !== 'active') return -1;
                if (a.get('status') !== 'active' && b.get('status') === 'active') return 1;
                return 0;
              })
              .slice(0, 5)
              .map(code => (
            <div key={code.get('code')} className="my-account-code-item">
              <span>{code.get('code').toUpperCase()}</span>
              <span className={`status ${code.get('status') === 'active' ? 'redeem-now' : 'redeemed'}`}>
                {code.get('status') === 'active' ? (
                  <a
                    href={'/redeem'}
                    rel="noopener noreferrer"
                    onClick={() => {
                      localStorage.setItem('action', 'redeemVoucher');
                      localStorage.setItem('voucher', code.get('code').toUpperCase());
                      trackRedeemNow(code.get('code').toUpperCase());
                    }}
                  >
                    {strings.redeemNow}
                  </a>
                ) : (
                  strings.redeemed
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderFittingResults() {
    const promoCode = JSON.parse(localstorage.get('promoCode'));

    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.fittingResults}</h2>
          {fittingResults.size > 0 && <button 
            onClick={() => history.push("/profile/my-results")} 
            className="my-account-view-all-button my-account-link-button"
          >
            {strings.viewAll}
          </button>}
        </div>
        <div className="my-account-results-list">
          {loadingResults ? (
            <LoadingIndicator />
          ) : fittingResults.size === 0 ? (
            <div>{strings.noFittingResults}</div>
          ) : (
            fittingResults.slice(0, 5).map(result => (
              <div key={result.created_at} className="my-account-result-item">
                <span>{moment(result.get('created_at')).format('MM/DD/YYYY @ hh:mma')}</span>
                <button 
                onClick={() => window.open(promoCode?.code ? `${result.get('url')}?p=${promoCode?.code}` : result.get('url'), '_blank')} 
                className="view-results-button">{strings.viewResults}</button>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  function renderSubscriptions() {
    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.subscriptions}</h2>
        </div>
        <div className="my-account-results-list">
          {loadingSubscriptions ? (
            <LoadingIndicator />
          ) : mySubscriptionsList.size === 0 ? (
            <div>{strings.noSubscriptions}</div>
          ) : (
            mySubscriptionsList.map(result => (
              <div key={result.name} className="my-account-result-item">
                <span>{(result.get('name'))}</span>
                <span>{(result.get('status'))}</span>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  function renderBillingHistory() {
    return (
      <div className="my-account-section">
        <div className="my-account-section-header">
          <h2>{strings.billingHistory}</h2>
          {transactions?.get('total') > 0 && <button 
            onClick={() => history.push("/profile#billing")} 
            className="my-account-view-all-button my-account-link-button"
          >
            {strings.viewAll}
          </button>
          }
        </div>
        <BillingHistory/>
      </div>
    );
  }

  return (
    <div className="my-account-page">
      <Helmet>
        <title>{`${strings.myProfile} - ${strings.title}`}</title>
      </Helmet>
      <h1>My Account</h1>
      <div className="my-account-sections-grid">
        {renderProfile()}
        {renderFittingCodes()}
        {renderFittingResults()}
        {renderBillingHistory()}
        {renderSubscriptions()}
      </div>
    </div>
  );
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
    userProfileUpdated: state.user.updateUserProfile.get('loaded'),
    myFittingCodesList: state.user.myFittingCodesList,
    transactions: state.payment.billingHistory,
    fittingResults: state.user.myResultsList,
    mySubscriptionsList: state.user.mySubscriptionsList,
  })),
  withFormik({
    mapPropsToValues: props => ({
      first_name: props.loggedInUser.get('first_name') || '',
      last_name: props.loggedInUser.get('last_name') || '',
    }),
    handleSubmit(values, { props }) {
      return props.dispatch(updateUserProfile(values));
    },
  })
)(MyAccount);
