import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserSettings } from '../../../../user/user-actions';
import { settingsModalStrings } from '../../../../fitting/SettingsModal';

const IronToggle = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.auth.loggedInUser);

  return (
    <div>
      <div className="button--toggle__wrapper">
        <button
          className={`button button-toggle ${!loggedInUser?.get('six_iron_fitting') && 'button--color--primary'}`}
          onClick={() => {
            dispatch(updateUserSettings({ six_iron_fitting: !loggedInUser.get('six_iron_fitting') }));
          }}
        >
          {settingsModalStrings?.sevenIronFitting}
        </button>
        <button
          className={`button button-toggle ${loggedInUser?.get('six_iron_fitting') && 'button--color--primary'}`}
          onClick={() => {
            dispatch(updateUserSettings({ six_iron_fitting: !loggedInUser.get('six_iron_fitting') }));
          }}
        >
          {settingsModalStrings?.sixIronFitting}
        </button>
      </div>
      <div style={{ marginTop: '10px' }}>
        <small className="iron-toggling-helper">
          Note that the results page will still reflect 7-iron carry/roll numbers
        </small>
      </div>
    </div>
  );
};

export default IronToggle;
