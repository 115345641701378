import React, { useCallback, useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import CoBrandPreview from './CoBrandPreview';
import { resetUploadCoBrandLogo, uploadCoBrandLogo } from '../admin-actions';
import Button from '../../common/components/Button';
import FieldWithLabelAndValidation from '../../common/forms/FieldWithLabelAndValidation';
import ValidationError from '../../common/forms/ValidationError';
import FileUpload from '../../common/forms/FileUpload';
import Arroba from '../../common/components/icons/Arroba';
import TooltipIcon from '../../common/components/TooltipIcon';
import ConfirmationModal from '../../common/components/ConfirmationModal';

const strings = new LocalizedStrings({
  en: {
    brandName: 'Brand Name',
    cancel: 'Cancel',
    discardChangesModalTitle: 'Unsaved Changes',
    discardChangesModalConfirm: 'Discard Changes',
    discardChangesModalCancel: 'Keep Editing',
    discardChangesModalContent: 'Leaving this page will discard unsaved changes. Are you sure you want to leave?',
    domain: 'Co-Brand Domain',
    emailDomain: 'Email Domain',
    emailDomainTooltip:
      'The email domain is the part that comes after the @ symbol. As an example, the email domain for johndow@gmail.com is gmail.com.',
    fileTypeError: 'Unsupported file type. Please upload a {0} file.',
    svgSrc: 'Co-brand Logo SVG',
    pngSrc: 'Co-brand Logo PNG',
    logoSvgUpload: 'Logo SVG Upload',
    logoPngUpload: 'Logo PNG Upload',
    logoUploadMessage: 'Drop your logo file here, or click to browse',
    logoUploadMessageActive: 'Drop your logo file here',
    name: 'Co-Brand Name',
    noCoBrands: "You haven't added any Co-Brands yet.",
    saveCoBrand: 'Save Co-Brand',
  },
});

Yup.addMethod(Yup.string, 'domain', function pattern(name, message) {
  const pattern = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/;

  return this.test({
    message,
    test: value => value === null || value === '' || value === undefined || pattern.test(value),
  });
});

const validationSchema = Yup.object().shape({
  domains: Yup.array()
    .of(
      Yup.object().shape({
        domain: Yup.string()
          .domain()
          .label(strings.domain)
          .required(),
        id: Yup.string().optional(),
      })
    )
    .min(1)
    .label(strings.domain),
  svgSrc: Yup.string()
    .url()
    .required()
    .label(strings.svgSrc),
  pngSrc: Yup.string()
    .url()
    .required()
    .label(strings.pngSrc),
  name: Yup.string()
    .required()
    .label(strings.name),
});

const CoBrandForm = ({ svgSrc, pngSrc, initialValues, onSubmit, title }) => {
  const [discardChangesModalIsOpen, setDiscardChangesModalIsOpen] = useState(false);
  const [svgPreview, setSvgPreview] = useState(svgSrc);
  const [pngPreview, setPngPreview] = useState(pngSrc);
  const dispatch = useDispatch();
  const history = useHistory();

  const onDrop = useCallback(
    (fieldName, mimeType, extension, setter) => (files, { setFieldError, setFieldTouched, setFieldValue }) => {
      if (files.length) {
        const [file] = files;

        if (file.type === mimeType) {
          dispatch(uploadCoBrandLogo(file, extension)).then(({ json }) => {
            setFieldTouched(fieldName);
            setFieldValue(fieldName, json.url);

            setter(json.url);
          });
        } else {
          setFieldTouched(fieldName);
          setFieldError(fieldName, strings.formatString(strings.fileTypeError, 'SVG'));
        }
      }
    },
    [dispatch]
  );

  useEffect(() => () => dispatch(resetUploadCoBrandLogo()), [dispatch]);

  return (
    <div className="container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ dirty, isSubmitting, status }) => (
          <Form className="admin-add-co-brand-form">
            <div className="admin-add-co-brand-form__header">
              <h1>{title}</h1>
            </div>
            <div className="admin-add-co-brand-form__wrapper">
              <div className="admin-add-co-brand-form__wrapper__column">
                <FileUpload
                  fieldProps={{ name: 'svgSrc' }}
                  label={strings.logoSvgUpload}
                  onDrop={onDrop('svgSrc', 'image/svg+xml', 'svg', setSvgPreview)}
                  accept={{
                    'image/svg': ['.svg'],
                  }}
                  message={strings.logoUploadMessage}
                  messageActive={strings.logoUploadMessageActive}
                />
                <FileUpload
                  fieldProps={{ name: 'pngSrc' }}
                  label={strings.logoPngUpload}
                  onDrop={onDrop('pngSrc', 'image/png', 'png', setPngPreview)}
                  accept={{
                    'image/png': ['.png'],
                  }}
                  message={strings.logoUploadMessage}
                  messageActive={strings.logoUploadMessageActive}
                />
                <FieldWithLabelAndValidation
                  fieldProps={{ name: 'name', type: 'text', required: true }}
                  label={strings.brandName}
                />
                <FieldWithLabelAndValidation
                  type="text"
                  fieldProps={{ name: 'domains[0].domain', type: 'text', required: true }}
                  label={strings.emailDomain}
                  icon={<Arroba size={30} />}
                  tooltip={<TooltipIcon iconSize={16}>{strings.emailDomainTooltip}</TooltipIcon>}
                />
              </div>
              <div className="admin-add-co-brand-form__wrapper__column">
                <CoBrandPreview svgSrc={svgPreview} pngSrc={pngPreview} />
              </div>
            </div>
            {status && <ValidationError>{status}</ValidationError>}
            <div className="admin-add-co-brand-form__footer">
              <Button
                className="button button--color--transparent"
                textTransform="none"
                buttonStyle="primary"
                type="button"
                disabled={isSubmitting}
                onClick={() => {
                  if (dirty) {
                    setDiscardChangesModalIsOpen(true);
                  } else {
                    history.push('/admin/co-branding');
                  }
                }}
              >
                {strings.cancel}
              </Button>
              <Button
                className="button button--color--primary"
                textTransform="none"
                buttonStyle="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {strings.saveCoBrand}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        isOpened={discardChangesModalIsOpen}
        buttonText={strings.discardChangesModalCancel}
        cancelText={strings.discardChangesModalConfirm}
        onClose={() => history.push('/admin/co-branding')}
        onConfirm={() => setDiscardChangesModalIsOpen(false)}
        content={strings.discardChangesModalContent}
        titleText={strings.discardChangesModalTitle}
      />
    </div>
  );
};

export default CoBrandForm;
