import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import GolfAlert from './icons/GolfAlert';
import Logo from './logo/Logo';

const strings = new LocalizedStrings({
  en: {
    somethingWentWrong: "It seems like we've run into an error.",
    genericErrorBody:
      'Selecting the Reload Page button below is likely to resolve the issue. Please contact {0} if the error is not resolved. Thanks for using Ballnamic to find the perfect ball for your game.',
    reloadPage: 'Reload Page',
  },
});

class SomethingWentWrong extends Component {
  render() {
    return (
      <div className="something-went-wrong--page">
        <div className="header">
          <Logo />
        </div>
        <div className="content">
          <GolfAlert fill="#5057FF" />
          <h1>{strings.somethingWentWrong}</h1>
          <p>
            {strings.formatString(
              strings.genericErrorBody,
              <a href="mailto:support@ballfitting.com">support@ballfitting.com</a>
            )}
          </p>
          <button className="button button--color--primary" onClick={() => document.location.reload()} type="button">
            {strings.reloadPage}
          </button>
        </div>
      </div>
    );
  }
}

export default SomethingWentWrong;
