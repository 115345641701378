import React from 'react';
import Svg, { Path } from 'svgs';

const Trophy = ({ size, fill, ...props }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
      {...props}
    >
      <Path
        d="M13 15.9C15.2822 15.4367 17 13.419 17 11V4H7V11C7 13.419 8.71776 15.4367 11 15.9V18H9V20H15V18H13V15.9ZM9 6H15V11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11V6Z"
        fillRule="evenodd"
      />
      <Path d="M18 6H20V11H18V6zM6 6H4V11H6V6z" />
    </Svg>
  );
};

export default Trophy;
