import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ValidationError from './ValidationError';
import { Field, ErrorMessage } from 'formik';

class RegisterFieldWithLabelAndValidation extends Component {
  static propTypes = {
    icon: PropTypes.node,
    label: PropTypes.string,
    fieldProps: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      required: PropTypes.bool,
    }),
  };

  render() {
    const { icon, label, fieldProps, tooltip } = this.props;

    const id = `${fieldProps.name}-id`;
    const fieldPropsWithId = Object.assign({ id }, fieldProps);

    return (
      <div className="input-scaffold">
        {label ? (
          <label className="input-label" htmlFor={id}>
            {label}
            {fieldProps.required && <span className="input-label--required">*</span>}
            {tooltip && tooltip}
          </label>
        ) : null}
        <div className="register-input-wrapper">
          {icon && <div className="input-wrapper__icon">{icon}</div>}
          <Field {...fieldPropsWithId}>{this.props.children}</Field>
        </div>
        <ErrorMessage name={fieldProps.name} component={ValidationError} />
      </div>
    );
  }
}

export default RegisterFieldWithLabelAndValidation;
