import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { connect as formikConnect } from 'formik';
import compose from 'lodash/flowRight';
import Slider from '../../common/forms/Slider';
import TooltipIcon from '../../common/components/TooltipIcon';
import numeral from 'numeral';
import { lookUpTemperatureAndAltitude } from './questionnaire-actions';
import ValidationError from '../../common/forms/ValidationError';

import {
  TEMPERATURE_MIN,
  TEMPERATURE_MAX,
  TEMPERATURE_STEP,
  ALTITUDE_MIN,
  ALTITUDE_MAX,
  ALTITUDE_STEP,
} from '../fitting-constants';

export const strings = new LocalizedStrings({
  en: {
    typicalPlayingTemp: 'Typical Playing Temperature',
    typicalAltitude: 'Typical Altitude',
    temperatureTooltip: `Temperature influences air density, which affects how your ball flies. If we know the climate where you play most of your golf, we can attempt to pair you with balls that may work better for those specific conditions.`,
    altitudeTooltip: `The higher the altitude, the thinner the air, which means less lift for the flight of your ball. Considering both temperature and altitude, our algorithms are designed to estimate which balls may work better in different altitude conditions.`,
    zipcodeLabel: 'ZIP Code',
    zipcodePlaceholder: 'Enter ZIP code',
    zipcodeDescription:
      'ZIP code is used to calculate your typical playing temperature and altitude in order to provide the most accurate fitting.',
    zipcodeButtonText: 'Calculate',
    zipErrorLabel: 'ZIP code error',
    zipError: 'Could not find conditions for that ZIP code',
    couldNotCompleteYourRequest: 'Could not complete your request at this time. Please try again.',
    selectedTemperature: 'Selected temperature',
    selectedAltitude: 'Selected altitude',
    loading: 'Calculating...',
  },
});

class AltitudeAndTemperature extends Component {
  state = {
    zipcode: this.props.loggedInUser?.get('playing_zip') || '',
  };
  
  componentDidMount() {
    // If we have a playing zip, look up the conditions on mount
    const playingZip = this.props.loggedInUser?.get('playing_zip');
    if (playingZip) {
      this.props.dispatch(lookUpTemperatureAndAltitude(playingZip));
    }
  }

  // callback to pass to YourProfile parent for zipcode tracking
  onCalculateClicked = () => {
    const { zipcode } = this.state;
    this.props.onZipcodeCalculated(zipcode);
    this.props.dispatch(lookUpTemperatureAndAltitude(zipcode));
  }

  componentDidUpdate(prevProps) {
    const { calculatedTemperatureAndAltitude } = this.props;
    const { setFieldValue } = this.props.formik;

    if (
      calculatedTemperatureAndAltitude &&
      calculatedTemperatureAndAltitude !== prevProps.calculatedTemperatureAndAltitude
    ) {
      setFieldValue('typical_alt', calculatedTemperatureAndAltitude.get('typical_alt'));
      setFieldValue('typical_temp', calculatedTemperatureAndAltitude.get('typical_temp'));
    }
  }

  renderError() {
    const { lookupError } = this.props;

    if (!lookupError) {
      return null;
    }

    const message = lookupError.get('statusCode') >= 500 ? strings.couldNotCompleteYourRequest : strings.zipError;

    return <ValidationError aria-label={strings.zipErrorLabel}>{message}</ValidationError>;
  }

  render() {
    const { formik, lookingUpConditions } = this.props;
    const { values, setFieldValue } = formik;

    return (
      <div>
        <div className="zip-code--field">
          <div className="input-scaffold">
            <label className="input-label" htmlFor={'alt-and-temp-lookup'}>
              {strings.zipcodeLabel}
            </label>
            <div className="helper-text">{strings.zipcodeDescription}</div>
            <div className="input-inner-flex">
              <input
                type="text"
                aria-label={strings.zipcodeLabel}
                id="alt-and-temp-lookup"
                onChange={event => {
                  this.setState({ zipcode: event.target.value });
                }}
                value={this.state.zipcode}
                placeholder={strings.zipcodePlaceholder}
              />
              <button
                aria-label={strings.zipcodeButtonText}
                type="button"
                className="button button--color--primary"
                disabled={!this.state.zipcode || !!lookingUpConditions}
               onClick={this.onCalculateClicked}
              >
                {lookingUpConditions ? (
                  <span aria-label={strings.loading}>{strings.loading}</span>
                ) : (
                  strings.zipcodeButtonText
                )}
              </button>
            </div>
          </div>
          {this.renderError()}
        </div>
        <Slider
          label={strings.typicalPlayingTemp}
          tooltip={<TooltipIcon>{strings.temperatureTooltip}</TooltipIcon>}
          displayValue={<span aria-label={strings.selectedTemperature}>{values.typical_temp}˚F</span>}
          sliderProps={{
            value: values.typical_temp,
            min: TEMPERATURE_MIN,
            max: TEMPERATURE_MAX,
            step: TEMPERATURE_STEP,
            marks: {
              [TEMPERATURE_MIN]: <div className="slider--mark">{TEMPERATURE_MIN}˚F</div>,
              [TEMPERATURE_MAX]: <div className="slider--mark">{TEMPERATURE_MAX}˚F</div>,
            },
            onChange: value => setFieldValue('typical_temp', value),
          }}
          displayValueString={`${values.typical_temp} Degrees Fahrenheit`}
        />
        <Slider
          label={strings.typicalAltitude}
          tooltip={<TooltipIcon>{strings.altitudeTooltip}</TooltipIcon>}
          displayValue={
            <span aria-label={strings.selectedAltitude}>{numeral(values.typical_alt).format('0,0')} ft</span>
          }
          sliderProps={{
            value: values.typical_alt,
            min: ALTITUDE_MIN,
            max: ALTITUDE_MAX,
            step: ALTITUDE_STEP,
            marks: {
              [ALTITUDE_MIN]: <div className="slider--mark">{ALTITUDE_MIN} ft</div>,
              [ALTITUDE_MAX]: <div className="slider--mark">{numeral(ALTITUDE_MAX).format('0,0')} ft</div>,
            },
            onChange: value => setFieldValue('typical_alt', value),
          }}
          displayValueString={numeral(values.typical_alt).format('0,0') + 'feet'}
        />
      </div>
    );
  }
}

export default compose(
  connect(state => {
    return {
      values: state.questionnaire.values,
      lookingUpConditions: state.questionnaire.lookupTemperatureAndAltitude.get('loading'),
      lookupError: state.questionnaire.lookupTemperatureAndAltitude.get('error'),
      calculatedTemperatureAndAltitude: state.questionnaire.calculatedTemperatureAndAltitude,
      loggedInUser: state.auth.loggedInUser,
    };
  }),
  formikConnect
)(AltitudeAndTemperature);
