export const FETCH_BRAINTREE_TOKEN = 'FETCH_BRAINTREE_TOKEN';
export const GET_PRICE = 'GET_PRICE';
export const FETCH_PRICES = 'FETCH_PRICES';
export const SUBMIT_RESULTS_PAYMENT = 'SUBMIT_RESULTS_PAYMENT';
export const VALIDATE_VOUCHER_CODE = 'VALIDATE_VOUCHER_CODE';
export const RESET_VALIDATE_VOUCHER_CODE = 'RESET_VALIDATE_VOUCHER_CODE';
export const GET_BILLING_HISTORY = 'GET_BILLING_HISTORY';
export const GET_SALES_TAX = 'GET_SALES_TAX';
export const RESET_SALES_TAX = 'RESET_SALES_TAX';
export const GET_PROMO_CODE = 'GET_PROMO_CODE';
export const RESET_SUBMIT_RESULTS_PAYMENT = 'RESET_SUBMIT_RESULTS_PAYMENT';
export const FETCH_PURCHASED_VOUCHERS_LIST = 'FETCH_PURCHASED_VOUCHERS_LIST';
