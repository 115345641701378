import React from 'react';
import Svg, { Path } from 'svgs';

const Trash = ({ size, fill, className }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size || 24}
      height={size || 24}
      className={className}
    >
      <Path
        d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
        fill={fill || '#2D3E4E'}
      />
    </Svg>
  );
};

export default Trash;
