import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';
import LocalizedStrings from 'react-localization';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import FieldWithLabelAndValidation from '../../common/forms/FieldWithLabelAndValidation';
import { setRecipients } from './questionnaire-actions';
import PrivacyPolicyField, { privacyPolicySchema } from '../../common/forms/PrivacyPolicyField';

let strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    getStarted: 'Get Started',
    enterYourInfo: `Enter your information before beginning the questionnaire`,
    customerDetails: 'Customer Details',
    namePlaceholder: 'Enter your name',
    emailPlaceholder: 'Enter your email',
    nameLabel: 'Name*',
    emailLabel: 'Email*',
    invalidEmail: 'Invalid email',
    readAndUnderstandPrivacyPolicy: 'I read and understand the Privacy Policy',
  },
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string()
    .email(strings.invalidEmail)
    .required(),
  agreedToTerms: privacyPolicySchema,
});

class GetStarted extends Component {
  componentDidMount() {
    window.analytics.page();

    const { alreadyStarted, loggedInUser, history } = this.props;

    if (alreadyStarted || loggedInUser.get('type') === 'player') {
      history.replace('/my-fitting/your-profile');
    }
  }

  render() {
    const { dispatch, history } = this.props;

    return (
      <div className="questionnaire__wrapper">
        <Helmet>
          <title>{`${strings.getStarted} - ${strings.title}`}</title>
        </Helmet>
        <div className="get-started--container">
          <div className="get-started--header-wrapper">
            <h1>{strings.getStarted}</h1>
            <h5>{strings.enterYourInfo}</h5>
          </div>
          <Formik
            initialValues={{
              name: '',
              email: '',
              agreedToTerms: false,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              dispatch(
                setRecipients([
                  {
                    name: values.name,
                    email: values.email,
                  },
                ])
              );
              history.push('/my-fitting/your-profile');
            }}
          >
            {({ values }) => (
              <Form className="get-started--form">
                <FieldWithLabelAndValidation
                  label={strings.nameLabel}
                  fieldProps={{
                    name: 'name',
                    type: 'text',
                    placeholder: strings.namePlaceholder,
                  }}
                />
                <FieldWithLabelAndValidation
                  label={strings.emailLabel}
                  fieldProps={{
                    name: 'email',
                    type: 'email',
                    placeholder: strings.emailPlaceholder,
                  }}
                />
                <PrivacyPolicyField />
                <div>
                  <button className="button button--color--primary" type="submit">
                    {strings.getStarted}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    alreadyStarted: !!state.questionnaire.recipients,
    loggedInUser: state.auth.loggedInUser,
  })),
  withRouter
)(GetStarted);
