import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getArticle, getArticlePreview } from '../lib/contentful/contentful-actions';
import LoadingIndicator from '../common/components/LoadingIndicator';
import MarkdownIt from 'markdown-it';
import ArrowLeftVariantIcon from '../common/components/icons/ArrowLeftVariant';
import compose from 'lodash/flowRight';

const md = new MarkdownIt();

class Article extends Component {
  componentDidMount() {
    window.analytics.page(this.props.match.params.slug);
    const { dispatch, isPreview } = this.props;

    if (isPreview) {
      dispatch(getArticlePreview(this.props.match.params.slug));
    } else {
      dispatch(getArticle(this.props.match.params.slug));
    }
  }

  render() {
    const { article, loaded, isPreview, location } = this.props;

    if (!loaded) {
      return <LoadingIndicator />;
    }

    return (
      <Fragment>
        <div className="article">
          {article.getIn(['fields', 'image']) ? (
            <div className="article__main-img">
              <img src={`https:${article.getIn(['fields', 'image', 'fields', 'file', 'url'])}`} alt="golf" />
            </div>
          ) : null}
          <div className="container library">
            {!isPreview && (
              <Link
                className="article__go-back"
                to={{
                  pathname: `/library`,
                  state: location.state,
                }}
              >
                <span>
                  <ArrowLeftVariantIcon />
                </span>
                <span>Go Back</span>
              </Link>
            )}
            <h1 className="article__title">{article.getIn(['fields', 'title'])}</h1>
            <div className="article__body">
              {
                <div
                  className="article__body--set-inner"
                  dangerouslySetInnerHTML={{ __html: md.render(article.getIn(['fields', 'body']) || '') }}
                />
              }
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default compose(
  connect(state => ({
    loaded: state.contentful.getArticle.get('loaded'),
    article: state.contentful.article,
  })),
  withRouter
)(Article);
