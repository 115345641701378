import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import LocalizedStrings from 'react-localization';
import PropTypes from 'prop-types';
import { ErrorMessage, useFormikContext } from 'formik';

import ValidationError from './ValidationError';
import Upload from '../components/icons/Upload';

const strings = new LocalizedStrings({
  en: {
    acceptedFiles: 'Accepted files: {0}',
    label: 'File Upload',
    message: 'Drop your file here, or click to browse',
    messageActive: 'Drop your file here',
  },
});

const FileUpload = ({ accept, fieldProps, label, message, messageActive, onDrop }) => {
  const formik = useFormikContext();

  const onFileSelect = useCallback(files => onDrop(files, formik), [formik, onDrop]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept, onDrop: onFileSelect });
  const id = `${fieldProps.name}-id`;

  const types = Object.values(accept).reduce(
    (acc, curr) => acc.concat(curr.map(val => val.slice(1).toUpperCase())),
    []
  );

  return (
    <div className="input-scaffold">
      {label ? (
        <label className="input-label" htmlFor={id}>
          {label}
          {fieldProps.required && <span className="input-label--required">*</span>}
        </label>
      ) : null}
      <div {...getRootProps()} className="file-upload">
        <input {...getInputProps()} />
        <Upload />
        {isDragActive ? <p>{messageActive}</p> : <p>{message}</p>}
        {Boolean(types.length) && <p>{strings.formatString(strings.acceptedFiles, types.join(', '))}</p>}
      </div>
      <ErrorMessage name={fieldProps.name} component={ValidationError} />
    </div>
  );
};

FileUpload.propTypes = {
  accept: PropTypes.object,
  label: PropTypes.string,
  fieldProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
  }),
  onDrop: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  accept: {},
  label: strings.label,
  message: strings.message,
  messageActive: strings.messageActive,
};

export default FileUpload;
