import React from 'react';
import Svg, { Path, G, Mask } from 'svgs';

const LoadingGolfHole = ({ width, height, ...rest }) => {
  return (
    <Svg width={width || 48} height={height || 17} viewBox="0 0 48 17" fill="none" {...rest}>
      <Mask
        id="mask0_2235_2700"
        style={{ maskType: 'alpha' }}
        width="48"
        height="17"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <Path
          fill="#2D3E4E"
          stroke="#2D3E4E"
          strokeWidth="4"
          d="M46 8.4c0 .326-.184.92-1.161 1.729-.964.798-2.48 1.606-4.53 2.323C36.23 13.88 30.465 14.8 24 14.8s-12.23-.92-16.31-2.348c-2.049-.717-3.565-1.525-4.529-2.323C2.184 9.32 2 8.726 2 8.4c0-.326.184-.92 1.161-1.729.964-.798 2.48-1.606 4.53-2.323C11.77 2.92 17.535 2 24 2s12.23.92 16.31 2.348c2.049.717 3.565 1.525 4.529 2.323C45.816 7.48 46 8.074 46 8.4z"
        />
      </Mask>
      <G mask="url(#mask0_2235_2700)">
        <Path
          fill="#2D3E4E"
          stroke="#fff"
          strokeWidth="3"
          d="M61.5 28.5c0 6.37-3.95 12.334-10.744 16.776C43.976 49.709 34.52 52.5 24 52.5S4.024 49.709-2.756 45.276C-9.55 40.834-13.5 34.87-13.5 28.5c0-6.37 3.95-12.334 10.744-16.776C4.024 7.291 13.48 4.5 24 4.5s19.976 2.791 26.756 7.224C57.55 16.166 61.5 22.13 61.5 28.5z"
        />
        <Path
          stroke="#2D3E4E"
          strokeWidth="4"
          d="M46 8.4c0 .326-.184.92-1.161 1.729-.964.798-2.48 1.606-4.53 2.323C36.23 13.88 30.465 14.8 24 14.8s-12.23-.92-16.31-2.348c-2.049-.717-3.565-1.525-4.529-2.323C2.184 9.32 2 8.726 2 8.4c0-.326.184-.92 1.161-1.729.964-.798 2.48-1.606 4.53-2.323C11.77 2.92 17.535 2 24 2s12.23.92 16.31 2.348c2.049.717 3.565 1.525 4.529 2.323C45.816 7.48 46 8.074 46 8.4z"
        />
      </G>
    </Svg>
  );
};

export default LoadingGolfHole;
