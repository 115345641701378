import React from 'react';
import Svg, { Path } from 'svgs';

const Menu = ({ width, height, fill }) => {
  return (
    <Svg
      height={height || 24}
      width={width || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M3 6h18v2H3V6m0 5h18v2H3v-2m0 5h18v2H3v-2z" />
    </Svg>
  );
};

export default Menu;
