import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { withRouter } from 'react-router-dom';
import { setQuestionnaireValues } from './questionnaire-actions';
import Slider from '../../common/forms/Slider';
import { connect as formikConnect } from 'formik';
import MultiButtonField from '../../common/forms/MultiButtonField';
import LocalizedStrings from 'react-localization';
import ArrowRight from '../../common/components/icons/ArrowRight';
import ArrowLeft from '../../common/components/icons/ArrowLeft';
import TooltipIcon from '../../common/components/TooltipIcon';
import SpinIcon from '../../common/components/icons/Spin';
import SpinHeightBalanceIcon from '../../common/components/icons/SpinHeightBalance';
import NoSpinIcon from '../../common/components/icons/NoSpin';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    wedgeDetails: 'Wedge Details',
    enterWedgeDetails: 'Enter your wedge details here',
    fullWedgeSpinPreference: 'Full Wedge Spin Preference',
    leastSpin: 'Least Spin',
    lowSpin: 'Low Spin',
    moderateSpin: 'Moderate Spin',
    highSpin: 'High Spin',
    maxSpin: 'Maximum Spin',
    greensideSpin: 'Greenside Spin Importance',
    fullWedgeSpinTooltip: `On full wedge shots, our testing has seen spin rate vary by as much as 20% between balls.`,
    fullWedgeSpinSubtext: `How a ball spins off a wedge can be very different to how it performs on a driver or irons. Whether you spin it too much or need more stopping power, this question can help us fine tune your wedge play.`,
    greensideSpinTooltip: `A golf ball can be low spin on a driver, irons, and full wedges – but high spin around the greens. Or, vice versa. If you hit more pitch and runs, a ball with more greenside spin may be less important to you.`,
    greensideSubtext: `How important is having high greenside spin? If you mainly play bump and run chip shots, high greenside spin is likely not important. Conversely, on firm and fast greens, high spin may be necessary to stop the ball quickly.`,
    greensideSpinMaximum: `High Importance`,
    greensideSpinModerate: `Moderate Importance`,
    greensideSpinMinimum: `Not Important`,
  },
});

const spinPreferenceLabelMap = {
  0: strings.leastSpin,
  1: strings.lowSpin,
  2: strings.moderateSpin,
  3: strings.highSpin,
  4: strings.maxSpin,
};
const greenSpinPreferenceMap = {
  0: strings.greensideSpinMinimum,
  1: strings.greensideSpinModerate,
  2: strings.greensideSpinMaximum,
};

class WedgeDetails extends Component {
  componentDidUpdate(prevProps) {
    const { setFieldValue } = this.props.formik;
    if (prevProps.loggedInUser.get('six_iron_fitting') !== this.props.loggedInUser.get('six_iron_fitting')) {
      setFieldValue('iron_distance', 145);
      setFieldValue('iron_spin_type', 'mid');
      setFieldValue('iron_launch_type', 'mid');
    }
  }
  render() {
    const {
      dispatch,
      history,
      formik: { values, setFieldValue },
    } = this.props;

    return (
      <div className="questionnaire__wrapper">
        <Helmet>
          <title>{`${strings.wedgeDetails} - ${strings.title}`}</title>
        </Helmet>
        <div className="questionnaire--inner">
          <div className="questionnaire--header">
            <h1>{strings.wedgeDetails}</h1>
            <h4>{strings.enterWedgeDetails}</h4>
          </div>
          <div className="questionnaire-blocks__container">
            <Slider
              label={strings.fullWedgeSpinPreference}
              tooltip={<TooltipIcon>{strings.fullWedgeSpinTooltip}</TooltipIcon>}
              subLabel={strings.fullWedgeSpinSubtext}
              displayValue={spinPreferenceLabelMap[values.wedge_spin_pref]}
              sliderProps={{
                min: 0,
                max: 4,
                step: 1,
                marks: {
                  0: <div>{spinPreferenceLabelMap[0]}</div>,
                  4: <div>{spinPreferenceLabelMap[4]}</div>,
                },
                dots: true,
                value: values.wedge_spin_pref,
                onChange: value => setFieldValue('wedge_spin_pref', value),
              }}
            />
            <MultiButtonField
              choices={[
                {
                  icon: <NoSpinIcon size={32} />,
                  label: strings.greensideSpinMinimum,
                  value: 0,
                },
                {
                  icon: <SpinHeightBalanceIcon width={84} height={28} />,
                  label: strings.greensideSpinModerate,
                  value: 1,
                },
                {
                  icon: <SpinIcon size={32} />,
                  label: strings.greensideSpinMaximum,
                  value: 2,
                },
              ]}
              value={values.greenside_spin_imp}
              onChange={setFieldValue.bind(this, 'greenside_spin_imp')}
              label={strings.greensideSpin}
              tooltip={<TooltipIcon>{strings.greensideSpinTooltip}</TooltipIcon>}
              subLabel={strings.greensideSubtext}
            />
          </div>
          <div className="questionnaire--button__wrapper">
            <button
              className="button button--color--primary button--theme--outline button--icon"
              type="button"
              onClick={() => {
                dispatch(setQuestionnaireValues(values));
                window.analytics.track("Player Profile Wedges", {
                  full_wedge_spin_preference: spinPreferenceLabelMap[values.wedge_spin_pref],
                  greenside_spin_importance: greenSpinPreferenceMap[values.greenside_spin_imp],
                  fitting_id: null
                });
                window.gtag('event', 'Player Profile Wedges', {
                  full_wedge_spin_preference: spinPreferenceLabelMap[values.wedge_spin_pref],
                  greenside_spin_importance: greenSpinPreferenceMap[values.greenside_spin_imp],
                  fitting_id: null
                });
                history.push('/my-fitting/irons');
              }}
            >
              <ArrowLeft />
              <div>Back</div>
            </button>
            <button
              className="button button--color--primary button--icon"
              type="button"
              onClick={() => {
                dispatch(setQuestionnaireValues(values));
                window.analytics.track("Player Profile Wedges", {
                  full_wedge_spin_preference: spinPreferenceLabelMap[values.wedge_spin_pref],
                  greenside_spin_importance: greenSpinPreferenceMap[values.greenside_spin_imp],
                  fitting_id: null
                });
                window.gtag('event', 'Player Profile Wedges', {
                  full_wedge_spin_preference: spinPreferenceLabelMap[values.wedge_spin_pref],
                  greenside_spin_importance: greenSpinPreferenceMap[values.greenside_spin_imp],
                  fitting_id: null
                });
                history.push('/my-fitting/finishing-touches');
              }}
            >
              <div>Next</div>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
  })),
  withRouter,
  formikConnect
)(WedgeDetails);
