import Dinero from 'dinero.js';

export default function getTotal(price, totalTax, promoCode) {
  if (promoCode && promoCode.get('valid')) {
    const discountAmount = Dinero({
      amount: promoCode.getIn(['order', 'total_discount_amount']),
      currency: 'USD',
    });
    return price.subtract(discountAmount).add(totalTax);
  }
  return price.add(totalTax);
}
