import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../common/components/Modal';
import LocalizedStrings from 'react-localization';
import compose from 'lodash/flowRight';
import Arroba from '../../../common/components/icons/Arroba';
import Trash from '../../../common/components/icons/Trash';
import FatCheck from '../../../common/components/icons/FatCheck';
import RecipientForm from './RecipientForm';
import { setRecipients } from '../questionnaire-actions';
import Immutable from 'immutable';
import FieldWithLabelAndValidation from '../../../common/forms/FieldWithLabelAndValidation';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';

const strings = new LocalizedStrings({
  en: {
    sendResults: 'Send Results',
    recipients: 'Main Recipient',
    otherRecipients: 'Additional recipients',
    deleteRecipient: 'Delete recipient?',
    delete: 'Delete',
    cancel: 'Cancel',
    fitterCommentsLabel: 'Enter any additional comments here:',
    fitterCommentsPlaceholder: 'Type comments here (240 chars. max)',
  },
});

class SendResultsModal extends Component {
  static propTypes = {
    isOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    sending: PropTypes.bool,
    success: PropTypes.bool,
    loggedInUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  state = {
    confirmingDeleteRecipient: null,
    addRecipientFormOpen: false,
  };

  renderSuccess() {
    const { onClose } = this.props;

    return (
      <div className="results-sent__wrapper">
        <div className="results-sent__flex">
          <FatCheck size={40} fill="#248227" />
          <h3 className="results-sent--title">Results Sent</h3>
        </div>
        <div className="modal--button__wrapper">
          <button
            className="button button--color--primary"
            type="button"
            onClick={() => {
              onClose();
            }}
          >
            Dismiss
          </button>
        </div>
      </div>
    );
  }

  renderRecipientForm() {
    const { dispatch, recipients } = this.props;

    return (
      <RecipientForm
        onSave={values => {
          dispatch(setRecipients(recipients.toJS().concat([values])));
        }}
        onFormShown={() => this.setState({ addRecipientFormOpen: true })}
        onFormHidden={() => this.setState({ addRecipientFormOpen: false })}
      />
    );
  }

  renderDeleteConfirmation(index) {
    const { dispatch, recipients } = this.props;
    return (
      <div className="confirmation-tooltip">
        <div className="confirmation-tooltip--header">{strings.deleteRecipient}</div>
        <div className="button--wrapper">
          <button className="faux-link gray" onClick={() => this.setState({ confirmingDeleteRecipient: null })}>
            Cancel
          </button>
          <button
            className="faux-link danger"
            onClick={() => {
              const newRecipients = recipients.delete(index).toJS();
              this.setState({ confirmingDeleteRecipient: null });
              dispatch(setRecipients(newRecipients));
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }

  renderRecipientList() {
    const { recipients } = this.props;
    return (
      <div className="recipient-list__wrapper">
        <h4 className="recipient-list--title">{strings.recipients}</h4>
        <div className="recipient-list">
          {recipients.map((recipient, index) => (
            <Fragment key={recipient.get('email')}>
              <div className="recipient">
                {index === 0 && (
                  <>
                    <span className="recipient--name">{recipient.get('name')}</span>
                    <Arroba className="arroba" size={20} fill="#2D3E4E" />
                  </>
                )}
                <span className="recipient--email">{recipient.get('email')}</span>
                {recipients.size > 1 && index !== 0 ? (
                  <div className="remove-recipient__wrapper">
                    <button
                      className="remove-recipient"
                      onClick={() => this.setState({ confirmingDeleteRecipient: index })}
                      aria-label="Remove Recipient"
                    >
                      <div className="remove-recipient__button-label">Remove</div>
                      <Trash size={20} fill="#E30033" />
                    </button>
                    {this.state.confirmingDeleteRecipient === index ? this.renderDeleteConfirmation(index) : null}
                  </div>
                ) : null}
              </div>
              {recipients.size > 1 && index === 0 && (
                <>
                  <hr className="recipient-list__hr" />
                  <h4 className="recipient-list--title additional">{strings.otherRecipients}</h4>
                </>
              )}
            </Fragment>
          ))}
        </div>
        {this.renderRecipientForm()}
      </div>
    );
  }

  renderSendForm() {
    const { onClose, loggedInUser, sending } = this.props;
    const isPlayer = loggedInUser.get('type') === 'player';
    return (
      <div>
        {isPlayer ? (
          <div className="single-recipient__wrapper">
            <h4 className="recipient-list--title">Recipient</h4>
            <div className="recipient">
              <span className="recipient--name">
                {loggedInUser.get('first_name')} {loggedInUser.get('last_name')}
              </span>
              <Arroba className="arroba" size={20} fill="#2D3E4E" />
              <span className="recipient--email">{loggedInUser.getIn(['oidcData', 'email'])}</span>
            </div>
          </div>
        ) : (
          this.renderRecipientList()
        )}

        <Form>
          <hr className="recipient-list__hr" />
          <div className="modal--fitter-comments">
            <FieldWithLabelAndValidation
              label={strings.fitterCommentsLabel}
              fieldProps={{
                name: 'fitter_comments',
                type: 'text',
                as: 'textarea',
                rows: 5,
                maxLength: 240,
                placeholder: strings.fitterCommentsPlaceholder,
                innerRef: ref => (this.inputRef = ref),
              }}
            />
          </div>

          <div className="modal--button__wrapper">
            <button
              className="button button--color--white"
              type="button"
              disabled={sending || this.state.addRecipientFormOpen}
              onClick={onClose}
            >
              {strings.cancel}
            </button>
            <button
              className="button button--color--primary"
              type="submit"
              disabled={sending || this.state.addRecipientFormOpen}
            >
              {strings.sendResults}
            </button>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    const { isOpened, onClose, success } = this.props;

    return (
      <Modal className="send-results--modal" isOpened={isOpened} onClose={onClose} title={strings.sendResults}>
        {success ? this.renderSuccess() : this.renderSendForm()}
      </Modal>
    );
  }
}

export default compose(
  connect(state => ({
    recipients: state.questionnaire.recipients,
  })),
  withFormik({
    mapPropsToValues: props => ({
      fitter_comments: '',
    }),
    validationSchema: Yup.object().shape({
      fitter_comments: Yup.string().max(240),
    }),
    handleSubmit: (values, { props }) => {
      props.onSubmit(values);
    },
  })
)(SendResultsModal);
