import React, { Component } from 'react';
import { calculateDriverHeightPreference, resetCalculatedDriverHeight } from './questionnaire-actions';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { connect as formikConnect } from 'formik';
import compose from 'lodash/flowRight';
import ValidationError from '../../common/forms/ValidationError';

export const strings = new LocalizedStrings({
  en: {
    recommendationText: 'Ballnamic Recommends',
    description: 'Find your recommended driver height preference:',
    recommendationButtonText: 'Calculate',
    recommendations: 'Recommendations',
    calculationErrorLabel: 'Trajectory calculation error',
    couldNotCompleteYourRequest: 'Could not complete your request at this time. Please try again.',
    loading: 'Calculating...',
    currentDriverHeight: 'Current Driver Height',
    recommendedDriverHeight: 'Recommended Driver Height',
  },
});

class TrajectoryRecommendation extends Component {
  componentDidUpdate(prevProps) {
    const { calculatedDriverHeight, dispatch } = this.props;
    const { values, setFieldValue } = this.props.formik;
    const {
      driver_ball_speed,
      driver_launch_angle,
      driver_spin_rate,
      driver_distance,
      driver_launch_type,
      driver_spin_type,
    } = values;

    if (calculatedDriverHeight && calculatedDriverHeight !== prevProps.calculatedDriverHeight) {
      setFieldValue('driver_height_pref_calc', calculatedDriverHeight.get('driver_height_pref'));
      setFieldValue('driver_height_pref', calculatedDriverHeight.get('driver_height_pref'));
    }

    if (
      calculatedDriverHeight &&
      (driver_spin_rate !== prevProps.formik.values.driver_spin_rate ||
        driver_launch_angle !== prevProps.formik.values.driver_launch_angle ||
        driver_ball_speed !== prevProps.formik.values.driver_ball_speed ||
        driver_distance !== prevProps.formik.values.driver_distance ||
        driver_launch_type !== prevProps.formik.values.driver_launch_type ||
        driver_spin_type !== prevProps.formik.values.driver_spin_type)
    ) {
      dispatch(resetCalculatedDriverHeight());
    }
  }

  getRecommendedTrajectory() {
    const { dispatch } = this.props;
    const {
      typical_alt,
      typical_temp,
      driver_ball_speed,
      driver_launch_angle,
      driver_spin_rate,
    } = this.props.formik.values;
    dispatch(
      calculateDriverHeightPreference({
        driver_spin_rate,
        driver_launch_angle,
        driver_ball_speed,
        typical_alt,
        typical_temp,
      })
    );
  }

  renderError() {
    const { error } = this.props;
    if (!error) {
      return null;
    }
    return (
      <ValidationError aria-label={strings.calculationErrorLabel}>
        {strings.couldNotCompleteYourRequest}
      </ValidationError>
    );
  }

  renderRecommendations() {
    const { gettingTrajectoryRecommendation, calculatedDriverHeight } = this.props;

    if (calculatedDriverHeight) {
      const actualHeight = calculatedDriverHeight.get('actual_height');
      const recHeight = calculatedDriverHeight.get('rec_height');
      const recommendation = calculatedDriverHeight.get('description');
      return (
        <div className="recommendations-wrapper" aria-label={strings.recommendations}>
          <h4>
            {strings.currentDriverHeight}: <span className="rec-value">{actualHeight}</span>
          </h4>
          <h4>
            {strings.recommendedDriverHeight}: <span className="rec-value">{recHeight}</span>
          </h4>
          <h4>
            {strings.recommendationText}: <span className="rec-value">{recommendation}</span>
          </h4>
        </div>
      );
    } else {
      return (
        <>
          <div className="helper-text">{strings.description}</div>
          <div>
            <button
              aria-label={strings.recommendationButtonText}
              type="button"
              className="button button--color--primary"
              disabled={!!gettingTrajectoryRecommendation}
              onClick={() => this.getRecommendedTrajectory()}
            >
              {gettingTrajectoryRecommendation ? (
                <span aria-label={strings.loading}>{strings.loading}</span>
              ) : (
                strings.recommendationButtonText
              )}
            </button>
          </div>
          {this.renderError()}
        </>
      );
    }
  }

  render() {
    return <div className="label-component">{this.renderRecommendations()}</div>;
  }
}

export default compose(
  connect(state => {
    return {
      values: state.questionnaire.values,
      gettingTrajectoryRecommendation: state.questionnaire.calculateDriverHeightPreference.get('loading'),
      error: state.questionnaire.calculateDriverHeightPreference.get('error'),
      calculatedDriverHeight: state.questionnaire.calculatedDriverHeight,
    };
  }),
  formikConnect
)(TrajectoryRecommendation);
