import React from 'react';
import Svg, { Path } from 'svgs';

const PutterFeel = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M9 2v11h2.2V8.6h2.2c1.2150264 0 2.2-.98497355 2.2-2.2V4.2c0-1.21502645-.9849736-2.2-2.2-2.2H9m2.2 2.2h2.2v2.2h-2.2V4.2zM18.4565994 18.3636364c.4358672 0 .8033133.1454545 1.1023385.4363636.2990251.2909091.4460036.630303.4409354 1.0181818L13.8951987 22l-5.32163423-1.4545455V14h1.48245523l5.5268972 1.9563636c.3953214.1503031.5929821.4218182.5929821.8145455 0 .2278788-.0861598.4266667-.2584794.5963636-.1723195.169697-.3902531.259394-.6538007.2690909h-2.1286537l-1.3304086-.4872727-.250877.6836364 1.5812856.5309091h5.3216342M4 14h3.05309756v8H4v-8z" />
    </Svg>
  );
};

export default PutterFeel;
