import React from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();

const FAQItem = ({ title, body }) => {
  return (
    <div className="faq-item">
      <h2>{title}</h2>
      <div className="faq-contentful__wrapper" dangerouslySetInnerHTML={{ __html: md.render(body || '') }} />
    </div>
  );
};

FAQItem.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default FAQItem;
