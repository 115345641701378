import LocalizedStrings from 'react-localization';

export const confidenceIntervalRanges = {
  carryDistance: '2.3',
  windDistance: '2.9',
  driverMaxHeight: '2.3',
  ironCarry: '1.4',
  ironRoll: '1.3',
  ironWorkabilityScore: '21',
  flierPrevention: '5.9',
  wedgeSpin: '1.7',
};

const strings = new LocalizedStrings({
  en: {
    carryDistanceRange: `±{0} yds`,
    carryDistance: 'Carry Distance',
    windDistanceRange: `±{0} yds`,
    windDistance: `Wind Distance`,
    driverMaxHeightRange: `±{0} ft`,
    driverMaxHeight: `Driver Max Height`,
    ironCarryRange: `±{0} yds`,
    ironCarry: `Iron Carry`,
    ironRollRange: `±{0} ft`,
    ironRoll: `Iron Roll`,
    ironWorkabilityScoreRange: `±{0} pts`,
    ironWorkabilityScore: `Iron Workability Score`,
    flierPreventionRange: `±{0}%`,
    flierPrevention: `Flier Prevention`,
    wedgeSpinRange: `±{0}%`,
    wedgeSpin: `Wedge Spin`,
  },
});

const confidenceIntervalStrings = {
  carryDistanceRange: strings.formatString(strings.carryDistanceRange, [confidenceIntervalRanges.carryDistance]),
  carryDistance: strings.carryDistance,
  windDistanceRange: strings.formatString(strings.windDistanceRange, [confidenceIntervalRanges.windDistance]),
  windDistance: strings.windDistance,
  driverMaxHeightRange: strings.formatString(strings.driverMaxHeightRange, [confidenceIntervalRanges.driverMaxHeight]),
  driverMaxHeight: strings.driverMaxHeight,
  ironCarryRange: strings.formatString(strings.ironCarryRange, [confidenceIntervalRanges.ironCarry]),
  ironCarry: strings.ironCarry,
  ironRollRange: strings.formatString(strings.ironRollRange, [confidenceIntervalRanges.ironRoll]),
  ironRoll: strings.ironRoll,
  ironWorkabilityScoreRange: strings.formatString(strings.ironWorkabilityScoreRange, [
    confidenceIntervalRanges.ironWorkabilityScore,
  ]),
  ironWorkabilityScore: strings.ironWorkabilityScore,
  flierPreventionRange: strings.formatString(strings.flierPreventionRange, [confidenceIntervalRanges.flierPrevention]),
  flierPrevention: strings.flierPrevention,
  wedgeSpinRange: strings.formatString(strings.wedgeSpinRange, [confidenceIntervalRanges.wedgeSpin]),
  wedgeSpin: strings.wedgeSpin,
};

export default confidenceIntervalStrings;
