import aws from 'aws-sdk';
import { v4 } from 'uuid';

import client from '../../client';

const uploadToS3 = (bucket, file, ext) => {
  const filename = `${v4()}.${ext}`;

  const params = {
    Key: filename,
    Body: file,
    ContentType: file.type,
    Bucket: bucket,
  };

  return client
    .get(`auth/s3-upload-token`)
    .then(response =>
      response.json().then(({ Region, IdentityId, IdentityPoolId, Token }) => {
        aws.config.correctClockSkew = true;
        aws.config.update({ region: Region });
        aws.config.apiVersions = {
          s3: '2006-03-01',
        };
        aws.config.credentials = new aws.CognitoIdentityCredentials({
          IdentityPoolId: IdentityPoolId,
          IdentityId: IdentityId,
          Logins: {
            'cognito-identity.amazonaws.com': Token,
          },
        });

        return new aws.S3();
      })
    )
    .then(s3Client => s3Client.upload(params).promise())
    .then(({ Location }) => ({ url: Location }));
};

export default uploadToS3;
