import { v4 } from 'uuid';
import { ContainerClient } from '@azure/storage-blob';

import client from '../../client';

const uploadToAzureBlob = (container, file, ext) => {
  const filename = `${v4()}.${ext}`;

  return client
    .get(`auth/upload-token/${container}`)
    .then(response => response.json())
    .then(({ url }) => {
      const containerClient = new ContainerClient(url);
      const blockBlobClient = containerClient.getBlockBlobClient(filename);

      return blockBlobClient
        .uploadBrowserData(file, { blobHTTPHeaders: { blobContentType: file.type } })
        .then(() => blockBlobClient.url.split('?')[0]);
    })
    .then(url => ({ url }));
};

export default uploadToAzureBlob;
