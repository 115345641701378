import localstorage from 'store2';
import uuid from 'uuid';
import config from '../../config';
import randomBytes from 'random-bytes-js';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';

function base64URLEncode(str) {
  return str
    .toString(CryptoJS.enc.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export function getLoginUrl() {
  const nonce = uuid();
  localstorage.set('nonce', nonce);

  const verifier = localstorage.has('code_verifier')
    ? localstorage.get('code_verifier')
    : base64URLEncode(randomBytes.rand(32));
  const challenge = base64URLEncode(sha256(verifier));

  // Only generate a new code_verifier if one doesn't already exist. This will increase the
  // likelyhood that the value passed along from forgot-password to reset-password is still
  // valid. The value will be cleared out after a successful login.
  if (!localstorage.has('code_verifier')) {
    localstorage.set('code_verifier', verifier);
  }

  return (
    `${config.auth.domain}/op/auth?client_id=${config.auth.clientId}` +
    `&response_type=${config.auth.responseType}&scope=${config.auth.scope}` +
    `&redirect_uri=${config.auth.redirectUri}&nonce=${nonce}&code_challenge=${challenge}&prompt=consent&code_challenge_method=S256`
  );
}

export function getEmailSettingsUrl() {
  const idToken = localstorage.get('idToken');
  return (
    `${config.auth.domain}/user/email-settings?client_id=${config.auth.clientId}` +
    `&redirect_uri=https://${config.appDomain}/profile&id_token_hint=${idToken}`
  );
}

export function getChangePasswordUrl() {
  const idToken = localstorage.get('idToken');
  return (
    `${config.auth.domain}/user/password?client_id=${config.auth.clientId}` +
    `&redirect_uri=https://${config.appDomain}/profile&id_token_hint=${idToken}`
  );
}
