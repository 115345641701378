import React from 'react';
import Svg, { Rect } from 'svgs';

const NoCoBrands = () => {
  return (
    <Svg width="434" height="227" viewBox="0 0 434 227" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Rect x="0.883186" y="40.2345" width="277.427" height="186.228" rx="4.21504" fill="white" />
      <Rect x="18.8929" y="58.2441" width="241.407" height="27.5894" rx="1.53274" fill="#DDE3EB" />
      <Rect x="18.8929" y="104.227" width="80.469" height="12.2619" rx="1.53274" fill="#DDE3EB" />
      <Rect x="18.8929" y="128.75" width="153.274" height="18.3929" rx="1.53274" fill="#DDE3EB" />
      <Rect x="18.8929" y="165.536" width="80.469" height="12.2619" rx="1.53274" fill="#DDE3EB" />
      <Rect x="18.8929" y="190.06" width="153.274" height="18.3929" rx="1.53274" fill="#DDE3EB" />
      <Rect
        x="0.883186"
        y="40.2345"
        width="277.427"
        height="186.228"
        rx="4.21504"
        stroke="#E6E7EC"
        strokeWidth="0.766372"
      />
      <Rect x="155.69" y="0.383186" width="277.427" height="186.228" rx="4.21504" fill="white" />
      <Rect x="173.7" y="18.3928" width="241.407" height="27.5894" rx="1.53274" fill="#DDE3EB" />
      <Rect x="173.7" y="64.3752" width="80.469" height="12.2619" rx="1.53274" fill="#DDE3EB" />
      <Rect x="173.7" y="88.8992" width="153.274" height="18.3929" rx="1.53274" fill="#DDE3EB" />
      <Rect x="173.7" y="125.685" width="80.469" height="12.2619" rx="1.53274" fill="#DDE3EB" />
      <Rect x="173.7" y="150.209" width="153.274" height="18.3929" rx="1.53274" fill="#DDE3EB" />
      <Rect
        x="155.69"
        y="0.383186"
        width="277.427"
        height="186.228"
        rx="4.21504"
        stroke="#E6E7EC"
        strokeWidth="0.766372"
      />
    </Svg>
  );
};

export default NoCoBrands;
