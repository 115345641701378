import React from 'react';
import Svg, { Path } from 'svgs';

const LoadingFlag = ({ width, height, ...rest }) => {
  return (
    <Svg width={width || 76} height={height || 196} viewBox="0 0 76 196" fill="none" {...rest}>
      <Path
        fill="#fff"
        stroke="#2D3E4E"
        strokeWidth="4"
        d="M11.067 4V2H5a3 3 0 00-3 3v185.067a3 3 0 003 3h3.067a3 3 0 003-3V4z"
      />
      <Path fill="#E30033" d="M9 69H4v39h5V69zM9 137H4v39h5v-39z" />
      <Path
        fill="#E30033"
        stroke="#2D3E4E"
        strokeWidth="4"
        d="M2 49.333v2h34.814l1.17 5.852a4 4 0 003.922 3.215H70a4 4 0 004-4V15.067a4 4 0 00-4-4H48.253l-1.17-5.851A4 4 0 0043.16 2H6a4 4 0 00-4 4v43.333z"
      />
    </Svg>
  );
};

export default LoadingFlag;
