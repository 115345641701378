import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '../../common/components/icons/CheckCircle';

class MultiButtonField extends Component {
  static propTypes = {
    choices: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.node.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }),
      ]).isRequired
    ).isRequired,
    icon: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    label: PropTypes.node,
    subLabel: PropTypes.string,
    tooltip: PropTypes.node,
    untabbable: PropTypes.bool,
  };

  render() {
    const { choices, onChange, value, label, subLabel, tooltip, untabbable } = this.props;

    return (
      <div className="multi-button-field__wrapper">
        <div className="multi-button__header">
          <div className="label-tooltip__wrapper">
            {label ? (
              <h3 id={label.replace(/\s+/g, '-')} className="mutli-button__title">
                {label}
              </h3>
            ) : null}
            {tooltip && tooltip}
          </div>
          {subLabel ? <div className="multi-button__sublabel">{subLabel}</div> : null}
        </div>
        <div className="multi-button__flex-container" aria-labelledby={label.replace(/\s+/g, '-')} role="radiogroup">
          {choices.map(choice => {
            const icon = choice.icon;
            const buttonLabel = typeof choice === 'string' ? choice : choice.label;
            const choiceValue = typeof choice === 'string' ? choice : choice.value;
            return (
              <button
                type="button"
                tabIndex={untabbable ? -1 : null}
                key={choiceValue}
                className={`multi-button ${choiceValue === value && 'active'}`}
                onClick={onChange.bind(this, choiceValue)}
                aria-label={`${label} ${buttonLabel}`}
                role="radio"
                aria-checked={choiceValue === value ? 'true' : 'false'}
              >
                <div className="check-circle">
                  <CheckCircleIcon />
                </div>
                {icon ? icon : null}
                <span className="multi-button__label">{buttonLabel}</span>
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

export default MultiButtonField;
