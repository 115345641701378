import React from 'react';
import Svg, { Path } from 'svgs';

const Clear = ({ width, height, fill }) => {
  return (
    <Svg
      height={height || 24}
      width={width || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </Svg>
  );
};

export default Clear;
