import { combineReducers } from 'redux';
import {
  GET_ARTICLES,
  GET_ARTICLE,
  GET_FAQS,
  GET_ACTIVE_ANNOUNCEMENT,
  DISMISS_ANNOUNCEMENT,
} from './contentful-action-constants';
import Immutable from 'immutable';
import getApiReducer from '../../common/utils/getApiReducer';
import localstorage from 'store2';

export default combineReducers({
  getArticles: getApiReducer(GET_ARTICLES),
  getArticle: getApiReducer(GET_ARTICLE),
  articles: (state = Immutable.Map(), action) => {
    if (action.type === `${GET_ARTICLES}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  article: (state = Immutable.Map(), action) => {
    if (action.type === `${GET_ARTICLE}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  getFAQs: getApiReducer(GET_FAQS),
  FAQs: (state = Immutable.Map(), action) => {
    if (action.type === `${GET_FAQS}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  activeAnnouncement: (state = null, action) => {
    if (action.type === `${GET_ACTIVE_ANNOUNCEMENT}_SUCCESS`) {
      return action.json.items[0] ? Immutable.fromJS(action.json.items[0].fields.activeAnnouncement) : null;
    }
    return state;
  },
  dismissedAnnouncements: (state = localstorage.get('dismissedAnnouncements') || [], action) => {
    if (action.type === DISMISS_ANNOUNCEMENT) {
      return action.dismissedAnnouncements;
    }
    return state;
  },
});
