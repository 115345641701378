import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ValidationError from '../../common/forms/ValidationError';
import { inviteFitter } from '../../user/user-actions';
import LocalizedStrings from 'react-localization';
import Button from '../../common/components/Button';
import classNames from 'classnames';

let strings = new LocalizedStrings({
  en: {
    invalidEmail: 'Invalid email',
    sendInvite: 'Send Invite',
  },
});

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(strings.invalidEmail)
    .required(),
});

class InviteFitterForm extends Component {
  render() {
    const { dispatch } = this.props;

    return (
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(values, { resetForm, setStatus }) => {
          resetForm();
          return dispatch(inviteFitter(values.email)).then(action => {
            if (!action.response.ok) {
              setStatus(action.json && action.json.message ? action.json.message : 'An error occurred');
            }
          });
        }}
      >
        {({ values, errors, isSubmitting, status }) => (
          <Form className="add-user-field--form__wrapper">
            <div className="add-user-field__container">
              <div className="add-user-field__container--flex">
                <div className={classNames('input-scaffold', { error: Object.keys(errors).length > 0 })}>
                  <div className="input-label">Add User</div>
                  <Field className="add-user--email" type="email" name="email" placeholder="Enter user email" />
                </div>
                <Button
                  className="button button--color--primary"
                  textTransform="none"
                  buttonStyle="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {strings.sendInvite}
                </Button>
              </div>
              <ErrorMessage name="email" component={ValidationError} />
              {status && <ValidationError>{status}</ValidationError>}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default compose(connect(state => ({})))(InviteFitterForm);
