import config from '../../config';
const contentful = require('contentful');

const client = contentful.createClient({
  space: config.contentful.space,
  accessToken: config.contentful.previewToken,
  environment: config.contentful.environment,
  host: 'preview.contentful.com',
});

export default client;
