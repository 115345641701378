import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import InviteFitterForm from './InviteFitterForm';
import FitterList from './FitterList';
import LocalizedStrings from 'react-localization';
import FitterCsvDownloadButton from './FitterCsvDownloadButton';
import { fetchFitters } from '../../user/user-actions';
import FittersFilter from './FittersFilter';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import querystring from 'query-string';
import FitterClearCacheButton from './FitterClearCacheButton';
import ControlledPager from '../../common/components/ControlledPager';

let strings = new LocalizedStrings({
  en: {
    fitters: 'Fitters',
  },
});

const DEFAULT_PAGE_SIZE = 20;

class ManageUsers extends Component {
  state = {
    page: 0,
    selectedAccountFilter: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchFitters(this.currentPage(), this.currentPageSize()));
  }

  componentDidUpdate(prevProps) {
    const { deleteFitter, inviteFitter, dispatch } = this.props;
    if (!prevProps.inviteFitter.get('loaded') && inviteFitter.get('loaded')) {
      dispatch(fetchFitters(this.currentPage(), this.currentPageSize()));
    }
    if (!prevProps.deleteFitter.get('loaded') && deleteFitter.get('loaded')) {
      dispatch(fetchFitters(this.currentPage(), this.currentPageSize()));
    }

    if (prevProps.location.search !== this.props.location.search) {
      dispatch(fetchFitters(this.currentPage(), this.currentPageSize()));
    }
  }

  currentPage() {
    const { location } = this.props;
    const searchQuery = querystring.parse(location.search);
    return searchQuery.page || 0;
  }

  currentPageSize() {
    const { location } = this.props;
    const searchQuery = querystring.parse(location.search);
    return searchQuery.page_size ? Number(searchQuery.page_size) : DEFAULT_PAGE_SIZE;
  }

  setPage(page) {
    this.setState({
      page,
    });
  }

  render() {
    const { dispatch, fitters, fittersLoading } = this.props;

    return (
      <div className="container">
        <div className="invite-user-form">
          <div className="invite-user-form__container">
            <h1>{strings.fitters}</h1>
            <div className="add-user--actions-bar__wrapper">
              <InviteFitterForm />
              <div className="actions">
                <FitterClearCacheButton />
                {fitters && (
                  <FittersFilter
                    fitters={fitters}
                    onFiltersApplied={selectedAccountFilter => {
                      this.setPage(0);
                      this.setState({
                        selectedAccountFilter,
                      });
                      dispatch(fetchFitters(0, this.currentPageSize(), selectedAccountFilter));
                    }}
                  />
                )}
                <FitterCsvDownloadButton selectedAccountFilter={this.state.selectedAccountFilter} />
              </div>
            </div>
          </div>
        </div>
        {fittersLoading && <LoadingIndicator />}
        <FitterList fitters={fitters} />

        {fitters && (
          <div className="pagination-div">
            <ControlledPager
              pageSize={this.currentPageSize()}
              totalItems={fitters.total}
              page={this.state.page}
              setPage={page => {
                this.setPage(page);
              }}
              onPageChange={page => {
                dispatch(fetchFitters(page, this.currentPageSize(), this.state.selectedAccountFilter));
              }}
              scrollOnChange
            />
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    fitters: state.user.fitters,
    fittersLoading: state.user.fetchFitters.get('loading'),
    inviteFitter: state.user.inviteFitter,
    deleteFitter: state.user.deleteFitter,
  }))
)(ManageUsers);
