import React from 'react';
import Svg, { Path, Rect } from 'svgs';

const CoBrandPlaceholder = () => {
  return (
    <Svg width="120" height="40" viewBox="0 0 120 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Rect x="0.747803" width="120" height="40" rx="4" fill="#EDF2F8" />
      <Path
        d="M57.2478 21.5L59.7478 24.5L63.2478 20L67.7478 26H53.7478L57.2478 21.5ZM69.7478 27V13C69.7478 11.89 68.8478 11 67.7478 11H53.7478C53.2174 11 52.7087 11.2107 52.3336 11.5858C51.9585 11.9609 51.7478 12.4696 51.7478 13V27C51.7478 27.5304 51.9585 28.0391 52.3336 28.4142C52.7087 28.7893 53.2174 29 53.7478 29H67.7478C68.2782 29 68.7869 28.7893 69.162 28.4142C69.5371 28.0391 69.7478 27.5304 69.7478 27Z"
        fill="#576975"
      />
    </Svg>
  );
};

export default CoBrandPlaceholder;
