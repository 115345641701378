// Defined in a separate file to prevent a circular dependency when importing updateToken from client.js
import { UPDATE_TOKEN, LOGOUT } from './auth-action-constants';

export function updateToken(token, refreshToken = null) {
  return {
    type: UPDATE_TOKEN,
    token,
    refreshToken,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}
