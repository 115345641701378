import React, { Component } from 'react';
import PrivacyPolicy from '../common/components/PrivacyPolicy';
import ScrollToTop from '../common/components/ScrollToTop';

class PrivacyPolicyPage extends Component {
  componentDidMount(){
    window.analytics.page("Privacy Policy");
  }
  render() {
    return (
      <ScrollToTop>
        <PrivacyPolicy />
      </ScrollToTop>
    );
  }
}

export default PrivacyPolicyPage;
