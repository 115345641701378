import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import InfoIcon from './icons/Info';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    moreInfo: 'More info',
  },
});

function TooltipIcon(props) {
  const { buttonProps, iconSize = 24 } = props;

  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const handleKeyDown = event => {
    if (event.key === 'Escape' && visible) {
      setVisible(false);
    }
    if (event.key === 'Enter' || event.key === ' ') {
      setVisible(prevVisible => !prevVisible);
    }
  };

  return (
    <Tippy
      content={props.children}
      visible={visible}
      onClickOutside={hide}
      appendTo={trigger => trigger.parentNode}
    >
      <button
        aria-label={strings.moreInfo}
        tabIndex="0"
        onKeyDown={handleKeyDown}
        onFocus={show}
        onBlur={hide}
        onMouseOver={show}
        onMouseOut={hide}
        {...buttonProps}
      >
        <InfoIcon fill="#5057FF" size={iconSize} />
      </button>
    </Tippy>
  );
}

TooltipIcon.propTypes = {
  buttonProps: PropTypes.object,
  iconSize: PropTypes.number,
};

export default TooltipIcon;