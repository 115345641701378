import { combineReducers } from 'redux';
import { fromJS, List, Map } from 'immutable';

import {
  ADD_CO_BRAND,
  DELETE_CO_BRAND,
  GET_CO_BRAND,
  GET_CO_BRANDS,
  RESET_UPLOAD_CO_BRAND_LOGO,
  RESET_DELETE_CO_BRAND,
  RESET_CO_BRAND,
  UPLOAD_CO_BRAND_LOGO,
  UPDATE_CO_BRAND,
} from './admin-action-constants';
import getApiReducer from '../common/utils/getApiReducer';

export default combineReducers({
  addCoBrand: getApiReducer(ADD_CO_BRAND),
  deleteCoBrand: getApiReducer(DELETE_CO_BRAND, [RESET_DELETE_CO_BRAND]),
  getCoBrand: getApiReducer(GET_CO_BRAND, [RESET_CO_BRAND]),
  getCoBrands: getApiReducer(GET_CO_BRANDS),
  coBrand: (state = new Map(), action) => {
    if (action.type === `${GET_CO_BRAND}_SUCCESS`) {
      return fromJS(action.json);
    } else if (action.type === RESET_CO_BRAND) {
      return new Map();
    }

    return state;
  },
  coBrands: (state = new List(), action) => {
    if (action.type === `${GET_CO_BRANDS}_SUCCESS`) {
      return fromJS(action.json);
    }

    return state;
  },
  updateCoBrand: getApiReducer(UPDATE_CO_BRAND, []),
  uploadCoBrandLogo: getApiReducer(UPLOAD_CO_BRAND_LOGO, [RESET_UPLOAD_CO_BRAND_LOGO]),
  coBrandLogo: (state = { url: null }, action) => {
    if (action.type === `${UPLOAD_CO_BRAND_LOGO}_SUCCESS`) {
      return {
        url: action.json.url,
      };
    }

    return state;
  },
});
