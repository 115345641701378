import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../common/components/Tooltip';
import Button from '../../../common/components/Button';
import PencilIcon from '../../../common/components/icons/Pencil';
import AccountForm from './AccountForm';

class AccountTooltipForm extends Component {
  static propTypes = {
    initialValue: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { onSubmit, initialValue } = this.props;

    return (
      <Tooltip
        top={40}
        left={-190}
        pointsAt={({ toggle }) => (
          <Button onClick={toggle} buttonStyle="icon-clear">
            <PencilIcon size={20} fill="#5057FF" />
          </Button>
        )}
      >
        {({ hideTooltip }) => (
          <AccountForm
            initialValue={initialValue}
            onCancel={hideTooltip}
            onSubmit={values => {
              onSubmit(values);
              hideTooltip();
            }}
          />
        )}
      </Tooltip>
    );
  }
}

export default AccountTooltipForm;
