import React, { useState } from 'react';
import Preference from '../../Preference';

const Brand = props => {
  const { brandName, on, handleSelectedBrand } = props;
  const [checked, setChecked] = useState(on);

  const handleToggle = () => {
    setChecked(previous => !previous);
    handleSelectedBrand({ brandName, on: !checked });
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Preference label={brandName} checked={checked} handleToggle={handleToggle} />
    </div>
  );
};

export default Brand;
