import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LocalizedStrings from 'react-localization';
import { Carousel } from 'react-responsive-carousel';
import TrophyIcon from '../../../common/components/icons/Trophy';
import GolfCart from '../../../common/components/icons/GolfCart';
import confidenceIntervalStrings from './confidence-interval-strings';
import GolfBallTee from '../../../common/components/icons/GolfBallTee';
import GolfHole from '../../../common/components/icons/GolfHole';
import { useHistory } from 'react-router-dom';

const strings = new LocalizedStrings({
  en: {
    introText: `Ballnamic utilizes advanced golf ball evaluation and modelling techniques that have the following estimated accuracy levels, using a 95% confidence interval:`,
    confidenceIntervalDescription: `The 95% confidence interval is an estimation of the probability that the reported values or figures fall within a specified range.  We would expect the ball performance to fall within the interval estimates 95% of the time based upon the testing and data provided and employed. Click on Testing Deep Dive in the Library to learn more about our testing methods.`,
    closingText: `The Trophies {icon} you will see on the Results page are designed as a visual guide to indicate the desired performance in each category. A trophy {icon} does not signify that the ball is definitively the best performer. Any balls within the specified confidence interval may produce equivalent or better performance for an individual golfer.`,
    youAssumeAnyRisk: `Ballnamic is intended to provide additional insight for a golfer to consider and possibly discuss with her/his preferred equipment advisor. Any other use of the results provided by Ballnamic are strongly discouraged - for example as a factor in support of or in opposition to any actual or proposed Rule of Golf, Local Rule or as a factor in handicapping.`,
    ourSelectionProcess: `Our selection process is designed to identify estimated ball performance for a wide range of golfers. We currently test urethane cover balls which are 3-piece construction or greater at a price point of $20 or higher, however, we are unable to test all balls in the marketplace.  We update our database at least twice a year, based on the timing of commercial launch dates. Ballnamic users acknowledge that there could be up to a 6-month period when a new version of a ball that we have included in our database is not in the database. We test the primary light finish of each ball (typically white), so actual results may vary with alternative color ball models.`,
    ballnamicRecommends: `Ballnamic recommends 5 balls that are estimated to be a good overall match based on your inputs.  Users are encouraged to consider the characteristics and relative performance of the top 5 or more balls identified on the Results page and consider which of the 10+ categories of performance may be more or less meaningful to you, the golfer, when considering which golf ball(s) you may wish to play.`,
    ballnamicProvides: `Ballnamic fitting results provide estimated performance projections based on individual user input, modelling, algorithm-based approximations, and product testing, along with data and assumptions developed by Ballnamic.  Results and recommendations rely upon the foregoing and other variables to provide estimated projections, and necessarily include uncertainty as identified balls may not perform as modelled and estimated. Ballnamic’s projections do not assure or guarantee that any ball will outperform any other ball.  The Ballnamic tool does not endorse any particular ball(s).`,
    getYourResults: 'Before you tee off',
    whichBalls: 'Which balls are in the Ballnamic database?',
    howToUse: 'How to Use Your Recommendations',
    getResults: 'Get Results',
    agree: 'I Agree',
    confirmInputs: 'Confirm Inputs',
    edit: 'Edit',
    yourProfile: 'Your Profile',
    driverDetails: 'Driver Details',
    ironDetails: 'Iron Details',
    wedgeDetails: 'Wedge Details',
    finishingTouches: 'Finishing Touches',
  },
});

function ConfidenceRangeDisclaimer({ onSubmit, hasAcceptedDisclaimer, questionnaireData, onDisclaimerAccept }) {
  const [step, setStep] = useState(0);
  const history = useHistory();

  const getHeightPreference = (value) => {
    const map = {
      0: 'Lowest Trajectory',
      1: 'Low-Mid Trajectory',
      2: 'Mid Trajectory',
      3: 'Mid-High Trajectory',
      4: 'Highest Trajectory'
    };
    return map[value] || value;
  };

  const getImportanceLevel = (value) => {
    const map = {
      0: 'Not Important',
      1: 'Moderate Importance',
      2: 'High Importance'
    };
    return map[value] || value;
  };

  const getStoppingPower = (value) => {
    const map = {
      0: 'Shallow Landing Angle',
      1: 'Moderately Shallow Landing Angle',
      2: 'Neutral Landing Angle',
      3: 'Moderately Steep Landing Angle',
      4: 'Steep Landing Angle'
    };
    return map[value] || value;
  };

  const getWorkability = (value) => {
    const map = {
      0: 'Least Curve',
      2: 'Some Workability',
      4: 'Most Workability'
    };
    return map[value] || value;
  };

  const getSpinPreference = (value) => {
    const map = {
      0: 'Least Spin',
      1: 'Low Spin',
      2: 'Moderate Spin',
      3: 'High Spin',
      4: 'Maximum Spin'
    };
    return map[value] || value;
  };

  const getPutterFeel = (value) => {
    const map = {
      0: 'Very Soft',
      1: 'Soft',
      2: 'Medium',
      3: 'Firm',
      4: 'Very Firm'
    };
    return map[value] || value;
  };

  const getPricePreference = (value) => {
    const map = {
      'none': 'I want the best ball possible for my game',
      'strict': 'I want the best matched ball for its price'
    };
    return map[value] || value;
  };

  const getTypicalValue = (value) => {
    const map = {
      'low': 'Low',
      'mid': 'Medium',
      'high': 'High'
    };
    return map[value] || value;
  };

  const handleEditSection = (section) => {
    return history.push(`/my-fitting/${section}`);
  };

  const handleAcceptDisclaimer = () => {
    onDisclaimerAccept();
    onSubmit();
  };

  return (
    <div className="container">
      <Carousel
        className="disclaimer-carousel"
        showStatus={false}
        showThumbs={false}
        swipeable={hasAcceptedDisclaimer}
        showArrows={hasAcceptedDisclaimer}
        showIndicators={hasAcceptedDisclaimer}
        dynamicHeight
        selectedItem={step}
      >
        {/* Confirm Inputs View */}
        <div className="carousel-container">
          <div className="get-results--payment__wrapper">
            <div className="questionnaire-icon-centered">
              <GolfHole size={48} fill="#5057FF" />
            </div>
            <div className="questionnaire-h1">{strings.confirmInputs}</div>
            
            <div className="questionnaire-confirm-inputs-section">
              {/* Profile Section */}
              <div className="questionnaire-section-header">
                <div className="questionnaire-h2">Your Profile</div>
                <button onClick={() => handleEditSection('your-profile')} className="questionnaire-edit-button">Edit</button>
              </div>   
              <div className="questionnaire-section-content questionnaire-profile">           
              <div className="questionnaire-detail-row">
                  <span>Handicap: {questionnaireData.handicap}</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Current Ball: {`${questionnaireData.year} ${questionnaireData.brand} ${questionnaireData.model}`}</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Zip Code: {localStorage.getItem('calculatedZipCode')}</span>
                </div>
              </div>

              {/* Driver Details Section */}
              <div className="questionnaire-section-header">
                <div className="questionnaire-h2">Driver Details</div>
                <button onClick={() => handleEditSection('driver')} className="questionnaire-edit-button">Edit</button>
              </div>
              <div className="questionnaire-section-content questionnaire-driver-details">
                <div className="questionnaire-detail-row">
                  <span>Total Driver Distance: {questionnaireData.driver_distance} yds</span>
                  <span>Ball Speed: {questionnaireData.driver_ball_speed} mph</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Typical Driver Launch Angle: {getTypicalValue(questionnaireData.driver_launch_type)}</span>
                  <span>Launch Angle (deg): {questionnaireData.driver_launch_angle}</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Typical Driver Spin Rate: {getTypicalValue(questionnaireData.driver_spin_type)}</span>
                  <span>Spin Rate (rpm): {questionnaireData.driver_spin_rate} rpm</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Height Preference: {getHeightPreference(questionnaireData.driver_height_pref)}</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Wind Performance: {getImportanceLevel(questionnaireData.driver_wind_imp)}</span>
                </div>
              </div>

              {/* Iron Details Section */}
              <div className="questionnaire-section-header">
                <div className="questionnaire-h2">Iron Details</div>
                <button onClick={() => handleEditSection('irons')} className="questionnaire-edit-button">Edit</button>
              </div>
              <div className="questionnaire-section-content questionnaire-iron-details">
                <div className="questionnaire-detail-row">
                  <span>7-Iron Carry Distance: {questionnaireData.iron_distance} yds</span>
                  <span>Ball Speed: {questionnaireData.iron_ball_speed} mph</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Typical 7-Iron Launch Angle: {getTypicalValue(questionnaireData.iron_launch_type)}</span>
                  <span>Launch Angle (deg): {questionnaireData.iron_launch_angle}</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Typical 7-Iron Spin Rate: {getTypicalValue(questionnaireData.iron_spin_type)}</span>
                  <span>Spin Rate (rpm): {questionnaireData.iron_spin_rate} rpm</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Stopping Power Preference: {getStoppingPower(questionnaireData.iron_stop_pref)}</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Workability Preference: {getWorkability(questionnaireData.iron_workability_pref)}</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Flier Prevention Importance: {getImportanceLevel(questionnaireData.iron_flier_imp)}</span>
                </div>
              </div>

              {/* Wedge Details Section */}
              <div className="questionnaire-section-header">
                <div className="questionnaire-h2">Wedge Details</div>
                <button onClick={() => handleEditSection('wedges')} className="questionnaire-edit-button">Edit</button>
              </div>
              <div className="questionnaire-section-content questionnaire-wedge-details">
                <div className="questionnaire-detail-row">
                  <span>Full Wedge Spin Preference: {getSpinPreference(questionnaireData.wedge_spin_pref)}</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Greenside Spin Importance: {getImportanceLevel(questionnaireData.greenside_spin_imp)}</span>
                </div>
              </div>

              {/* Finishing Touches Section */}
              <div className="questionnaire-section-header">
                <div className="questionnaire-h2">Finishing Touches</div>
                <button onClick={() => handleEditSection('finishing-touches')} className="questionnaire-edit-button">Edit</button>
              </div>
              <div className="questionnaire-section-content questionnaire-finishing-touches">
                <div className="questionnaire-detail-row">
                  <span>Putter Feel Preference: {getPutterFeel(questionnaireData.putter_feel_pref)}</span>
                </div>
                <div className="questionnaire-detail-row">
                  <span>Price Preference: {getPricePreference(questionnaireData.cost_preference)}</span>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="button button--color--primary questionnaire-centered-button"
              onClick={() => {
                setStep(1);
                window.scrollTo(0, 0);
              }}
            >
              {strings.getResults}
            </button>
          </div>
        </div>

        {/* Before you tee off View */}
        <div className="carousel-container">
          <div className="get-results--payment__wrapper">
            <div className="questionnaire-icon-centered">
              <GolfHole size={48} fill="#5057FF" />
            </div>
            <div className="questionnaire-h1">{strings.getYourResults}</div>
            <div className="get-results--disclaimer__empty-state">
              <div>{strings.introText}</div>
              <ul>
                <li>
                  {confidenceIntervalStrings.carryDistanceRange} {confidenceIntervalStrings.carryDistance}
                </li>
                <li>
                  {confidenceIntervalStrings.windDistanceRange} {confidenceIntervalStrings.windDistance}
                </li>
                <li>
                  {confidenceIntervalStrings.driverMaxHeightRange} {confidenceIntervalStrings.driverMaxHeight}
                </li>
                <li>
                  {confidenceIntervalStrings.ironCarryRange} {confidenceIntervalStrings.ironCarry}
                </li>
                <li>
                  {confidenceIntervalStrings.ironRollRange} {confidenceIntervalStrings.ironRoll}
                </li>
                <li>
                  {confidenceIntervalStrings.ironWorkabilityScoreRange} {confidenceIntervalStrings.ironWorkabilityScore}
                </li>
                <li>
                  {confidenceIntervalStrings.flierPreventionRange} {confidenceIntervalStrings.flierPrevention}
                </li>
                <li>
                  {confidenceIntervalStrings.wedgeSpinRange} {confidenceIntervalStrings.wedgeSpin}
                </li>
              </ul>
              <div>{strings.confidenceIntervalDescription}</div>
              <br />
              <div>{strings.formatString(strings.closingText, { icon: <TrophyIcon fill="#EDAE49" /> })}</div>
              <br />
              <div>{strings.youAssumeAnyRisk}</div>
            </div>
            {hasAcceptedDisclaimer ? (
              <button type="button" className="button button--color--primary questionnaire-centered-button" onClick={onSubmit}>
                {strings.getResults}
              </button>
            ) : (
              <button
                type="button"
                className="button button--color--primary questionnaire-centered-button"
                onClick={() => {
                  setStep(2);
                  window.scrollTo(0, 0);
                }}
              >
                {strings.agree}
              </button>
            )}
          </div>
        </div>

        {/* Which balls are in the Ballnamic database? View */}
        <div className="carousel-container">
          <div className="get-results--payment__wrapper">
            <div className="questionnaire-icon-centered">
              <GolfBallTee size={48} fill="#5057FF" />
            </div>
            <div className="questionnaire-h1">{strings.whichBalls}</div>
            <div className="get-results--disclaimer__empty-state">
              <div>{strings.ourSelectionProcess}</div>
            </div>
            <button
              type="button"
              className="button button--color--primary questionnaire-centered-button"
              onClick={() => {
                setStep(3);
                window.scrollTo(0, 0);
              }}
            >
              {strings.agree}
            </button>
          </div>
        </div>

        {/* How to Use Your Recommendations View */}
        <div className="carousel-container">
          <div className="get-results--payment__wrapper">
            <div className="questionnaire-icon-centered">
              <GolfCart size={48} fill="#5057FF" />
            </div>
              <div className="questionnaire-h1">{strings.howToUse}</div>
            <div className="get-results--disclaimer__empty-state">
              <div>{strings.ballnamicRecommends}</div>
              <br />
              <div>{strings.ballnamicProvides}</div>
            </div>
            <button 
              type="button" 
              className="button button--color--primary questionnaire-centered-button" 
              onClick={handleAcceptDisclaimer}
            >
              {strings.getResults}
            </button>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

ConfidenceRangeDisclaimer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hasAcceptedDisclaimer: PropTypes.bool.isRequired,
  questionnaireData: PropTypes.object.isRequired,
  onDisclaimerAccept: PropTypes.func.isRequired,
};

export default ConfidenceRangeDisclaimer;
