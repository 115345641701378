import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import LocalizedStrings from 'react-localization';
import FieldWithLabelAndValidation from '../../../common/forms/FieldWithLabelAndValidation';
import Button from '../../../common/components/Button';

let strings = new LocalizedStrings({
  en: {
    cancel: 'Cancel',
    saveAccount: 'Save Account',
  },
});

class AccountForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
  };

  componentDidMount() {
    this.inputRef.focus();
  }

  render() {
    const { onSubmit, onCancel, initialValue } = this.props;

    return (
      <Formik
        initialValues={{ account: initialValue || '' }}
        onSubmit={(values, { resetForm }) => {
          resetForm();
          onSubmit({ account: values.account.trim() });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="tooltip-form">
            <FieldWithLabelAndValidation
              label="Account"
              fieldProps={{ name: 'account', type: 'text', innerRef: ref => (this.inputRef = ref) }}
            />
            <div className="tooltip-button__wrapper">
              <Button onClick={onCancel} buttonStyle="text" type="button" disabled={isSubmitting}>
                {strings.cancel}
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {strings.saveAccount}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default AccountForm;
