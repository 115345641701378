import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingGolfBall from '../../../common/components/icons/LoadingGolfBall';
import LoadingFlag from '../../../common/components/icons/LoadingFlag';
import LoadingGolfHole from '../../../common/components/icons/LoadingGolfHole';
import ApplauseAudioClip from './assets/GolfApplause.mp3';

const ResultsAnimatedLoadingIndicator = ({ onComplete }) => {
  const steps = [
    {
      percent: 0,
      waitTime: 3500,
      message: 'Analyzing weather and altitude data...',
    },
    {
      percent: 35,
      waitTime: 2500,
      message: 'Simulating driver trajectories...',
    },
    {
      percent: 60,
      waitTime: 2000,
      message: 'Simulating 7-iron trajectories...',
    },
    {
      percent: 80,
      waitTime: 1000,
      message: 'Matching your wedge preferences...',
    },
    {
      percent: 90,
      waitTime: 1000,
      message: 'Optimizing across all categories...',
    },
    {
      percent: 100,
      waitTime: 500,
      message: 'Finalizing...',
    },
    {
      percent: 120,
      waitTime: 1000,
      message: 'Finalizing...',
    },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [complete, setComplete] = useState(false);
  const currentPercent = steps[currentStep].percent;

  useEffect(() => {
    if (!complete) {
      setTimeout(() => {
        if (currentStep < steps.length - 1) {
          setCurrentStep(currentStep + 1); // i think theres a better way to write this
        } else {
          new Audio(ApplauseAudioClip).play();
          setTimeout(() => {
            setComplete(true);
            onComplete();
          }, 5000);
        }
      }, steps[currentStep].waitTime);
    }
  });

  return (
    <div className="results-loading-indicator container">
      <div className="results-loading-container">
        <div className="loading-animation__wrapper">
          <div className="loading-golf-ball__container" style={{ transform: `translate(%, 0px)` }}>
            <LoadingGolfBall className="loading-golf-ball" />
          </div>
          <div className="flag-hole--flex__wrapper">
            <div className="flag__wrapper">
              <LoadingFlag className="flag" />
            </div>
            <div className="hole-flex">
              <LoadingGolfHole className="hole" />
            </div>
          </div>
        </div>
        <div className="results-loading--left">
          <div className="loading-header-text">Finding the best ball for your game</div>
          <div className="loading-bar-container">
            <div className="progress-bar">
              <div className="progress-bar--fill" style={{ width: `${currentPercent}%`, maxWidth: '100%' }} />
            </div>
          </div>
          <div className="loading-text">{steps[currentStep].message}</div>
        </div>
      </div>
    </div>
  );
};

ResultsAnimatedLoadingIndicator.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default ResultsAnimatedLoadingIndicator;
