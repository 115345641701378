import React from 'react';
import Svg, { Path } from 'svgs';

const IronWork = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M8 7v2.2H6.5v6.6H8V18H2v-2.2h1.5V9.2H2V7h6zm6.4776059.41042782c.5784006.41253608.9219741 1.07902062.9224813 1.78948499a2.09549206 2.09549206 0 01-.0824935.5499782l6.522483 6.53374099c.2132311.2145121.2132311.5609572 0 .7754693l-.7809381.7809691c-.2145036.2132395-.5609349.2132395-.7754385 0l-6.5334821-6.5227415c-.1788394.0518125-.3637866.0795557-.5499564.0824967-.7104362-.0005072-1.3768944-.3440943-1.7894141-.9225179-.4125197-.57842358-.5203131-1.32047034-.2894211-1.99236656l1.3968892 1.39694464 1.3638919-1.36394594-1.3968893-1.39694464c.6718696-.23090115 1.413887-.12310345 1.9922877.28943262zm-.3094211 5.87032328l1.5563767 1.5564383-3.0082616 3.002881c-.2145036.2132395-.5609349.2132395-.7754385 0l-.7809381-.7809691c-.2132311-.2145121-.2132311-.5609572 0-.7754693l3.0082615-3.0028809zM20.8996512 7l1.0999129 1.0999564-1.0999129 2.1999128h-1.0999128l-1.1934054 1.1934527-1.0999128-1.0999564 1.1934054-1.19345269V8.0999564L20.8996512 7z" />
    </Svg>
  );
};

export default IronWork;
