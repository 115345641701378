import React, { Component } from 'react';
import ScrollToTop from '../common/components/ScrollToTop';
import { Helmet } from 'react-helmet';
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    page: 'Terms of Use',
  },
});

class TermsOfUsePage extends Component {
  componentDidMount(){
    window.analytics.page("Terms of Use")
  }
  render() {
    return (
      <ScrollToTop>
        <div className="container legal legal-container">
          <Helmet>
            <title>{`${strings.page} - ${strings.title}`}</title>
          </Helmet>
          <div id="top">
            <h2>Terms of Use</h2>

            <p id="last-updated-date">Effective Date: September 28, 2023</p>
            <br />
            <p>
              This Terms of Use (this <strong>“Agreement”</strong> or the “Terms”) is agreed to between PING, Inc. (
              <strong>“PING”</strong>) and You, or if You represent an entity or other organization, that entity or
              organization (in either case, <strong>“You”</strong> or <strong>“Your”</strong>).
            </p>
            <br />
            <p>
              PING offers an online platform (the <strong>“Platform”</strong>) and makes the Platform available through
              PING’s website located at https://ping.com/ and any other websites operated by PING (each, a{' '}
              <strong>“Site”</strong>) and any mobile applications provided by and on behalf of PING (each, an{' '}
              <strong>“Application”</strong>). The Platform provides individual end users (<strong>“Users”</strong>)
              with the ability to access information, data, and content (<strong>“Content”</strong>), on a variety of
              topics, as well as additional services, functionality, and resources described on the Platform (
              <strong>“Services”</strong>). Finally, Users may be able to purchase PING <strong>“Products”</strong> on
              the Platform or elsewhere.
            </p>
            <br />
            <p>
              Together, the Sites and any Applications are treated as a part of the “Platform” for purposes of this
              Agreement, and the Platform and Services provided by PING, including its Products, are all governed
              exclusively by this Agreement unless a separate agreement has been entered into by You and PING. Unless
              You or Your organization have entered into a separate written agreement with PING regarding the Platform,
              this Agreement is the complete and exclusive agreement between You and PING regarding Your access to and
              use of the Platform, Content, and Services and Your purchase of Products.
            </p>
            <br />
            <p>
              <strong>
                THIS AGREEMENT CONTAINS A BINDING ARBITRATION AGREEMENT WHICH LIMITS YOUR RIGHTS TO BRING AN ACTION IN
                COURT, BRING A CLASS ACTION, AND HAVE DISPUTES DECIDED BY A JUDGE OR JURY, AS WELL AS PROVISIONS THAT
                LIMIT OUR LIABILITY TO YOU.
              </strong>
            </p>
            <br />
            <p>
              PLEASE CAREFULLY READ THIS AGREEMENT. BY ACCESSING OR USING THE PLATFORM, CONTENT, OR SERVICES, OR BY
              CLICKING A BOX THAT STATES THAT YOU ACCEPT OR AGREE TO THESE TERMS, OR BY PURCHASING PRODUCTS, YOU AGREE
              THAT YOU HAVE READ AND AGREE TO BE BOUND BY THIS AGREEMENT.
            </p>
            <br />
            <p>
              IF YOU DO NOT AGREE TO THIS AGREEMENT, OR DO NOT MEET THE QUALIFICATIONS INCLUDED IN THIS AGREEMENT, PING
              IS NOT WILLING TO PROVIDE YOU WITH ACCESS TO OR USE OF THE PLATFORM, CONTENT, PRODUCTS, OR SERVICES, AND
              YOU MUST NOT ACCESS OR USE THE PLATFORM, CONTENT, OR SERVICES.
            </p>
            <br />

            <p>
              In addition to this Agreement, please read our Privacy Policy, located at https://ping.com/privacy (the
              “Privacy Policy”) for more information on our data collection, storage, and protection practices.
            </p>

            <ol>
              <li>
                <strong>DEFINITIONS.</strong> Terms used in this Agreement have the definitions given in this Agreement
                or, if not defined in this Agreement, have their plain English meaning as commonly interpreted in the
                United States.
              </li>
              <li>
                <strong>TERM.</strong> This Agreement is entered into as of the earlier of the date You first download
                or install any Application, order a Product, or first access or use the Platform, Content, or Services
                (the <strong>“Effective Date”</strong>) and will continue until terminated as set forth herein.
              </li>
              <li>
                <strong>MODIFICATIONS TO THESE TERMS.</strong> PING may, at any time, modify or discontinue all or part
                of the Platform, Content, Products, or Services; change, modify or waive fees required to use the
                Platform, Content, or Services; or offer opportunities to some or all Users, subject to any restrictions
                placed on PING’s exercise of such rights under applicable laws. PING also reserves the right, at any
                time, to modify this Agreement. PING will inform You of the presence of any changes to this Agreement by
                posting those changes on the Platform or by providing You with notice through the Platform. Any
                modifications will be effective immediately upon posting on the Platform or delivery of such notice
                through the Platform. You may terminate this Agreement as set forth below if You object to any such
                modifications. However, You will be deemed to have agreed to any and all modifications through Your
                continued use of the Platform, Content, or Services or purchase of Products following such notice
                period. PING will note the effective date of the latest version of this Agreement at the end of this
                Agreement.
              </li>
              <li>
                <strong>AGE OF ELIGIBILITY.</strong> By accepting this Agreement, You also certify that You are 18 years
                of age or older. If You are under the age of 18 but at least 13 years of age, You may use this Platform,
                Content, or Services only under the supervision of a parent or legal guardian who agrees to be bound by
                this Agreement. Children under the age of 13 may not use the Platform, Content, or Services under any
                circumstances. If You are a parent or legal guardian agreeing to this Agreement for the benefit of a
                child between the ages of 13 and 18, be advised that You are fully responsible for his or her use of the
                Platform, Content, or Services, including the legal liability that he or she may incur and any Products
                he or she may purchase.
              </li>
              <li>
                <strong>ACCOUNT.</strong> You may be required to establish an account on the Platform (an{' '}
                <strong>“Account”</strong>) to access portions of the Platform, Content, or Services. Approval of Your
                request to establish an Account will be at the sole discretion of PING. Each Account and the User
                identification and password for each Account (the <strong>“Account ID”</strong>) is personal in nature.
                Each Account is for Your personal use and each Account ID may be used only by You alone. You may not
                distribute or transfer Your Account or Account ID or provide a third party with the right to access Your
                Account or Account ID. You are solely responsible for all use of the Platform, Content, or Services
                through Your Account. You will ensure the security and confidentiality of Your Account ID and will
                notify PING immediately if any Account ID is lost, stolen, or otherwise compromised. You are fully
                responsible for all liabilities and damages incurred through the use of Your Account or under any
                Account ID (whether lawful or unlawful) and that any actions completed through any Account or under any
                Account ID will be deemed to have been lawfully completed by You. In connection with establishing an
                Account, You will be asked to submit certain information about Yourself (
                <strong>“Account Information”</strong>). In consideration of your use of this Site and the ability to
                create an Account, You agree that in addition to complying with all of the conditions of these Terms of
                Use, you will also ensure that: (a) all Account Information You provide will be true and complete; and
                (b) You will maintain and promptly update Your Account Information to keep it accurate and current. You
                may not: (i) use or input Account Information of another person with the intent to impersonate that
                person; and (ii) use or input Account Information that PING, in its sole discretion, deems offensive.
              </li>
              <li>
                <strong>YOUR CONTENT.</strong>{' '}
                <ol>
                  <li>
                    <strong>Use Of Your Content.</strong> You are solely responsible for all data, information, and
                    other materials (such as text, audio, video, photographs, illustrations, product reviews, graphics,
                    and other media) that You may provide or generate through Your use of the Platform or Services
                    (collectively, <strong>“Your Content”</strong>). As between You and PING, You retain ownership of
                    Your Content, except as provided in this Agreement. However, subject to any limitations included in
                    the Privacy Policy, You grant PING a nonexclusive, royalty-free, perpetual, irrevocable, and fully
                    sublicensable (including, without limitation, to Users and other third parties) right to use, copy,
                    store, reproduce, modify, display, adapt, publish, translate, create derivative works from,
                    distribute, and display (<strong>“Use”</strong>) Your Content for purposes of providing the Services
                    to You and for use in making enhancements and improvements to the Platform. You represent and
                    warrant that none of Your Content or the Use of Your Content by PING: (a) violates this Agreement or
                    any requirements under applicable laws, rules, or regulations (<strong>“Laws”</strong>); (b) is
                    libelous, defamatory, obscene, abusive, pornographic, threatening, or an invasion of privacy; (c)
                    constitutes an infringement, misappropriation or violation of the IPR or other rights of any third
                    party; (d) is illegal in any way or advocates illegal activity; (e) is an advertisement or
                    solicitation of funds, goods, or services (unless You have entered into a separate advertiser
                    agreement with PING); (f) is false, misleading or inaccurate; or (g) is or could be considered junk
                    mail, spam, a part of a pyramid scheme, a disruptive commercial message or disruptive advertisement.
                    PING is not responsible or liable for any deletion, correction, destruction, damage, loss, or
                    failure to store, restore or back-up any of Your Content. You agree that You have all right, title,
                    interest, and consent in Your Content necessary to allow PING to Use Your Content as set forth in
                    the rights and licenses You grant to PING under this Agreement.
                  </li>
                  <li>
                    <strong>Product Testimonials, Submissions, and Comments.</strong> All of Your Content that
                    constitutes product-related testimonials, submissions, or other product comments disclosed,
                    submitted or offered to PING on or by this Platform or otherwise disclosed, submitted or offered in
                    connection with Your use of PING Products (collectively, the <strong>“Product Comments”</strong>)
                    shall be and remain the property of PING. Such disclosure, submission or offer of any Product
                    Comments shall constitute an assignment to PING of all worldwide right, title, and interest in, to,
                    and under all copyrights and other IPR in the Product Comments. PING will own exclusively all such
                    right, title, and interest and shall not be limited in any way in its use, commercial or otherwise,
                    of any Product Comments. PING will be entitled to use, reproduce, disclose, modify, adapt, create
                    derivative works from, publish, display, and distribute any Product Comments You submit for any
                    purpose whatsoever, without restriction and without compensating You in any way. PING is and shall
                    be under no obligation: (a) to maintain any Product Comments in confidence; (b) to pay to You or any
                    other User any compensation for any Product Comments; or (c) to respond to You or any other User
                    regarding Product Comments. You agree that any Product Comments submitted by You to the Platform
                    will not violate this Agreement or any right of any third party, including copyright, trademark,
                    privacy or other personal or proprietary rights, and will not cause injury to any person or entity.
                    PING does not regularly review posted Product Comments but does reserve the right (but not the
                    obligation) to monitor and edit or remove any Product Comments submitted to the Platform. You grant
                    PING the right to use the name that You submit in connection with any Product Comments. You agree
                    not to impersonate any person or entity, or otherwise mislead as to the origin of any Product
                    Comments You submit. You are and shall remain solely responsible for the content of any Product
                    Comments that You make and You agree to indemnify PING for all claims resulting from any Product
                    Comments You submit. PING take no responsibility and assume no liability for any Product Comments
                    submitted by You or any other User and may remove these comments or post for any reason whatsoever.
                    For the avoidance of doubt, Product Comments are a subset of Your Content, and all the rights and
                    restrictions contained in this Section shall be in addition to rights and restrictions regarding
                    Your Content contained in this Agreement.
                  </li>
                </ol>
              </li>
              <li>
                <strong>YOUR ACCESS TO:</strong>{' '}
                <ol>
                  <li>
                    <strong>The Platform.</strong> Subject to Your compliance with this Agreement, PING will permit You
                    to access and use the Platform solely for lawful purposes and only in accordance with the terms of
                    this Agreement. You are solely responsible for obtaining and maintain all telecommunications,
                    broadband, and computer hardware, equipment, and services needed to access and use the Platform to
                    meet Your operational and business requirements based on Your particular circumstances, and for
                    paying all charges related thereto.
                  </li>
                  <li>
                    <strong>The Applications.</strong> Subject to Your compliance with this Agreement and any other
                    terms and conditions accompanying each Application, PING will permit You to download and install
                    Applications and operate those Applications solely for the purpose of using and accessing the
                    Platform, Content, and Services. You may install each Application only on computers, mobile devices,
                    or smart phones owned or controlled by You and used only for Your own personal and non-commercial
                    purposes in accordance with this Agreement and any applicable terms and conditions accompanying the
                    Application or otherwise provided to You by PING. Except as expressly set forth in the previous
                    sentence, You are granted no licenses or other rights in or to any Application or any IPR therein or
                    related thereto. You agree not to use, modify, reproduce, perform, display, create derivative works
                    from, republish, post, transmit, participate in the transfer or sale of, distribute, or in any way
                    exploit or utilize any Application other than as expressly permitted in this Agreement or any other
                    agreement You are required to agree to before being given access to any Application.
                  </li>
                  <li>
                    <strong>The Content.</strong> You will be provided with access to a variety of Content through the
                    Platform, including, text, audio, video, photographs, maps, illustrations, product reviews,
                    graphics, and other data, information, and media. Unless otherwise noted on the Platform, all
                    Content available through the Platform (<strong>“Platform Content”</strong>) is owned by PING and
                    PING’s other third party providers. All Platform Content is provided for informational purposes only
                    and You are solely responsible for verifying the accuracy, completeness, and applicability of all
                    Platform Content and for Your use of any Platform Content. Subject to Your compliance with this
                    Agreement, You may access the Platform Content solely for Your own personal, non-commercial purposes
                    in connection with Your use of the Platform. You will not and will not permit any third party to:
                    (a) alter, modify, reproduce, or create derivative works of any Platform Content; (b) distribute,
                    sell, resell, lend, loan, lease, license, sublicense or transfer any Platform Content; or (c) alter,
                    obscure or remove any copyright, trademark or any other notices that are provided on or in
                    connection with any Platform Content. Certain Content may include or be based on data, information
                    or content from other Users and other third party Content providers (
                    <strong>“Third Party Content”</strong>). PING has not verified the accuracy of and will not be
                    responsible for any errors or omissions in, any Content provided through the Platform. Without
                    limiting the foregoing, PING will not be held liable to You or any other third party for any
                    Content, including Your Content (as defined below) under a Federal Law referred to as the
                    Communications Decency Act or CDA, 47 U.S.C. § 230. Except as set forth in this Agreement, You are
                    granted no licenses or rights in or to any Content, or any IPR therein or related thereto. If You
                    would like to use the Content in a manner not permitted by this Agreement, please contact PING.
                  </li>
                  <li>
                    <strong>Third-Party Services.</strong> The Platform may provide You with the choice to access
                    services developed, provided, or maintained by other third-party service providers (
                    <strong>“Third Party Services”</strong>). In addition to the terms of this Agreement, Your access to
                    and use of any Third Party Services is also subject to any other agreement You may agree to before
                    being given access to the Third Party Services (each, a{' '}
                    <strong>“Third Party Service Agreement”</strong>). The terms of any Third Party Service Agreement
                    (which may include payment of additional fees) will apply to the applicable Third Party Services
                    provided under that Third Party Service Agreement in addition to the terms of this Agreement but
                    will not apply to any other Services You may access through PING. Except as set forth in this
                    Agreement, the terms of any Third Party Service Agreement will control in the event of a conflict
                    between the terms of this Agreement and that Third Party Service Agreement.
                  </li>
                  <li>
                    <strong>Social Media Features.</strong> The Platform may provide certain social media features that
                    enable You to link certain Content available on the Platform to third-party social media sites or
                    cause limited portions of Content available on the Platform to appear on third-party social media
                    sites. You may use these social media features only as they are provided by PING, solely with
                    respect to the Content as it is displayed on the Platform, and otherwise in accordance with any
                    additional terms and conditions provided by PING with respect to such social media feature. PING may
                    disable all or any social media features and any links at any time without notice in PING’s sole
                    discretion.
                  </li>
                </ol>
              </li>
              <li>
                <strong>YOUR COMMUNICATIONS WITH US</strong>{' '}
                <ol>
                  <li>
                    <strong>Contacting us by Telephone.</strong> If you communicate with us by telephone, we may monitor
                    or record the call. This is done for reasons such as maintaining the integrity of your account,
                    providing effective and timely service, and the improvement of our products.
                  </li>
                  <li>
                    <strong>Contact Us Via Website Chat.</strong> If you communicate with us via our web chat feature on
                    our site, we may monitor or record those interactions. This is done for reasons such as maintaining
                    the integrity of your account, providing effective and timely service, and the improvement of our
                    products.
                  </li>
                </ol>
              </li>
              <li>
                <strong>APP STORE.</strong> The Application may be obtained through a third party distribution platform
                (e.g., the Apple App Store) (the <strong>“App Store”</strong>) and is to be used solely on a mobile
                device owned or controlled by You that operates such third party’s operating system. This Agreement
                incorporates by reference the terms of the Addendum to this Agreement and any other terms available at
                the respective App Store from which You have obtained the Application. You agree that this Agreement is
                between You and PING, and not with the App Store. The App Store is not responsible for the Application,
                maintenance and support services or any warranty thereof, or addressing any claims related thereto. You
                agree to pay all fees charged by the App Store in connection with the Application (if any). The
                Application may also be subject to additional terms and conditions and privacy policies, and PING is not
                a party to nor responsible for those additional terms.
              </li>
              <li>
                <strong>PLACING YOUR ORDERS.</strong> You may be permitted to place orders seeking to purchase Products
                and other offerings available for sale on the Platform (whether in the form of an online order submitted
                through the Platform, a service provider’s platform, or an order submitted by way of email, phone, or
                other form permitted by PING, an “Order”). By submitting any Order, YOU REPRESENT AND WARRANT THAT YOU
                HAVE THE LEGAL RIGHT TO USE ANY METHOD OF PAYMENT UTILIZED IN CONNECTION WITH THAT ORDER. All Orders are
                subject to acceptance by PING, in its sole discretion, and PING may limit or restrict quantities of
                Products purchased without notice to You, even after the Order has been placed.
              </li>
              <li>
                <strong>TERMS OF SALE, REFUNDS, AND RETURNS.</strong> In addition to this Agreement, Your purchase of
                any Products is governed by the terms displayed on the Platform in connection with each product and any
                additional terms provided to You in connection with Your Order or any acceptance of Your Order provided
                by PING, for example, if a product is non-refundable, not available for returns, or if other terms
                apply, and includes PING’s shipping and return policies (taken together, the “Terms of Sale”). By
                placing an Order, You agree to be bound by the Terms of Sale with respect to that Order and all products
                included in that Order. If this Agreement conflicts with the Terms of Sale, the Terms of Sale will
                govern and control with respect to the products provided to You under that Order. Risk of loss and title
                to any products You purchase pass to You upon delivery of those products to the applicable carrier. Any
                Product descriptions or visualizations on the Platform are for convenience purposes only, and PING does
                not represent that they are accurate. This includes size, weight, color, and other specifications
                provided. If a Product is listed at an incorrect price or with incorrect information, PING shall have
                the right to refuse or cancel any Order placed for those Products, whether or not the Order has been
                confirmed or Your credit card has been charged. If Your credit card has already been charged for an
                Order and the Order is canceled, PING will issue You a credit or refund in the amount of the charge.
                Please note that for any sales tax errors, the issuance of a refund for the amount in error will be the
                exclusive remedy. For more information about how You can return your product, or for questions related
                to shipping, please see our Frequently Asked Questions page, and the entries on “Online Order Returns”
                and “Orders” which includes responses to questions such as “Where do You ship?” and “What is my shipping
                charge?”
              </li>
              <li>
                <strong>PROMO CODES AND DISCOUNTS.</strong> Our Sites may provide or advertise promo codes, discounts,
                coupon codes, and offers that provide a benefit to You when entered upon checkout. These cannot be
                applied to prior or completed transactions, and they must be provided at the time of purchase.
                Generally, promo codes and discounts cannot be combined with other offers or used with shipping offers
                or subscription orders. Only consumers can use promo codes, and the codes cannot be used by resellers,
                wholesalers, practitioners, or the like. You may have no right to discounts, coupons, or offers that are
                expired or discontinued even if they remain visible on the Site.
              </li>
              <li>
                <strong>TERMINATION.</strong> This Agreement may be terminated by either party at any time, in that
                party’s sole discretion, upon notice to the other party as permitted under this Agreement. Upon
                termination or expiration of this Agreement for any reason: (a) all rights and subscriptions granted to
                You under this Agreement will terminate; (b) You will immediately cease all use of and access to the
                Platform and all Content and Services (including, without limitation, all Content You obtained prior to
                termination); (c) You will immediately delete any Applications You have downloaded or installed prior to
                termination; and (d) PING may, in its sole discretion, delete Your Account and any of Your Content held
                by PING at any time. Sections 1 (Definitions), 6 (Your Content), 10 (Placing Your Orders) 11 (Terms of
                Sale, Refunds, and Returns), 13 (Termination), 14 (Suspension), 15 (Platform Technology), 16
                (Ownership), 17 (PING Marks), 18 (Representations and Warranties), 19 (No Warranties; Disclaimers), 20
                (Indemnity), 21 (Limitation on Liability), 23 (Data Privacy), 24 (Improvements and Feedback), 25
                (Governing Law and Venue), 27 (Notices), 28 (Linked Sites and Linking to Ping’s Sites), 29 (DMCA and
                Claims of Infringement), 30 (Termination for Repeat Infringement and Counter Notices), 31 (Filtering and
                Parental Controls), 32 (Equitable Relief), 33 (U.S. Government), 34 (Export), 35 (Additional Terms), 38
                (How to Contact Us; Complaints), will survive any expiration or termination of this Agreement.
              </li>
              <li>
                <strong>SUSPENSION.</strong> Without limiting PING’s right to terminate this Agreement, PING may also
                suspend Your access to Your Account and the Platform, Content, or Services (including any of Your
                Content held by PING), with or without notice to You, upon any actual, threatened, or suspected breach
                of this Agreement or applicable law or upon any other conduct deemed by PING to be inappropriate or
                detrimental to the Platform, Services, Content, PING, or any other PING User, App Store, or third party.
              </li>
              <li>
                <strong>PLATFORM TECHNOLOGY.</strong> The Platform, and the databases, software, hardware, and other
                technology used by or on behalf of PING to operate the Platform, and the structure, organization, and
                underlying data, information, and software code thereof (collectively, the <strong>“Technology”</strong>
                ), constitute valuable trade secrets of PING or its parent company. The Platform may allow you to
                download certain Content, applications, software, and other information or materials. PING makes no
                representation that such download will be error or malware free or fit for a particular purpose. Certain
                downloads may be subject to a separate agreement either with the Company or a third party, for example
                an agreement with a mobile application store.
                <ol>
                  <li>
                    <strong>Unauthorized Uses.</strong> You will not, and will not permit any third party to: (a) access
                    or attempt to access the Technology except as expressly provided in this Agreement; (b) use the
                    Technology in any unlawful manner or in any other manner that could damage, disable, overburden, or
                    impair the Technology; (c) use automated scripts to collect information from or otherwise interact
                    with the Technology; (d) alter, modify, reproduce, create derivative works of the Technology; (e)
                    distribute, sell, resell, lend, loan, lease, license, sublicense or transfer any of Your rights to
                    access or use the Technology or otherwise make the Technology available to any third party; (f)
                    reverse engineer, disassemble, decompile, or otherwise attempt to derive the method of operation of
                    the Technology; (g) attempt to circumvent or overcome any technological protection measures intended
                    to restrict access to any portion of the Technology; (h) monitor the availability, performance or
                    functionality of the Technology; (i) interfere with the operation or hosting of the Technology;(j)
                    disseminate on a Site any viruses, worms, spyware, adware, or other malicious computer code, file or
                    program that is harmful or invasive or is intended to damage or hijack the operation of, or monitor
                    the use of, any hardware, software or equipment; or (k) attempt to bypass security protections on
                    our Sites, introduce viruses or other harmful code, or use our Sites to attack other websites or
                    services.
                  </li>
                </ol>
              </li>
              <li>
                <strong>OWNERSHIP.</strong> PING or its parent company retain all right, title, and interest, including,
                without limitation, all IPR, in, to, and under the Technology and any additions, improvements, updates,
                and modifications thereto. You receive no ownership interest in, to, or under the Technology and You are
                not granted any right or license to use the Technology itself, apart from Your ability to access the
                Platform, Content, and Services under this Agreement. For purposes of this Agreement, “IPR” means all
                intellectual property rights, proprietary rights, rights of publicity, rights of privacy, and any and
                all other legal rights protecting data, information or intangible property throughout the world,
                including, without limitation, any and all copyrights, trademarks, service marks, trade secrets, patent
                rights, moral rights, sui generis rights, and contract rights. PLEASE NOTE THAT UNAUTHORIZED USE OF THE
                TECHNOLOGY (INCLUDING WITHOUT LIMITATION ANY SOFTWARE USED TO MAKE THE TECHNOLOGY AVAILABLE) MAY SUBJECT
                YOU TO MONETARY DAMAGES AND OTHER CIVIL AND CRIMINAL PENALTIES INCLUDING WITHOUT LIMITATION, FOR
                COPYRIGHT INFRINGEMENT.
              </li>
              <li>
                <strong>PING MARKS.</strong> PING or its parent company, and their respective licensors or suppliers,
                own the trade names, trademarks, and service marks on the Technology, including without limitation
                “PING”, the PING logo, and any related names and marks. All trademarks and service marks on the
                Technology not owned by PING or its parent company are the property of their respective owners. You may
                not use our trade names, trademarks, or service marks in connection with any product or service that is
                not ours, or in any manner that is likely to cause confusion. Nothing contained herein or on the
                Technology should be construed as granting any license or right to use any trade names, trademarks, or
                service marks without express prior written consent of PING.
              </li>
              <li>
                <strong>REPRESENTATIONS AND WARRANTIES.</strong>{' '}
                <ol>
                  <li>
                    <strong>Mutual.</strong> Each party hereby represents and warrants to the other party that: (a) it
                    has the legal right and authority to enter into this Agreement; (b) this Agreement forms a binding
                    legal obligation on behalf of such party; and (c) it has the legal right and authority to perform
                    its obligations under this Agreement and to grant the rights and licenses described in this
                    Agreement.
                  </li>
                  <li>
                    <strong>Compliance With Laws.</strong> You acknowledge that the Platform is a general purpose online
                    service and is not specifically designed to facilitate compliance with any specific Law. Your use of
                    the Platform in compliance with any specific Law applicable to You, or to Your Content or other data
                    or information You may provide or generate through the Platform, is Your sole responsibility. PING
                    is not responsible for enabling Your compliance with any such Law or for Your failure to comply. You
                    represent and warrant to PING that Your use of and access to the Platform, including, without
                    limitation, Your Content and any other data or information You may provide or generate through Your
                    use of or access to the Platform, will comply with all Laws and will not cause PING itself to
                    violate any Laws.
                  </li>
                </ol>
              </li>
              <li>
                <strong>NO WARRANTIES; DISCLAIMER.</strong> THE PLATFORM, CONTENT, AND SERVICES ARE PROVIDED “AS IS” AND
                “AS AVAILABLE.” PING AND ITS PROVIDERS DO NOT WARRANT OR GUARANTEE THE ACCURACY, COMPLETENESS, ADEQUACY,
                OR CURRENCY OF ANY CONTENT OR SERVICES, AND DO NOT ENDORSE THE VIEWS OR OPINIONS THAT MAY BE EXPRESSED
                IN THE CONTENT OR OTHER DATA OR INFORMATION PROVIDED THROUGH THE PLATFORM. PING AND ITS PROVIDERS
                EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH REGARD TO THE PLATFORM,
                CONTENT, SERVICES, AND OTHER SUBJECT MATTER OF THIS AGREEMENT, WHETHER EXPRESS, IMPLIED OR STATUTORY,
                INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY,
                TITLE, OR NON-INFRINGEMENT. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY PING, ITS EMPLOYEES,
                PROVIDERS OR AGENTS WILL INCREASE THE SCOPE OF, OR CREATE ANY NEW WARRANTIES IN ADDITION TO THE
                WARRANTIES EXPRESSLY SET FORTH IN THIS AGREEMENT.
                <br />
                <br />
                PING AND ITS PROVIDERS MAKE NO WARRANTY THAT: (a) THE PLATFORM WILL MEET YOUR REQUIREMENTS; (b) THE
                PLATFORM WILL BE FREE OF COMPUTER VIRUSES, MALWARE, OR SIMILAR CYBER THREATS; (c) THE PLATFORM WILL BE
                UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (d) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
                PLATFORM WILL BE ACCURATE OR RELIABLE; (e) THE QUALITY OF ANY CONTENT OR SERVICES OBTAINED THROUGH THE
                PLATFORM WILL MEET YOUR EXPECTATIONS; (e) THE PLATFORM WILL NOT INCLUDE ANY TYPOGRAPHICAL OR OTHER
                ERRORS; OR (f) ANY ERRORS IN THE PLATFORM WILL BE CORRECTED. ANY CONTENT OR SERVICES OBTAINED THROUGH
                THE USE OF THE PLATFORM IS OBTAINED AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR
                ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
              </li>
              <li>
                <strong>INDEMNITY.</strong> You hereby agree to indemnify, defend, and hold harmless PING and its parent
                company, officers, directors, shareholders, affiliates, employees, agents, contractors, assigns, Users,
                customers, providers, licensees, and successors in interest (“Indemnified Parties”) from any and all
                claims, losses, liabilities, damages, fees, expenses and costs (including attorneys’ fees, court costs,
                damage awards, and settlement amounts) that result from any claim or allegation against any Indemnified
                Party arising in any manner from: (a) Your access to or use of the Products, Platform, Content, or
                Services; (b) Your Content or other data, information, or Product Comments that You access or provide
                through the Platform; and (c) Your breach of any representation, warranty, or other provision of this
                Agreement. PING will provide You with notice of any such claim or allegation, and PING will have the
                right to participate in the defense of any such claim at its expense.
              </li>
              <li>
                <strong>LIMITATION ON LIABILITY.</strong> PING AND ITS THIRD PARTY PROVIDERS WILL NOT BE LIABLE FOR ANY
                INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, UNDER ANY THEORY OF
                LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE), ARISING
                IN CONNECTION WITH OR OUT OF YOUR PURCHASE OF PRODUCTS THE USE OF THE PLATFORM, CONTENT, OR SERVICES,
                EVEN IF PING HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY
                LOSS OF YOUR CONTENT, OPPORTUNITY, REVENUES OR PROFITS, BUSINESS INTERRUPTION, OR PROCUREMENT OF
                SUBSTITUTE GOODS OR SERVICES. PING’S TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THIS AGREEMENT AND
                ALL SERVICES PROVIDED UNDER THIS AGREEMENT, WHETHER IN CONTRACT OR TORT OR OTHERWISE, WILL NOT EXCEED
                $100 OR, IN THE CASE OF ANY ORDERS YOU MAKE THROUGH THE PLATFORM, THE AMOUNTS PAID BY YOU TO PING FOR
                THOSE PURCHASES IN THE 3 MONTH PERIOD PRECEDING THE EVENTS GIVING RISE TO ANY LIABILITY. IN
                JURISDICTIONS WHERE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES IS NOT PERMITTED,
                PING’S LIABILITY IS LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW. YOU AGREE THAT THIS LIMITATION OF
                LIABILITY IS ESSENTIAL TO THIS AGREEMENT AND THAT PING WOULD NOT PROVIDE YOU WITH PRODUCTS, SERVICE,
                CONTENT, OR ACCESS TO THE PLATFORM IN ITS ABSENCE.
              </li>
              <li>
                <strong>FOR NEW JERSEY RESIDENTS ONLY.</strong> If you are a consumer residing in New Jersey, the
                following provisions of this Agreement do not apply to you (and do not limit any rights that you may
                have) to the extent that they are unenforceable under New Jersey law: (a) “No Warranties; Disclaimer”;
                (b) “Indemnity”; (c) Limitation of Liability; and (d) “Disputes: Arbitration and Class Action Waiver”
                and the governing law provisions (solely to the extent that your rights as a consumer residing in New
                Jersey are required to be governed by New Jersey law). According to N.J.S.A. 56:12-16, you may have
                additional rights if you are a New Jersey resident and other provisions of this Agreement are found to
                violate an established legal right.
              </li>
              <li>
                <strong>DATA PRIVACY.</strong> PING’s data collection and storage practices are described in our Privacy
                Policy, linked at the beginning of these Terms. Notwithstanding anything in the Privacy Policy, PING
                will have the right to collect, extract, compile, synthesize, and analyze any non-personally
                identifiable data or information (i.e., anonymized data or information that does not identify an entity
                or natural person as the source thereof) resulting from Your access to and use of the Platform, Content,
                or Services and Your purchase of Products. To the extent any such non-personally identifiable data or
                information is collected or generated by PING, the data and information will be solely owned by PING and
                may be used by PING for any lawful business purpose without a duty of accounting to You, provided that
                the data and information is used only in an aggregated form, without directly identifying You or any
                other entity or natural person as the source thereof.
              </li>
              <li>
                <strong>IMPROVEMENTS AND FEEDBACK.</strong> You shall, and hereby do, assign to PING Your entire right,
                title and interest (including, without limitation, all patent rights, design rights, copyrights and
                trade secrets) in any modifications or improvements to the Platform which You may propose or make at any
                time (<strong>“Improvements”</strong>). You shall, and hereby do, assign to PING Your entire right,
                title, and interest in any feedback You provide to PING regarding the Platform, including but not
                limited to its usability, bug reports, and testing results (collectively, <strong>“Feedback”</strong>).
                PING may use and otherwise exploit the Feedback and Improvements as it wishes with no compensation to
                You. In the event that any of Your rights with respect to any Improvement or Feedback are not fully
                assignable or otherwise transferable to PING for any reason whatsoever, You shall, and hereby do, grant
                to PING an exclusive, irrevocable, perpetual, royalty-free, fully paid-up worldwide license in and to
                all of Your rights with respect to such Improvement or Feedback and all IPR therein. You shall notify
                PING of any Improvement within a reasonable time after its development and provide PING with access to
                all information concerning such Improvement as PING shall reasonably request.
              </li>
              <li>
                <strong>GOVERNING LAW AND VENUE.</strong> You hereby agree that this Agreement (and any claim or dispute
                arising in connection with this Agreement or Your use of the Platform) is governed by and shall be
                construed in accordance with the laws of the State of Arizona, U.S.A., without regard to its principles
                of conflicts of law, and You consent to the exclusive jurisdiction of the federal and state courts
                located in Maricopa County, Arizona, U.S.A., and waive any jurisdictional, venue or inconvenient forum
                objections thereto. You further agree that the United Nations Convention on the International Sale of
                Goods does not apply to this Agreement.
              </li>
              <li>
                <strong>DISPUTES: ARBITRATION AND CLASS ACTION WAIVER.</strong> <br />
                <br />
                <strong>
                  PLEASE READ THIS SECTION CAREFULLY—IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT
                  TO FILE A LAWSUIT IN COURT.
                </strong>
                <br />
                <br />
                ARBITRATION USES A NEUTRAL ARBITRATOR INSTEAD OF A JUDGE OR JURY, ALLOWS FOR REASONABLE, BUT MORE
                LIMITED DISCOVERY THAN IN COURT, AND IS SUBJECT TO VERY LIMITED REVIEW BY COURTS. YOU MAY CHOOSE TO BE
                REPRESENTED BY A LAWYER IN ARBITRATION OR PROCEED WITHOUT ONE. THIS ARBITRATION PROVISION SHALL SURVIVE
                TERMINATION OF THIS AGREEMENT.
                <br />
                <br />
                Any dispute, claim or controversy arising out of or relating to this Agreement, other agreements on the
                Site, or the breach, termination, enforcement, interpretation, or validity thereof, including the
                determination of the scope or applicability of this agreement to arbitrate, shall be either determined
                by binding arbitration in Phoenix, Arizona before one arbitrator or submitted to small claims court in
                Phoenix, Arizona. If the arbitrator finds this location to be unreasonably burdensome to you, a new
                location may be selected or arbitration may be conducted over the phone, using video conferencing, or
                similar. You may be entitled to an in-person hearing near your place of residence. Judgment on the award
                may be entered in any court having jurisdiction. This clause shall not preclude parties from seeking
                provisional remedies in aid of arbitration from a court of appropriate jurisdiction. Any arbitration
                arising out of or related to this Agreement shall be conducted in accordance with the expedited
                procedures set forth in the JAMS Comprehensive Arbitration Rules and Procedures as those Rules exist on
                the effective date of this Agreement, including Rules 16.1 and 16.2 of those Rules.
                <ol>
                  <li>
                    <strong>
                      NO CLASS ACTIONS: YOU AND WE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR
                      OUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                      REPRESENTATIVE PROCEEDING.
                    </strong>{' '}
                    Further, unless both You and we agree otherwise, the arbitrator may not consolidate more than one
                    person’s claims with your claims and may not otherwise preside over any form of a representative or
                    class proceeding. If this specific provision is found to be unenforceable, then the entirety of this
                    arbitration provision shall be null and void. The arbitrator may award declaratory or injunctive
                    relief only in favor of the individual party seeking relief and only to the extent necessary to
                    provide relief warranted by that party’s individual claim.
                  </li>
                  <li>
                    <strong>Arbitration:</strong> Any arbitration will be administered by JAMS and conducted under The
                    Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (“JAMS
                    Rules”) then in effect at the time of the dispute. You may obtain copies of the JAMS Rules and forms
                    and instructions for initiating arbitration by visiting the JAMS website at www.jamsadr.com. If for
                    any reason JAMS is unavailable, the parties shall mutually select another arbitration forum. If You
                    elect to seek arbitration or file a small claim court action, You must first send to us, by
                    certified mail, a written notice of your claim (“Notice”). The Notice to us must be addressed to:
                    PING, Inc., 2201 West Desert Cove Avenue, Phoenix, AZ 85029. If we initiate arbitration, we will
                    send a written Notice to an email address You have previously provided to us, if available. We may
                    also use any other means to contact you, including a message in your Account. A Notice, whether sent
                    by You or by us, must (a) describe the nature and basis of the claim or dispute; and (b) set forth
                    the specific relief sought (“Demand”). If You and we do not reach an agreement to resolve the claim
                    within thirty (30) days after the Notice is received, You or us may commence an arbitration
                    proceeding or file a claim in small claims court. Arbitration forms can be downloaded from
                    www.jamsadr.com. If You are required to pay a filing fee, after we receive Notice that You have
                    commenced arbitration, we will promptly reimburse You for your payment of the filing fee, unless
                    your claim is for greater than US$10,000 or the arbitrator determines the claims are frivolous, in
                    which event You will be responsible for filing fees.
                  </li>
                  <li>
                    <strong>Hearing:</strong> If your claim is for US$10,000 or less, we agree that You may choose
                    whether the arbitration will be conducted solely on the basis of documents submitted to the
                    arbitrator, through a telephonic or video hearing, or by an in-person hearing as established by the
                    JAMS Rules. If your claim exceeds US$10,000, the right to a hearing will be determined by the JAMS
                    Rules. In the event that the arbitration will be conducted solely on the basis of submitted
                    documents, the arbitrator’s decision and award will be made and delivered within six (6) months of
                    the selection of the arbitrator, unless extended by the arbitrator. Except as expressly set forth
                    herein, the payment of all filing, administration and arbitrator fees will be governed by the JAMS
                    Rules.
                  </li>
                  <li>
                    <strong>AWARD:</strong> IN THE EVENT ARBITRATION AWARDS YOU DAMAGES OF AN AMOUNT AT LEAST $100
                    GREATER THAN OUR LAST DOCUMENTED SETTLEMENT OFFER WE WILL PAY YOUR AWARDED DAMAGES OR $2,500,
                    WHICHEVER IS GREATER.
                  </li>
                  <li>
                    <strong>Injunctive Relief:</strong> Notwithstanding the foregoing, You and we both agree that You or
                    we may sue in court to enjoin infringement or other misuse of intellectual property rights or in
                    other scenarios where injunctive relief is appropriate. In the event a court or arbitrator having
                    jurisdiction finds any portion of this Agreement unenforceable, that portion shall not be effective
                    and the remainder of the Agreement shall remain effective. No waiver, express or implied, by either
                    party of any breach of or default under this Agreement will constitute a continuing waiver of such
                    breach or default or be deemed to be a waiver of any preceding or subsequent breach or default.
                  </li>
                  <li>
                    <strong>Arbitration Confidentiality:</strong> The parties shall maintain the confidential nature of
                    the arbitration proceeding and the Award, including the hearing, except as may be necessary to
                    prepare for or conduct the arbitration hearing on the merits, or except as may be necessary in
                    connection with a court application for a preliminary remedy, a judicial challenge to an award or
                    its enforcement, or unless otherwise required by law or judicial decision.
                  </li>
                  <li>
                    <strong>Arbitration: Governing Law and Rules:</strong> As noted above in Section 25, this Agreement
                    and the rights of the parties hereunder shall be governed by and construed in accordance with the
                    laws of Arizona, exclusive of conflict or choice of law rules. The parties acknowledge that this
                    Agreement evidences a transaction involving interstate commerce. Notwithstanding the provision in
                    the preceding paragraph with respect to applicable substantive law, any arbitration conducted
                    pursuant to the terms of this Agreement shall be governed by the Federal Arbitration Act (9 U.S.C.,
                    Secs. 1-16). In any arbitration arising out of or related to this Agreement, the arbitrator is not
                    empowered to award punitive or exemplary damages, except where permitted by statute, and the parties
                    waive any right to recover any such damages. In any arbitration arising out of or related to this
                    Agreement, the arbitrator may not award any incidental, indirect or consequential damages, including
                    damages for lost profits. The parties adopt and agree to implement the JAMS Optional Arbitration
                    Appeal Procedure (as it exists on the effective date of this Agreement) with respect to any final
                    award in an arbitration arising out of or related to this Agreement.
                  </li>
                </ol>
              </li>
              <li>
                <strong>NOTICES.</strong> Unless otherwise specified in this Agreement, any notices required or allowed
                under this Agreement will be provided to PING by postal mail to the address for PING listed on the
                Platform. PING may provide You with any notices required or allowed under this Agreement by sending You
                an email to any email address You provide to PING in connection with Your Account, provided that in the
                case of any notice applicable both to You and other Users of the Platform, PING may instead provide such
                notice by posting on the Platform. Notices provided to PING will be deemed given when actually received
                by PING. Notice provided to You will be deemed given 24 hours after posting to the Platform or sending
                via e-mail, unless (as to e-mail) the sending party is notified that the e-mail address is invalid.
              </li>
              <li>
                <strong>LINKED SITES AND LINKING TO PING’S SITES.</strong> The Platform may contain links to third party
                sites or Content that are not under the control of PING. If You access a third party site or any Content
                from the Platform, then You do so at Your own risk, and PING is not responsible for any content on any
                linked site or linked content. You may not frame or otherwise incorporate into another site the Content
                or other materials on the Platform without PING’s prior written consent.
              </li>
              <li>
                <strong>DMCA AND CLAIMS OF INFRINGEMENT.</strong> It is our policy to expeditiously respond to notices
                of alleged copyright infringement that comply with the United States Digital Millennium Copyright Act
                (“DMCA”). PING respects Your copyrights and other intellectual property rights and those of other third
                parties. If You believe in good faith that Your copyrighted work has been reproduced on the Platform
                without Your authorization in a way that constitutes copyright infringement, You may notify our
                compliance department by mail to:
              </li>
            </ol>
            <address>
              PING, Inc.
              <br />
              Attn: PING Compliance
              <br />
              2201 West Desert Cove Avenue
              <br />
              Phoenix, AZ 85029
              <br />
              compliance@ping.com
              <br />
              602-687-5000
            </address>
            <br />
            <p>
              Please provide the following information to PING Compliance: (a) the identity of the infringed work, and
              of the allegedly infringing work; (b) Your name, address, daytime phone number and email address, if
              available; (c) a statement that You have a good-faith belief that the use of the copyrighted work is not
              authorized by the owner, his or her agent, or the law; (d) a statement that the information in the
              notification is accurate and, under penalty of perjury, that You are authorized to act on behalf of the
              owner; and (e) Your electronic or physical signature.
            </p>
            <p>
              <strong>TERMINATION FOR REPEAT INFRINGEMENT AND COUNTER NOTICES.</strong> We may also terminate users who,
              in our sole discretion, are deemed to be repeat infringers. Knowinglymisrepresenting in a notification
              that material is infringing can subject You to damages, including costs and attorneys’ fees, incurred by
              us or the alleged infringer. If You receive an infringement notification from us, You may file a counter
              notification with our Designated Agent pursuant to the DMCA. To file a counter notification, please
              provide our Designated Agent with the following information:
              <ul>
                <li>
                  Identification of the material that has been removed or to which access has been disabled and the
                  location at which the material appeared before it was removed or access was disabled;
                </li>
                <li>Your name, mailing address, telephone number and email address;</li>
                <li>
                  The following statement: “I consent to the jurisdiction of Federal District Court for the District of
                  Arizona”;
                </li>
                <li>
                  The following statement: “I will accept service of process from [insert the name of the person who
                  submitted the infringement notification] or his/her agent”;
                </li>
                <li>
                  The following statement: “I swear, under penalty of perjury, that I have a good faith belief that the
                  affected material was removed or disabled as a result of a mistake or misidentification of the
                  material to be removed or disabled”; and
                </li>
                <li>Your signature, in physical or electronic form.</li>
              </ul>
            </p>
            <br />
            <p>
              Upon receipt of valid counter notification, we will promptly provide the person who provided the original
              infringement notification with a copy of your counter notification and inform that person that we will
              replace the removed material or cease disabling access to it in ten (10) business days. Further, we will
              replace the removed material and cease disabling access to it not less than ten (10), nor more than
              fourteen (14), business days following receipt of your counter notice, unless Designated Agent first
              receives notice from the person who submitted the original infringement notification that such person has
              filed an action seeking a court order to restrain You from engaging in infringing activity relating to the
              material on the Site.
            </p>
            <ol className="tou-bottom-ol">
              <li>
                <strong>FILTERING AND PARENTAL CONTROLS.</strong> Pursuant to 47 U.S.C. Section 230(d), as amended, PING
                hereby is notifying You that parental control protections (such as computer hardware, software, or
                filtering services) are commercially available that may assist You in limiting access to material that
                is harmful to minors. Information identifying current providers of such protections is available on the
                following website: OnGuard Online (http://onguardonline.gov/). Note that PING does not endorse, support,
                or approve any of the products or services listed at such website.
              </li>
              <li>
                <strong>EQUITABLE RELIEF.</strong> You agree that any use of the Platform in breach of this Agreement
                will cause PING irreparable harm for which monetary damages is not an adequate remedy; in such event,
                PING will be entitled to injunctive or other equitable relief in any court of competent jurisdiction
                without prejudice to any other right or remedy and without posting a bond or proving actual damages.
              </li>
              <li>
                <strong>U.S. GOVERNMENT.</strong> The Platform is a “commercial item” as that term is defined at FAR
                2.101 (Oct 1995), consisting of “commercial computer software” and “commercial computer software
                documentation” as such terms are used in 48 C.F.R. 12.212 (Sep 1995) and is provided to the U.S.
                Government only as a commercial end item. Consistent with FAR. 12.212 and DFARS 227.7202 (Jun 1995), all
                U.S. Government Users acquire the Platform with only those rights set forth herein.
              </li>
              <li>
                <strong>EXPORT.</strong> The Platform and related Technology are subject to U.S. export control laws and
                may be subject to export or import regulations in other countries. You agree to strictly comply with all
                such laws and regulations and acknowledge that You have the responsibility to obtain such licenses to
                export, re-export, or import as may be required.
              </li>
              <li>
                <strong>ADDITIONAL TERMS.</strong>{' '}
                <ol>
                  <li>
                    <strong>Exclusivity.</strong> Unless otherwise amended as provided herein, this Agreement will
                    exclusively govern Your access to and use of the Platform, Content, and Services, and is the
                    complete and exclusive understanding and agreement between the parties, and supersedes any oral or
                    written proposal, agreement, or other communication between the parties, regarding Your access to
                    and use of the Platform, Content, and Services.
                  </li>
                  <li>
                    <strong>Amending and Modifying the Agreement.</strong> Except as expressly set forth in this
                    Agreement, this Agreement may be amended or modified only by a writing signed by both parties. All
                    waivers by PING under this Agreement must be in writing or later acknowledged by PING in writing.
                  </li>
                  <li>
                    <strong>No Waiver of Rights by PING.</strong> Any waiver or failure by PING to enforce any provision
                    of this Agreement will not be deemed a waiver by PING of any other provision or of such provision on
                    any other occasion.
                  </li>
                  <li>
                    <strong>Severability.</strong> If any provision of this Agreement is held to be unenforceable, that
                    provision will be removed to the extent necessary to comply with the law, replaced by a provision
                    that most closely approximates the original intent and economic effect of the original to the extent
                    consistent with the law. Notwithstanding the unenforceability of any such provision, or the ability
                    or inability to substitute that provision with another close approximation, the remaining provisions
                    shall remain in full force.
                  </li>
                  <li>
                    <strong>Consent to Communication.</strong> When you use a Site or send communications to us through
                    a Site, you are communicating with us electronically. You consent to receive electronically any
                    communications related to your use of a Site. We may communicate with you by email or by posting
                    notices on the Site. You agree that all agreements, notices, disclosures, and other communications
                    that are provided to you electronically satisfy any legal requirement that such communications be in
                    writing. All notices from us intended for receipt by you shall be deemed delivered and effective
                    when sent to the email address you provide to us. Please note that by submitting User Content,
                    creating a Registered User Account, or otherwise providing us with your email address, postal
                    address, or phone number, you are agreeing that we or our agents may contact you at that address or
                    number in a manner consistent with our Privacy Policy.
                  </li>
                  <li>
                    <strong>Recovery of Costs from Legal Disputes.</strong> The prevailing party in any lawsuit or
                    proceeding arising from or related to this Agreement will be entitled to receive its costs, expert
                    witness fees and reasonable attorneys’ fees, including costs and fees on appeal.
                  </li>
                  <li>
                    <strong>No Assignment of Rights to You.</strong> Neither this Agreement nor any rights or
                    obligations of You hereunder may be assigned or transferred by You (in whole or in part and
                    including by sale, merger, consolidation, or other operation of law) without the prior written
                    approval of PING. Any assignment in violation of the foregoing will be null and void. PING may
                    assign this Agreement to any party that assumes PING’s obligations hereunder.
                  </li>
                  <li>
                    <strong>Parties are Independent.</strong> The parties hereto are independent parties, not agents,
                    employees, or employers of the other or joint venturers, and neither acquires hereunder any right or
                    ability to bind or enter into any obligation on behalf of the other.
                  </li>
                  <li>
                    <strong>References to You and Your Use of PING.</strong> PING may reference You as a User of the
                    Platform and use Your name and logo, as applicable, in listings of Users of the Platform appearing
                    on the Platform and for other marketing and promotional purposes relating to the Platform, Services,
                    or Content.
                  </li>
                  <li>
                    <strong>No Conferral of Rights.</strong> Except for third parties as stated in this Agreement,
                    nothing in this Agreement express or implied, is intended to or shall confer on any person (other
                    than the parties and their respective successors and permitted assigns) any rights, remedies,
                    obligations, or liabilities.
                  </li>
                </ol>
              </li>
              <li>
                <strong>SUPPLEMENTAL TERMS FOR APPLE.</strong> Notwithstanding anything set forth above, if You obtained
                a license to the Application through the Apple App Store, the following additional terms and conditions
                apply:
                <ol>
                  <li>
                    <strong>Acknowledgement:</strong> You acknowledge that this Agreement is between You and PING, not
                    Apple—and that PING, not Apple, is solely responsible for the Application and the content thereof.
                  </li>
                  <li>
                    <strong>Scope of License:</strong> For the avoidance of doubt, the license granted herein is solely
                    for Your use on any Apple-branded products that You own or control as permitted by this Agreement
                    and the Usage Rules set forth in the App Store Agreement.
                  </li>
                  <li>
                    <strong>Maintenance and Support:</strong> You acknowledge that Apple will not have any obligation
                    whatsoever to provide any maintenance or support with respect to the Application.
                  </li>
                  <li>
                    <strong>Warranty:</strong> You agree that PING shall be solely responsible for any product
                    warranties, whether express or implied by law, to the extent not effectively disclaimed in this
                    Agreement. In the event of any failure of the Application to conform to any applicable warranty, You
                    have the right to notify Apple, and Apple shall refund the purchase price for the Application to
                    You. You acknowledge that, to the maximum extent permitted by applicable law, Apple has no other
                    warranty obligation whatsoever with respect to the Application, and any other claims, losses,
                    liabilities, damages, costs, or expenses attributable to any failure to conform to any warranty will
                    be the sole responsibility of PING, to the extent applicable.
                  </li>
                  <li>
                    <strong>Product Claims:</strong> Both parties hereto agree that PING, not Apple, is responsible for
                    addressing any claims made by You or any third party relating to the Application or Your possession
                    and/or use of the Application, including, but not limited to: (i) product liability claims; (ii) any
                    claim that the Application fails to conform to any applicable legal or regulatory requirement, and
                    (iii) claims arising under consumer protection or similar legislation.
                  </li>
                  <li>
                    <strong>Intellectual Property Rights:</strong> Both parties hereto acknowledge that, in the event of
                    any third party claim that the Application or Your possession and use of the Application infringes
                    such third party’s intellectual property rights, PING, not Apple, will solely be responsible for the
                    investigation, defense, settlement, and discharge of any such intellectual property infringement
                    claim.
                  </li>
                  <li>
                    <strong>Legal Compliance:</strong> You represent and warrant that: (i) You are not located in a
                    country that is subject to a U.S. Government embargo, or that has been designated by the U.S.
                    Government as a “terrorist supporting” country, and (ii) You are not listed on a U.S. Government
                    list of prohibited or restricted parties.
                  </li>
                  <li>
                    <strong>Developer Name and Address:</strong> PING’s contact information for any questions,
                    complaints, or claims with respect to the Application is set forth in this Agreement.
                  </li>
                  <li>
                    <strong>Third Party Terms of Agreement:</strong> You must comply with all applicable third party
                    terms of agreement when using the Application, e.g., You must not be in violation of Your wireless
                    data service when using the Application.
                  </li>
                  <li>
                    <strong>Third Party Beneficiary:</strong> Both parties acknowledge and agree that Apple and its
                    subsidiaries are third party beneficiaries of this Agreement, and that, upon Your acceptance of the
                    terms and conditions of this Agreement, Apple will have the right (and will be deemed to have
                    accepted the right) to enforce this Agreement against You as a third party beneficiary hereof.
                  </li>
                  <li>
                    <strong>Apple Minimum Terms Control:</strong> You acknowledge and agree that if any of the terms and
                    conditions of this Agreement are inconsistent or in conflict with Apple’s applicable Instructions
                    for Minimum Terms for Developer’s End User License Agreement, the terms and conditions of Apple’s
                    Instructions for Minimum Terms of Developer’s End User License Agreement shall apply.
                  </li>
                </ol>
              </li>
              <li>
                <strong>FOR INTERNATIONAL USERS.</strong> Each claim or statement about the effectiveness of our
                products or comparing the effectiveness of our products is expressly limited to the United States. The
                products referred to on Sites may only be available in the territory to which that Site is directed and
                may not be available in Your country. WE MAKE NO REPRESENTATION THAT THE INFORMATION AND MATERIALS ON
                ANY SITE, INCLUDING WITHOUT LIMITATION THE INFORMATION AND OTHER MATERIALS PROMOTING THE PRODUCTS
                IDENTIFIED ON THAT SITE, ARE APPROPRIATE OR AVAILABLE FOR USE IN OTHER LOCATIONS OTHER THAN THE LOCATION
                FOR WHICH THE SITE IS DIRECTED. WE DO NOT REPRESENT OR WARRANT THAT A SITE OR ANY PART THEREOF IS
                APPROPRIATE OR AVAILABLE FOR USE IN ANY PARTICULAR JURISDICTION OTHER THAN THE UNITED STATES. Those who
                choose to access a Site do so on their own initiative and at their own risk, and are responsible for
                complying with all local statutes, orders, regulations, rules, and other laws. You are also subject to
                United States export controls and are responsible for any violations of such controls, including without
                limitation any United States embargoes or other federal rules and regulations restricting exports.
              </li>
            </ol>
          </div>
        </div>
      </ScrollToTop>
    );
  }
}

export default TermsOfUsePage;
