import React from 'react';
import Svg, { Path } from 'svgs';

const TriangleUp = ({ size, fill }) => {
  return (
    <Svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'currentColor'}
    >
      <Path d="M7 14l5-5 5 5z" />
    </Svg>
  );
};

export default TriangleUp;
